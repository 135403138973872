var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { CurrentUserFactory } from './CurrentUserFactory';
import { CurrentCompanyFactory } from './CurrentCompanyFactory';
import { LogIn, CheckIfLoggedIn, LogOut } from '../graphql/auth';
import { AsyncActionStatus, BaseUser, BaseUserAppTYpe, MaybeEmptyModel } from './common';
import browserHistory from '../browserHistory';
import { FetchOrderToDoStats, FetchUser, UpdateCurrentUser } from '../graphql';
import { toast } from 'react-toastify';
import LangController, { i18n, KEYS } from '../i18n';
import { FetchEmailTemplates, UpdateEmailTemplate } from '../graphql/email.templates';
import { globalUtils, i18nAddLang, IS_DEV_ENV, sleep } from '../utils';
import { SOUND_KEY } from '../components/Sounds';
import { OrderStatus } from '../models';
import { browserStorage } from '../browserStorage';
import { langOptions } from '../i18n/utils';
import { FetchAllLang, FetchAllLangs } from '../graphql/langs';
import { FetchLogs } from '../graphql/logs';
import { CreateUserSubscription } from '../graphql/userSubscriptions';
import { notificationRequest } from '../components/NotificationRequest';
import { cloneDeep } from '../lodashUtils';
import { googleTagManager } from '../utils1';
import moment from 'moment';
import { clearZendesk } from '../components/ReportIssue/zendesk';
import { OrderPreparingStatuses } from '../models/OrderPreparingStatuses';
import { OrderVehicleType } from '../models/OrderVehicleType';
var APP_TYPE = process.env.REACT_APP_TYPE;
var idleSeconds = 60 * 20;
var idleTimer;
function resetTimer(_a) {
    var store = _a.store;
    clearTimeout(idleTimer);
    if (store.isWatingScreen)
        store.changeWaitingScreen(false);
    idleTimer = setTimeout(function () {
        store.changeWaitingScreen(true);
        // window.location.href = '/waitingscreen';
        browserHistory.push('/waitingscreen');
    }, idleSeconds * 1000);
}
function attachEvent(obj, evt, fnc, useCapture) {
    if (useCapture === void 0) { useCapture = false; }
    if (obj.addEventListener) {
        obj.addEventListener(evt, fnc, !!useCapture);
        return true;
    }
    else if (obj.attachEvent) {
        return obj.attachEvent('on' + evt, fnc);
    }
}
var CAN_REDIRECT = process.env.REACT_APP_IGNORE_REDIRECT;
export var LanguagesModel = function (_a) {
    var client = _a.client;
    return types.compose(AsyncActionStatus, types.model('LanguagesModel', {
        id: types.optional(types.string, ''),
        name: types.optional(types.string, ''),
        code: types.maybeNull(types.optional(types.string, '')),
        indexId: types.maybeNull(types.optional(types.number, 0)),
        isActive: types.maybeNull(types.optional(types.boolean, false)),
    }));
};
var EnvModel = function (_a) {
    var client = _a.client;
    return types.compose(AsyncActionStatus, types.model('LanguagesModel', {
        name: types.optional(types.string, ''),
        value: types.optional(types.string, ''),
    }));
};
var TourStepModel = function (_a) {
    var client = _a.client;
    return types.compose(AsyncActionStatus, types.model('TourStepModel', {
        selector: types.optional(types.string, ''),
        type: types.optional(types.string, ''),
    }));
};
function setAppMomentLocale(lang) {
    var _lang = lang || browserStorage.getItem('lang') || 'en';
    if (!['en'].includes(lang))
        require("moment/locale/" + lang + ".js");
    moment.locale(_lang);
}
export var AccountStoreFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(AsyncActionStatus, types.model({
        preparingStatuses: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderPreparingStatuses)), function () { return []; })),
        orderVehicleTypes: types.maybeNull(types.optional(types.array(MaybeEmptyModel(OrderVehicleType)), function () { return []; })),
        showSupport: types.optional(types.boolean, true),
        totalToDo: types.optional(types.number, 0),
        totalToDoRatingCa: types.optional(types.number, 0),
        isChecked: types.optional(types.boolean, false),
        isWatingScreen: types.optional(types.boolean, false),
        loadingLogs: types.optional(types.boolean, false),
        isEmailTemplateLoading: types.optional(types.boolean, false),
        isEmailTemplateUpdate: types.optional(types.boolean, false),
        isSettingsWizardVisible: types.optional(types.boolean, false),
        isSettingsWizardClosed: types.optional(types.boolean, false),
        isToDoClosed: types.optional(types.boolean, false),
        globalTourSteps: types.optional(types.array(MaybeEmptyModel(TourStepModel({ client: client }))), []),
        isToDoCARatingClosed: types.optional(types.boolean, false),
        menuIsOpened: types.optional(types.boolean, false),
        isLoggedOut: types.optional(types.boolean, false),
        isApiLoaded: types.optional(types.number, 0),
        inviteLink: types.optional(types.string, ''),
        inviteCode: types.optional(types.string, ''),
        menuUpdated: types.optional(types.number, Date.now()),
        refreshList: types.optional(types.number, Date.now()),
        languages: types.optional(types.array(MaybeEmptyModel(LanguagesModel({ client: client }))), []),
        env: types.optional(types.array(MaybeEmptyModel(EnvModel({ client: client }))), []),
        tempUser: MaybeEmptyModel(CurrentUserFactory({ client: client })),
        currentUser: MaybeEmptyModel(CurrentUserFactory({ client: client })),
        currentCompany: MaybeEmptyModel(CurrentCompanyFactory({ client: client })),
        currentLocation: types.optional(types.string, ''),
        subscriptionAuthToken: types.optional(types.string, ''),
        refreshSidebarChatListKey: types.optional(types.number, 0),
        checkedToken: types.optional(types.number, 0),
        relatedAppUrls: types.optional(types.map(types.string), {}),
    }))
        .named('AccountStore')
        .actions(function (self) { return ({
        changeWaitingScreen: function (isWatingScreen) {
            self.isWatingScreen = isWatingScreen;
        },
        changeLanguage: flow(function (lang, ignoreUserUpdate) {
            var result, e_1;
            if (ignoreUserUpdate === void 0) { ignoreUserUpdate = false; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, client.query({
                                query: FetchAllLangs,
                                variables: {
                                    filter: { fields: [{ name: 'lang', value: lang }] },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        setAppMomentLocale(lang);
                        return [4 /*yield*/, sleep(500)];
                    case 2:
                        _a.sent();
                        i18nAddLang(JSON.parse(result.data.allLangList.keys));
                        if (i18n.changeLanguage)
                            i18n.changeLanguage(lang);
                        if (!ignoreUserUpdate) {
                            self.currentUser.language = lang;
                            browserStorage.setItem('lang', lang);
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        toast.error(e_1.message || e_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }),
    }); })
        .actions(function (self) { return ({
        onLogin: flow(function (response) {
            var user, LogRocket, locationUrl, newUrl, userSnap, companySnap;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        user = response.user;
                        try {
                            LogRocket = window.LogRocket;
                            LogRocket.identify(user._id, {
                                name: [user.firstName, user.lastName].filter(function (e) { return e; }).join(' '),
                                email: user.email,
                                // Add your own custom user variables here, ie:
                                id: user._id,
                                company: [user.company._id, user.company.name, user.company.vatCode].filter(function (e) { return e; }).join(', '),
                            });
                        }
                        catch (e) { }
                        if (!process.env.REACT_APP_NONE_BRAND && user.portalLinK && !user.portalLinK.match(location.origin)) {
                            if (CAN_REDIRECT || process.env.REACT_APP_NONE_BRAND) {
                            }
                            else {
                                locationUrl = browserStorage.getItem('last-url');
                                if (locationUrl) {
                                    locationUrl = removeURLParameter(locationUrl, 'token');
                                }
                                newUrl = (location.href = "" + user.portalLinK + (locationUrl ? (locationUrl.includes('?') ? locationUrl + '&' : locationUrl + '?') : '/?') + "token=" + response.token.split(' ')[1] + "&client_id=" + (response.izi_client_id || ''));
                                return [2 /*return*/, newUrl];
                            }
                        }
                        if (!(browserStorage.getItem("lang") !== (user === null || user === void 0 ? void 0 : user.language))) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.changeLanguage(user.language)];
                    case 1:
                        _b.sent();
                        setAppMomentLocale(user.language);
                        _b.label = 2;
                    case 2:
                        ['inviteLink', 'inviteCode'].forEach(function (key) {
                            self[(key === null || key === void 0 ? void 0 : key.name) || key] = response[(key === null || key === void 0 ? void 0 : key.key) || key] || '';
                        });
                        browserStorage.setItem("token", response.token);
                        [{ n: 'izi_client_id', k: 'client_id' }, 'booking_token', 'temp'].forEach(function (e) {
                            if (response[e.n || e]) {
                                browserStorage.setItem(e.k || e, response[e.n || e]);
                            }
                            else {
                                browserStorage.removeItem(e.k || e);
                            }
                        });
                        browserStorage.setItem("token", response.token);
                        browserStorage.setItem("user-id", [user._id, user.firstName, user.lastName].filter(function (e) { return e; }).join(', '));
                        browserStorage.setItem("companyId", [user.company._id, user.company.name, user.company.vatCode].filter(function (e) { return e; }).join(', '));
                        browserStorage.setItem("appType", user.company.type);
                        if ((_a = user.forwarders) === null || _a === void 0 ? void 0 : _a.length)
                            browserStorage.setItem('forwarder', user.forwarders.map(function (e) { return [e.company._id, e.company.origin_name].join(', '); }).join(', '));
                        browserStorage.removeItem('booking_access_token');
                        userSnap = __assign({}, cloneDeep(response.user));
                        companySnap = cloneDeep(Object.assign(response.user.company, response.companyAdditionalInfo));
                        self.currentUser.reset(userSnap);
                        self.currentCompany.reset(companySnap);
                        self.relatedAppUrls = response.relatedAppUrls || {};
                        googleTagManager('set', 'user_id', [userSnap._id, userSnap.firstName, userSnap.lastName].filter(function (e) { return e; }).join(', '));
                        googleTagManager('set', 'user_properties', {
                            user_id: [userSnap._id, userSnap.firstName, userSnap.lastName].filter(function (e) { return e; }).join(', '),
                            company_id: [companySnap._id, companySnap.name, companySnap.vatCode].filter(function (e) { return e; }).join(', '),
                        });
                        googleTagManager('config', window.googleAnalyticsId, {
                            user_id: [userSnap._id, userSnap.firstName, userSnap.lastName].filter(function (e) { return e; }).join(', '),
                            company_id: [companySnap._id, companySnap.name, companySnap.vatCode].filter(function (e) { return e; }).join(', '),
                        });
                        googleTagManager('event', 'login', {
                            method: 'email',
                        });
                        if (!IS_DEV_ENV) {
                            attachEvent(window.document.body, 'mousemove', function () { return resetTimer({ store: self }); });
                            attachEvent(window.document.body, 'keydown', function () { return resetTimer({ store: self }); });
                            attachEvent(window.document.body, 'click', function () { return resetTimer({ store: self }); });
                            resetTimer({ store: self });
                        }
                        return [2 /*return*/];
                }
            });
        }),
        logout: flow(function () {
            var key, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        clearZendesk();
                        return [4 /*yield*/, client.mutate({
                                mutation: LogOut,
                                variables: {
                                    input: { subscriptionAuthToken: self.subscriptionAuthToken },
                                },
                            })];
                    case 1:
                        _a.sent();
                        ['inviteLink', 'inviteCode'].forEach(function (key) {
                            self[(key === null || key === void 0 ? void 0 : key.name) || key] = '';
                        });
                        self.currentUser.clear({ language: self.currentUser.language });
                        self.currentCompany.clear();
                        self.isLoggedOut = true;
                        browserStorage.clearKeys([], 1);
                        __spread(((location.hostname === 'localhost' && ['http://localhost:3001', 'http://localhost:3000']) || []), ((location.hostname.match(/^izi-logistics/gi) && [
                            'https://izi-logistics-customer-master.netlify.app',
                            'https://izi-logistics-carrier-master.netlify.app',
                            'https://izi-logistics-sender-consignee-master.netlify.app',
                            'https://izi-logistics-forwarder-customer-master.netlify.app',
                            'https://izi-logistics-forwarder-master.netlify.app',
                        ]) ||
                            []), ((location.hostname.match(/\.izi-logistics/gi) && [
                            'https://app.izi-logistics.com',
                            'https://customer.izi-logistics.com',
                            'https://carrier.izi-logistics.com',
                            'https://sender-consignee.netlify.app',
                            'https://forwarder.izi-expedition.com',
                            'https://customer.izi-expedition.com',
                            'https://affiliate.izi-logistics.com',
                        ]) ||
                            [])).forEach(function (path) {
                            if (path.match(location.origin))
                                return;
                            var f = document.createElement('iframe');
                            f.src = path + "/#clear-cache";
                            document.body.appendChild(f);
                            f.onloadend = function () {
                                f.remove();
                            };
                            f.load = function () {
                                f.remove();
                            };
                            setTimeout(function () {
                                document.body.removeChild(f);
                            }, 1000);
                        });
                        googleTagManager('set', 'user_properties', {
                            user_id: '',
                            company_id: '',
                        });
                        googleTagManager('user_id', '');
                        for (key in browserStorage.keys()) {
                            if (key.match('base-table')) {
                                localStorage.removeItem(key);
                            }
                        }
                        setTimeout(function () {
                            try {
                                client.resetStore(); //TODO: need to clear all store
                            }
                            catch (e) { }
                        }, 1000);
                        return [3 /*break*/, 3];
                    case 2:
                        e_2 = _a.sent();
                        console.log(e_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
    }); })
        .actions(function (self) { return ({
        update: flow(function (updates) {
            return __generator(this, function (_a) {
                Object.assign(self, updates);
                return [2 /*return*/];
            });
        }),
        toggleShowSupport: flow(function () {
            return __generator(this, function (_a) {
                self.showSupport = !self.showSupport;
                return [2 /*return*/];
            });
        }),
        loadToDo: flow(function (opt) {
            var res, fields, e_3;
            if (opt === void 0) { opt = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        res = [];
                        fields = (opt === null || opt === void 0 ? void 0 : opt.fields) || [
                            {
                                name: 'status',
                                value: OrderStatus.ApprovedQuote,
                            },
                            {
                                name: 'all-in-progress',
                                value: 1,
                            },
                            {
                                name: 'stats-to-do-v1',
                                value: 1,
                            },
                        ];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, client.query({
                                query: FetchOrderToDoStats,
                                variables: {
                                    filter: {
                                        fields: fields,
                                    },
                                },
                            })];
                    case 2:
                        res = _a.sent();
                        self[(opt === null || opt === void 0 ? void 0 : opt.key) || 'totalToDo'] = res.data.orderToDoStats.map(function (e) { return e.count; }).reduce(function (a, b) { return a + b; }, 0);
                        res = res.data.orderToDoStats || [];
                        return [3 /*break*/, 4];
                    case 3:
                        e_3 = _a.sent();
                        console.log(e_3);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, res];
                }
            });
        }),
        clearOnSignUp: flow(function (refreshList) {
            if (refreshList === void 0) { refreshList = 0; }
            return __generator(this, function (_a) {
                self.tempUser.reset(getSnapshot(BaseUser.create({})));
                return [2 /*return*/];
            });
        }),
        refreshMenu: flow(function (refreshList) {
            if (refreshList === void 0) { refreshList = 0; }
            return __generator(this, function (_a) {
                return [2 /*return*/];
            });
        }),
        setNewLocation: flow(function (currentLocation) {
            return __generator(this, function (_a) {
                self.currentLocation = currentLocation;
                return [2 /*return*/];
            });
        }),
        toggleMenuVisible: flow(function () {
            return __generator(this, function (_a) {
                self.menuIsOpened = !self.menuIsOpened;
                if (self.menuIsOpened) {
                    globalUtils.sounds.play(SOUND_KEY.MODAL_OPEN);
                }
                else {
                    globalUtils.sounds.play(SOUND_KEY.MODAL_CLOSE);
                }
                return [2 /*return*/];
            });
        }),
        refreshSidebarChatList: function () {
            self.refreshSidebarChatListKey = Date.now();
        },
        loadEmailTemplates: flow(function (filter) {
            var result, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        self.isEmailTemplateLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchEmailTemplates,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        self.isEmailTemplateLoading = false;
                        return [2 /*return*/, result.data.emailTemplates.data[0]];
                    case 2:
                        e_4 = _a.sent();
                        self.isEmailTemplateLoading = false;
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        updateEmailTemplate: flow(function (input) {
            var result, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        self.isEmailTemplateUpdate = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateEmailTemplate,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.SETTINGS, 'settings_email_template')));
                        self.isEmailTemplateUpdate = false;
                        return [2 /*return*/, result.data.updatedEmailTemplate];
                    case 2:
                        e_5 = _a.sent();
                        self.isEmailTemplateUpdate = false;
                        toast.error(e_5.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        updateUser: flow(function (input) {
            var result, e_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateCurrentUser,
                                variables: {
                                    input: __assign(__assign({}, input), { _id: input._id }),
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'profile_label')));
                        return [2 /*return*/, result];
                    case 2:
                        e_6 = _a.sent();
                        console.log(e_6);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        login: flow(function (input) {
            var locationUrl_1, result, redirectUrl_1, e_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        locationUrl_1 = browserStorage.getItem('last-url');
                        return [4 /*yield*/, client.mutate({
                                mutation: LogIn,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        window.envirements = result.data.LogIn.env;
                        delete result.data.LogIn.env;
                        return [4 /*yield*/, self.onLogin(result.data.LogIn)];
                    case 2:
                        redirectUrl_1 = _a.sent();
                        setTimeout(function () {
                            if (locationUrl_1) {
                                // if (redirectUrl) self.logout();
                                browserHistory.push(redirectUrl_1 || removeURLParameter(locationUrl_1, 'token'));
                            }
                        });
                        clearZendesk();
                        return [3 /*break*/, 4];
                    case 3:
                        e_7 = _a.sent();
                        console.log(e_7, e_7.key, e_7.message);
                        googleTagManager('event', 'izi_error', {
                            event_name: 'error',
                            value: e_7.key || e_7.message || e_7,
                        });
                        throw e_7;
                    case 4: return [2 /*return*/];
                }
            });
        }),
        addLanguage: flow(function (lang) {
            var result, e_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.query({
                                query: FetchAllLang,
                                variables: {
                                    filter: { fields: [{ name: 'lang', value: lang }] },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        i18nAddLang(JSON.parse(result.data.allLang.keys));
                        return [3 /*break*/, 3];
                    case 2:
                        e_8 = _a.sent();
                        toast.error(e_8.message || e_8);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        relogin: flow(function () {
            var subscription, e_9, result_1, version, keys, i18n_1, user, input, LogRocket, userSnap, companySnap, e_10;
            var _a, _b, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 7, 8, 9]);
                        subscription = null;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 4, , 5]);
                        if (!notificationRequest.isGranted()) return [3 /*break*/, 3];
                        return [4 /*yield*/, notificationRequest.askPermissionHelper()];
                    case 2:
                        subscription = _d.sent();
                        self.subscriptionAuthToken = subscription.auth_token;
                        _d.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        e_9 = _d.sent();
                        return [3 /*break*/, 5];
                    case 5: return [4 /*yield*/, client.mutate({
                            mutation: CheckIfLoggedIn,
                            variables: {
                                filter: {
                                    fields: [
                                        { name: 'lang', value: langOptions.lng },
                                        subscription && { name: 'subscription', value: subscription },
                                    ].filter(function (e) { return e; }),
                                },
                            },
                        })];
                    case 6:
                        result_1 = _d.sent();
                        result_1 = result_1.data.CheckIfLoggedIn;
                        window.envirements = result_1.env;
                        if ((_a = result_1.preparingStatuses) === null || _a === void 0 ? void 0 : _a.length) {
                            self.preparingStatuses = result_1.preparingStatuses;
                            delete result_1.preparingStatuses;
                        }
                        if ((_b = result_1.orderVehicleTypes) === null || _b === void 0 ? void 0 : _b.length) {
                            self.orderVehicleTypes = result_1.orderVehicleTypes;
                            delete result_1.orderVehicleTypes;
                        }
                        version = (browserStorage.getItem('API_VERSION') || '').toString();
                        browserStorage.setItem('IZI_VERSION', result_1.API_VERSION);
                        if (result_1.API_VERSION && version !== result_1.API_VERSION.toString()) {
                            browserStorage.setItem('API_VERSION', result_1.API_VERSION);
                            // if (version) {
                            //   return reloadPageWithoutCache();
                            // }
                        }
                        ['env', 'inviteLink', 'inviteCode', { key: 'langs', name: 'languages' }].forEach(function (key) {
                            self[(key === null || key === void 0 ? void 0 : key.name) || key] = result_1[(key === null || key === void 0 ? void 0 : key.key) || key] || '';
                        });
                        self.isApiLoaded = 1;
                        keys = JSON.parse(result_1.keys);
                        i18n_1 = LangController.init(keys);
                        if (IS_DEV_ENV) {
                            window.i18n = i18n_1;
                            window.KEYS = KEYS;
                        }
                        user = result_1.user;
                        if (i18n_1.changeLanguage) {
                            i18n_1.changeLanguage((user && user.language) || langOptions.lng);
                            setAppMomentLocale((user && user.language) || langOptions.lng);
                        }
                        if (IS_DEV_ENV) {
                            input = document.createElement('input');
                            input.setAttribute('type', 'hidden');
                            input.setAttribute('name', 'data-izi-lang-loaded');
                            document.body.appendChild(input);
                        }
                        if (user) {
                            if (!process.env.REACT_APP_NONE_BRAND && user.portalLinK && !user.portalLinK.match(location.origin)) {
                                if (CAN_REDIRECT || process.env.REACT_APP_NONE_BRAND) {
                                }
                                else {
                                    return [2 /*return*/, (location.href = user.portalLinK + "?token=" + browserStorage.getItem('token') + "&client_id=" + (browserStorage.getItem('client_id') || ''))];
                                } //
                            }
                            try {
                                LogRocket = window.LogRocket;
                                LogRocket.identify(user._id, {
                                    name: [user.firstName, user.lastName].filter(function (e) { return e; }).join(' '),
                                    email: user.email,
                                    // Add your own custom user variables here, ie:
                                    id: user._id,
                                    company: [user.company._id, user.company.name, user.company.vatCode].filter(function (e) { return e; }).join(', '),
                                });
                            }
                            catch (e) { }
                            [{ n: 'izi_client_id', k: 'client_id' }, 'booking_token'].forEach(function (e) {
                                if (result_1[e.n || e]) {
                                    browserStorage.setItem(e.k || e, result_1[e.n || e]);
                                }
                                else {
                                    browserStorage.removeItem(e.k || e);
                                }
                            });
                            browserStorage.setItem('user-id', [user._id, user.firstName, user.lastName].filter(function (e) { return e; }).join(', '));
                            if ((_c = user.forwarders) === null || _c === void 0 ? void 0 : _c.length)
                                browserStorage.setItem('forwarder', user.forwarders.map(function (e) { return [e.company._id, e.company.origin_name].join(', '); }).join(', '));
                            browserStorage.setItem("appType", user.company.type);
                            browserStorage.setItem('companyId', [user.company._id, user.company.name, user.company.vatCode].filter(function (e) { return e; }).join(', '));
                            googleTagManager('event', 'login', {
                                method: 'token',
                            });
                            googleTagManager('set', 'user_properties', {
                                user_id: [user._id, user.firstName, user.lastName].filter(function (e) { return e; }).join(', '),
                                user_id_dimension: [user._id, user.firstName, user.lastName].filter(function (e) { return e; }).join(', '),
                                company_id: [user.company._id, user.company.name, user.company.vatCode].filter(function (e) { return e; }).join(', '),
                            });
                            googleTagManager('event', 'izi_app_loaded', {
                                event_name: 'loaded',
                                value: moment().format('YYYY-MM-DD HH:mm'),
                            });
                            userSnap = __assign({}, cloneDeep(user));
                            companySnap = cloneDeep(Object.assign(user.company, result_1.companyAdditionalInfo, { updatedAt: Date.now().toString() }));
                            self.currentUser.reset(userSnap);
                            self.currentCompany.reset(companySnap);
                            self.relatedAppUrls = result_1.relatedAppUrls || {};
                            try {
                                googleTagManager('config', window.googleAnalyticsId, {
                                    user_id: [userSnap._id, userSnap.firstName, userSnap.lastName].filter(function (e) { return e; }).join(', '),
                                    company_id: [companySnap._id, companySnap.name, companySnap.vatCode].filter(function (e) { return e; }).join(', '),
                                });
                            }
                            catch (e) {
                                console.log(e);
                            }
                        }
                        else {
                            clearZendesk();
                            browserStorage.clearKeys([], 1);
                            // if (browserStorage.getItem('booking_access_token')) {
                            // if (!location.href.match(/register/gi)) location.href = '/register';
                            // }
                            client.resetStore(); //TODO: need to clear all store
                        }
                        if (result_1.tempUser) {
                            self.tempUser.reset(result_1.tempUser);
                        }
                        if (result_1.error) {
                            browserHistory.push('/');
                        }
                        self.checkedToken = 0;
                        return [3 /*break*/, 9];
                    case 7:
                        e_10 = _d.sent();
                        console.log(e_10, self.checkedToken);
                        clearZendesk();
                        if (self.checkedToken++ > 20) {
                            self.checkedToken = 0;
                            browserStorage.clearKeys(['API_VERSION', 'APP_VERSION', 'isManual'], 1);
                        }
                        client.resetStore(); //TODO: need to clear all store
                        setTimeout(function () {
                            self.relogin();
                        }, 2000);
                        browserHistory.push('/');
                        return [3 /*break*/, 9];
                    case 8:
                        self.isChecked = true;
                        if (!IS_DEV_ENV) {
                            attachEvent(window.document.body, 'mousemove', function () { return resetTimer({ store: self }); });
                            attachEvent(window.document.body, 'keydown', function () { return resetTimer({ store: self }); });
                            attachEvent(window.document.body, 'click', function () { return resetTimer({ store: self }); });
                            resetTimer({ store: self });
                        }
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        }),
        loadHistory: flow(function (filter) {
            var res, e_11;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.loadingLogs = true;
                        return [4 /*yield*/, client.query({
                                query: FetchLogs,
                                variables: { filter: filter },
                            })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data.logs];
                    case 2:
                        e_11 = _a.sent();
                        // toast.error(e.message);
                        console.log(e_11);
                        return [3 /*break*/, 4];
                    case 3:
                        self.loadingLogs = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        subscribeToNotification: flow(function (subscription) {
            return __generator(this, function (_a) {
                try {
                    client.mutate({
                        mutation: CreateUserSubscription,
                        variables: {
                            input: subscription,
                        },
                    });
                    self.subscriptionAuthToken = subscription.auth_token;
                }
                catch (err) {
                    console.log('Error to save user subscription', err);
                }
                return [2 /*return*/];
            });
        }),
        loadUser: flow(function (_id) {
            var res, e_12;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.query({
                                query: FetchUser,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data.user];
                    case 2:
                        e_12 = _a.sent();
                        toast.error(e_12.message);
                        console.log(e_12);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
    }); })
        .views(function (self) { return ({
        get isLoggedIn() {
            return (!(self.currentUser._id === '-1' ||
                self.currentCompany._id === '_id' ||
                !self.currentUser._id ||
                !self.currentCompany._id) && !self.currentUser.isPreventLogin);
        },
        get isForwarderNotIziLogisticsEnv() {
            var _a;
            return (APP_TYPE === BaseUserAppTYpe.FORWARDER &&
                (self.currentCompany.type === BaseUserAppTYpe.FORWARDER ||
                    (((_a = self.currentUser.forwarders) === null || _a === void 0 ? void 0 : _a.length) &&
                        !self.currentUser.forwarders.find(function (e) { var _a, _b; return (_b = (_a = e.company) === null || _a === void 0 ? void 0 : _a.settings) === null || _b === void 0 ? void 0 : _b.isIziBranding; }))));
        },
        get isForwarderEnvOnly() {
            var _a;
            return (_a = self.currentUser.forwarders) === null || _a === void 0 ? void 0 : _a.length;
        },
    }); });
};
function removeURLParameter(url, parameter) {
    //prefer to use l.search if you have a location/link object
    var urlparts = url.split('?');
    if (urlparts.length >= 2) {
        var prefix = encodeURIComponent(parameter) + '=';
        var pars = urlparts[1].split(/[&;]/g);
        //reverse iteration as may be destructive
        for (var i = pars.length; i-- > 0;) {
            //idiom for string.startsWith
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }
        return urlparts[0] + (pars.length > 0 ? '?' + pars.join('&') : '');
    }
    return url;
}
