var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { DeleteButtonIcon } from '../../../DeleteButton';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { KEYS } from '../../../../i18n';
import { inject } from 'mobx-react';
import { USER_ROLE_TOOLS } from '../../../../models/UserRoles';
import BaseTooltip from '../../../BaseTooltip';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import BaseIconButton from '../../../BaseIconButton';
export var actionsVisibility = function (_a) {
    var option = _a.option, cannotDelete = _a.cannotDelete, canDuplicate = _a.canDuplicate, canEdit = _a.canEdit;
    return [
        (!option.isGlobal && !option.approvedAt && option.onDeleteItem && !cannotDelete) ||
            option.approvedAt ||
            option.isGlobal,
        (!option.isGlobal && option.onEditItem && canEdit) ||
            option.approvedAt ||
            option.isGlobal ||
            (!option.isGlobal && option.onEditItem && canEdit && canDuplicate),
    ];
};
export default inject('store')(function DropDownItemActions(_a) {
    var option = _a.option, deleteTitle = _a.deleteTitle, errorTitle = _a.errorTitle, editTitle = _a.editTitle, canEdit = _a.canEdit, canDuplicate = _a.canDuplicate, cannotDelete = _a.cannotDelete, isFreeActions = _a.isFreeActions, currentUser = _a.store.accountStore.currentUser;
    var langForm = useTranslation(KEYS.FORM);
    var langToast = useTranslation(KEYS.TOAST);
    if (option.isGlobal ||
        currentUser.isCompanyAdmin ||
        (option.createdBy && (option.createdBy._id || option.createdBy) === currentUser._id) ||
        isFreeActions) {
        var isOwn = option.createdBy && currentUser._id === (option.createdBy._id || option.createdBy);
        var canEditV1 = currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
        var disabled = !(isOwn || canEditV1);
        var optionsShow = actionsVisibility({ option: option, cannotDelete: cannotDelete, canDuplicate: canDuplicate, canEdit: canEdit });
        return (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'flex-end', style: { width: 'initial', maxWidth: 62 } },
            optionsShow[0] ? (React.createElement(DeleteButtonIcon, { disabled: disabled || option.approvedAt || option.isGlobal, title: deleteTitle, onClick: !(option.approvedAt || option.isGlobal) ? function (e) { return option.onDeleteItem(e, option); } : function (e) { return 0; }, size: "small" })) : null,
            optionsShow[1] ? (React.createElement(BaseTooltip, { title: editTitle || langForm.t('form_btn_edit') },
                React.createElement(BaseIconButton, { disabled: disabled || option.approvedAt || option.isGlobal, size: "small", onClick: !(option.approvedAt || option.isGlobal) ? function (e) { return option.onEditItem(e, option); } : function (e) { return 0; }, id: 'edit-btn' },
                    React.createElement(EditIcon, null)))) : null,
            optionsShow[2] ? (React.createElement(BaseTooltip, { title: langToast.t('toast_items_duplicate').replace(/_X_/, '') },
                React.createElement(BaseIconButton, { disabled: disabled, size: "small", onClick: function (e) {
                        var _id = option._id, warehouse = option.warehouse, warehouses = option.warehouses, company = option.company, item = __rest(option, ["_id", "warehouse", "warehouses", "company"]);
                        ['additionalMembers', 'members', 'locationAdvanced'].forEach(function (key) {
                            if (typeof item[key] !== 'undefined') {
                                item[key] = (item[key] || []).map(function (_a) {
                                    var _id = _a._id, company = _a.company, rest = __rest(_a, ["_id", "company"]);
                                    return (__assign({}, rest));
                                });
                            }
                        });
                        option.onEditItem(e, item);
                    }, id: 'duplicate-btn' },
                    React.createElement(FileCopyIcon, null)))) : null));
    }
    return null;
});
