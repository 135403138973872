var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import moment from 'moment';
import { CurrencyFormat } from './components';
import { i18n, KEYS } from './i18n';
import { toast } from 'react-toastify';
import { formatDateChars, timeViewFormat2 } from './components/DateView';
import { browserStorage } from './browserStorage'; //
export var dayInEn = function (e) { return moment(e).locale('en').format('dddd'); };
export var sleep = function (timeout) {
    if (timeout === void 0) { timeout = 1000; }
    return new Promise(function (resolve) { return setTimeout(function () { return resolve(1); }, timeout); });
};
export var toDateOnly = function (date, format) {
    if (date === void 0) { date = new Date(); }
    if (format === void 0) { format = 'YYYY/MM/DD'; }
    return (date && moment(date).format(format)) || '';
};
export var toDateOnlyV1 = function (date, format) {
    if (date === void 0) { date = new Date(); }
    if (format === void 0) { format = 'YYYY MMM DD'; }
    return formatDateChars(toDateOnly(date, format));
};
export var toDateOnlyV12 = function (date, format) {
    if (date === void 0) { date = new Date(); }
    if (format === void 0) { format = 'YYYY MMM DD HH:mm'; }
    return formatDateChars(toDateOnly(date, format));
};
export var toDateOnlyV4 = function (date, lang) {
    if (date === void 0) { date = new Date(); }
    return toDateOnly(date, "MMM DD");
};
export var toDateOnlyV41 = function (date, lang) {
    if (date === void 0) { date = new Date(); }
    return toDateOnly(date, "MMM DD HH:mm");
};
export var toDateOnlyV3 = function (date, lang) {
    if (date === void 0) { date = new Date(); }
    return formatDateChars(toDateOnly(date, "YYYY MMM DD"));
};
export var toDateOnlyV2 = function (date) { return date.replace(/\\/gi, '-').replace(/\//gi, '-'); };
export var sequenceGenerator1 = function sequenceGenerator1(minVal, maxVal) {
    var currVal;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                currVal = minVal;
                _a.label = 1;
            case 1:
                if (!(currVal < maxVal)) return [3 /*break*/, 3];
                return [4 /*yield*/, currVal++];
            case 2:
                _a.sent();
                return [3 /*break*/, 1];
            case 3: return [2 /*return*/];
        }
    });
};
export var sequenceGenerator = function (minVal, maxVal, step) {
    if (step === void 0) { step = 0.25; }
    var res = [];
    for (var i = minVal; i < maxVal; i += step) {
        var min = 60 * i;
        res.push(timeViewFormat2(min));
    }
    return res;
};
export var nextMonday = function (isDate, date) {
    if (date === void 0) { date = new Date(); }
    var d = new Date(date);
    d.setDate(d.getDate() + ((1 + 7 - d.getDay()) % 7 || 7));
    return isDate ? d : toDateOnly(d);
};
export var sequenceGeneratorV2 = function (minVal, maxVal, step) {
    if (step === void 0) { step = 0.25; }
    debugger;
    var res = [];
    for (var i = minVal; i <= maxVal; i += step) {
        var min = 60 * i;
        res.push(timeViewFormat2(min));
    }
    return res;
};
export var timeViewFormat2ToValue = function (time) {
    var parts = time.split(':');
    return parseInt(parts[0]) + parseInt(parts[1]) / 60;
};
export var sequenceGeneratorV1 = function (minVal, maxVal, step) {
    if (step === void 0) { step = 1; }
    var res = [];
    for (var i = minVal; i < maxVal; i += step) {
        res.push(i);
    }
    return res;
};
export var getDaysArray = function (start, end) {
    var arr = [];
    for (var dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};
export var toDateTime = function (day, start, startFrom) {
    return toDateTimeUTC(day, start, startFrom).getTime();
};
export var toDateTimeUTC = function (day, start, startFrom, endTo) {
    var startTime = new Date(day);
    var _time = (start && start.toString()) || '';
    var startHour = parseInt(_time.split(':')[0] || 0);
    var _startFrom = (startFrom && parseInt(startFrom.split(':')[0] || 0)) || 0;
    var _endTo = endTo && parseInt(endTo.split(':')[0] || 0);
    var hour = (startFrom && startHour <= _startFrom && _startFrom + 1) ||
        (_endTo && startHour >= _endTo && _endTo - 1) ||
        startHour;
    startTime.setHours(hour);
    startTime.setMinutes(parseInt((_time.split(':')[1] || 0).toString()));
    startTime.setSeconds(0);
    return startTime;
};
export var ConsoleHelper = function () {
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (process.env.NODE_ENV === 'production')
        return;
    console.log.apply(console, __spread(data));
};
export var ConsoleWarnHelper = function () {
    var data = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        data[_i] = arguments[_i];
    }
    if (process.env.NODE_ENV === 'production')
        return;
    console.warn.apply(console, __spread(data));
};
export var fileSave = function (path) {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('href', path);
    a.setAttribute('target', '_blank');
    a.click();
    document.body.removeChild(a);
};
export function getQueryParams(url) {
    var qparams = {}, parts = (url || '').split('?'), qparts, qpart, i = 0;
    if (parts.length <= 1) {
        return qparams;
    }
    else {
        try {
            qparts = parts[1].split('&');
        }
        catch (e) { }
        for (var i_1 in qparts) {
            try {
                qpart = qparts[i_1].split('=');
                qparams[decodeURIComponent(qpart[0])] = decodeURIComponent(qpart[1] || '');
            }
            catch (e) { }
        }
    }
    return qparams;
}
export var humanFileSize = function humanFileSize(bytes, si, dp) {
    if (si === void 0) { si = false; }
    if (dp === void 0) { dp = 1; }
    var thresh = si ? 1000 : 1024;
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B';
    }
    var units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    var u = -1;
    var r = Math.pow(10, dp);
    do {
        bytes /= thresh;
        ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);
    return bytes.toFixed(dp) + ' ' + units[u];
};
export var serialFormat = function serialFormat(format, value) {
    if (!format)
        return value.toString();
    return "" + format.substr(0, format.length - value.toString().length) + value;
};
export var focusField = function (form, id) {
    try {
        var key = Object.keys(form.current.errors)[0];
        // console.log(id, key);
        var input = document.getElementById(id).querySelector("input[name=" + key + "]");
        if (!input) {
            input = document.getElementById(id).querySelector("input");
        }
        input.focus();
        console.error(id + " Form No Valid");
    }
    catch (e) {
        console.log(e);
    }
    return -1;
};
export var globalUtils = {};
export var initQELib = function () {
    return new Promise(function (resolve) {
        var script = document.createElement('script');
        script.setAttribute('src', 'https://cdn.rawgit.com/davidshimjs/qrcodejs/gh-pages/qrcode.min.js');
        script.onload = resolve;
        document.body.appendChild(script);
    });
};
export var initChartJsLib = function () {
    return new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.setAttribute('src', 'https://cdn.jsdelivr.net/npm/chart.js@3.5.1/dist/chart.min.js');
        script.onload = resolve;
        script.onerror = reject;
        document.body.appendChild(script);
    });
};
var numberRemain = function (n, _and, language) {
    if (_and === void 0) { _and = ''; }
    var string = n.toString(), units, tens, scales, start, end, chunks, chunksLen, chunk, ints, i, word, words, and = _and || i18n.getResource(language ? language : i18n.language, KEYS.APP, 'prefix_and');
    /* Is number zero? */
    if (parseInt(string) === 0) {
        return i18n.getResource(language ? language : i18n.language, KEYS.APP, 'number_words_zero');
    }
    /* Array of units as words */
    units = i18n
        .getResource(language ? language : i18n.language, KEYS.APP, 'number_words_units')
        .replace(/'/gi, '')
        .split(',');
    /* Array of tens as words */
    tens = i18n
        .getResource(language ? language : i18n.language, KEYS.APP, 'number_words_tens')
        .replace(/'/gi, '')
        .split(',');
    /* Array of scales as words */
    scales = i18n
        .getResource(language ? language : i18n.language, KEYS.APP, 'number_words_scales')
        .replace(/'/gi, '')
        .split(',');
    /* Split user argument into 3 digit chunks from right to left */
    start = string.length;
    chunks = [];
    while (start > 0) {
        end = start;
        chunks.push(string.slice((start = Math.max(0, start - 3)), end));
    }
    /* Check if function has enough scale words to be able to stringify the user argument */
    chunksLen = chunks.length;
    if (chunksLen > scales.length) {
        return '';
    }
    /* Stringify each integer in each chunk */
    words = [];
    for (i = 0; i < chunksLen; i++) {
        chunk = parseInt(chunks[i]);
        if (chunk) {
            /* Split chunk into array of individual integers */
            ints = chunks[i].split('').reverse().map(parseFloat);
            /* If tens integer is 1, i.e. 10, then add 10 to units integer */
            if (ints[1] === 1) {
                ints[0] += 10;
            }
            /* Add scale word if chunk is not zero and array item exists */
            if ((word = scales[i])) {
                words.push(word);
            }
            /* Add unit word if array item exists */
            if ((word = units[ints[0]])) {
                words.push(word);
            }
            /* Add tens word if array item exists */
            if ((word = tens[ints[1]])) {
                words.push(word);
            }
            /* Add 'and' string after units or tens integer if: */
            if (ints[0] || ints[1]) {
                /* Chunk has a hundreds integer or chunk is the first of multiple chunks */
                if (ints[2] || i + 1 > chunksLen) {
                    words.push(and);
                }
            }
            /* Add hundreds word if array item exists */
            if ((word = units[ints[2]])) {
                words.push(word + (" " + i18n.getResource(language ? language : i18n.language, KEYS.APP, 'number_words_hundred')));
            }
        }
    }
    return words
        .filter(function (e) { return e; })
        .reverse()
        .join(' ');
};
export var numberToEnglish = function numberToEnglish(n, currency, language) {
    var string = parseFloat(n.toString()).toFixed(2);
    var zero = i18n.getResource(language ? language : i18n.language, KEYS.APP, 'number_words_zero');
    var and = i18n.getResource(language ? language : i18n.language, KEYS.APP, 'prefix_and');
    /* Remove spaces and commas */
    var val = string.replace(/[, ]/g, '').split('.');
    var remain = numberRemain(val[1], '', language);
    var values = [numberRemain(val[0], remain && remain !== zero ? '' : and, language)];
    if (remain && remain !== zero) {
        values.push(and + " " + remain + " " + i18n.getResource(language ? language : i18n.language, KEYS.APP, 'currency_USD_coin'));
    }
    var res = values.join(" " + CurrencyFormat(currency, true, language) + " ");
    res += values.length === 1 ? " " + CurrencyFormat(currency, true, language) : '';
    res = res.replace(/\s{2,}/g, ' ');
    return res;
};
export var isValidMongoId = function (id) { return id.toString().match(/^[0-9a-fA-F]{24}$/); };
export var copyLink = function (link, successMessage) { return __awaiter(void 0, void 0, void 0, function () {
    var err_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, navigator.clipboard.writeText(link)];
            case 1:
                _a.sent();
                // console.log('Fallback: Copying text command was successful');
                toast.success(successMessage || 'Link was copied. You can share it now');
                return [3 /*break*/, 3];
            case 2:
                err_1 = _a.sent();
                console.error('Fallback: Oops, unable to copy', err_1);
                toast.error(err_1.message || err_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var convertToKg = function (value, dimension) {
    if (dimension === 't') {
        return value * 1000;
    }
    else if (dimension === 'kg') {
        return value;
    }
    else if (dimension === 'g') {
        return Math.round(value / 1000);
    }
    else if (dimension === 'lb' || dimension === 'lbs') {
        return Math.round(value * 0.453592);
    }
    return value;
};
// re-use canvas object for better performance
var canvas = document.createElement('canvas');
export function getTextWidth(text, font) {
    var context = canvas.getContext('2d');
    if (context)
        context.font = font;
    var metrics = context === null || context === void 0 ? void 0 : context.measureText(text);
    return metrics === null || metrics === void 0 ? void 0 : metrics.width;
}
function getCssStyle(element, prop) {
    return window.getComputedStyle(element, null).getPropertyValue(prop);
}
export var madeNextLineBreaks = function (rows, style, isSmall, width, rowsNextLine) {
    var res = [];
    rows.forEach(function (row) {
        var iRow = 0;
        var line = '';
        var len = 0;
        var part = row.sizeValue ? row.sizeValue / 12 : 0.5;
        var lineWidth = isSmall ? width * 0.9 : width * part * 0.9;
        if (typeof row.value === 'string' && rowsNextLine.includes(row.name)) {
            row.value.split(' ').forEach(function (word, index) {
                var wordLen = getTextWidth(word, style);
                if (wordLen) {
                    if (index === 0) {
                        line = word;
                        len = wordLen;
                    }
                    else {
                        if (len + wordLen <= (iRow === 0 ? lineWidth : width * 0.98)) {
                            len += wordLen;
                            line += ' ' + word;
                        }
                        else {
                            res.push(__assign(__assign({}, row), { name: iRow === 0 ? row.name : '', value: line, sizeName: iRow === 0 ? row.sizeName : 0, sizeValue: iRow === 0 ? row.sizeValue : 12 }));
                            len = wordLen;
                            iRow += 1;
                            line = word;
                        }
                    }
                }
            });
        }
        else {
            res.push(row);
        }
        if (typeof row.value === 'string' && line !== '') {
            res.push(__assign(__assign({}, row), { name: iRow === 0 ? row.name : '', value: line, sizeName: iRow === 0 ? row.sizeName : 0, sizeValue: iRow === 0 ? row.sizeValue : 12 }));
        }
    });
    return res;
};
var docuSignfileTypes = new Set([
    'doc',
    'docm',
    'docx',
    'dot',
    'dotm',
    'dotx',
    'htm',
    'html',
    'msg',
    'pdf',
    'rtf',
    'txt',
    'wpd',
    'xps',
    'bmp',
    'gif',
    'jpg',
    'jpeg',
    'png',
    'tif',
    'tiff',
    'pot',
    'potx',
    'pps',
    'ppt',
    'pptm',
    'pptx',
    '.csv',
    'xls',
    'xlsm',
    'xlsx',
]);
export var isCanBeSigned = function (file, props) {
    if ((props === null || props === void 0 ? void 0 : props.noEmail) || !(file === null || file === void 0 ? void 0 : file.name)) {
        return false;
    }
    var nameParts = file.name.split('.');
    var type = nameParts[nameParts.length - 1];
    return docuSignfileTypes.has(type);
};
var os = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' },
];
var browser = [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' },
];
function matchItem(string, data) {
    var i = 0, j = 0, html = '', regex, regexv, match, matches, version;
    for (i = 0; i < data.length; i += 1) {
        regex = new RegExp(data[i].value, 'i');
        match = regex.test(string);
        if (match) {
            regexv = new RegExp(data[i].version + '[- /:;]([d._]+)', 'i');
            matches = string.match(regexv);
            version = '';
            if (matches) {
                if (matches[1]) {
                    matches = matches[1];
                }
            }
            if (matches) {
                matches = matches.split(/[._]+/);
                for (j = 0; j < matches.length; j += 1) {
                    if (j === 0) {
                        version += matches[j] + '.';
                    }
                    else {
                        version += matches[j];
                    }
                }
            }
            else {
                version = '0';
            }
            return {
                name: data[i].name,
                version: parseFloat(version),
            };
        }
    }
    return { name: 'unknown', version: 0 };
}
export var isMobileView = function () { return window.innerWidth <= 420; };
export var getDeviceInfo = function () {
    try {
        var header = [navigator.platform, navigator.userAgent, navigator.appVersion, navigator.vendor];
        var agent = header.join(' ');
        var osInfo = matchItem(agent, os);
        var browserInfo = matchItem(agent, browser);
        return {
            osName: osInfo.name,
            browserName: browserInfo.name,
        };
    }
    catch (e) {
        return {
            osName: 'IOS',
            browser: 'unknown',
        };
    }
};
export var getWeekNumber = function (date) {
    var startDate = new Date(date.getFullYear(), 0, 1);
    var daysFromYear = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
    var weekNumber = Math.ceil(daysFromYear / 7);
    return weekNumber;
};
function isSubArray(a, b) {
    if (a === b)
        return true;
    if (a == null || b == null)
        return false;
    var set = new Set(b);
    for (var i = 0; i < a.length; ++i) {
        if (!set.has(a[i]))
            return false;
    }
    return true;
}
export function arraysHasEqualItems(a, b) {
    if (a === b)
        return true;
    if (a == null || b == null)
        return false;
    if (a.length !== b.length)
        return false;
    return isSubArray(a, b) && isSubArray(b, a);
}
export var generateInvoiceHTML = function (dom, footer) {
    var parser = new DOMParser();
    var doc = parser.parseFromString(dom, 'text/html');
    if (!doc.documentElement.querySelector('#qr-code-image')) {
        throw 'Invoice template is not valid, please reload the page and try again';
    }
    return "<link\n      href=\"https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap\"\n      rel=\"stylesheet\"\n      /><style>\n      @page {\n        size: auto;\n        margin: 5px;\n      }\n\n      body {\n        font-family: 'Open Sans', sans-serif;\n        -webkit-print-color-adjust: exact;\n        color-adjust: exact;\n      }\n      .footer, .footer-space {\n        height: 60px;\n        width: 100%;\n      }\n      .footer {\n        position: fixed;\n        bottom: 0;\n      }\n      .content {\n        width: 100%;\n      }\n      .avoidBreaking {\n        page-break-inside: avoid; \n      }\n      </style>\n      <table class=\"content\">\n        <tbody class=\"content\"><tr class=\"content\"><td class=\"content\">\n          <div class=\"content\">" + dom + "</div>\n        </td></tr></tbody>\n        <tfoot><tr><td>\n          <div class=\"footer-space\">&nbsp;</div>\n        </td></tr></tfoot>\n      </table>\n      <div class=\"footer\">" + footer + "</div>\n      ";
};
export var i18nAddLang = function (resources) {
    Object.keys(resources).forEach(function (lang) {
        Object.keys(resources[lang]).forEach(function (key) {
            i18n.addResourceBundle(lang, key, resources[lang][key]);
        });
    });
};
export var diffBetweenDatesInDays = function (bigger, smaller) {
    return Math.ceil(Math.abs(bigger - smaller) / (1000 * 60 * 60 * 24));
};
export var reloadPageWithoutCache = function () {
    try {
        if (
        // process.env.NODE_ENV === 'development' ||
        // browserStorage.getItem('temp') ||
        // browserStorage.getItem('testing') ||
        window.is_testing) {
            return;
        }
        browserStorage.clearKeys([
            'user-id',
            'appType',
            'forwarder',
            'companyId',
            'lang',
            'token',
            'client_id',
            'last-url',
            'logistics-latest-visit',
            'sounds',
            'API_VERSION',
            'APP_VERSION',
            'isManual',
            'IZI_VERSION',
        ]);
        var query_1 = getQueryParams(location.href);
        ['API_VERSION', 'APP_VERSION', 'IZI_VERSION'].forEach(function (e) {
            var v = browserStorage.getItem(e);
            if (v)
                query_1[e] = browserStorage.getItem(e);
        });
        var path = location.origin +
            location.pathname + //here probably can be lastPage visited
            ("?" + Object.keys(query_1)
                .map(function (e) { return e + "=" + (query_1[e] || '1'); })
                .join('&'));
        location.href = path;
    }
    catch (e) { }
};
export var IS_STRONG_DEVICE = window.navigator.deviceMemory >= 6;
window.IS_STRONG_DEVICE = IS_STRONG_DEVICE;
export var IS_DEV_ENV = !!(process.env.REACT_APP_API_URL || '').match(/localhost|127.0.0.1|izi_test_/gi);
export var IS_CYPRESS_ENV = IS_DEV_ENV && location.origin.match(/127.0.0.1|izi_test_/gi);
export var checkIsDevEnv = function (t) {
    if (t === void 0) { t = 700; }
    return __awaiter(void 0, void 0, void 0, function () { var _a; return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _a = IS_CYPRESS_ENV;
                if (!_a) return [3 /*break*/, 2];
                return [4 /*yield*/, sleep(t || 1500)];
            case 1:
                _a = (_b.sent());
                _b.label = 2;
            case 2: return [2 /*return*/, _a];
        }
    }); });
};
export var wrapTextToId = function (text) { return ((text === null || text === void 0 ? void 0 : text.toString().replace(/\s| /gi, '_')) || '').toLowerCase(); };
export var getMonthRange = function (monthStart, opt) {
    if (opt === void 0) { opt = {}; }
    var monthEnd = new Date(new Date(monthStart).setDate(opt.monthStartRange || 1));
    return {
        minDate: new Date(new Date(monthStart).setDate(0)),
        maxDate: new Date(new Date(monthEnd).setMonth(new Date(monthEnd).getMonth() + (opt.monthEndRange || 1))),
    };
};
