var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import clsx from 'clsx';
import { theme } from '../../../../../theme';
import { TextOverflow } from '../../../../TextOverflow';
import { PriceComponent } from '../../../../PriceComponent';
import { useMainStyles } from '../../../../../styles';
import { OrderQuoteStatus } from '../../../../../models';
import { BaseUserAppTYpe } from '../../../../../stores/common';
import { dateViewFormat } from '../../../../DateView';
import { inject } from 'mobx-react';
import BaseTooltip from '../../../../BaseTooltip';
import { formatPhoneNumber } from '../../../../../utils1';
var useStyles = makeStyles(function (theme) {
    var _a, _b;
    return createStyles({
        root: (_a = {
                width: 228,
                minHeight: 104
            },
            _a[theme.breakpoints.down('sm')] = {
                width: '100%',
                minHeight: '100%',
            },
            _a),
        details: {
            borderColor: '#BAB8B8',
            borderTop: '1px solid',
            borderLeft: '1px solid',
            borderRight: '1px solid',
            borderRadius: 3,
        },
        button: {
            flex: 1,
            borderRadius: 0,
        },
        actions: (_b = {},
            _b[theme.breakpoints.down('xs')] = {
                flexWrap: 'nowrap',
                flexDirection: 'column',
            },
            _b),
    });
});
export var OrderQuoteCardHeader = inject('store')(function (_a) {
    var _b, _c, _d, _e, _f;
    var order = _a.order, record = _a.record, noSpacing = _a.noSpacing, hidePrice = _a.hidePrice, _g = _a.store.accountStore, currentUser = _g.currentUser, currentCompany = _g.currentCompany;
    var className = useStyles();
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isOwn = ((_b = currentCompany._id) === null || _b === void 0 ? void 0 : _b.toString()) === ((_d = (_c = order === null || order === void 0 ? void 0 : order.createdBy) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d._id.toString());
    var isSenderConsignee = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
        return currentUser.appType.includes(t);
    });
    var nestedStyles = (!noSpacing && {
        padding: theme.spacing(0, 2),
    }) ||
        {};
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    if (isSmall) {
        Object.assign(nestedStyles, {
            padding: theme.spacing(0, 0.5),
            flexWrap: 'nowrap',
            flexDirection: 'column',
        });
    }
    return (React.createElement(Grid, { container: true, alignItems: 'center', className: (!noSpacing && className.details) || '' },
        React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', style: nestedStyles },
            (!isCustomer || (isCustomer && isOwn)) && (!isSenderConsignee || (isSenderConsignee && isOwn)) ? (React.createElement(TextOverflow, { variant: 'subtitle1', style: {
                    maxWidth: "calc(100% - " + (record.comments && record.comments.length ? 100 : 50) + "px)",
                } }, ((record.createdBy && [
                record.createdBy.company.name,
                [record.createdBy.firstName || '', record.createdBy.lastName || ''].filter(function (e) { return e; }).join(' '),
                record.createdBy.email,
                formatPhoneNumber(record.createdBy.phone),
            ]) ||
                [])
                .filter(function (e) { return e; })
                .join(', ') || '-')) : null,
            React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', style: { width: 'initial' } }, record.comments && record.comments[0] && record.comments[0].text ? (React.createElement(BaseTooltip, { title: record.comments[0].text },
                React.createElement(QuestionAnswerIcon, { color: 'primary' }))) : null)),
        record.amount === null ? (React.createElement(TextOverflow, { variant: 'subtitle1', style: __assign(__assign({}, nestedStyles), { height: 20 }) }, "-")) : (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', style: nestedStyles },
            React.createElement(TextOverflow, { color: 'primary', variant: 'subtitle1', style: {
                    maxWidth: "calc(100% - " + (((((_f = (_e = record.amount) === null || _e === void 0 ? void 0 : _e.toString()) === null || _f === void 0 ? void 0 : _f.length) || 0) + (record.currency || order.currency).length + 1) * 9 + 2) + "px)",
                } }, ((record.tempTransportations && record.tempTransportations.length && record.tempTransportations) ||
                (order.transportations && order.transportations.length && order.transportations) ||
                [])
                .map(function (e) { return dateViewFormat(e.startDate) + ' - ' + dateViewFormat(e.endDate); })
                .join(',')),
            (!hidePrice && (React.createElement(PriceComponent, { hide: [BaseUserAppTYpe.WAREHOUSE, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
                    return currentUser.appType.includes(t);
                }), amount: record.amount, currency: record.currency || order.currency }))) ||
                null))));
});
export var BaseOrderQuoteCard = function (_a) {
    var _b;
    var actions = _a.actions, store = _a.store, props = __rest(_a, ["actions", "store"]);
    var className = useStyles();
    var mainClassName = useMainStyles();
    return (React.createElement(Grid, { container: true, className: clsx(className.root), justify: 'space-between', direction: 'row' },
        React.createElement(OrderQuoteCardHeader, __assign({}, props)),
        React.createElement(Grid, { container: true, className: clsx(className.actions, (_b = {},
                _b[mainClassName.disabled] = props.record.amount === null ||
                    (props.record.status !== OrderQuoteStatus.Requested &&
                        store &&
                        store.accountStore.currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER)),
                _b)) }, actions)));
};
