var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid, LinearProgress, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import StepConnector from '@material-ui/core/StepConnector';
import Stepper from '@material-ui/core/Stepper';
import clsx from 'clsx';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { TextOverflow } from './TextOverflow';
import { makeStyles } from '@material-ui/core/styles';
import Check from '@material-ui/icons/Check';
import { theme } from '../theme';
import { ColorlibStepIconV1, QontoConnectorV1, useStepperStylesV1 } from './Order/List/OrderProgress';
import { MdiAccountCogOutlineIcon, TruckOkIcon } from './icons';
import QontoStepIcon from './StepsQuntoStepIcon';
import { useStatusFocusStyles } from '../styles/main';
var styles = makeStyles(function (theme) {
    var _a;
    return ({
        root: (_a = {
                width: '100%',
                padding: theme.spacing(2, 0)
            },
            _a[theme.breakpoints.down('sm')] = {
                '& .MuiStepLabel-vertical': {
                    '& .MuiStepLabel-iconContainer': {
                        padding: 0,
                    },
                },
                '& .MuiStepConnector-vertical': {
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    '& .MuiStepConnector-line': {
                    // marginLeft: theme.spacing(-2),
                    },
                },
            },
            _a),
    });
});
var QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: theme.palette.grey['400'],
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);
export var iconSelected = function (_a) {
    var steps = _a.steps;
    return function (getIconClass) {
        var _a, _b, _c, _d, _e, _f;
        return ({
            1: {
                icon: React.createElement(Check, { className: getIconClass(1) }),
                title: (_a = steps[0]) === null || _a === void 0 ? void 0 : _a.label,
                titleLabel: (_b = steps[0]) === null || _b === void 0 ? void 0 : _b.titleLabel,
            },
            2: {
                icon: React.createElement(MdiAccountCogOutlineIcon, { className: getIconClass(2) }),
                title: (_c = steps[1]) === null || _c === void 0 ? void 0 : _c.label,
                titleLabel: (_d = steps[1]) === null || _d === void 0 ? void 0 : _d.titleLabel,
            },
            3: {
                icon: React.createElement(TruckOkIcon, { className: getIconClass(3) }),
                title: (_e = steps[2]) === null || _e === void 0 ? void 0 : _e.label,
                titleLabel: (_f = steps[2]) === null || _f === void 0 ? void 0 : _f.titleLabel,
            },
        });
    };
};
export default (function (_a) {
    var steps = _a.steps, v1 = _a.v1, v2 = _a.v2, isProgress = _a.isProgress, activeIndex = _a.activeIndex, disabled = _a.disabled, content = _a.content, isFocused = _a.isFocused, props = __rest(_a, ["steps", "v1", "v2", "isProgress", "activeIndex", "disabled", "content", "isFocused"]);
    var stepperClasses = useStepperStylesV1();
    var index = v1 || v2 ? steps.findIndex(function (e) { return props.current === e._id; }) : steps.filter(function (e) { return e.active; }).length + 1;
    var _b = __read(React.useState(disabled ? -1 : activeIndex ? activeIndex : index < 0 ? 0 : index), 1), activeStep = _b[0];
    var classes = styles(); //
    var classesFocus = useStatusFocusStyles();
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var stylesContent = {};
    return (React.createElement(Grid, { container: true, direction: 'column', justify: "center", style: __assign({}, (v2 && { marginBottom: theme1.spacing(1), maxHeight: theme1.spacing(6) })) },
        React.createElement(Stepper, { alternativeLabel: isProgress, connector: isProgress ? React.createElement(QontoConnectorV1, null) : React.createElement(QontoConnector, null), activeStep: activeStep, orientation: isProgress ? undefined : isSmall ? 'vertical' : 'horizontal', style: isProgress
                ? { padding: 0, backgroundColor: 'transparent' }
                : v2
                    ? {}
                    : __assign({}, (props.loading && { pointerEvents: 'none', opacity: 0.7 })), classes: { root: classes.root } }, steps.map(function (step, index) {
            var _a;
            var StepIconComponent = isProgress
                ? ColorlibStepIconV1
                : content && (v1 || v2)
                    ? function (p) { return content(__assign(__assign(__assign({}, props), p), { step: step, v1: v1, v2: v2, isProgress: isProgress, activeIndex: activeIndex, disabled: disabled })); }
                    : QontoStepIcon;
            return (React.createElement(Step, { key: step._id || step.label, className: clsx((_a = {}, _a[classesFocus.root] = isFocused && activeStep === index, _a)), classes: isProgress ? { root: stepperClasses.stepRoot } : {}, style: __assign(__assign({}, (v2 ? {} : __assign(__assign({}, (index === 0 && !isSmall && !v2 ? { paddingLeft: 0 } : {})), props.stepStyle))), stylesContent) },
                React.createElement(StepLabel, { StepIconProps: {
                        icons: iconSelected({ steps: steps }),
                        disabled: disabled,
                    }, StepIconComponent: StepIconComponent }, (!(v1 || v2) && React.createElement(TextOverflow, null, step.labelShort || step.label)) || null)));
        })),
        props.loading && React.createElement(LinearProgress, null)));
});
