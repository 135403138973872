import { applySnapshot, types } from 'mobx-state-tree';
export var OrderVehicleType = types
    .model('OrderVehicleType', {
    _id: types.maybeNull(types.optional(types.identifier, '')),
    createdAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    updatedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    deletedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    approvedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    name: types.optional(types.string, ''),
    description: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    isTermo: types.maybeNull(types.optional(types.boolean, false)),
    isGlobal: types.maybeNull(types.optional(types.boolean, false)),
    orderIndex: types.maybeNull(types.number),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
