var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { makeStyles, createStyles } from '@material-ui/core/styles';
export var useStyles1 = makeStyles(function (theme) {
    return createStyles({
        root: {},
        countryIcon: {
            width: 24,
            height: 14,
        },
    });
});
var white = 'rgba(0, 0, 0, 0.12)';
var mobileBox = function (theme, color) {
    if (color === void 0) { color = theme.palette.common.white; }
    return ({
        borderBottom: "1px solid " + white,
        position: 'relative',
        '&::before': {
            content: "' '",
            width: 0,
            height: 0,
            bottom: 1,
            left: '50%',
            transform: 'translate(-50%,100%)',
            zIndex: 2,
            borderWidth: 14,
            borderStyle: 'solid',
            position: 'absolute',
            borderColor: color + " transparent transparent transparent",
        },
        '&::after': {
            content: "' '",
            display: 'inline-block',
            // transform: 'translateX(-50%)',
            borderWidth: 14,
            borderStyle: 'solid',
            borderColor: white + "  transparent transparent transparent ",
            width: 0,
            height: 0,
            bottom: -1,
            left: '50%',
            transform: 'translate(-50%,100%)',
            position: 'absolute',
            zIndex: 1,
        },
    });
};
export var useStyles = makeStyles(function (theme) {
    var _a, _b, _c, _d, _e, _f;
    return createStyles({
        item: {},
        itemExpandedElement: {},
        itemExpandedElementV3: (_a = {},
            // position: 'relative',
            // '&::after': {
            //   content: "' '",
            //   position: 'absolute',
            //   right: 0,
            //   top: '50%',
            //   transform: 'translateY(-50%)',
            //   width: 2,
            //   height: '100%',
            //   backgroundColor: theme.palette.grey[400],
            // },
            _a[theme.breakpoints.up('sm')] = {
                borderRight: "1px solid " + white,
                position: 'relative',
                '&::before': {
                    borderColor: "transparent transparent transparent #F6F6F6!important",
                },
            },
            _a),
        itemExpanded: {
        // position: 'relative',
        // paddingLeft: theme.spacing(3),
        // '&::before': {
        //   content: "' '",
        //   position: 'absolute',
        //   left: theme.spacing(2),
        //   top: 32,
        //   marginTop: -4,
        //   width: 10,
        //   height: 10,
        //   borderRadius: '50%',
        //   backgroundColor: theme.palette.grey[400],
        // },
        },
        root: (_b = {},
            _b[theme.breakpoints.up('md')] = {
                height: '100%',
            },
            _b[theme.breakpoints.down('sm')] = __assign({}, mobileBox(theme)),
            _b),
        rootAdditional: (_c = {},
            _c[theme.breakpoints.up('md')] = {
                // borderRight: `1px solid ${white}`,
                position: 'relative',
                '&::before': {
                    content: "' '",
                    width: 0,
                    height: 0,
                    right: -2,
                    top: theme.spacing(4.5),
                    transform: 'translate(89%,-50%)',
                    zIndex: 2,
                    borderWidth: 10,
                    borderStyle: 'solid',
                    position: 'absolute',
                    borderColor: "  transparent transparent transparent " + theme.palette.common.white + "!important",
                },
                '&::after': {
                    content: "' '",
                    display: 'inline-block',
                    // transform: 'translateX(-50%)',
                    borderWidth: 11,
                    borderStyle: 'solid',
                    borderColor: "transparent transparent transparent " + white + "  ",
                    width: 0,
                    height: 0,
                    right: -3,
                    top: theme.spacing(4.5),
                    transform: 'translate(89%,-50%)',
                    position: 'absolute',
                    zIndex: 1,
                },
            },
            _c),
        rootAdditionalGrey: (_d = {},
            _d[theme.breakpoints.up('md')] = {
                '&::before': {
                    borderColor: "  transparent transparent transparent " + theme.palette.grey['100'] + "!important",
                },
            },
            _d),
        rootV1: __assign(__assign((_e = {}, _e[theme.breakpoints.up('md')] = {
            height: 'auto',
        }, _e), mobileBox(theme)), { paddingBottom: theme.spacing(1), marginBottom: theme.spacing(1) }),
        rootV1Grey: __assign(__assign((_f = {}, _f[theme.breakpoints.up('md')] = {
            height: 'auto',
        }, _f), mobileBox(theme, theme.palette.grey['100'])), { paddingBottom: theme.spacing(1), marginBottom: theme.spacing(1) }),
    });
});
