import { makeStyles, createStyles } from '@material-ui/core/styles';
import { DISABLED_COLOR } from '../components/BaseCalendar/MonthViewV1/style';
import { CALENDAR_ELEMENTS } from '../components/BaseCalendar/types';
export var COLOR_PICKUP = '#64dd17';
export var COLOR_DELIVERY = '#0091ea';
export var SEARCH_COLOR = '#cbf4eb';
export var useAnimationStyles = makeStyles(function (theme) {
    return createStyles({
        pulse: {
            '&::before': {
                content: "' '",
                display: 'block',
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                backgroundColor: 'transparent',
                borderRadius: 'inherit',
                transition: 'opacity .3s, -webkit-transform .3s',
                animationDelay: '1s',
                animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1)',
                animationIterationCount: 2,
                zIndex: 1,
            },
        },
    });
});
export var useMainAreaStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            maxWidth: 1920,
            left: '50%',
            transform: 'translateX(-50%)',
        },
        rootV1: {
            maxWidth: 1920,
        },
    });
});
export var useFormInputStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            marginBottom: theme.spacing(2),
        },
        rootV1: {
            marginBottom: theme.spacing(1),
        },
        rootV2: {
            marginBottom: theme.spacing(3),
        },
    });
});
export var useUserTypeFormStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            border: "2px solid " + theme.palette.grey['400'],
            borderRadius: 6,
            cursor: 'pointer',
            '&:hover': {
                borderColor: theme.palette.primary.main,
            },
        },
        active: {
            borderColor: theme.palette.primary.main,
            '& *': {
                color: theme.palette.primary.main,
                fill: theme.palette.primary.main,
            },
        },
    });
});
export var useIconStyles = makeStyles(function (theme) {
    return createStyles({
        iconDisabled: {
            '& *': {
                fill: theme.palette.grey[600],
            },
        },
        iconOption: {
            width: 92,
            height: 24,
            display: 'block',
            '& svg': {
                width: 92,
                height: 24,
            },
        },
        icon: {
            width: theme.spacing(3),
            height: theme.spacing(3),
            display: 'block',
            '& svg': {
                width: theme.spacing(3),
                height: theme.spacing(3),
            },
        },
        iconMD: {
            width: 18,
            height: 18,
            display: 'block',
            '& svg': {
                width: 18,
                height: 18,
            },
        },
        iconActive: {
            '&.izi-svg-1': {
                width: 24,
                height: 24,
                '& *': {
                    stroke: theme.palette.common.white,
                    fill: theme.palette.common.white,
                },
                '& .izi-svg-path': {
                    stroke: theme.palette.primary.light,
                    fill: theme.palette.primary.light,
                },
            },
        },
        iconMapRoot: {
            '& svg': {
                marginTop: -74,
                marginLeft: -32,
                transform: "translateY(-20px)",
                backgroundColor: 'rgba(0,0,0,0)',
            },
            '&:hover': {
                '& svg': {
                    backgroundColor: 'rgb(242, 160, 71)',
                    '& path': {
                        fill: 'rgb(255,255,255)',
                    },
                },
                '&::after': {
                    content: "' '",
                    position: 'absolute',
                    top: ' 50%',
                    right: -2,
                    width: 0,
                    height: 0,
                    zIndex: -2,
                    borderColor: 'transparent transparent transparent rgb(242, 160, 71)',
                    borderStyle: 'solid',
                    borderWidth: 19,
                    transform: 'translate(45%,-120%) rotate(-133deg)',
                },
            },
        },
        iconMap: {
            width: 64,
            height: 64,
            backgroundColor: theme.palette.common.white,
            borderRadius: '50%',
            padding: theme.spacing(1),
            '& path': {
                fill: theme.palette.primary.light,
            },
        },
        iconStatus: {
            width: 22,
            height: 22,
            '&.izi-svg': {
                '& *': {
                    fill: 'inherit',
                    stroke: theme.palette.success.light,
                },
                '& .izi-svg-path': {
                    fill: theme.palette.success.light,
                },
            },
            '&.izi-svg-1': {
                '& *': {
                    stroke: theme.palette.success.light,
                    fill: theme.palette.success.light,
                },
                '& .izi-svg-path': {
                    fill: theme.palette.common.white,
                    stroke: theme.palette.common.white,
                },
            },
            '& path': {
                fill: theme.palette.success.light,
            },
        },
        iconInvoice: {
            fill: theme.palette.success.light,
        },
        iconCMR: {
            width: 20,
            height: 20,
            '& .primary': {
                fill: theme.palette.success.light,
            },
            '& .secondary': {
                fill: theme.palette.secondary.light,
                stroke: theme.palette.secondary.light,
            },
        },
        orderListDefaultIcon: {
            fill: theme.palette.secondary.main,
            '& .primary': {
                fill: theme.palette.secondary.main,
            },
            '& .secondary': {
                stroke: theme.palette.secondary.main,
            },
        },
        orderListIcon: {
            fill: theme.palette.primary.main,
            '& .primary': {
                fill: theme.palette.primary.main,
            },
            '& .secondary': {
                stroke: theme.palette.primary.main,
            },
        },
        orderListMenu: {
            '& *': {
                fill: theme.palette.primary.main,
            },
        },
        offerComment: {
            '& *': {
                fill: theme.palette.primary.main,
            },
        },
        iconTimacom: {
            width: 20,
            height: 20,
            '& .primary': {
                fill: theme.palette.success.light,
            },
            '& .secondary': {
                fill: theme.palette.secondary.light,
                stroke: theme.palette.secondary.light,
            },
        },
        iconTimacomPrimary: {
            '& *': {
                fill: theme.palette.primary.light,
            },
        },
        iconTimacomError: {
            '& *': {
                fill: theme.palette.error.light,
            },
        },
        iconTimacomSuccess: {
            '& *': {
                fill: theme.palette.success.light,
            },
        },
        iconPrimary: {
            '& *': {
                stroke: theme.palette.primary.main,
            },
        },
        iconWhitePrimary: {
            '& *': {
                // stroke: theme.palette.common.white,
                color: theme.palette.common.white,
            },
        },
        menuIcon: {
            '& *': {
                fill: theme.palette.secondary[900],
            },
        },
    });
});
export var useMainStyles = makeStyles(function (theme) {
    return createStyles({
        groupCheckBox: {
            '& .MuiFormControlLabel-root': {
                marginRight: 0,
            },
        },
        innerHtml: {
            '& *': {
                margin: 0,
                padding: 0,
            },
        },
        textSuccess: {
            color: theme.palette.success.light,
        },
        textError: {
            color: theme.palette.error.light,
        },
        table: {
            borderCollapse: 'collapse',
            width: '100%',
            '& th': {
                border: "1px solid #ddd",
                padding: 8,
                paddingTop: 12,
                paddingBottom: 12,
                textAlign: 'left',
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.white,
            },
            '& td': {
                border: "1px solid #ddd",
                padding: 8,
            },
            '& tr:nth-child(even)': {
                backgroundColor: '#f2f2f2',
            },
            '& tr:hover': {
                backgroundColor: '#ddd',
            },
        },
        widthInitial: {
            position: 'relative',
            width: 'initial',
        },
        sup: {
            top: 0,
            right: 0,
            position: 'absolute',
            fontSize: 12,
            transform: 'translate(100%,-15%)',
        },
        disabled: {
            pointerEvents: 'none',
            opacity: 0.75,
            boxShadow: 'none',
            '& *': {
                boxShadow: 'none!important',
            },
        },
        opacity: {
            opacity: 0.4,
        },
        iconDisabled: {
            '& path': {
                fill: theme.palette.grey[400],
            },
        },
        icon: {
            width: 22,
            height: 22,
            '& path': {
                fill: 'red',
            },
        },
        hidden: {
            display: 'none',
        },
        link: {
            textDecoration: 'none',
            cursor: 'pointer',
        },
        textField: {
            width: '100%',
            '& input': {
                padding: theme.spacing(1.2),
                maxWidth: 150,
                width: '100%',
            },
            '& .MuiInputBase-input': {
                padding: theme.spacing(1.2),
                maxWidth: 150,
                width: '100%',
                '&.MuiSelect-select': {
                    paddingRight: theme.spacing(4),
                },
            },
        },
    });
});
export var useSectionStyles = makeStyles(function (theme) {
    var _a;
    return ({
        section: (_a = {
                width: '100%',
                // marginBottom: theme.spacing(2),
                padding: theme.spacing(4, 0),
                '& form': {
                    width: '100%',
                }
            },
            _a[theme.breakpoints.down('sm')] = {
                padding: theme.spacing(2),
            },
            _a),
        marginV1: {
            marginLeft: theme.spacing(1) + "px!important",
        },
        sectionItem: {
            width: '100%',
            backgroundColor: theme.palette.common.white,
            border: '1px solid #D5DDE5',
        },
        sectionTitle: {
            color: theme.palette.success.light,
            fontWeight: "900!important",
        },
        btnTitle: {
            color: theme.palette.primary.main,
            fontWeight: "500!important",
        },
        button: {},
        focusSections: {
            boxShadow: '0px 1px 6px 6px rgb(0 0 0 / 10%)',
        },
        title: {
            margin: theme.spacing(2, 0, 3, 0),
        },
        check: {
            marginLeft: theme.spacing(1),
        },
        group: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        margin: {
            marginBottom: theme.spacing(2),
        },
    });
});
export var useGridStyles = makeStyles(function (theme) { return ({
    borderTop: {
        borderTop: "1px solid " + theme.palette.grey[300],
    },
}); });
export var useOptionsStyles = makeStyles(function (theme) { return ({
    root: {
        maxWidth: "calc(100% - 40px)",
    },
}); });
export var useAppTypeStyles = makeStyles(function (theme) { return ({
    customer: {
        backgroundColor: '#ffa726!important',
    },
    carrier: {
        backgroundColor: '#e65100!important',
    },
    warehouse: {
        backgroundColor: '#00e676!important',
    },
    forwarder: {
        backgroundColor: '#0091ea!important',
    },
    sender: {
        backgroundColor: '#cf7411!important',
    },
    system: {
        backgroundColor: '#cf7411!important',
    },
}); });
export var useCalendarFilterStyles = makeStyles(function (theme) {
    var _a;
    return (_a = {
            unavailable: {
                backgroundColor: DISABLED_COLOR,
                height: '100%',
            },
            unavailableV1: {
                backgroundColor: '#D6DCE7',
                height: '100%',
            },
            search: {
                backgroundColor: SEARCH_COLOR,
                height: '100%',
            },
            root: {
                color: theme.palette.common.white,
                padding: theme.spacing(0, 0.5),
                display: 'flex',
                height: 25,
                '& path': {
                    fill: theme.palette.common.white,
                },
                '& *': {
                    fill: theme.palette.common.white,
                    stroke: theme.palette.common.white,
                },
                '& svg': {
                    maxWidth: 15,
                    maxHeight: 15,
                },
            },
            disabled: {
                backgroundColor: theme.palette.grey['300'] + "!important",
                '& .izi-svg-path': {
                    fill: theme.palette.grey['300'] + "!important",
                    stroke: theme.palette.grey['300'] + "!important",
                },
            },
            disabledV1: {
                pointerEvents: 'none',
            }
        },
        _a[CALENDAR_ELEMENTS.ARCHIVE] = {
            '& *': {
                color: theme.palette.grey['400'] + "!important",
            },
            'div &:hover': {
                opacity: 0.7,
                backgroundColor: theme.palette.grey['600'],
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: theme.palette.grey['600'],
                        fill: theme.palette.grey['600'],
                    },
                },
            },
            'div &:focus': {
                backgroundColor: theme.palette.grey['600'],
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: theme.palette.grey['600'],
                        fill: theme.palette.grey['600'],
                    },
                },
            },
            '& .izi-svg-1': {
                '& .izi-svg-path': {
                    stroke: theme.palette.grey['600'],
                    fill: theme.palette.grey['600'],
                },
            },
            '&::before': {
                backgroundColor: theme.palette.grey['600'],
            },
            '&.izi-chip': {
                backgroundColor: theme.palette.grey['600'],
            },
        },
        _a[CALENDAR_ELEMENTS.ORDERS_PLAN_PICKUP] = {
            'div &:hover': {
                opacity: 0.7,
                backgroundColor: '#FF9900',
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: '#FF9900',
                        fill: '#FF9900',
                    },
                },
            },
            'div &:focus': {
                backgroundColor: '#FF9900',
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: '#FF9900',
                        fill: '#FF9900',
                    },
                },
            },
            '& .izi-svg-1': {
                '& .izi-svg-path': {
                    stroke: '#FF9900',
                    fill: '#FF9900',
                },
            },
            '&::before': {
                backgroundColor: "#FF9900",
            },
            '&.izi-chip': {
                backgroundColor: "#FF9900",
            },
        },
        _a[CALENDAR_ELEMENTS.ORDERS_PLAN_DELIVERY] = {
            'div &:hover': {
                opacity: 0.7,
                backgroundColor: '#40c4ff',
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: '#40c4ff',
                        fill: '#40c4ff',
                    },
                },
            },
            'div &:focus': {
                backgroundColor: '#40c4ff',
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: '#40c4ff',
                        fill: '#40c4ff',
                    },
                },
            },
            '& .izi-svg-1': {
                '& .izi-svg-path': {
                    stroke: '#40c4ff',
                    fill: '#40c4ff',
                },
            },
            '&::before': {
                backgroundColor: "#40c4ff",
            },
            '&.izi-chip': {
                backgroundColor: "#40c4ff",
            },
        },
        _a[CALENDAR_ELEMENTS.ORDERS_PICKUP] = {
            'div &:hover': {
                opacity: 0.7,
                backgroundColor: COLOR_PICKUP,
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: COLOR_PICKUP,
                        fill: COLOR_PICKUP,
                    },
                },
            },
            'div &:focus': {
                backgroundColor: COLOR_PICKUP,
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: COLOR_PICKUP,
                        fill: COLOR_PICKUP,
                    },
                },
            },
            '& .izi-svg-1': {
                '& .izi-svg-path': {
                    stroke: COLOR_PICKUP,
                    fill: COLOR_PICKUP,
                },
            },
            '&::before': {
                backgroundColor: COLOR_PICKUP,
            },
            '&.izi-chip': {
                backgroundColor: COLOR_PICKUP,
            },
        },
        _a[CALENDAR_ELEMENTS.ORDERS_DELIVERY] = {
            'div &:focus': {
                backgroundColor: COLOR_DELIVERY,
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: COLOR_DELIVERY,
                        fill: COLOR_DELIVERY,
                    },
                },
            },
            'div &:hover': {
                opacity: 0.7,
                backgroundColor: COLOR_DELIVERY,
                '& .izi-svg-1': {
                    '& .izi-svg-path': {
                        stroke: COLOR_DELIVERY,
                        fill: COLOR_DELIVERY,
                    },
                },
            },
            '& .izi-svg-1': {
                '& .izi-svg-path': {
                    stroke: COLOR_DELIVERY,
                    fill: COLOR_DELIVERY,
                },
            },
            '&.izi-chip': {
                backgroundColor: COLOR_DELIVERY,
            },
            '&::before': {
                backgroundColor: COLOR_DELIVERY,
            },
        },
        _a[CALENDAR_ELEMENTS.HOLIDAYS] = {
            '&.MuiChip-root': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.white,
                '& *': {
                    color: theme.palette.common.white,
                },
                'div &:focus': {
                    backgroundColor: theme.palette.primary.light,
                },
                'div &:hover': {
                    opacity: 0.7,
                    backgroundColor: theme.palette.primary.light,
                },
            },
        },
        _a[CALENDAR_ELEMENTS.NONE_WORKING_DAY] = {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
            '& *': {
                color: theme.palette.common.white,
            },
            'div &:focus': {
                backgroundColor: theme.palette.primary.light,
            },
            'div &:hover': {
                opacity: 0.7,
                backgroundColor: theme.palette.primary.light,
            },
            // '& *': {
            //   color: `${theme.palette.grey['400']}!important`,
            // },
            '& .izi-svg-1': {
                '& .izi-svg-path': {
                    stroke: theme.palette.grey['600'],
                    fill: theme.palette.grey['600'],
                },
            },
            '&::before': {
                backgroundColor: theme.palette.primary.light,
            },
            '&.izi-chip': {
                backgroundColor: theme.palette.primary.light,
            },
        },
        _a);
});
export var usePopOverStyles = makeStyles(function (theme) { return ({
    root: {
        flexGrow: 1,
    },
    legend: {
        marginTop: theme.spacing(2),
        maxWidth: 300,
    },
    paper: {
        maxWidth: 400,
        overflow: 'auto',
    },
    select: {
        width: 200,
    },
    popper: {
        zIndex: 1,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: "transparent transparent " + theme.palette.background.paper + " transparent",
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: theme.palette.background.paper + " transparent transparent transparent",
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: "transparent " + theme.palette.background.paper + " transparent transparent",
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: "transparent transparent transparent " + theme.palette.background.paper,
            },
        },
    },
    closeButton: {
        position: 'absolute',
        right: 0,
        top: 24,
        color: theme.palette.grey[500],
        transform: 'translateY(-50%)',
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
}); });
export var useStatusFocusStyles = makeStyles(function (theme) { return ({
    root: {
        '& .MuiStepLabel-iconContainer': {
            position: 'relative',
            '& div[data-id="izi-order-progress-icon-tootip"]': {
                '&::before': {
                    content: "' '",
                    display: 'block',
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    backgroundColor: 'inherit',
                    borderRadius: 'inherit',
                    transition: 'opacity .3s, -webkit-transform .3s',
                    animationDelay: '1s',
                    animation: 'pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1)',
                    animationIterationCount: 2,
                    zIndex: -1,
                },
            },
        },
    },
}); });
export var usePreloadederBlocker = makeStyles(function (theme) { return ({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        background: 'rgba(255,255,255,0.4)',
        zIndex: 100,
    },
}); });
