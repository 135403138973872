var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as material from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import { getSnapshot } from 'mobx-state-tree';
import * as Yup from 'yup';
import { useLocalStore } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/styles';
import { useFormStyles } from '../../../../../styles/form';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { ACTION_CHANGE } from '../../../../BasicList';
import TermConfirmation from '../../../../TermConfirmation';
import InfoIcon from '@material-ui/icons/Info';
import { OrderCargoType, OrderCurrency, OrderLocation, OrderQuoteCOVehicleType, OrderQuoteFastPaymentStatus, OrderQuotes, OrderRouteType, } from '../../../../../models';
import { FormikSelect, FormikTextField } from '../../../../Formik';
import { CreateOrderQuote, UpdateOrderQuote, CheckOrderOfferPrice } from '../../../../../graphql/order';
import { BasicDialog } from '../../../../Dialog';
import { FormikSingleDatePicker } from '../../../../DateTimePicker';
import { MainButton } from '../../../../MainButton';
import { TextOverflow } from '../../../../TextOverflow';
import { KEYS, i18n } from '../../../../../i18n';
import { onlySpacesRegExp } from '../../../../../common';
import { HelpIconComponent } from '../../../../HelpIcon';
import { checkIsDevEnv, diffBetweenDatesInDays, sleep, toDateOnly, toDateOnlyV1, } from '../../../../../utils';
import { FetchComments, UpdateComment } from '../../../../../graphql';
import { TERMS_REQUEST } from '../../../../../models/Terms';
import { theme } from '../../../../../theme';
import { observer } from 'mobx-react';
import { priceValue } from '../../../../PriceComponent';
import { withOrderListItemStore } from '../../Customer/storeListItem';
import { BaseOrderQuoteTransportationCreateForm } from './BaseOrderQuoteTransportationCreateForm';
import { getCargoInfo, googleTagManager } from '../../../../../utils1';
import { dateViewFormatV1 } from '../../../../DateView';
import BaseTooltip from '../../../../BaseTooltip';
var Box = material.Box;
import { inject } from 'mobx-react';
import { BaseUserAppTYpe } from '../../../../../stores';
import PreloadingComponent from '../../../../PreloadingComponent';
import { GuideTypes, getTourSteps } from '../../../../../guides';
import BaseIconButton from '../../../../BaseIconButton';
import { basicParseFloat, yupTransformFloatNumber } from '../../../../../utils3';
var Grid = material.Grid, MenuItem = material.MenuItem;
var BasicTour = React.lazy(function () { return import('../../../../BasicTour'); });
var BaseOrderQuoteCreationForCA = React.lazy(function () { return import('./BaseOrderQuoteCreationForCA'); });
var useStyles = makeStyles(function (theme) {
    var _a;
    return ({
        quoteDetails: {
            // padding: theme.spacing(2, 0, 2, 2),
            display: 'flex',
            width: '100%',
        },
        button: (_a = {
                margin: theme.spacing(0, 0, 1, 0)
            },
            _a[theme.breakpoints.down('sm')] = {
                margin: theme.spacing(2, 0, 1, 0),
            },
            _a),
        submit: {
            margin: theme.spacing(3, 0, 2),
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.grey['100'],
        },
    });
});
var request = null;
var isAcceptedFastPayment = false;
var BaseOrderQuoteCreationFormComponent = React.forwardRef(function (props, ref) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    var _q = props.defaultValue, defaultValue = _q === void 0 ? {} : _q, order = props.order, orderListItemProps = props.orderListItemProps, onChange = props.onChange, record = props.record, config = props.config, canManage = props.canManage, editRef = props.editRef, v1 = props.v1, v2 = props.v2, isOwn = props.isOwn, isTrading = props.isTrading, store = props.store;
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langMenu = useTranslation(KEYS.MENU);
    var langApp = useTranslation(KEYS.APP);
    var langToast = useTranslation(KEYS.TOAST);
    var langForm = useTranslation(KEYS.FORM);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var classesFormStyles = useFormStyles();
    var classes = useStyles();
    var _r = store.accountStore, currentUser = _r.currentUser, isLoggedIn = _r.isLoggedIn;
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var _val = __assign(__assign(__assign({ isForCarrier: false, isDateRange: false }, defaultValue), getSnapshot(OrderQuotes.create(defaultValue))), (defaultValue._id && {
        vehicleCO2Type: defaultValue.vehicleCO2Type || '',
        daysBeforeExpired: diffBetweenDatesInDays(new Date(defaultValue.expireEnd), new Date(defaultValue.createdAt)),
    }));
    if (!_val.amount)
        _val.amount = 0;
    var _s = __read(React.useState(_val), 2), formValues = _s[0], setFormValues = _s[1];
    var inputDialog = React.useRef();
    var mainForm = React.useRef();
    var confirmDialog = React.useRef();
    var dateForms = Array.from({ length: 10 }, function (_) { return React.useRef(); });
    var fastPaymentRef = React.useRef();
    var isSmall = material.useMediaQuery(theme.breakpoints.down('sm'));
    var _t = __read(React.useState(0), 2), averagePrice = _t[0], setAveragePrice = _t[1];
    var requestNewPrice = isOwn || !defaultValue._id;
    var ownerNameOrCompanyName = ((_a = order === null || order === void 0 ? void 0 : order.createdBy) === null || _a === void 0 ? void 0 : _a.firstName) || ((_c = (_b = order === null || order === void 0 ? void 0 : order.createdBy) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.name) || langApp.t('user_app_type_customer');
    var cargoInfo = (((_d = order === null || order === void 0 ? void 0 : order.transportations) === null || _d === void 0 ? void 0 : _d.length) &&
        order.transportations[0].cargoes.length &&
        getCargoInfo(order.transportations[0].cargoes[0], langOrderForm)) ||
        '-';
    var loading = ((_e = order === null || order === void 0 ? void 0 : order.transportations) === null || _e === void 0 ? void 0 : _e.length) && ((_f = order.transportations[0].routes) === null || _f === void 0 ? void 0 : _f.length) &&
        order.transportations[0].routes.find(function (e) { return e.type === OrderRouteType.Preferred; });
    var unloading = ((_g = order === null || order === void 0 ? void 0 : order.transportations) === null || _g === void 0 ? void 0 : _g.length) && ((_h = order.transportations[0].routes) === null || _h === void 0 ? void 0 : _h.length) &&
        order.transportations[0].routes.find(function (e) { return e.type === OrderRouteType.Delivery; });
    var preferredPickupDates = loading
        ? dateViewFormatV1(loading.startDate, 0, 0, store.accountStore.currentUser.language) + " - " + dateViewFormatV1(loading.endDate, 0, 0, store.accountStore.currentUser.language)
        : '-';
    var preferredDeliveryDates = unloading
        ? dateViewFormatV1(unloading.startDate, 0, 0, store.accountStore.currentUser.language) + " - " + dateViewFormatV1(unloading.endDate, 0, 0, store.accountStore.currentUser.language)
        : '-';
    var showCreateOfferGuide = isCarrier &&
        isLoggedIn &&
        currentUser.settings.guides &&
        currentUser.settings.guides[GuideTypes.CARRIER_OPEN_ORDERS_LIST] &&
        !currentUser.settings.guides[GuideTypes.CARRIER_CREATE_OFFER];
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        comments: [],
        onValidate: function () {
            var _a, _b;
            return __awaiter(this, void 0, void 0, function () {
                var validated, i, _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            validated = true;
                            i = 0;
                            _d.label = 1;
                        case 1:
                            if (!(i < dateForms.length)) return [3 /*break*/, 5];
                            if (!dateForms[i].current) return [3 /*break*/, 3];
                            return [4 /*yield*/, ((_b = (_a = dateForms[i]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.submitForm())];
                        case 2:
                            validated = _d.sent();
                            _d.label = 3;
                        case 3:
                            if (!validated)
                                return [3 /*break*/, 5];
                            _d.label = 4;
                        case 4:
                            i++;
                            return [3 /*break*/, 1];
                        case 5:
                            _c = validated;
                            if (!_c) return [3 /*break*/, 7];
                            return [4 /*yield*/, mainForm.current.submitForm()];
                        case 6:
                            _c = (_d.sent());
                            _d.label = 7;
                        case 7: return [2 /*return*/, _c];
                    }
                });
            });
        },
        onSubmit: function (input) {
            var _a, _b, _c, _d;
            var e = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                e[_i - 1] = arguments[_i];
            }
            return __awaiter(this, void 0, void 0, function () {
                var v, i, variables, res;
                return __generator(this, function (_e) {
                    switch (_e.label) {
                        case 0:
                            v = null;
                            i = 0;
                            _e.label = 1;
                        case 1:
                            if (!(i < dateForms.length)) return [3 /*break*/, 5];
                            if (!dateForms[i].current) return [3 /*break*/, 3];
                            return [4 /*yield*/, ((_b = (_a = dateForms[i]) === null || _a === void 0 ? void 0 : _a.current) === null || _b === void 0 ? void 0 : _b.submitForm())];
                        case 2:
                            v = _e.sent();
                            _e.label = 3;
                        case 3:
                            if (!v)
                                return [3 /*break*/, 5];
                            _e.label = 4;
                        case 4:
                            i++;
                            return [3 /*break*/, 1];
                        case 5:
                            if (requestNewPrice && !v) {
                                return [2 /*return*/];
                            }
                            if (input.isForCarrier)
                                return [2 /*return*/, input];
                            if (!(order.cargoType === OrderCargoType.FullTrackLoad)) return [3 /*break*/, 7];
                            return [4 /*yield*/, localStore.onApprove(null, input)];
                        case 6: return [2 /*return*/, _e.sent()];
                        case 7:
                            variables = {
                                filter: {
                                    fields: [
                                        { name: 'offer', value: input },
                                        { name: 'order', value: order },
                                    ],
                                },
                            };
                            return [4 /*yield*/, config.client.query({
                                    query: CheckOrderOfferPrice,
                                    variables: variables,
                                })];
                        case 8:
                            res = _e.sent();
                            if ((_c = res.data.checkOrderOfferPrice) === null || _c === void 0 ? void 0 : _c.isOfferExceed) {
                                setAveragePrice(res.data.checkOrderOfferPrice.averagePrice);
                            }
                            if (!((_d = res.data.checkOrderOfferPrice) === null || _d === void 0 ? void 0 : _d.isOfferExceed)) return [3 /*break*/, 9];
                            confirmDialog.current.click();
                            return [3 /*break*/, 11];
                        case 9: return [4 /*yield*/, localStore.onApprove(null, input)];
                        case 10:
                            _e.sent();
                            _e.label = 11;
                        case 11: return [2 /*return*/];
                    }
                });
            });
        },
        onOk: function (e) {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(!formValues._id && !v1)) return [3 /*break*/, 2];
                            return [4 /*yield*/, localStore.onApprove(e, mainForm.current.values)];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 4];
                        case 2:
                            if (!editRef) return [3 /*break*/, 4];
                            return [4 /*yield*/, localStore.onApprove(null, editRef.current.values)];
                        case 3: return [2 /*return*/, _a.sent()];
                        case 4: return [2 /*return*/];
                    }
                });
            });
        },
        aproveReqBody: function (input) {
            return __awaiter(this, void 0, void 0, function () {
                var tempTransportations, daysBeforeExpired, amount, currency, comment, needApprove, expireEnd, updates, _a;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            tempTransportations = input.tempTransportations, daysBeforeExpired = input.daysBeforeExpired, amount = input.amount, currency = input.currency, comment = input.comment, needApprove = input.needApprove;
                            daysBeforeExpired = parseFloat(daysBeforeExpired.toString());
                            expireEnd = new Date();
                            if (defaultValue._id) {
                                expireEnd = new Date(defaultValue.createdAt);
                            }
                            expireEnd.setDate(expireEnd.getDate() + daysBeforeExpired);
                            updates = Object.assign(__assign({}, (isTrading && { isTrading: isTrading })), (requestNewPrice && {
                                needApprove: needApprove,
                                tempTransportations: tempTransportations.map(function (_a) {
                                    var startDate = _a.startDate, endDate = _a.endDate;
                                    return ({
                                        startDate: toDateOnly(startDate),
                                        endDate: toDateOnly(endDate),
                                    });
                                }),
                            }) ||
                                {}, {
                                order: order._id,
                                amount: basicParseFloat(amount.toString()),
                                distance: basicParseFloat((input.distance || 0).toString().replace(/,/gi, '')),
                                vehicleCO2Type: input.vehicleCO2Type,
                                currency: currency,
                            }, expireEnd ? { expireEnd: expireEnd } : {}, input.isDateRange ? { expireEnd: input.expireEnd } : {}, record ? { parent: record._id } : {}, !record
                                ? {
                                    fastPaymentStatus: isAcceptedFastPayment
                                        ? OrderQuoteFastPaymentStatus.Available
                                        : OrderQuoteFastPaymentStatus.NotAvailable,
                                }
                                : {});
                            if (!localStore.comments.length) return [3 /*break*/, 3];
                            _a = config;
                            if (!_a) return [3 /*break*/, 2];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: UpdateComment,
                                    variables: {
                                        input: {
                                            text: comment,
                                            _id: localStore.comments[0]._id,
                                        },
                                    },
                                })];
                        case 1:
                            _a = (_b.sent());
                            _b.label = 2;
                        case 2:
                            _a;
                            return [3 /*break*/, 4];
                        case 3:
                            if (comment) {
                                Object.assign(updates, { comment: comment });
                            }
                            _b.label = 4;
                        case 4: return [2 /*return*/, {
                                updates: updates,
                            }];
                    }
                });
            });
        },
        onApprove: function (e, input) {
            return __awaiter(this, void 0, void 0, function () {
                var tempTransportations, amount, currency, updates, quote, type, _a, res, _b, e_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (request)
                                return [2 /*return*/];
                            request = 1;
                            _c.label = 1;
                        case 1:
                            _c.trys.push([1, 10, 11, 12]);
                            localStore.error = '';
                            localStore.isLoading = true;
                            return [4 /*yield*/, checkIsDevEnv()];
                        case 2:
                            _c.sent();
                            setFormValues(__assign(__assign({}, input), { updatedAt: new Date().toString() }));
                            tempTransportations = input.tempTransportations, amount = input.amount, currency = input.currency;
                            return [4 /*yield*/, localStore.aproveReqBody(input)];
                        case 3:
                            updates = (_c.sent()).updates;
                            quote = void 0, type = void 0;
                            if (!formValues._id) return [3 /*break*/, 6];
                            _a = config;
                            if (!_a) return [3 /*break*/, 5];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: UpdateOrderQuote,
                                    variables: {
                                        input: __assign(__assign({}, updates), { _id: formValues._id }),
                                    },
                                })];
                        case 4:
                            _a = (_c.sent());
                            _c.label = 5;
                        case 5:
                            quote = _a;
                            quote = quote.data.updateOrderQuote;
                            return [3 /*break*/, 9];
                        case 6:
                            _b = config;
                            if (!_b) return [3 /*break*/, 8];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: CreateOrderQuote,
                                    variables: {
                                        input: updates,
                                    },
                                })];
                        case 7:
                            _b = (_c.sent());
                            _c.label = 8;
                        case 8:
                            res = _b;
                            quote = res.data.createOrderQuote;
                            type = 1;
                            _c.label = 9;
                        case 9:
                            localStore.onFinishCreateOffer({ type: type, amount: amount, currency: currency, updates: updates, tempTransportations: tempTransportations }, quote);
                            return [2 /*return*/, 1];
                        case 10:
                            e_1 = _c.sent();
                            console.log(e_1);
                            toast.error(e_1.message || e_1);
                            localStore.error = e_1.message;
                            return [3 /*break*/, 12];
                        case 11:
                            localStore.isLoading = false;
                            request = 0;
                            setAveragePrice(0);
                            return [7 /*endfinally*/];
                        case 12: return [2 /*return*/];
                    }
                });
            });
        },
        onFinishCreateOffer: function (_a, quote) {
            var _b, _c, _d;
            var type = _a.type, amount = _a.amount, currency = _a.currency, updates = _a.updates, tempTransportations = _a.tempTransportations;
            if (type === 1) {
                googleTagManager('event', "izi-form" + ("_offer_" + (isTrading ? 'trading' : formValues._id ? 'edit' : 'create')), __assign(__assign({}, [
                    'sorting_countryPickup',
                    'sorting_pickupAt',
                    'sorting_countryDelivery',
                    'sorting_deliveryAt',
                    'sorting_totalWeight',
                    'orderId',
                ]
                    .filter(function (e) { return order[e]; })
                    .map(function (e) {
                    var _a;
                    return (_a = {}, _a["order_" + e] = order[e], _a);
                })
                    .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { offer_startDate: toDateOnly(tempTransportations[0].startDate), offer_endDate: toDateOnly(tempTransportations[updates.tempTransportations.length - 1].endDate), offer_amount: amount, offer_currency: currency, event_name: "offer_" + (isTrading ? 'trading' : formValues._id ? 'edit' : 'create') }));
            }
            if (orderListItemProps === null || orderListItemProps === void 0 ? void 0 : orderListItemProps.onChange) {
                orderListItemProps.onChange({
                    record: __assign(__assign(__assign({}, (_b = orderListItemProps.state) === null || _b === void 0 ? void 0 : _b.record), (!formValues._id ? { totalQuotes: (((_d = (_c = orderListItemProps.state) === null || _c === void 0 ? void 0 : _c.record) === null || _d === void 0 ? void 0 : _d.totalQuotes) || 0) + 1 } : {})), { updatedAt: new Date().toString(), latestQuote: quote }),
                });
            }
            order.latestQuote = __assign(__assign({}, order.latestQuote), quote);
            setTimeout(function () {
                if (inputDialog.current)
                    inputDialog.current.click();
            }, 500);
        },
        clear: function () {
            localStore.error = '';
        },
    }); });
    var onClose = function () {
        if (orderListItemProps === null || orderListItemProps === void 0 ? void 0 : orderListItemProps.onChange) {
            orderListItemProps.onChange({
                record: __assign(__assign({}, orderListItemProps.state.record), { updatedAt: new Date().toString() }),
            });
        }
        else {
            if (onChange)
                onChange(order, ACTION_CHANGE.DELETED);
        }
    };
    var onChangeTranportation = function (newTransportation, values) {
        setFormValues(__assign(__assign(__assign({}, formValues), values), { updatedAt: new Date().toString(), tempTransportations: formValues.tempTransportations.map(function (e) {
                if (e._id === newTransportation._id) {
                    newTransportation.updatedAt = new Date().toString();
                    return newTransportation;
                }
                return e;
            }) }));
    };
    var _onChange = function (e) {
        // setFormValues({
        //   ...formValues,
        //   [e.target.name]: e.target.value,
        // });
    };
    /*eslint-disable */
    useEffect(function () {
        isAcceptedFastPayment = false;
        var value = __assign({}, formValues);
        if (!value.amount)
            value.amount = order.price;
        if (!value.distance && (order === null || order === void 0 ? void 0 : order.distance))
            value.distance = basicParseFloat(order.distance.toString().replace(/,/gi, ''));
        if (!value._id) {
            value.currency = order.currency || OrderCurrency.USD;
        }
        var startDateBooking;
        value.tempTransportations = (order.transportations || [])
            .map(function (e, index) {
            var loading = e.routes.find(function (route) { return route.type === OrderRouteType.Preferred; });
            var unloading = e.routes.find(function (route) { return route.type === OrderRouteType.Delivery; });
            if (!unloading || !loading)
                return false;
            var existTransportation = value.tempTransportations && value.tempTransportations[index];
            var dates = [loading.endDate, loading.startDate, unloading.endDate, unloading.startDate].map(function (e) {
                return moment(e);
            });
            var minDate = moment.min(dates).startOf('day');
            var startDate = (existTransportation && existTransportation.startDate) || minDate;
            var endDate = (existTransportation && existTransportation.endDate) || moment.max(dates).endOf('day');
            if (!startDateBooking)
                startDateBooking = loading.endDate;
            if (toDateOnly(startDate) < toDateOnly(new Date())) {
                startDate = moment().startOf('day');
            }
            if (toDateOnly(endDate) < toDateOnly(new Date())) {
                endDate = moment().startOf('day');
            }
            var defRouteDates = { startDate: startDate, endDate: endDate };
            if (!formValues._id) {
                startDate = '';
                endDate = '';
            }
            return {
                _id: index,
                defRouteDates: defRouteDates,
                label: (React.createElement(Grid, { container: true, direction: 'column' },
                    React.createElement(Grid, { container: true, alignItems: 'flex-end' },
                        React.createElement(Typography, { variant: 'subtitle1' },
                            React.createElement("b", null,
                                langOfferForm.t('offer_from_label'),
                                ":")),
                        React.createElement(TextOverflow, { variant: 'subtitle1', style: { maxWidth: 'calc(100% - 52px)' } }, (loading.origin && OrderLocation.create(loading.origin).fullAddress) || '-')),
                    React.createElement(Grid, { container: true, alignItems: 'flex-end' },
                        React.createElement(Typography, { variant: 'subtitle1' },
                            React.createElement("b", null,
                                langOfferForm.t('offer_to_label'),
                                ":")),
                        React.createElement(TextOverflow, { variant: 'subtitle1', style: { maxWidth: 'calc(100% - 62px)' } }, (unloading.origin && OrderLocation.create(unloading.origin).fullAddress) || '-')))),
                loading: loading,
                unloading: unloading,
                minDate: minDate,
                maxDate: moment.max(dates).endOf('day'),
                startDate: startDate,
                endDate: endDate,
            };
        })
            .filter(function (e) { return e; });
        if (!value.expireEnd) {
            var s = toDateOnly(startDateBooking);
            var t = s <= toDateOnly(new Date())
                ? new Date(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(23, 59, 59, 999)).getTime()
                : new Date(new Date(new Date(new Date(s).setDate(new Date(s).getDate() + 1)).getTime()).setHours(23, 59, 59, 999)).getTime();
            value.expireEnd = new Date(t);
        }
        if (!value.amount)
            value.amount = '';
        setFormValues(__assign(__assign({}, value), { updatedAt: new Date().toString() }));
        config &&
            value._id &&
            config.client
                .query({
                query: FetchComments,
                variables: {
                    filter: { fields: [{ name: 'parent', value: value._id }], limit: 1 },
                },
            })
                .then(function (res) {
                if (res.data && res.data.comments && res.data.comments.data.length) {
                    localStore.comments = res.data.comments.data;
                    setFormValues(__assign(__assign({}, value), { updatedAt: new Date().toString() + 1, comment: '' }));
                }
            });
        return function () { return localStore.clear(); };
    }, []);
    /*eslint-enable*/
    var isNew = !formValues._id;
    var isCAAllowed = !isCarrier ||
        !((_j = order.approvedCarrier) === null || _j === void 0 ? void 0 : _j._id) ||
        (isCarrier && props.store.accountStore.currentCompany._id === order.approvedCarrier._id);
    var percentage = (((_l = (_k = order.createdBy) === null || _k === void 0 ? void 0 : _k.company) === null || _l === void 0 ? void 0 : _l.isFastPayment) && order.createdBy.company.fastPaymentPercentage) ||
        (((_p = (_o = (_m = order.forwarders[0]) === null || _m === void 0 ? void 0 : _m.company) === null || _o === void 0 ? void 0 : _o.settings) === null || _p === void 0 ? void 0 : _p.isDefaultFastPayment) &&
            order.forwarders[0].company.settings.defaultFastPaymentPercentage) ||
        0;
    var isFastPayment = isCarrier && isCAAllowed && percentage && !editRef;
    return (React.createElement(React.Fragment, null,
        showCreateOfferGuide ? (React.createElement(PreloadingComponent, null,
            React.createElement(BasicTour, { client: config.client, steps: getTourSteps(GuideTypes.CARRIER_CREATE_OFFER, i18n, KEYS), updateSettings: true }))) : null,
        (isNew && (React.createElement(BasicDialog, { onOk: localStore.onOk, title: React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                React.createElement(HelpIconComponent, { warn: true, title: langOfferForm.t('offer_confirm_on_create') }),
                langForm.t('form_btn_confirm')), onClose: function () { return setAveragePrice(0); }, actionOkTitle: langForm.t('form_btn_continue'), withoutWrapper: true, body: React.createElement(TextOverflow, { style: { textAlign: 'center' }, v1: true, variant: 'subtitle1' },
                averagePrice ? (React.createElement(Box, null,
                    langOfferForm.t('offer_price_exceeds_average_price'),
                    '.')) : null,
                langOfferForm.t('offer_confirm_on_create')), bodyStyle: { justifyContent: 'center' }, actionInit: function (onOpen) { return React.createElement("span", { ref: confirmDialog, onClick: onOpen }); } }))) ||
            null,
        (!isNew && (React.createElement(BasicDialog, { onOk: localStore.onOk, title: React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                React.createElement(HelpIconComponent, { warn: true, title: langOfferForm.t('offer_exceeds_average_price_help') }),
                langOfferForm.t('offer_exceeds_average_price_title')), onClose: function () { return setAveragePrice(0); }, actionOkTitle: langForm.t('form_btn_continue'), withoutWrapper: true, body: React.createElement(TextOverflow, { style: { textAlign: 'center' }, v1: true, variant: 'subtitle1' },
                React.createElement(Box, null,
                    langOfferForm.t('offer_price_exceeds_average_price'),
                    '.')), bodyStyle: { justifyContent: 'center' }, actionInit: function (onOpen) { return React.createElement("span", { ref: confirmDialog, onClick: onOpen }); } }))) ||
            null,
        React.createElement(BasicDialog, { onClose: onClose, withoutWrapper: true, title: React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                React.createElement(InfoIcon, { color: 'primary' }),
                ' ',
                isNew ? langOfferForm.t('offer_form_create_btn') : langOfferForm.t('offer_form_edit_btn')), isMiddleBody: true, hidActionOkBth: true, actionCancelTitle: langApp.t('modal_actions_close'), body: React.createElement(Grid, { container: true, alignItems: 'center' },
                React.createElement(Grid, { container: true, justify: 'center', alignItems: 'center' }, isNew ? (React.createElement(Typography, { style: { textAlign: 'center' }, variant: 'subtitle1', color: 'primary', dangerouslySetInnerHTML: {
                        __html: (langToast.t('toast_items_available_after_create') || '').replace(/_X_/, langMenu.t('menu_sidebar_offer_submitted').toLowerCase()),
                    } })) : (React.createElement(Typography, { variant: 'subtitle1', color: 'primary', style: { textAlign: 'center' } }, (langToast.t('toast_items_updated') || '').replace(/_X_/, langOfferForm.t('offer_title').toLowerCase()))))), actionInit: function (onOpen) { return React.createElement("input", { ref: inputDialog, onClick: onOpen, type: 'hidden' }); } }),
        React.createElement(Grid, { container: true, direction: 'column' },
            React.createElement(Typography, { color: 'error' }, localStore.error),
            React.createElement(Formik, { key: [formValues.updatedAt].join('_'), innerRef: isNew && !v1 ? mainForm : editRef, initialValues: formValues, validate: function (values) {
                    return (requestNewPrice && !dateForms.find(function (formRef) { return !formRef.current || formRef.current.handleSubmit(); })) ||
                        !requestNewPrice;
                }, validationSchema: Yup.object(__assign(__assign(__assign({ distance: Yup.number()
                        .transform(yupTransformFloatNumber)
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(100000000, langForm
                        .t('form_input_validate_max_size')
                        .replace(/_X_/g, (canManage
                        ? props.v3 && isTrading
                            ? defaultValue.amount
                            : formValues.amount
                        : 100000000).toString())), amount: Yup.number()
                        .transform(yupTransformFloatNumber)
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(canManage ? (props.v3 && isTrading ? defaultValue.amount : formValues.amount) : 100000000, langForm
                        .t('form_input_validate_max_size')
                        .replace(/_X_/g, (canManage
                        ? props.v3 && isTrading
                            ? defaultValue.amount
                            : formValues.amount
                        : 100000000).toString()))
                        .required(langForm.t('form_input_validate_required')) }, (!formValues.isDateRange && {
                    daysBeforeExpired: Yup.number()
                        .min(0, langForm.t('form_input_validate_min_size').replace(/_X_/g, '0'))
                        .max(100000000, langForm.t('form_input_validate_max_size').replace(/_X_/g, (100000000).toString()))
                        .required(langForm.t('form_input_validate_required'))
                        .integer(langForm.t('form_error_should_be_integer')),
                })), { comment: Yup.string()
                        .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                        .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')) }), (isFastPayment && {
                    fastPaymentStatus: Yup.string()
                        .typeError(langForm.t('form_input_validate_required'))
                        .required(langForm.t('form_input_validate_required')),
                }))), onSubmit: function () {
                    var e = [];
                    for (var _i = 0; _i < arguments.length; _i++) {
                        e[_i] = arguments[_i];
                    }
                    return __awaiter(void 0, void 0, void 0, function () {
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0: return [4 /*yield*/, sleep(100)];
                                case 1:
                                    _b.sent(); //TODO: temporary dirty fix submit delay preventing
                                    if (isFastPayment && e.length && e[0].fastPaymentStatus === OrderQuoteFastPaymentStatus.Available) {
                                        (_a = fastPaymentRef === null || fastPaymentRef === void 0 ? void 0 : fastPaymentRef.current) === null || _a === void 0 ? void 0 : _a.click();
                                        return [2 /*return*/];
                                    }
                                    return [4 /*yield*/, localStore.onSubmit.apply(localStore, __spread(e))];
                                case 2: return [2 /*return*/, _b.sent()];
                            }
                        });
                    });
                }, validateOnBlur: false, validateOnChange: false }, function (formik) {
                var expireProps = { minDate: new Date(), maxDate: new Date() };
                (order.transportations || []).forEach(function (e, index) {
                    var loading = e.routes.find(function (route) { return route.type === OrderRouteType.Preferred; });
                    var unloading = e.routes.find(function (route) { return route.type === OrderRouteType.Delivery; });
                    if (!unloading || !loading)
                        return false;
                    var existTransportation = formik.values.tempTransportations && formik.values.tempTransportations[index];
                    var dates = [loading.endDate, loading.startDate, unloading.endDate, unloading.startDate].map(function (e) {
                        return moment(e);
                    });
                    var minDate = moment.min(dates).startOf('day');
                    var startDate = (existTransportation && existTransportation.startDate) || minDate;
                    var endDate = (existTransportation && existTransportation.endDate) || moment.max(dates).endOf('day');
                    if (!expireProps.minDate)
                        expireProps.minDate = startDate;
                    expireProps.maxDate = endDate;
                });
                var daysBeforeExpired = parseFloat(formik.values.daysBeforeExpired.toString());
                if (isNaN(daysBeforeExpired))
                    daysBeforeExpired = 1;
                var expireEnd = new Date();
                if (daysBeforeExpired) {
                    if (defaultValue._id) {
                        expireEnd = new Date(defaultValue.createdAt);
                    }
                    expireEnd.setDate(expireEnd.getDate() + daysBeforeExpired);
                }
                var disabled = canManage;
                var onToggleExpire = function () {
                    setFormValues(__assign(__assign({}, formValues), { isDateRange: !formik.values.isDateRange }));
                    formik.setFieldValue('isDateRange', !formik.values.isDateRange);
                };
                var newPriceValue = (((100 - percentage) * (formik.values.amount || 0)) / 100).toFixed(2);
                var newPrice = priceValue({
                    amount: newPriceValue,
                    currency: formik.values.currency || OrderCurrency.EUR,
                });
                return (React.createElement(Form, { style: { width: '100%' }, id: 'form-order-offer-create' },
                    React.createElement(BasicDialog, { withoutWrapper: true, onOk: function () {
                            isAcceptedFastPayment = true;
                        }, onClose: function () {
                            localStore.onSubmit(formik.values);
                        }, body: React.createElement(Grid, { container: true, style: { display: 'flex' } },
                            React.createElement(Grid, { container: true, style: { alignItems: 'center', justifyContent: 'center' } },
                                React.createElement(Typography, { dangerouslySetInnerHTML: {
                                        __html: langOrderList
                                            .t("order_list_fast_payment_modal_offer_text")
                                            .replace(/_X_/g, newPrice),
                                    } }))), actionOkTitle: langOfferForm.t('offer_form_fast_payment_ok_button'), actionCancelTitle: langOfferForm.t('create_offer_fast_payment_not_available'), title: langApp.t('app_payment_terms'), dialogProps: { fullWidth: true, maxWidth: 'sm' }, actionInit: function (onOpen) { return (React.createElement(React.Fragment, null,
                            React.createElement("span", { onClick: onOpen, ref: fastPaymentRef }))); } }),
                    React.createElement(Grid, { container: true, alignItems: "center", justify: "flex-start", className: classes.quoteDetails }, requestNewPrice || v1 ? (React.createElement(React.Fragment, null,
                        props.isCarrierRestrictedOrdersAccess && props.order && v1 ? (React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(Typography, { dangerouslySetInnerHTML: {
                                    __html: langForm
                                        .t('wizard_offer_order_share_description')
                                        .replace(/_customerName_/, ownerNameOrCompanyName)
                                        .replace(/_Goods_/, cargoInfo)
                                        .replace(/_PickupDates_/, preferredPickupDates)
                                        .replace(/_DeliveryDates_/, preferredDeliveryDates)
                                        .replace(/_OrderNumber_/, (order === null || order === void 0 ? void 0 : order.orderId) || '-'),
                                } }))) : null,
                        React.createElement(Grid, { container: true, alignItems: "center", item: true, xs: 12, md: v2 ? 12 : isNew && !v1 ? 7 : 12, justify: "flex-start", "data-id": "izi-quote-transportations-dates", key: JSON.stringify(__assign(__assign({}, formValues), formik.values)), style: { marginBottom: theme.spacing(1) } }, formik.values.tempTransportations.map(function (e, index) { return (React.createElement(BaseOrderQuoteTransportationCreateForm, { v1: v1, index: index, disabled: disabled, defaultValue: e, key: e._id, order: order, ref: dateForms[index], onChange: function (e) { return onChangeTranportation(e, formik.values); } })); })),
                        React.createElement(Grid, { item: true, container: true, alignItems: "center", justify: "flex-start", spacing: 1, md: v2 ? 12 : isNew && !v1 ? 7 : 12, style: { margin: theme.spacing(-1, 0, 2, 0) } },
                            React.createElement(Grid, { container: true, item: true, xs: 12, md: 6 },
                                React.createElement(Grid, { item: true, xs: 12, md: "auto", style: __assign({ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }, (!isSmall && { paddingRight: theme.spacing(7) })) },
                                    React.createElement(TextOverflow, { variant: 'subtitle1' },
                                        React.createElement("b", null,
                                            langOfferForm.t('offer_form_valid_days'),
                                            ":"))),
                                React.createElement(Grid, { item: true, xs: 12, md: true }, formik.values.isDateRange ? (React.createElement(FormikSingleDatePicker, __assign({ title: "" + langForm.t('form_input_expire_end'), name: "expireEnd" }, expireProps, { withoutSeconds: true, value: formik.values.expireEnd, disabled: disabled, onChange: function (value) { return _onChange({ target: { value: value, name: 'expireEnd' } }); }, InputProps: {
                                        endAdornment: (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', minWidth: 48 } },
                                            React.createElement(HelpIconComponent, { title: langOfferForm
                                                    .t('offer_form_days_until_help')
                                                    .replace(/_X_/, toDateOnlyV1(expireEnd, 'DD MMM YYYY HH:mm')) }),
                                            React.createElement(BaseTooltip, { title: langApp.t('switch_to_range') },
                                                React.createElement(BaseIconButton, { style: { padding: 2 }, onClick: onToggleExpire },
                                                    React.createElement(RotateLeftIcon, null))))),
                                    } }))) : (React.createElement(FormikTextField, __assign({ iziInputType: "integer", variant: "outlined", name: "daysBeforeExpired" }, expireProps, { value: formik.values.daysBeforeExpired, disabled: disabled, onChange: function (value) { return _onChange({ target: { value: value, name: 'daysBeforeExpired' } }); }, InputProps: {
                                        endAdornment: (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', minWidth: 48 } },
                                            React.createElement(HelpIconComponent, { title: langOfferForm
                                                    .t('offer_form_days_until_help')
                                                    .replace(/_X_/, toDateOnlyV1(expireEnd, 'DD MMM YYYY HH:mm')) }),
                                            React.createElement(BaseTooltip, { title: langApp.t('switch_to_date') },
                                                React.createElement(BaseIconButton, { style: { padding: 2 }, onClick: onToggleExpire },
                                                    React.createElement(RotateLeftIcon, null))))),
                                    } }))))),
                            React.createElement(Grid, { container: true, direction: "column", item: true, xs: 12, md: 6 },
                                isSmall && (React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                                    React.createElement(TextOverflow, { color: 'primary', style: {
                                            fontSize: '0.75rem',
                                            marginBottom: theme.spacing(1),
                                            width: '100%',
                                        } }, langApp.t('aspected_price').replace(/_X_/, priceValue(order))))),
                                React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                                    React.createElement(Grid, { item: true, xs: 7, style: { position: 'relative' } },
                                        React.createElement(FormikTextField, { title: langOfferForm.t('offer_form_price_placeholder'), fullWidth: true, className: classesFormStyles.priceInput, variant: "outlined", name: "amount", iziInputType: "float", onChange: _onChange }),
                                        (order.price && !isSmall && (React.createElement(TextOverflow, { color: 'primary', style: {
                                                position: 'absolute',
                                                fontSize: '0.75rem',
                                                bottom: -15,
                                                width: '100%',
                                            } }, langApp.t('aspected_price').replace(/_X_/, priceValue(order))))) ||
                                            null),
                                    React.createElement(Grid, { item: true, xs: 5 },
                                        React.createElement(FormikSelect, { v22: true, fullWidth: true, className: classesFormStyles.currencyInput, variant: "outlined", onChange: _onChange, disabled: disabled || !!defaultValue._id, name: "currency" }, Object.keys(OrderCurrency).map(function (value, index) {
                                            return (React.createElement(MenuItem, { key: index, value: value }, value));
                                        })))))),
                        React.createElement(Grid, { item: true, container: true, alignItems: "center", justify: "flex-start", spacing: 1, style: { margin: theme.spacing(-1, 0, 2, 0) } },
                            React.createElement(Grid, { item: true, xs: 6, style: { position: 'relative' } },
                                React.createElement(FormikTextField, { disabled: true, title: langForm.t('form_label_distance'), fullWidth: true, variant: "outlined", name: "distance", iziInputType: "float", onChange: _onChange })),
                            React.createElement(Grid, { item: true, xs: 6, style: { position: 'relative' } },
                                React.createElement(FormikSelect, { canRemove: true, v22: true, fullWidth: true, variant: "outlined", name: "vehicleCO2Type" }, Object.keys(OrderQuoteCOVehicleType).map(function (value, index) {
                                    return (React.createElement(MenuItem, { key: index, value: value }, value));
                                })))),
                        React.createElement(Grid, { item: true, container: true },
                            React.createElement(Grid, { item: true, xs: 12, md: v2 ? 12 : isNew && !v1 ? 7 : 12, container: true, alignItems: "flex-end", justify: "flex-start" },
                                React.createElement(FormikTextField, { title: langOrderForm.t('create_order_additional_requirements_title'), multiline: true, variant: "outlined", type: "text", rows: 4, name: "comment", onChange: _onChange, placeholder: langOrderForm.t('create_order_additional_requirements_title'), value: formik.values.comment })),
                            React.createElement(Grid, { container: true, justify: "space-between" },
                                isFastPayment ? (React.createElement(Grid, { container: true, item: true, xs: 12, md: 3 },
                                    React.createElement(FormikSelect, { required: true, variant: "outlined", title: langApp.t('app_payment_terms'), onChange: onChange, name: "fastPaymentStatus", value: formik.values.fastPaymentStatus, style: { marginTop: theme.spacing(2), marginBottom: theme.spacing(1) } }, [
                                        {
                                            value: OrderQuoteFastPaymentStatus.Available,
                                            title: 'create_offer_fast_payment_available',
                                        },
                                        {
                                            value: OrderQuoteFastPaymentStatus.NotAvailable,
                                            title: 'create_offer_fast_payment_not_available',
                                        },
                                    ].map(function (_a) {
                                        var value = _a.value, title = _a.title;
                                        return (React.createElement(MenuItem, { id: "menu-item-select-" + value, key: value, value: value }, langOfferForm.t(title)));
                                    })))) : null,
                                isNew && !v1 ? (React.createElement(Grid, { item: true, xs: 12, md: isFastPayment ? 3 : 12, container: true, alignItems: "flex-end", justify: "flex-end", style: __assign({}, (v2 && { marginTop: theme.spacing(2) })), className: classes.button },
                                    React.createElement(TermConfirmation, __assign({}, (order.forwarders &&
                                        order.forwarders[0] &&
                                        order.forwarders[0].company && {
                                        filter: {
                                            fields: [
                                                { name: 'request', value: TERMS_REQUEST.QUOTE_REQUESTED },
                                                { name: 'company', value: order.forwarders[0].company._id },
                                            ],
                                        },
                                    }), { config: config, content: function (passed) {
                                            var _a;
                                            var submit = (React.createElement(MainButton, __assign({ type: 'submit', loading: localStore.isLoading, disabled: !passed || localStore.isLoading }, (isSmall && { style: { width: '100%' } }), { title: formValues._id
                                                    ? langOfferForm.t('offer_form_edit_btn')
                                                    : langOfferForm.t('offer_form_create_btn') })));
                                            if (!formValues._id && ((_a = props.store.accountStore.currentCompany.settings) === null || _a === void 0 ? void 0 : _a.canRequestOfferForCA)) {
                                                return (React.createElement(Grid, { container: true, alignItems: "center", style: { width: 'initial' } },
                                                    React.createElement("div", { style: isSmall
                                                            ? { width: '100%', marginBottom: theme.spacing(1) }
                                                            : { marginRight: theme.spacing(1) } },
                                                        React.createElement(PreloadingComponent, null,
                                                            React.createElement(BaseOrderQuoteCreationForCA, __assign({}, __assign(__assign({}, (isSmall && { style: { width: '100%' } })), { onValidate: localStore.onValidate, formik: function () { return formik; }, dateForm: function () { return dateForms[0].current; }, config: config }), { onCreateStart: localStore.aproveReqBody, onCreateEnd: localStore.onFinishCreateOffer, order: order })))),
                                                    submit));
                                            }
                                            return submit;
                                        } })))) : null)))) : (React.createElement(React.Fragment, null,
                        React.createElement(Grid, { container: true, direction: "column", style: { margin: theme.spacing(2, 0, 2, 0) } },
                            React.createElement(Grid, { container: true, direction: "row", spacing: 0 },
                                React.createElement(Grid, { item: true, xs: 7 },
                                    React.createElement(FormikTextField, { title: langOfferForm.t('offer_form_price_placeholder'), fullWidth: true, className: classesFormStyles.priceInput, variant: "outlined", name: "amount", iziInputType: "float", onChange: _onChange })),
                                React.createElement(Grid, { item: true, xs: 5 },
                                    React.createElement(FormikSelect, { v22: true, fullWidth: true, className: classesFormStyles.currencyInput, variant: "outlined", onChange: _onChange, disabled: disabled || !!defaultValue._id, name: "currency" }, Object.keys(OrderCurrency).map(function (value, index) {
                                        return (React.createElement(MenuItem, { key: index, value: value }, value));
                                    }))))),
                        !props.isLowerModal ? (React.createElement(Grid, { item: true, container: true },
                            React.createElement(Grid, { item: true, xs: 12, md: isNew ? 9 : 12, container: true, alignItems: "flex-end", justify: "flex-start" },
                                React.createElement(FormikTextField, { title: langOrderForm.t('create_order_additional_requirements_title'), multiline: true, variant: "outlined", type: "text", rows: 4, name: "comment", onChange: _onChange, placeholder: langOrderForm.t('create_order_additional_requirements_title'), value: formik.values.comment })))) : null)))));
            }))));
});
export var BaseOrderQuoteCreationForm = inject('store')(observer(withOrderListItemStore(BaseOrderQuoteCreationFormComponent)));
