import { applySnapshot, types } from 'mobx-state-tree';
export var OrderPreparingStatuses = types
    .model('OrderPreparingStatuses', {
    _id: types.maybeNull(types.optional(types.identifier, '')),
    createdAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    updatedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    deletedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    approvedAt: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    description: types.maybeNull(types.union(types.null, types.undefined, types.string)),
    name: types.maybeNull(types.optional(types.array(types.string), [])),
    descriptions: types.maybeNull(types.optional(types.array(types.string), [])),
    order: types.maybeNull(types.number),
})
    .actions(function (self) { return ({
    reset: function (snapshot) {
        applySnapshot(self, snapshot);
    },
}); });
