var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles, MenuItem, Button, Grid, Paper, useTheme, useMediaQuery } from '@material-ui/core';
import { useField } from 'formik';
import ExpandMore from '@material-ui/icons/ExpandMore';
import InputTitle from './InputTitle';
import Fade from '@material-ui/core/Fade/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import Popper from '@material-ui/core/Popper/Popper';
import { theme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../i18n';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { useInputStyles } from '../../styles/form';
import BaseTooltip from '../BaseTooltip';
import { useStyles as useStylesFormik } from './styles';
import BaseIconButton from '../BaseIconButton';
var useStyles = makeStyles(function (theme) { return ({
    icon: {
        '& .MuiSelect-root': {
            paddingLeft: theme.spacing(1),
        },
        '& .MuiSelect-icon': {
            color: theme.palette.primary.main,
        },
    },
}); });
export var BaseSelect = function (_a) {
    var _b, _c;
    var className = _a.className, props = __rest(_a, ["className"]);
    var classes = useStyles();
    var classes1 = useInputStyles();
    var classesFormik = useStylesFormik();
    var options = props.options, _d = props.margin, margin = _d === void 0 ? 'dense' : _d, _e = props.fullWidth, fullWidth = _e === void 0 ? true : _e, _f = props.variant, variant = _f === void 0 ? 'standard' : _f;
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    return (React.createElement(React.Fragment, null,
        React.createElement(InputTitle, __assign({}, props)),
        React.createElement(TextField, __assign({}, (_b = {}, _b["data-title-1"] = props.placeholder || props.title, _b), { autoComplete: "off", className: clsx(classesFormik.base, classes1.root5, className, (_c = {},
                _c[classesFormik.updatedLegend] = props.value && props.v22,
                _c[classes1.root3] = props.v12,
                _c[classes1.root6] = props.v13,
                _c)), id: props.id || (props.title || 'text-select-input').toString().replace(/ /gi, '').toLowerCase(), select: true, SelectProps: __assign(__assign({ IconComponent: ExpandMore, className: classes.icon, displayEmpty: true, onOpen: props.onOpen }, (props.multiple && {
                multiple: true,
                renderValue: props.renderValue || (function (selected) { return React.createElement("div", null, selected.map(function (value) { return value; })); }),
            })), props.SelectProps), variant: variant, margin: margin, fullWidth: fullWidth }, props, { 
            // placeholder={
            //   !props.withoutPlaceholder ? props.placeholder || (typeof props.title === 'string' && props.title) || null : ''
            // }
            style: style, placeholder: !(props.v1 || props.v14) ? '' : props.title || props.placeholder }, (!props.v18 && {
            title: '',
            required: false,
            label: !(props.v1 || props.v14) ? props.title || props.placeholder : '',
        })), props.children ||
            (options &&
                options.map(function (v) {
                    var value = (typeof v === 'string' && v) || (typeof v !== 'string' && v.value) || '';
                    var label = v.name || v || '';
                    return (React.createElement(MenuItem, { key: value, value: value }, (!props.noCaseChange &&
                        typeof label === 'string' &&
                        label.charAt(0).toUpperCase() + label.slice(1).toLowerCase()) ||
                        label));
                })))));
};
export var BaseSelectV1 = function (_a) {
    var title = _a.title, props = __rest(_a, ["title"]);
    var langApp = useTranslation(KEYS.APP);
    var _b = __read(React.useState(null), 2), arrowRef = _b[0], setArrowRef = _b[1];
    var style = { marginTop: 0, marginBottom: 0 };
    if (props.style)
        Object.assign(style, props.style);
    var onToggle = function (e) {
        setArrowRef(arrowRef ? null : e.target);
        if (props.onClose)
            props.onClose(e);
    };
    var onClose = function (e) {
        setArrowRef(null);
    };
    var open = Boolean(arrowRef);
    React.useEffect(function () {
        return function () {
            if (props.onClose)
                props.onClose();
        };
    }, []);
    var id = props.id || (open ? 'scroll-playground' : '');
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    return (React.createElement(React.Fragment, null,
        (props.actionOnInit && props.actionOnInit({ onToggle: onToggle, open: open })) || (React.createElement(Button, { variant: "contained", color: "primary", onClick: onToggle }, "Toggle")),
        React.createElement(Popper, __assign({}, props, { id: id, open: open, anchorEl: arrowRef, placement: "bottom", transition: true, disablePortal: false, modifiers: {
                flip: {
                    enabled: true,
                },
                preventOverflow: {
                    enabled: true,
                    boundariesElement: 'scrollParent',
                },
                arrow: {
                    enabled: true,
                    element: arrowRef,
                },
            } }), function (_a) {
            var TransitionProps = _a.TransitionProps;
            return (React.createElement(Fade, __assign({}, TransitionProps),
                React.createElement(ClickAwayListener, { onClickAway: onClose },
                    React.createElement(Paper, null,
                        React.createElement(Grid, { container: true, alignItems: 'center', style: { maxWidth: isSmall ? '100%' : 340 } },
                            React.createElement(Grid, { container: true, justify: 'center', style: { position: 'relative' } },
                                title && React.createElement("div", { style: __assign({ padding: "11px 0" }, props.titleStyle) }, title),
                                React.createElement(BaseTooltip, { title: langApp.t('modal_actions_close') },
                                    React.createElement(BaseIconButton, { "aria-label": "close", onClick: onToggle, style: { position: 'absolute', right: 0 } },
                                        React.createElement(CloseIcon, null)))),
                            React.createElement(Grid, { container: true, style: {
                                    padding: theme.spacing(2),
                                    maxHeight: 240,
                                    overflow: 'auto',
                                } }, props.children))))));
        })));
};
export var FormikSelect = function (props) {
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, helperError = props.helperError, onChange = props.onChange;
    var _b = __read(useField(props), 3), field = _b[0], meta = _b[1], helpers = _b[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var _onChange = function (e) {
        helpers.setValue(e.target.value);
        if (onChange)
            onChange(e);
    };
    var onClose = function () {
        _onChange({ target: { value: '' } });
    };
    var styles = __assign(__assign({}, props.style), (props.canRemove && { maxWidth: "calc(100% - 26px)" }));
    var content = (React.createElement(BaseSelect, __assign({}, field, props, { style: styles, error: !!touchedError || helperError, onChange: _onChange, helperText: touchedError || helperText })));
    if (props.canRemove)
        return (React.createElement(Grid, { container: true, alignItems: "center", justify: "space-between" },
            content,
            React.createElement(BaseTooltip, { title: i18n.getResource(i18n.language, KEYS.APP, 'form_btn_clear') },
                React.createElement(BaseIconButton, { "aria-label": "close", onClick: onClose, id: 'clear_input', style: { padding: 0 } },
                    React.createElement(CloseIcon, null)))));
    return content;
};
