var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Link } from 'react-router-dom';
import { BaseUserAppTYpe } from '../../../stores/common';
import { inject } from 'mobx-react';
import { OrderStatus } from '../../../models';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import EditIcon from '@material-ui/icons/Edit';
import Visibility from '@material-ui/icons/Visibility';
import BaseTooltip from '../../BaseTooltip';
import { withRouter } from 'react-router';
import BaseIconButton from '../../BaseIconButton';
var isManufacturing = function () { return window.location.pathname.match(/manufacturing/gi); };
var OrderEditView = function OrderEditView(_a) {
    var _b, _c;
    var record = _a.record, v1 = _a.v1, _d = _a.store.accountStore, currentUser = _d.currentUser, currentCompany = _d.currentCompany, match = _a.match, props = __rest(_a, ["record", "v1", "store", "match"]);
    var isOrderGroup = match.url.match(/groups/gi);
    var isCompanyOrder = currentCompany._id === ((_c = (_b = record.manager) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c._id) || currentCompany._id === record.createdBy.company._id;
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    if (!record ||
        !(currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER) ||
            currentUser.appType.includes(BaseUserAppTYpe.FORWARDER) ||
            isCompanyOrder)) {
        return null;
    }
    var isNotEdit = [OrderStatus.Finished, OrderStatus.ApprovedQuote].includes(record.status);
    var label = isOrderGroup
        ? langForm.t('form_btn_edit')
        : (!isNotEdit || isCompanyOrder
            ? langOfferForm.t('offer_form_order_edit_btn')
            : langForm.t('form_btn_view_order'));
    var icon = (React.createElement(BaseTooltip, { title: label },
        React.createElement(BaseIconButton, { id: 'btn-order-edit' }, !isNotEdit || isCompanyOrder ? React.createElement(EditIcon, { color: 'primary' }) : React.createElement(Visibility, { color: 'primary' }))));
    var rootRouteUrl = match.url.split(/\/orders/i)[0];
    if (isOrderGroup && match.params.orderId && rootRouteUrl.match(match.params.orderId))
        rootRouteUrl = rootRouteUrl.split(new RegExp("/" + match.params.orderId, 'gi'))[0];
    return (React.createElement(Link, { style: __assign({ textDecoration: 'none' }, (props.label && { width: '100%' })), to: isOrderGroup
            ? match.params.orderId
                ? rootRouteUrl + "/" + match.params.orderId + "/orders/" + record._id
                : rootRouteUrl + "/" + record._id
            : "/" + (isManufacturing() ? 'manufacturing' : 'orders') + "/" + record._id }, props.label ? React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: label, handleClickOpen: function () { return 0; } })) : icon));
};
export default withRouter(inject('store')(OrderEditView));
