var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { AsyncActionStatus } from './common';
import { toast } from 'react-toastify';
import { i18n } from '../i18n';
import { CreateOrderCargo, UpdateOrderCargo, DeleteOrderCargo, CreateOrderCargoProductType, CreateOrderCargoPalletType, CreateOrderCargoPackage, UpdateOrderCargoPackage, UpdateOrderCargoProductType, UpdateOrderCargoPalletType, CreateOrderRoute, } from '../graphql';
import { OrderRoute, OrderLocation, OrderRouteType, } from '../models';
import { FetchOrderCargoPackage, FetchOrderCargoProductType } from '../graphql';
import { DeleteOrderTransportation, CreateOrderTransportation, UpdateOrderTransportation, FetchOrderTransportation, } from '../graphql/order/orderTransportation';
import { KEYS } from '../i18n';
import moment from 'moment';
import { CreateOrderCargoTaintedCode, FetchOrderCargoTaintedCode, UpdateOrderCargoTaintedCode, } from '../graphql/order/orderCargoTaintedCode';
import { OrderStoreFactoryUtils } from './OrderStoreFactoryUtils';
import { toDateOnly } from '../utils';
import { basicParseFloat } from '../utils3';
function checkOrderRoute(reqBody, isPallet) {
    [
        {
            name: 'startDate',
            resolver: function (v) { return (v ? toDateOnly(v) : v); },
        },
        {
            name: 'endDate',
            resolver: function (v) { return (v ? toDateOnly(v) : v); },
        },
    ].forEach(function (el) {
        if (reqBody.hasOwnProperty(el.name) && typeof reqBody[el.name] !== 'undefined') {
            if (el.resolver)
                reqBody[el.name] = el.resolver(reqBody[el.name]);
        }
        else {
            delete reqBody[el.name];
        }
    });
    return reqBody;
}
function checkOrderCargo(reqBody, isPallet) {
    __spread([
        {
            name: 'amount',
            resolver: function (v) { return parseInt(v); },
        },
        {
            name: 'weight',
            resolver: function (v) { return basicParseFloat(v); },
        },
        {
            name: 'volume',
            resolver: function (v) { return basicParseFloat(v); },
        },
        {
            name: 'height',
            resolver: function (v) { return basicParseFloat(v); },
        },
        {
            name: 'width',
            resolver: function (v) { return basicParseFloat(v); },
        },
        {
            name: 'length',
            resolver: function (v) { return basicParseFloat(v); },
        }
    ], (!isPallet
        ? [
            {
                name: 'productType',
                resolver: function (v) { return (v && v._id) || (v === null && v); },
            },
            {
                name: 'palletType',
                resolver: function (v) { return (v && v._id) || (v === null && v); },
            },
            {
                name: 'package',
                resolver: function (v) { return (v && v._id) || (v === null && v); },
            },
        ]
        : [])).forEach(function (el) {
        if (reqBody.hasOwnProperty(el.name) &&
            typeof reqBody[el.name] !== 'undefined' &&
            (reqBody[el.name] || ['productType', 'palletType', 'package'].includes(el.name))) {
            if (el.resolver)
                reqBody[el.name] = el.resolver(reqBody[el.name]);
        }
        else {
            delete reqBody[el.name];
        }
    });
    if (reqBody.dimension === null || typeof reqBody.dimension === undefined) {
        delete reqBody.dimension;
    }
    return reqBody;
}
var timeOut = {};
export var OrderStoreGeneralFactory = function (_a) {
    var client = _a.client, model = _a.model, modelOptions = _a.modelOptions;
    return types
        .compose(AsyncActionStatus, types.model('OrderStoreGeneralFactory', {
        item: types.optional(types.maybeNull(model), function () { return model.create(modelOptions || {}); }),
        updatedAt: types.optional(types.number, 1),
        isUpdating: types.optional(types.boolean, false),
        isPublishing: types.optional(types.boolean, false),
        isLoading: types.optional(types.boolean, false),
    }))
        .actions(function (self) { return ({
        clearItem: function (order) {
            if (self.item) {
                self.item && self.item.reset(__assign(__assign({}, order), { _id: '' }));
            }
        },
        clearItemForce: function (order) {
            self.item = order;
        },
        checkOrderId: function () {
            var _a, _b;
            if (self.item.orderId) {
                console.log('checkOrderId--', self.item.orderId);
                return;
            }
            var name = '';
            var pickup = self.item.transportations[0].routes.find(function (e) { return e.type === OrderRouteType.Preferred; });
            var unload = self.item.transportations[self.item.transportations.length - 1].routes.find(function (e) { return e.type === OrderRouteType.Delivery; });
            if (pickup && unload && (pickup.startDate || unload.endDate) && ((_a = pickup.origin) === null || _a === void 0 ? void 0 : _a._id) && ((_b = unload.origin) === null || _b === void 0 ? void 0 : _b._id)) {
                name += "" + ((pickup.origin && pickup.origin.address.country) || '');
                name += "" + ((unload.origin && unload.origin.address.country) || '');
                name += "" + moment(new Date()).format('YY') || '';
                // name += (unload.endDate && `${moment(unload.endDate).format('YY')}`) || '';
                name += self.item.orderIndex < 10000 ? "0" + (self.item.indexId || '') : self.item.indexId || '';
                console.log(name);
                self.updateItem({ orderId: name, _id: self.item._id });
            }
        },
        createOrderRoute: flow(function (routeInput, noToast) {
            var rangeDates, origin_1, updatedAt, _input_1, order, beforeCreate, route, res, input_1, result, transportation, s, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, 5, 6]);
                        console.log(routeInput);
                        rangeDates = routeInput.rangeDates, origin_1 = routeInput.origin, updatedAt = routeInput.updatedAt, _input_1 = __rest(routeInput, ["rangeDates", "origin", "updatedAt"]);
                        self.isUpdating = true;
                        order = self.item;
                        if (!(!order || !order._id)) return [3 /*break*/, 2];
                        beforeCreate = getSnapshot(self.item);
                        route = beforeCreate.transportations
                            .map(function (e) { return e.routes.find(function (r) { return r.origin && r.origin._id && r.email.length && r.startDate && r.endDate; }); })
                            .filter(function (e) { return e; })[0];
                        if (route) {
                            self.item.transportations.forEach(function (t) {
                                t.routes.forEach(function (r) {
                                    if (r.type === _input_1.type) {
                                        ['email', 'startDate', 'endDate'].forEach(function (key, index) {
                                            if (_input_1[key])
                                                r[key] = index > 0 ? toDateOnly(_input_1[key]) : _input_1[key];
                                        });
                                    }
                                });
                            });
                        }
                        return [4 /*yield*/, self.createItem({}, routeInput, noToast)];
                    case 1:
                        res = _a.sent();
                        if (route || res)
                            return [2 /*return*/, res];
                        _a.label = 2;
                    case 2:
                        input_1 = Object.assign(__assign(__assign({}, _input_1), { orderTransportation: _input_1.orderTransportation._id, order: order && order._id }), rangeDates && rangeDates.find(function (e) { return e; })
                            ? {
                                startDate: rangeDates[0],
                                endDate: rangeDates[1],
                            }
                            : {}, origin_1
                            ? {
                                origin: origin_1._id,
                            }
                            : {});
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input_1[e])
                                input_1[e] = toDateOnly(input_1[e]);
                        });
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderRoute,
                                variables: {
                                    input: input_1,
                                },
                            })];
                    case 3:
                        result = _a.sent();
                        if (order) {
                            transportation = (order.transportations || []).find(function (e) { return e._id === _input_1.orderTransportation._id; });
                            if (!transportation) {
                                s = getSnapshot(order);
                                order.reset(__assign(__assign({}, s), { transportations: [_input_1.orderTransportation] }));
                                transportation = _input_1.orderTransportation;
                            }
                            transportation.updatedAt = new Date().toString();
                            transportation.routes.push(OrderRoute.create(__assign(__assign({}, result.data.createOrderRoute), { origin: result.data.createOrderRoute.origin && OrderLocation.create(result.data.createOrderRoute.origin) })));
                        }
                        //Update Apollo Cache
                        if (timeOut.orderRoute)
                            clearTimeout(timeOut.orderRoute);
                        timeOut.orderRoute = setTimeout(function () {
                            client.resetStore();
                        }, 1000);
                        //Update Apollo Cache
                        return [2 /*return*/, result.data.createOrderRoute];
                    case 4:
                        e_1 = _a.sent();
                        console.log('create order route error', e_1);
                        toast.error(e_1.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderRoute: flow(function (record) {
            var res_1, snapshot, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!record._id) {
                            delete record._id;
                            return [2 /*return*/, self.createOrderRoute(record)];
                        }
                        return [4 /*yield*/, OrderStoreFactoryUtils.updateOrderRoute(checkOrderRoute(record), { client: client })];
                    case 1:
                        res_1 = _a.sent();
                        if (self.item) {
                            snapshot = getSnapshot(self.item);
                            self.item &&
                                self.item.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                        return __assign(__assign({}, e), { routes: e.routes.map(function (route) {
                                                if (route._id === res_1._id) {
                                                    return __assign(__assign({}, route), res_1);
                                                }
                                                return route;
                                            }) });
                                    }) }));
                        }
                        return [2 /*return*/, res_1];
                    case 2:
                        e_2 = _a.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        self.checkOrderId();
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderPairRoutes: flow(function (records) {
            var e_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, OrderStoreFactoryUtils.updateOrderPairRoutes(records.map(function (e) { return checkOrderRoute(e); }), { client: client })];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _a.sent();
                        toast.error(e_3.message);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        loadTransportation: flow(function (_id) {
            var result, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, client.query({
                                query: FetchOrderTransportation,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.orderTransportation];
                    case 2:
                        e_4 = _a.sent();
                        console.log(e_4);
                        toast.error(e_4.message);
                        return [3 /*break*/, 4];
                    case 3: return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderTransportation: flow(function (input, noToast) {
            var existOrder, result, snapshot, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, 5, 6]);
                        self.isUpdating = true;
                        if (!(!self.item || !self.item._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createItem({}, null, noToast)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input && input[e])
                                input[e] = toDateOnly(input[e]);
                        });
                        existOrder = self.item;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderTransportation,
                                variables: {
                                    input: __assign(__assign({}, input), { order: existOrder._id, prevRoutes: existOrder
                                            ? existOrder.transportations[existOrder.transportations.length - 1].routes.map(function (route) { return route._id; })
                                            : null }),
                                },
                            })];
                    case 3:
                        result = _a.sent();
                        if (existOrder) {
                            snapshot = getSnapshot(existOrder);
                            existOrder.reset(__assign(__assign({}, snapshot), { transportations: __spread(snapshot.transportations.filter(function (e) { return e._id; }), [
                                    result.data.createOrderTransportation,
                                ]).filter(function (e) { return e; }) }));
                        }
                        return [2 /*return*/, result.data.createOrderTransportation];
                    case 4:
                        e_5 = _a.sent();
                        toast.error(e_5.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderTransportations: flow(function (_a, options) {
            var existOrder, result, snapshot, _b, routes, cargoes, updates_1, e_6;
            if (options === void 0) { options = {}; }
            var input = __rest(_a, []);
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 4, 5, 6]);
                        if (!!input._id) return [3 /*break*/, 2];
                        delete input._id;
                        return [4 /*yield*/, self.createOrderTransportation(input)];
                    case 1: return [2 /*return*/, _c.sent()];
                    case 2:
                        self.isUpdating = true;
                        existOrder = self.item;
                        ['startDate', 'endDate'].forEach(function (e) {
                            if (input[e])
                                input[e] = toDateOnly(input[e]);
                        });
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderTransportation,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 3:
                        result = _c.sent();
                        if (existOrder) {
                            snapshot = getSnapshot(existOrder);
                            _b = result.data.updateOrderTransportation, routes = _b.routes, cargoes = _b.cargoes, updates_1 = __rest(_b, ["routes", "cargoes"]);
                            existOrder.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                    if (e._id === updates_1._id) {
                                        if (options.keys) {
                                            return __assign(__assign({}, e), options.keys.map(function (e) {
                                                var _a;
                                                return (_a = {}, _a[e] = updates_1[e], _a);
                                            }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                                        }
                                        return __assign(__assign({}, e), updates_1);
                                    }
                                    return e;
                                }) }));
                        }
                        return [2 /*return*/, result.data.updateOrderTransportation];
                    case 4:
                        e_6 = _c.sent();
                        console.log(e_6);
                        toast.error(e_6.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        deleteOrderTransportations: flow(function (_a) {
            var existOrder, snapshot, e_7;
            var _id = _a._id;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        existOrder = self.item;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteOrderTransportation,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        _b.sent();
                        if (existOrder) {
                            snapshot = getSnapshot(existOrder);
                            existOrder.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.filter(function (e) { return e._id !== _id; }) }));
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        e_7 = _b.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = true;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargo: flow(function (_a, noToast) {
            var order, isNew, _b, reqBody_1, result_1, snapshot, orderSnapshot, transportation, e_8;
            var _c;
            var _id = _a._id, updatedAt = _a.updatedAt, input = __rest(_a, ["_id", "updatedAt"]);
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 6, 7, 8]);
                        self.isUpdating = true;
                        order = self.item;
                        if (!(!order || !order._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createItem({}, null, noToast)];
                    case 1:
                        _d.sent();
                        _d.label = 2;
                    case 2:
                        isNew = false;
                        if (!(!input.orderTransportation || !input.orderTransportation._id)) return [3 /*break*/, 4];
                        _b = input;
                        return [4 /*yield*/, self.createOrderTransportation({}, noToast)];
                    case 3:
                        _b.orderTransportation = _d.sent();
                        isNew = true;
                        _d.label = 4;
                    case 4:
                        reqBody_1 = checkOrderCargo(__assign(__assign({}, input), { orderTransportation: input.orderTransportation._id, order: order && order._id }));
                        if (isNew && !reqBody_1._id) {
                            reqBody_1._id = (_c = self.item.transportations.find(function (t) { return t._id === input.orderTransportation._id; })) === null || _c === void 0 ? void 0 : _c.cargoes[0]._id;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: reqBody_1._id ? UpdateOrderCargo : CreateOrderCargo,
                                variables: {
                                    input: reqBody_1,
                                },
                            })];
                    case 5:
                        result_1 = _d.sent();
                        if (order) {
                            if (isNew) {
                                snapshot = getSnapshot(order);
                                order.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                        return __assign(__assign({}, e), { updatedAt: new Date().toString(), cargoes: e.cargoes.map(function (e) {
                                                if (e._id === reqBody_1._id) {
                                                    return __assign(__assign({}, e), result_1.data.updateOrderCargo);
                                                }
                                                return e;
                                            }) });
                                    }) }));
                            }
                            else {
                                orderSnapshot = getSnapshot(order);
                                transportation = (orderSnapshot.transportations || []).find(function (e) { return input.orderTransportation._id === e._id; });
                                if (!transportation) {
                                    order.reset(__assign(__assign({}, orderSnapshot), { transportations: [input.orderTransportation] }));
                                }
                                else {
                                    order.reset(__assign(__assign({}, orderSnapshot), { transportations: orderSnapshot.transportations.map(function (e) { return (__assign(__assign({}, e), (e._id === input.orderTransportation._id && {
                                            cargoes: __spread(e.cargoes, [
                                                __assign(__assign({}, input), result_1.data.createOrderCargo),
                                            ]),
                                        }))); }) }));
                                }
                            }
                        }
                        return [2 /*return*/, result_1];
                    case 6:
                        e_8 = _d.sent();
                        toast.error(e_8.message);
                        throw e_8.message;
                    case 7:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 8: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargo: flow(function (input, opt) {
            var order, reqBody, result_2, snapshot, e_9;
            if (opt === void 0) { opt = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!input._id) {
                            return [2 /*return*/, self.createOrderCargo(input)];
                        }
                        order = self.item;
                        reqBody = checkOrderCargo(__assign(__assign({}, input), { order: order && order._id }));
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargo,
                                variables: {
                                    input: Object.assign(reqBody, reqBody.orderTransportation ? { orderTransportation: reqBody.orderTransportation._id } : null),
                                },
                            })];
                    case 1:
                        result_2 = _a.sent();
                        if (order) {
                            snapshot = getSnapshot(order);
                            order.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                    return __assign(__assign({}, e), { updatedAt: new Date().toString(), cargoes: e.cargoes.map(function (e) {
                                            if (e._id === input._id) {
                                                return __assign(__assign({}, e), result_2.data.updateOrderCargo);
                                            }
                                            return e;
                                        }) });
                                }) }));
                        }
                        return [2 /*return*/, result_2];
                    case 2:
                        e_9 = _a.sent();
                        toast.error(e_9.message);
                        throw e_9.message;
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        deleteOrderCargo: flow(function (input) {
            var order, snapshot, e_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        order = self.item;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteOrderCargo,
                                variables: {
                                    _id: input._id,
                                },
                            })];
                    case 1:
                        _a.sent();
                        if (order) {
                            snapshot = getSnapshot(order);
                            order.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                    return __assign(__assign({}, e), { updatedAt: new Date().toString(), cargoes: e.cargoes.filter(function (e) { return e._id !== input._id; }) });
                                }) }));
                        }
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_deleted')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.ORDER_FORM, 'create_order_cargo_label')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_10 = _a.sent();
                        toast.error(e_10.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargoProductType: flow(function (_a) {
            var res, data, e_11;
            var _id = _a._id, updatedAt = _a.updatedAt, input = __rest(_a, ["_id", "updatedAt"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderCargoProductType,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoProductType });
                            client.writeQuery({
                                query: FetchOrderCargoProductType,
                                data: {
                                    orderCargoProductTypes: {
                                        count: data.orderCargoProductTypes.count + 1,
                                        data: __spread(data.orderCargoProductTypes.data, [res.data.createOrderCargoProductType]),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) { }
                        return [2 /*return*/, res.data.createOrderCargoProductType];
                    case 2:
                        e_11 = _b.sent();
                        toast.error(e_11.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargoProductType: flow(function (_a) {
            var res_2, data, e_12;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "updatedAt", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargoProductType,
                                variables: {
                                    input: {
                                        name: name,
                                        description: description,
                                        _id: _id,
                                    },
                                },
                            })];
                    case 1:
                        res_2 = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoProductType });
                            client.writeQuery({
                                query: FetchOrderCargoProductType,
                                data: {
                                    orderCargoProductTypes: {
                                        count: data.orderCargoProductTypes.count,
                                        data: data.orderCargoProductTypes.data.map(function (e) {
                                            if (e._id === res_2.data.updateOrderCargoProductType._id) {
                                                return res_2.data.updateOrderCargoProductType;
                                            }
                                            return e;
                                        }),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) { }
                        return [2 /*return*/, res_2.data.updateOrderCargoProductType];
                    case 2:
                        e_12 = _b.sent();
                        toast.error(e_12.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargoPalletType: flow(function (_a, transportationId, cargoId, isPublic) {
            var res_3, existOrder, snapshot, e_13;
            var _id = _a._id, updatedAt = _a.updatedAt, width = _a.width, length = _a.length, dimension = _a.dimension, input = __rest(_a, ["_id", "updatedAt", "width", "length", "dimension"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        self.isUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderCargoPalletType,
                                variables: {
                                    input: checkOrderCargo({
                                        width: width,
                                        length: length,
                                        dimension: dimension,
                                    }, true),
                                },
                            })];
                    case 1:
                        res_3 = _b.sent();
                        if (!!isPublic) return [3 /*break*/, 3];
                        return [4 /*yield*/, self.updateOrderCargo({ _id: cargoId, palletType: res_3.data.createOrderCargoPalletType })];
                    case 2:
                        _b.sent();
                        existOrder = self.item;
                        if (existOrder) {
                            snapshot = getSnapshot(existOrder);
                            existOrder.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                    if (e._id === transportationId) {
                                        return __assign(__assign({}, e), { cargoes: e.cargoes.map(function (cargo) {
                                                if (cargo._id === cargoId) {
                                                    return __assign(__assign({}, cargo), { palletType: res_3.data.updateOrderCargoPalletType });
                                                }
                                                return cargo;
                                            }) });
                                    }
                                    return e;
                                }) }));
                        }
                        _b.label = 3;
                    case 3: return [2 /*return*/, res_3.data.createOrderCargoPalletType];
                    case 4:
                        e_13 = _b.sent();
                        toast.error(e_13.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargoPalletType: flow(function (_a, transportationId, cargoId, isPublic) {
            var res_4, existOrder, snapshot, e_14;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, length = _a.length, width = _a.width, dimension = _a.dimension, input = __rest(_a, ["_id", "updatedAt", "name", "length", "width", "dimension"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargoPalletType,
                                variables: {
                                    input: checkOrderCargo({
                                        length: length,
                                        width: width,
                                        dimension: dimension,
                                        _id: _id,
                                    }, true),
                                },
                            })];
                    case 1:
                        res_4 = _b.sent();
                        if (!isPublic) {
                            existOrder = self.item;
                            if (existOrder) {
                                snapshot = getSnapshot(existOrder);
                                existOrder.reset(__assign(__assign({}, snapshot), { transportations: snapshot.transportations.map(function (e) {
                                        if (e._id === transportationId) {
                                            return __assign(__assign({}, e), { cargoes: e.cargoes.map(function (cargo) {
                                                    if (cargo._id === cargoId) {
                                                        return __assign(__assign({}, cargo), { palletType: res_4.data.updateOrderCargoPalletType });
                                                    }
                                                    return cargo;
                                                }) });
                                        }
                                        return e;
                                    }) }));
                            }
                        }
                        return [2 /*return*/, res_4.data.updateOrderCargoPalletType];
                    case 2:
                        e_14 = _b.sent();
                        toast.error(e_14.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargoPackage: flow(function (_a) {
            var res, data, e_15;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, input = __rest(_a, ["_id", "updatedAt", "name"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderCargoPackage,
                                variables: {
                                    input: { name: name },
                                },
                            })];
                    case 1:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoPackage });
                            client.writeQuery({
                                query: FetchOrderCargoPackage,
                                data: {
                                    orderCargoPackages: {
                                        count: data.orderCargoPackages.count + 1,
                                        data: __spread([res.data.createOrderCargoPackage], data.orderCargoPackages.data),
                                    },
                                },
                            });
                        }
                        catch (e) { }
                        return [2 /*return*/, res.data.createOrderCargoPackage];
                    case 2:
                        e_15 = _b.sent();
                        console.log(e_15);
                        toast.error(e_15.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargoPackage: flow(function (_a) {
            var res_5, data, e_16;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "updatedAt", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargoPackage,
                                variables: {
                                    input: { name: name, _id: _id },
                                },
                            })];
                    case 1:
                        res_5 = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoPackage });
                            client.writeQuery({
                                query: FetchOrderCargoPackage,
                                data: {
                                    orderCargoPackages: {
                                        count: data.orderCargoPackages.count,
                                        data: data.orderCargoPackages.data.map(function (e) {
                                            if (e._id === res_5.data.updateOrderCargoPackage._id) {
                                                return res_5.data.updateOrderCargoPackage;
                                            }
                                            return e;
                                        }),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) { }
                        return [2 /*return*/, res_5.data.updateOrderCargoPackage];
                    case 2:
                        e_16 = _b.sent();
                        toast.error(e_16.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createOrderCargoTaintedCode: flow(function (_a) {
            var res, data, e_17;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, input = __rest(_a, ["_id", "updatedAt", "name"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateOrderCargoTaintedCode,
                                variables: {
                                    input: { name: name },
                                },
                            })];
                    case 1:
                        res = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoTaintedCode });
                            client.writeQuery({
                                query: FetchOrderCargoTaintedCode,
                                data: {
                                    orderCargoTaintedCodes: {
                                        count: data.orderCargoTaintedCodes.count + 1,
                                        data: __spread([res.data.createOrderCargoTaintedCode], data.orderCargoTaintedCodes.data),
                                    },
                                },
                            });
                        }
                        catch (e) { }
                        return [2 /*return*/, res.data.createOrderCargoTaintedCode];
                    case 2:
                        e_17 = _b.sent();
                        console.log(e_17);
                        toast.error(e_17.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateOrderCargoTaintedCode: flow(function (_a) {
            var res_6, data, e_18;
            var _id = _a._id, updatedAt = _a.updatedAt, name = _a.name, description = _a.description, input = __rest(_a, ["_id", "updatedAt", "name", "description"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        if (!input.orderCargo) {
                            delete input.orderCargo;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateOrderCargoTaintedCode,
                                variables: {
                                    input: { name: name, _id: _id },
                                },
                            })];
                    case 1:
                        res_6 = _b.sent();
                        try {
                            data = client.readQuery({ query: FetchOrderCargoTaintedCode });
                            client.writeQuery({
                                query: FetchOrderCargoTaintedCode,
                                data: {
                                    orderCargoTaintedCodes: {
                                        count: data.orderCargoTaintedCodes.count,
                                        data: data.orderCargoTaintedCodes.data.map(function (e) {
                                            if (e._id === res_6.data.updateOrderCargoTaintedCode._id) {
                                                return res_6.data.updateOrderCargoTaintedCode;
                                            }
                                            return e;
                                        }),
                                    },
                                },
                            });
                            //Update Apollo Cache
                        }
                        catch (e) { }
                        return [2 /*return*/, res_6.data.updateOrderCargoTaintedCode];
                    case 2:
                        e_18 = _b.sent();
                        toast.error(e_18.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
    }); })
        .named('OrderStoreGeneral');
};
