import React from 'react';
import { client } from '../../api/apolloClient';
import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import CustomerSidebarMenu from '../../components/common/CustomerSidebarMenu';

const PublicPage = React.lazy(() => import('@izi-logistics/common/lib/pages/Public'));
//
const Index = () => (
  <PreloadingComponent>
    <PublicPage config={{ client }} sideBarMenuContent={<CustomerSidebarMenu />} />
  </PreloadingComponent>
);
export default Index;
