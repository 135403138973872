var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from 'react';
import { OrderQuoteFastPaymentStatus, OrderQuoteStatus, OrderStatus } from '../../../models';
import { TextOverflow } from '../../TextOverflow';
import { OrderQuoteRequestChanges } from './OrderQuoteRequestChanges';
import OrderCMR from './OrderCMR';
import OrderPDFV1 from './OrderPDFV1';
import RoomIcon from '@material-ui/icons/Room';
import { OrderPaymentAction, OrderPaymentActionForwarder } from './OrderPaymentActions';
import { OrderQuoteStatistics } from '../dialogs/OrderQuoteStatistics';
import { OrderCancel } from './OrderCancel';
import { OrderRestore } from './OrderEditAfterDelivered';
import { withRouter } from 'react-router-dom';
import { DeleteDialog } from '../../Dialog';
import OrderMoreView from './OrderMoreView';
import { BaseUserAppTYpe, USER_ROLE } from '../../../stores/common';
import { OrderPaymentStatus } from '../../../models/OrderPayment';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject } from 'mobx-react';
import { OrderRequestPaymentAction } from './OrderRequestPaymentAction';
import OrderEditView from './OrderEditView';
import SendInvoice from '../../../pages/Invoices/Send';
import PreloadingComponent from '../../PreloadingComponent';
import OrderNotifyPublished from './OrderNotifyPublished';
import OrderUserRating from './OrderUserRating';
import { checkIfOrderDetailsIsHide } from '../../../utils1';
import MenuListItem from '../../MenuListItem';
import OrderMakeTemplateView from './OrderMakeTemplateView';
import { canEdit } from '../form/utils';
import OrderArchive from './OrderArchive';
import { OrderUnarchive } from './OrderUnarchive';
import OrderFastPayment from './OrderFastPayment';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import BaseTooltip from '../../BaseTooltip';
import { withOrderListItemStore } from './Customer/storeListItem';
import { FetchUploadsCount } from '../../../graphql';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
import FormItem from '../../../pages/Issues/FormItem';
import { getSnapshot } from 'mobx-state-tree';
import { Issues } from '../../../models/Issues';
import BaseIconButton from '../../BaseIconButton';
var OrderShareComponent = React.lazy(function () { return import('./OrderShare'); });
var OrderPostToMyCA = React.lazy(function () { return import('./OrderPostToMyCA'); });
var SectionHistory = React.lazy(function () { return import('../../SectionDetails/SectionHistory'); });
var OrderSyncRemove = React.lazy(function () { return import('./OrderSyncRemove'); });
var OrderSync = React.lazy(function () { return import('./OrderSync'); });
var ResendNotificaion = React.lazy(function () { return import('./ResendNotifications'); });
// import OrderRouteMenuItem from './OrderRoutesMenuItem';
// import BaseTooltip from '../../BaseTooltip';
// import { IconButton } from '@material-ui/core';
// import PanoramaWideAngleIcon from '@material-ui/icons/PanoramaWideAngle';
// import { Link } from 'react-router-dom';
// import { OrderRouteType } from '../../../models';
var isHideInvoice = process.env.REACT_APP_VERSION === '2.1.0';
var OrderRoutesMenuDocuments = React.lazy(function () { return import('./OrderRoutesMenuDocuments'); });
var LinkToBook = React.lazy(function () { return import('./LinkToBook'); });
var OrderLinkV1 = React.lazy(function () { return import('./OrderLinkV1'); });
function OrderRouteMenu(props) {
    var _this = this;
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r;
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    // const classesIcon: any = useIconStyles();
    if (!props.order)
        return null;
    var _s = props.store.accountStore, currentUser = _s.currentUser, currentCompany = _s.currentCompany, order = props.order, config = props.config, onDeleteItem = props.onDeleteItem, moreActions = props.moreActions, orderListItemProps = props.orderListItemProps, store = props.store;
    var show = true;
    var isForwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER) || isForwarder; //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isCarrierMatch = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isCarrier = isCarrierMatch; //|| order.approvedCarrier; //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isWarehouse = currentUser.appType.includes(BaseUserAppTYpe.WAREHOUSE); //|| currentUser.roles.includes(USER_ROLE.ADMIN);
    var isSenderConsignee = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
        return currentUser.appType.includes(t);
    });
    var isCompanyOrder = show && (currentCompany._id === ((_a = order.createdBy) === null || _a === void 0 ? void 0 : _a.company._id) || currentCompany._id === ((_b = order.manager) === null || _b === void 0 ? void 0 : _b.company._id));
    var isCompaniesOrder = show && (order.companies || []).includes(currentCompany._id);
    var isCaApproved = ((_c = order.approvedCarrier) === null || _c === void 0 ? void 0 : _c._id) && currentCompany._id === order.approvedCarrier._id;
    var isCAAllowed = !isCarrierMatch || !((_d = order.approvedCarrier) === null || _d === void 0 ? void 0 : _d._id) || isCaApproved;
    var paid = ((_e = order.lastPayment) === null || _e === void 0 ? void 0 : _e.status) === OrderPaymentStatus.PAID &&
        ((!isCarrier && order.lastPayment.releasedAt) || (isCarrier && order.lastPayment.approvedAt));
    var approvedQuotes = props.match.url.toLowerCase().startsWith('/in-progress');
    var finished = props.match.url.toLowerCase().startsWith('/archive');
    var isHide = checkIfOrderDetailsIsHide(props.store, order);
    var canEditOrder = canEdit(order, props.store, { inProgress: true });
    var customerAvailable = show && isCustomer && (order === null || order === void 0 ? void 0 : order.status) === OrderStatus.ApprovedQuote;
    var customerOrderAvailable = show &&
        (isCustomer || ((isWarehouse || isSenderConsignee) && isCompaniesOrder)) &&
        [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(order.status);
    var senderConsigneeOrderAvailable = show &&
        (isCustomer || isForwarder || isWarehouse || isSenderConsignee) &&
        isCompaniesOrder &&
        [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(order.status);
    var isOwnOrder = show &&
        order &&
        order.createdBy &&
        customerAvailable &&
        ([order.createdBy._id || order.createdBy].includes(currentUser._id) || currentUser.roles.includes(USER_ROLE.ADMIN));
    var customerAvailableFinished = isCustomer && order.status === OrderStatus.Finished;
    var isOwnOrderFinished = show &&
        order &&
        order.createdBy &&
        order.publishedAt &&
        (customerAvailableFinished || customerAvailable) &&
        ([order.createdBy._id || order.createdBy].includes(currentUser._id) ||
            [order.manager._id || order.manager].includes(currentUser._id) ||
            canEditOrder ||
            currentUser.roles.includes(USER_ROLE.ADMIN));
    var transportation = order && order.transportation && order.transportation[0];
    var lastTransportation = order && order.transportations && order.transportations[order.transportations.length - 1];
    var isDeliveredLastTransportation = lastTransportation === null || lastTransportation === void 0 ? void 0 : lastTransportation.unloadedAt;
    var isFoManager = order === null || order === void 0 ? void 0 : order.forwarders.find(function (e) { var _a; return (((_a = e.company) === null || _a === void 0 ? void 0 : _a._id) || e.company) === currentCompany._id; });
    var isOpenOrders = location.href.toLowerCase().match('/open-orders');
    var isInProgressOrders = location.href.toLowerCase().match('/in-progress');
    var isPreparation = location.href.toLowerCase().match('/preparation');
    var orderWithApprovedQuote = order &&
        order.latestQuote &&
        (isCAAllowed || isCompanyOrder || isFoManager) &&
        [OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(order.latestQuote.status);
    var canViewOffer = currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_view_offer); });
    var onMouseEnter = function () { return __awaiter(_this, void 0, void 0, function () {
        var res, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!order.latestQuote) return [3 /*break*/, 4];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    (orderListItemProps === null || orderListItemProps === void 0 ? void 0 : orderListItemProps.onChange) && orderListItemProps.onChange({ loading: true });
                    return [4 /*yield*/, (config &&
                            config.client.query({
                                query: FetchUploadsCount,
                                variables: {
                                    filter: {
                                        fields: [
                                            { name: 'parent', value: order.latestQuote._id },
                                            { name: 'parentOffer', value: 1 },
                                            { name: 'canAccess', value: true },
                                            ((_a = order.view_access) === null || _a === void 0 ? void 0 : _a.length) && {
                                                name: 'view_access',
                                                value: order.view_access,
                                            },
                                            order &&
                                                checkIfOrderDetailsIsHide(store, order) && {
                                                name: 'access',
                                                value: [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE],
                                            },
                                        ].filter(function (e) { return e; }),
                                        limit: 0,
                                    },
                                },
                            }))];
                case 2:
                    res = _b.sent();
                    order.latestQuote.totalUploads = { count: res.data.uploadsCount.count };
                    (orderListItemProps === null || orderListItemProps === void 0 ? void 0 : orderListItemProps.onChange) && orderListItemProps.onChange({ record: order, loading: false });
                    return [3 /*break*/, 4];
                case 3:
                    e_1 = _b.sent();
                    (orderListItemProps === null || orderListItemProps === void 0 ? void 0 : orderListItemProps.onChange) && orderListItemProps.onChange({ loading: false });
                    throw e_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var timacomSynced;
    try {
        timacomSynced = JSON.parse(((_f = order.sync_logs) === null || _f === void 0 ? void 0 : _f.length) && order.sync_logs[0].changes).status === 'STORED';
    }
    catch (e) { }
    var gpsDetails;
    try {
        gpsDetails = JSON.parse((_g = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _g === void 0 ? void 0 : _g.p44ShipmentLog.changes);
    }
    catch (e) { }
    return (React.createElement(React.Fragment, null, !props.isAdmin &&
        (isCustomer ||
            isForwarder ||
            ((isWarehouse || isSenderConsignee || isCaApproved) && (approvedQuotes || finished)) ||
            ((isWarehouse || isSenderConsignee) && isCompanyOrder)) ? (React.createElement(MenuListItem, { id: "order-" + order.orderId, onMouseEnter: onMouseEnter, additional: order.lastPayment && order.status === OrderStatus.ApprovedQuote && !props.v2 ? (React.createElement(TextOverflow, { variant: 'caption', color: 'primary', style: { position: 'absolute', minWidth: 24 } }, (paid && langOrderList.t('in_progress_payment_paid')) ||
            langOrderList.t('in_progress_payment_not_paid'))) : null, list: [
            {
                id: 'p44-track',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(OrderRouteMenuItem, __assign({}, {
                        icon: (React.createElement(BaseTooltip, { title: langApp.t('traking_order') },
                            React.createElement(BaseIconButton, { color: 'primary' },
                                React.createElement(RoomIcon, null)))),
                        label: langApp.t('traking_order'),
                        handleClickOpen: function (ev) {
                            var _a;
                            window.open((_a = gpsDetails === null || gpsDetails === void 0 ? void 0 : gpsDetails.result) === null || _a === void 0 ? void 0 : _a.mapUrl, '_blank');
                        },
                    })))),
                available: ((_h = gpsDetails === null || gpsDetails === void 0 ? void 0 : gpsDetails.result) === null || _h === void 0 ? void 0 : _h.mapUrl) && isCAAllowed,
            },
            {
                id: 'link-to-view',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(OrderLinkV1, { quote: order.latestQuote, order: order, config: config, label: langApp.t('link_to_book') }))),
                available: (order.approvedCarrier || order.publishedAt) && (isFoManager || isCompanyOrder),
            },
            {
                id: 'link-to-book',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(LinkToBook, { quote: order.latestQuote, order: order, config: config, label: langApp.t('link_to_book') }))),
                available: order.approvedCarrier &&
                    (isFoManager || isCompanyOrder) &&
                    props.order.noQuotes &&
                    [OrderStatus.ApprovedQuote].includes(order.status),
            },
            {
                id: 'btn-request-payment',
                content: function (open) {
                    return open && (React.createElement(OrderRequestPaymentAction, { quote: order.latestQuote, order: order, config: config, label: langForm.t('form_btn_get_payment') }));
                },
                available: !isHide && isCaApproved && [OrderStatus.ApprovedQuote].includes(order.status),
            },
            {
                id: 'btn-fast-payment',
                content: function (open) {
                    return open && (React.createElement(OrderFastPayment, { quote: order.latestQuote, onChange: props.onChangeV1, order: order, config: config, label: langForm.t('form_btn_get_payment') }));
                },
                available: !isHide &&
                    isCaApproved &&
                    [OrderStatus.ApprovedQuote].includes(order.status) &&
                    ((_j = order.latestQuote) === null || _j === void 0 ? void 0 : _j.fastPaymentStatus) === OrderQuoteFastPaymentStatus.Available &&
                    (((_l = (_k = order.createdBy) === null || _k === void 0 ? void 0 : _k.company) === null || _l === void 0 ? void 0 : _l.isFastPayment) || ((_p = (_o = (_m = order.forwarders[0]) === null || _m === void 0 ? void 0 : _m.company) === null || _o === void 0 ? void 0 : _o.settings) === null || _p === void 0 ? void 0 : _p.isDefaultFastPayment)),
            },
            {
                id: 'btn-request-changes',
                content: isCarrier ? (React.createElement(OrderQuoteRequestChanges, { shouldNotPrevent: true, quote: order.latestQuote, order: order, config: config, label: langForm.t('form_btn_order_request_change') })) : null,
                available: isCaApproved && [OrderStatus.ApprovedQuote].includes(order.status),
            },
            {
                id: 'resend-notification',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(ResendNotificaion, { quote: order.latestQuote, order: order, config: config, label: langForm.t('form_btn_send_notification') }))),
                available: (isPreparation || isInProgressOrders) &&
                    (order.approvedCarrier || order.hasPreparing || order.preparedAt) &&
                    (isFoManager || isCompanyOrder),
            },
            {
                id: 'btn-notify-published',
                content: isCustomer ? (React.createElement(OrderNotifyPublished, { order: order, config: config, label: langForm.t('form_btn_order_request_change') })) : null,
                available: isOpenOrders &&
                    !isHide &&
                    (isCompanyOrder || isFoManager) &&
                    [OrderStatus.WaitingQuotes, OrderStatus.AcceptedQuote, OrderStatus.HasQuotes].includes(order.status),
            },
            {
                id: 'btn-create-cmr',
                content: (React.createElement(OrderCMR, { order: order, tranportation: transportation, config: config, label: langOrderList.t('in_progress_cmr'), onChange: props.onChange })),
                available: (!isHide && customerOrderAvailable) || senderConsigneeOrderAvailable,
            },
            {
                id: 'btn-create-order-pdf',
                content: (React.createElement(OrderPDFV1, { order: order, config: config, label: langForm.t('form_btn_print_order'), onChange: props.onChange })),
                available: !isHide && (isCompanyOrder || isFoManager),
            },
            {
                id: 'btn-order-documents',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(OrderRoutesMenuDocuments, { order: order, hideAdd: true, record: order.latestQuote, config: config, label: '1' }))),
                available: orderWithApprovedQuote,
            },
            {
                id: 'btn-payment',
                content: (React.createElement(OrderPaymentAction, { order: order, config: config, label: langOrderList.t('in_progress_payment_title') })),
                available: ((isCaApproved && [BaseUserAppTYpe.FORWARDER].find(function (t) { return currentUser.appType.includes(t); })) ||
                    ([BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.CUSTOMER].find(function (t) { return currentUser.appType.includes(t); }) &&
                        isCAAllowed)) &&
                    !isHide,
            },
            !isHideInvoice &&
                (isCaApproved || isFoManager) && {
                id: 'btn-send-invoice',
                content: (React.createElement(SendInvoice, { order: order, quote: order.latestQuote, config: config, label: langForm.t('form_btn_send_invoice') })),
                available: !isHide &&
                    order.latestQuote &&
                    [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(order.status) &&
                    (isCaApproved || isFoManager),
            },
            {
                id: 'btn-payment-release',
                content: (React.createElement(OrderPaymentActionForwarder, { order: order, config: config, label: langOrderList.t('in_progress_payment_title') + " " + langApp
                        .t('user_app_type_carrier')
                        .toLowerCase() })),
                available: !isHide && order.status === OrderStatus.ApprovedQuote && isFoManager,
            },
            {
                id: 'btn-payment-approve',
                content: (React.createElement(OrderPaymentActionForwarder, { order: order, config: config, isCarrier: true, label: langOrderList.t('in_progress_payment_title') + " " + langApp
                        .t('user_app_type_customer')
                        .toLowerCase() })),
                available: !isHide && order.status === OrderStatus.ApprovedQuote && isFoManager,
            },
            {
                id: 'btn-statistics',
                content: (React.createElement(OrderQuoteStatistics, { order: order, config: config, quote: order.latestQuote, label: langOfferForm.t('offer_form_statistic') })),
                available: !isHide && (customerAvailable || isFoManager),
            },
            {
                id: 'btn-order-create-template',
                content: (React.createElement(OrderMakeTemplateView, { order: order, store: props.store, label: langApp.t('create_order_create_template_checkbox') })),
                available: !isHide && isCompanyOrder,
            },
            {
                id: 'btn-order-edit',
                content: React.createElement(OrderEditView, { record: order, label: langApp.t('collapse_more') }),
                available: !isHide && (isFoManager || isCompanyOrder) && canEditOrder,
            },
            {
                id: 'btn-order-cancel',
                content: (React.createElement(OrderCancel, { order: order, config: config, onChange: props.onChange, label: langOrderList.t('order_list_cancel_order') })),
                available: !isHide,
            },
            {
                id: 'btn-order-restore',
                content: (React.createElement(OrderRestore, { order: order, config: config, label: langOrderList.t('order_list_restore_order') })),
                available: !isHide && (isOwnOrderFinished || isFoManager),
            },
            {
                id: 'btn-order-archive',
                content: (React.createElement(OrderArchive, { onChange: props.onChange, order: order, config: config, label: langOrderList.t('order_list_restore_order') })),
                available: !isHide && (isFoManager || isCompanyOrder),
            },
            {
                id: 'btn-order-un-archive',
                content: (React.createElement(OrderUnarchive, { onChange: props.onChange, order: order, config: config, label: langOrderList.t('order_list_restore_order') })),
                available: !isHide && (isFoManager || isCompanyOrder),
            },
            {
                id: 'btn-more-actions',
                content: moreActions,
                available: !isHide && !!moreActions,
            },
            {
                id: 'btn-register-order-issue',
                content: (React.createElement(FormItem, { v2: true, label: true, withText: true, fileInput: props.fileInput, item: __assign(__assign({}, getSnapshot(Issues.create())), (parent && { parent: (_q = order === null || order === void 0 ? void 0 : order.latestQuote) === null || _q === void 0 ? void 0 : _q._id, parent_collection_name: 'order_quotes' })), onChange: props.onChangeV2, config: props.config })),
                available: !isHide && isCompanyOrder && [OrderStatus.ApprovedQuote, OrderStatus.Finished].includes(order.status),
            },
            {
                id: 'btn-delete-order',
                content: (React.createElement(DeleteDialog, { id: 'btn-delete-order', withoutWrapper: true, value: order.orderId, onOk: function (e) { return __awaiter(_this, void 0, void 0, function () { return __generator(this, function (_a) {
                        return [2 /*return*/, onDeleteItem(e, order)];
                    }); }); }, withText: true })),
                available: !isHide && onDeleteItem && (isFoManager || isCompanyOrder) && canEditOrder,
            },
            {
                id: 'btn-view-offer',
                content: (React.createElement(OrderMoreView, { order: order, record: order.latestQuote, label: isCarrier ? langForm.t('form_btn_view_offer_edit') : langForm.t('form_btn_view_offer') })),
                available: orderWithApprovedQuote && canViewOffer,
            },
            {
                id: 'btn-order-sync',
                content: React.createElement(OrderSync, { order: order, config: config, label: '1' }),
                available: 
                // !timacomSynced &&
                !isHide &&
                    [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes].includes(order.status) &&
                    isFoManager &&
                    (currentCompany.sync.includes(1) || location.href.match(/localhost|127.0.0.1/gi)),
            },
            {
                id: 'btn-order-remove-timacom',
                content: React.createElement(OrderSyncRemove, { order: order, config: config, label: '1', onChange: props.onChange }),
                available: timacomSynced &&
                    !isHide && ((_r = order.approvedCarrier) === null || _r === void 0 ? void 0 : _r._id) &&
                    isFoManager &&
                    currentCompany.sync.includes(1),
            },
            {
                id: 'btn-order-rating',
                content: (React.createElement(OrderUserRating, { onChangeV1: props.onChangeV1, quote: order.latestQuote, order: order, config: config, label: '1' })),
                available: !isHide &&
                    order &&
                    order.latestQuote &&
                    order.createdBy &&
                    order.latestQuote.createdBy &&
                    order.publishedAt &&
                    (isDeliveredLastTransportation || [OrderStatus.Finished].includes(order.status)),
            },
            {
                id: 'btn-order-share',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(OrderShareComponent, { label: true, order: order, config: config }))),
                available: !isHide &&
                    !props.order.isTemplate &&
                    [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes, OrderStatus.AcceptedQuote].includes(order.status),
            },
            {
                id: 'btn-order-post',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(OrderPostToMyCA, { order: props.order, config: props.config, label: true, onChange: props.onChangeV1 }))),
                available: !isHide &&
                    isCompanyOrder &&
                    !props.order.isTemplate &&
                    [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes, OrderStatus.AcceptedQuote].includes(order.status),
            },
            {
                id: 'btn-order-history',
                content: (React.createElement(PreloadingComponent, null,
                    React.createElement(SectionHistory, { v2: true, filters: {
                            fields: [
                                { name: 'orders', value: [props.order._id] },
                                { name: 'type', value: 2 },
                            ],
                        }, type: 1, record: props.order, order: props.order, config: props.config }))),
                available: !isHide,
            },
        ] })) : null));
}
export default inject('store')(withOrderListItemStore(withRouter(OrderRouteMenu)));
