var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Grid, Collapse, TextField, createStyles, useMediaQuery } from '@material-ui/core';
import { inject, useObserver } from 'mobx-react';
import { useField } from 'formik';
import { onPreventEvent } from '../Dialog';
import InputTitle from './InputTitle';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { KEYS } from '../../i18n';
import clsx from 'clsx';
import Truck1 from '-!svg-react-loader!../assets/truck_Tent_92m3.svg';
import Truck2 from '-!svg-react-loader!../assets/truck_Thermo_trailer_reefer.svg';
import Truck3 from '-!svg-react-loader!../assets/truck_Box_trailer.svg';
import Truck4 from '-!svg-react-loader!../assets/truck_Mega_trailer.svg';
import Truck5 from '-!svg-react-loader!../assets/truck_Flatbed_trailer.svg';
import Truck6 from '-!svg-react-loader!../assets/truck_Autotrain.svg';
import Truck7 from '-!svg-react-loader!../assets/truck_Container.svg';
import Truck8 from '-!svg-react-loader!../assets/truck_Container_20.svg';
import Truck9 from '-!svg-react-loader!../assets/truck_Container_40.svg';
import Truck10 from '-!svg-react-loader!../assets/truck_Container_45.svg';
import Truck11 from '-!svg-react-loader!../assets/truck_Crane_truck.svg';
import Truck12 from '-!svg-react-loader!../assets/truck_Mega_trailer.svg';
import Truck13 from '-!svg-react-loader!../assets/truck_Tank_truck.svg';
import Truck14 from '-!svg-react-loader!../assets/truck_Tip_up_lorry.svg';
import Truck15 from '-!svg-react-loader!../assets/truck_other.svg';
import Truck16 from '-!svg-react-loader!../assets/truck_other1.svg';
import TruckLess7point5t from '-!svg-react-loader!../assets/truck_less_7_point_5.svg';
import PaddingSpace from '../Order/form/formsV1/PaddingSpce';
import { makeStyles } from '@material-ui/core/styles';
import { TextOverflow } from '../TextOverflow';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { theme } from '../../theme';
import { useMainStyles } from '../../styles';
import BaseTooltip from '../BaseTooltip';
import BaseIconButton from '../BaseIconButton';
export var trackIcons = [
    { name: 'Tent 92 m3', icon: React.createElement(Truck1, null) },
    { name: 'Thermo trailer, reefer', icon: React.createElement(Truck2, null) },
    { name: 'Box trailer', icon: React.createElement(Truck3, null) },
    { name: 'Mega 100 m3', icon: React.createElement(Truck4, null) },
    { name: 'Flatbed trailer', icon: React.createElement(Truck5, null) },
    { name: 'Autotrain', icon: React.createElement(Truck6, null) },
    { name: 'Container', icon: React.createElement(Truck7, null) },
    { name: 'Container 20', icon: React.createElement(Truck8, null) },
    { name: 'Container 40', icon: React.createElement(Truck9, null) },
    { name: 'Container 45', icon: React.createElement(Truck10, null) },
    { name: 'Crane truck', icon: React.createElement(Truck11, null) },
    { name: 'Mega trailer', icon: React.createElement(Truck12, null) },
    { name: 'Tank truck', icon: React.createElement(Truck13, null) },
    { name: 'Tip up lorry', icon: React.createElement(Truck14, null) },
    { name: 'Open trailer', icon: React.createElement(Truck15, null) },
    { name: 'Truck <7.5 t', icon: React.createElement(TruckLess7point5t, null) },
    { name: 'Open trailer', icon: React.createElement(Truck16, null) },
];
var useVehicleTypeStyles = makeStyles(function (theme) {
    return createStyles({
        checkBox: {
            width: 24,
            padding: 0,
        },
        itemSelected: {
            backgroundColor: theme.palette.primary.main,
            cursor: 'pointer',
            '& *': {
                stroke: theme.palette.common.white,
                fill: theme.palette.common.white,
                color: theme.palette.common.white,
            },
            '& path[fill="white"]': {
                fill: theme.palette.primary.main,
            },
            '& circle[fill="white"]': {
                fill: theme.palette.primary.main,
            },
            '& path[stroke="#002B69"]': {
                fill: theme.palette.primary.main,
            },
        },
        item: {
            border: "1px solid #D5DDE5",
            borderRadius: 5,
            height: 106,
            '& svg': {
                width: '100%',
                maxWidth: 95,
                height: 'initial',
                marginBottom: -16,
            },
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                cursor: 'pointer',
                '& *': {
                    stroke: theme.palette.common.white,
                    fill: theme.palette.common.white,
                    color: theme.palette.common.white,
                },
                '& path[fill="white"]': {
                    fill: theme.palette.primary.main,
                },
                '& circle[fill="white"]': {
                    fill: theme.palette.primary.main,
                },
                '& path[stroke="#002B69"]': {
                    fill: theme.palette.primary.main,
                },
            },
        },
    });
});
export default inject('store')(function FormikVehicleType(props) {
    var _a = props.helperText, helperText = _a === void 0 ? '' : _a, onChange = props.onChange, _b = props.margin, margin = _b === void 0 ? 'dense' : _b, _c = props.fullWidth, fullWidth = _c === void 0 ? true : _c, _d = props.variant, variant = _d === void 0 ? 'standard' : _d, title = props.title, config = props.config, store = props.store, inputProps = __rest(props, ["helperText", "onChange", "margin", "fullWidth", "variant", "title", "config", "store"]);
    var accountStore = store.accountStore;
    var classes = useVehicleTypeStyles();
    var langApp = useTranslation(KEYS.APP);
    var _e = __read(React.useState([]), 2), data = _e[0], setData = _e[1];
    var _f = __read(React.useState(true), 2), loading = _f[0], setLoading = _f[1];
    var _g = __read(React.useState(0), 2), time = _g[0], setTime = _g[1];
    var _h = __read(useField(props), 3), field = _h[0], meta = _h[1], helpers = _h[2];
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var isMiddle = useMediaQuery(theme.breakpoints.down('sm'));
    var _j = __read(React.useState(true), 2), isOpen = _j[0], setIsOpen = _j[1];
    var classcesMain = useMainStyles();
    var toggleCollapse = function (e) {
        setIsOpen(true);
        return onPreventEvent(e);
    };
    var collapseItem = (React.createElement(BaseTooltip, { title: (isOpen ? langApp.t('collapse_less') : langApp.t('collapse_more')) },
        React.createElement(BaseIconButton, { basic: true, disabled: true, onClick: toggleCollapse, onMouseEnter: onPreventEvent, style: { padding: 0 } }, isOpen ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null))));
    React.useEffect(function () {
        var list = __spread(JSON.parse(JSON.stringify(accountStore.orderVehicleTypes || [])));
        if (props.setTotal)
            props.setTotal(list.length);
        var value = [];
        while (list.length)
            value.push(list.splice(0, props.v1 ? 4 : isMiddle ? 2 : 5));
        setData(value);
        setLoading(false);
    }, [isSmall, isMiddle]);
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var onClick = function (ev, element) {
        var exist = field.value.find(function (e) { return (e._id || e) === element._id; });
        var valueToAdd = props.valueAsId ? element._id : element;
        var newValue = __spread(field.value.filter(function (e) { return (e._id || e) !== element._id; }), [exist ? null : valueToAdd]).filter(function (e) { return e; });
        helpers.setValue(newValue);
        if (onChange)
            onChange({ target: { name: inputProps.name, value: newValue, item: element } });
    };
    var _onHandleClose = function () {
        // setOpen(false);
    };
    var onClose = function (newTime) {
        if (props.onClose) {
            props.onClose((newTime.hour < 10 ? '0' : '') + newTime.formatted24);
        }
        setTime(Date.now() + 3000);
        _onHandleClose();
    };
    var style = { marginTop: 0, marginBottom: 0, display: 'none' };
    if (props.style)
        Object.assign(style, props.style);
    return useObserver(function () { return (React.createElement(React.Fragment, null,
        React.createElement(Grid, { container: true, spacing: 3, style: isSmall ? {} : { marginBottom: theme.spacing(1) } },
            React.createElement(PaddingSpace, null),
            React.createElement(Grid, { container: true, alignItems: 'center', item: true, xs: 12, md: 6 },
                React.createElement("div", null,
                    React.createElement(InputTitle, __assign({}, props, { v1: true, noStyle: true }))),
                !!touchedError ? (React.createElement(TextOverflow, { v2: true, style: { marginLeft: theme.spacing(1) } }, touchedError || helperText)) : null,
                React.createElement(TextField, __assign({ variant: variant, margin: margin, autoComplete: "off", InputProps: {
                        endAdornment: collapseItem,
                    }, inputProps: { maxLength: 1000 }, placeholder: inputProps.placeholder || (typeof title === 'string' && title) || null, fullWidth: fullWidth }, field, props, { style: style, onFocus: toggleCollapse, error: !!touchedError, helperText: touchedError || helperText }))),
            React.createElement(Grid, { item: true, xs: 12, md: 4 }, props.children)),
        React.createElement(Collapse, { in: isOpen, timeout: "auto", unmountOnExit: true, style: { width: '100%' } },
            React.createElement(React.Fragment, null,
                (loading && (React.createElement(Grid, { container: true, justify: 'center' },
                    React.createElement(CircularIndeterminate, null)))) ||
                    null,
                data.map(function (el, index) {
                    var _a;
                    return (React.createElement(Grid, __assign({ container: true, spacing: 3, key: index }, (isSmall && {
                        container: true,
                        justify: 'space-between',
                        style: { marginBottom: theme.spacing(1) },
                    }), { className: clsx((_a = {}, _a[classcesMain.disabled] = props.disabled, _a)) }),
                        !props.v1 ? React.createElement(PaddingSpace, null) : null,
                        el.map(function (e) {
                            var _a;
                            var _b;
                            // const checked = field.value.map((val) => val.name || val).includes(e.name);
                            var checked = field.value.map(function (val) { return val.name || val; }).includes(props.valueAsId ? e._id : e.name);
                            var icon = (_b = trackIcons[e.orderIndex - 1]) === null || _b === void 0 ? void 0 : _b.icon;
                            return (React.createElement(Grid, { id: ("vehicle-type-" + e.name).replace(/\s/g, '-'), item: true, xs: 5, md: props.v1 ? 3 : 2, key: e._id, onClick: function (ev) { return onClick(ev, e); } },
                                React.createElement(Grid, { style: __assign({}, ((isSmall && { paddingTop: theme.spacing(1) }) || { marginBottom: theme.spacing(1) })), container: true, className: clsx(classes.item, (_a = {}, _a[classes.itemSelected] = checked, _a)), justify: isSmall ? undefined : 'center', direction: 'column', alignItems: 'center' },
                                    icon,
                                    React.createElement(TextOverflow, { style: { textAlign: 'center' } }, e.name))));
                        }),
                        (index === 3 && props.content && (React.createElement(PaddingSpace, { item: true, xs: 12, md: 6 }, props.content))) ||
                            null));
                }))))); });
});
