var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import gql from 'graphql-tag';
import { FORWARDER_USER_BASE, BASE_INDEX_RESPONSE, BASE_QUERY, BASE_RESPONSE, LIST_SIMPLE_DATA } from '../base';
import { ORDER_TRANSPORTATION_BASE, ORDER_TRANSPORTATION_FULL, STATS_DETAILS } from './types';
import { ORDER_VEHICLE_TYPE_BASE } from './orderVehicleType';
import { USER_BASE, USER_WITH_COMPANY_BASE } from '../user';
import { ORDER_QUOTE_BASE } from './orderQuotes';
import { ORDER_CERTIFICATE_BASE } from './orderCertificate';
import { LOGS_BASE } from '../logs';
import { ORDER_LOAN_BASE } from './orderLoan';
import { ORDER_LIST_DETAILS_v1, ORDER_PREPARATION_STATUS } from './type.v1';
import { USER_RATING_BASE } from '../userRatings';
import { ORDER_BASE_v2 } from './types.v2';
export var ORDER_BASE = "" + ORDER_BASE_v2 + ORDER_PREPARATION_STATUS + "\n      ";
export var ORDER_BASE_TO_VIEW = "\n            vehicleType{\n                " + ORDER_VEHICLE_TYPE_BASE + "\n            }\n            ";
export var ORDER_ALL_BASE = "\n  " + ORDER_BASE + "\n  certificates{\n    " + ORDER_CERTIFICATE_BASE + "\n  }\n  forwarders{\n    " + FORWARDER_USER_BASE + "\n  }\n  transportations{\n    " + ORDER_TRANSPORTATION_BASE + "\n  }\n  " + ORDER_BASE_TO_VIEW + "\n  membersSawRatingPromotion {\n    _id\n  }\n  rating{\n    " + USER_RATING_BASE + "\n  }\n";
export var ORDER_ALL_BASE_FULL = "\n  " + ORDER_BASE + "\n  certificates{\n    " + ORDER_CERTIFICATE_BASE + "\n  }\n  forwarders{\n    " + FORWARDER_USER_BASE + "\n  }\n  transportations{\n    " + ORDER_TRANSPORTATION_FULL + "\n  }\n  " + ORDER_BASE_TO_VIEW + "\n  membersSawRatingPromotion {\n    _id\n  }\n  rating{\n    " + USER_RATING_BASE + "\n  }\n";
export var ORDER_LIST_DETAILS = "" + ORDER_LIST_DETAILS_v1;
export var ReadOrders = gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  {\n    assets {\n      _id\n      name\n      orderInMenu\n    }\n  }\n"], ["\n  {\n    assets {\n      _id\n      name\n      orderInMenu\n    }\n  }\n"])));
export var GetStatisticsOrdersByDate = gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  query getStatisticsOrdersByDate($filter: MainFilter) {\n    getStatisticsOrdersByDate(filter: $filter) {\n      pickup {\n        _id\n        count\n      }\n      delivery {\n        _id\n        count\n      }\n    }\n  }\n"], ["\n  query getStatisticsOrdersByDate($filter: MainFilter) {\n    getStatisticsOrdersByDate(filter: $filter) {\n      pickup {\n        _id\n        count\n      }\n      delivery {\n        _id\n        count\n      }\n    }\n  }\n"])));
export var FetchOrderIndex = gql(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n    query orderIndex {\n        orderIndex  {\n            ", "\n        }\n    }\n"], ["\n    query orderIndex {\n        orderIndex  {\n            ", "\n        }\n    }\n"])), BASE_INDEX_RESPONSE);
export var ALL_ORDER = "sorting_hasInvoice\n            transport_other\n            forwarderOrderMarkUpPercentage\n            forwarderOrderMarkUpMinPrice\n            forwarderOrderMarkUpMinPriceCurrency\n            forwarderOrderMarkUpMaxPrice\n            forwarderOrderMarkUpMaxPriceCurrency\n            publishedAt \n                companies \n                lastChatMessage{\n                    _id\n                }\n                    createdFrom{\n                      _id\n                      orderId\n                      templateName\n                      orderCustomId\n                    }\n                    sourceFrom{\n                      _id\n                      orderId\n                      templateName\n                      orderCustomId\n                    }\n            createdBy{\n                " + USER_WITH_COMPANY_BASE + "\n            }\n            lastLoan{\n                " + ORDER_LOAN_BASE + "\n            }";
export var FetchOrder = gql(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    query order($_id: MongoId!) {\n        order(_id: $_id) {\n         ", "\n           ", "\n         }\n    }\n"], ["\n    query order($_id: MongoId!) {\n        order(_id: $_id) {\n         ", "\n           ", "\n         }\n    }\n"])), ORDER_ALL_BASE, ALL_ORDER);
export var FetchOrderFull = gql(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n    query order($_id: MongoId!) {\n        order(_id: $_id) {\n        ", "\n          ", "\n          quote{\n            _id\n          }\n        }\n    }\n"], ["\n    query order($_id: MongoId!) {\n        order(_id: $_id) {\n        ", "\n          ", "\n          quote{\n            _id\n          }\n        }\n    }\n"])), ORDER_ALL_BASE_FULL, ALL_ORDER);
export var FetchOrders = gql(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n    query orders($filter:MainFilter){\n        orders(filter:$filter) {\n            redirect\n            statsFacets{\n              ", "\n            }\n            data{\n                ", "\n                lastLog{\n                    ", "\n                }\n            }\n            count\n        }\n    }\n"], ["\n    query orders($filter:MainFilter){\n        orders(filter:$filter) {\n            redirect\n            statsFacets{\n              ", "\n            }\n            data{\n                ", "\n                lastLog{\n                    ", "\n                }\n            }\n            count\n        }\n    }\n"])), STATS_DETAILS, ORDER_LIST_DETAILS, LOGS_BASE);
export var FetchOrderUserV1 = gql(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n    query orderUsers($filter:MainFilter){\n      orderUsers(filter:$filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"], ["\n    query orderUsers($filter:MainFilter){\n      orderUsers(filter:$filter) {\n            data{\n                ", "\n            }\n            count\n        }\n    }\n"])), USER_BASE);
export var FetchAllOrders = gql(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  query allOrderList($filter: MainFilter) {\n    allOrderList(filter: $filter) {\n      data {\n        _id\n        orderId\n        orderCustomId\n        status\n      }\n      count\n    }\n  }\n"], ["\n  query allOrderList($filter: MainFilter) {\n    allOrderList(filter: $filter) {\n      data {\n        _id\n        orderId\n        orderCustomId\n        status\n      }\n      count\n    }\n  }\n"])));
export var FetchAllOrdersDetailed = gql(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  query allOrderList($filter: MainFilter) {\n    allOrderList(filter: $filter) {\n      data {\n         ", "\n          generatedAt\n      }\n      count\n    }\n  }\n"], ["\n  query allOrderList($filter: MainFilter) {\n    allOrderList(filter: $filter) {\n      data {\n         ", "\n          generatedAt\n      }\n      count\n    }\n  }\n"])), ORDER_LIST_DETAILS);
export var CreateFakeOrder = gql(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  mutation createFakeOrders($input: OrderFakeInput) {\n    createFakeOrders(input: $input) {\n      count\n    }\n  }\n"], ["\n  mutation createFakeOrders($input: OrderFakeInput) {\n    createFakeOrders(input: $input) {\n      count\n    }\n  }\n"])));
export var FetchOrderTemplates = gql(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n    query orders($filter: MainFilter) {\n        orders(filter: $filter) {\n            data {\n                ", "\n                orderId\n                orderCustomId\n                templateName\n                noQuotes\n            }\n            count\n        }\n    }\n"], ["\n    query orders($filter: MainFilter) {\n        orders(filter: $filter) {\n            data {\n                ", "\n                orderId\n                orderCustomId\n                templateName\n                noQuotes\n            }\n            count\n        }\n    }\n"])), BASE_QUERY);
export var FetchOrderStats = gql(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  query orderStatsQuotes($filter: MainFilter) {\n    orderStatsQuotes(filter: $filter) {\n      ", "\n    }\n  }\n"], ["\n  query orderStatsQuotes($filter: MainFilter) {\n    orderStatsQuotes(filter: $filter) {\n      ", "\n    }\n  }\n"])), STATS_DETAILS);
export var FetchOrderToDoStats = gql(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  query orderToDoStats($filter: MainFilter) {\n      orderToDoStats(filter: $filter) {\n      ", "\n    }\n  }\n"], ["\n  query orderToDoStats($filter: MainFilter) {\n      orderToDoStats(filter: $filter) {\n      ", "\n    }\n  }\n"])), STATS_DETAILS);
export var FetchOrderRateCaStats = gql(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  query orderRateCarrierStats($filter: MainFilter) {\n    orderRateCarrierStats(filter: $filter) {\n      ", "\n    }\n  }\n"], ["\n  query orderRateCarrierStats($filter: MainFilter) {\n    orderRateCarrierStats(filter: $filter) {\n      ", "\n    }\n  }\n"])), STATS_DETAILS);
export var FetchOrderQuoteStatsDetails = gql(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  query orderQuotesDetailsStats($filter: MainFilter) {\n    orderQuotesDetailsStats(filter: $filter) {\n     ", "\n    }\n  }\n"], ["\n  query orderQuotesDetailsStats($filter: MainFilter) {\n    orderQuotesDetailsStats(filter: $filter) {\n     ", "\n    }\n  }\n"])), STATS_DETAILS);
export var CreateOrder = gql(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n    mutation createOrder($input: OrderInput!) {\n        createOrder(input: $input) {\n            ", "\n            forwarders{\n              ", "\n            }\n            transportations{\n              ", "\n            }\n        }\n    }\n"], ["\n    mutation createOrder($input: OrderInput!) {\n        createOrder(input: $input) {\n            ", "\n            forwarders{\n              ", "\n            }\n            transportations{\n              ", "\n            }\n        }\n    }\n"])), ORDER_BASE, FORWARDER_USER_BASE, ORDER_TRANSPORTATION_BASE);
export var CreateOrderWarehouse = gql(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n    mutation createWarehouseOrder($input: MainBody) {\n        createWarehouseOrder(input: $input) {\n            ", "\n            forwarders{\n              ", "\n            }\n            transportations{\n              ", "\n            }\n        }\n    }\n"], ["\n    mutation createWarehouseOrder($input: MainBody) {\n        createWarehouseOrder(input: $input) {\n            ", "\n            forwarders{\n              ", "\n            }\n            transportations{\n              ", "\n            }\n        }\n    }\n"])), ORDER_BASE, FORWARDER_USER_BASE, ORDER_TRANSPORTATION_BASE);
export var UpdateWarehouseOrder = gql(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n    mutation updateWarehouseOrder($input: MainBody) {\n      updateWarehouseOrder(input: $input) {\n            ", "\n            forwarders{\n              ", "\n            }\n            transportations{\n              ", "\n            }\n        }\n    }\n"], ["\n    mutation updateWarehouseOrder($input: MainBody) {\n      updateWarehouseOrder(input: $input) {\n            ", "\n            forwarders{\n              ", "\n            }\n            transportations{\n              ", "\n            }\n        }\n    }\n"])), ORDER_BASE, FORWARDER_USER_BASE, ORDER_TRANSPORTATION_BASE);
export var CreateOrderTemplate = gql(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n    mutation createOrderTemplate($input: OrderTemplateInput!) {\n        createOrderTemplate(input: $input) {\n          ", "\n        }\n    }\n"], ["\n    mutation createOrderTemplate($input: OrderTemplateInput!) {\n        createOrderTemplate(input: $input) {\n          ", "\n        }\n    }\n"])), ORDER_BASE);
export var DuplicateOrder = gql(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n    mutation duplicateOrder($_id:MongoId!) {\n        duplicateOrder(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation duplicateOrder($_id:MongoId!) {\n        duplicateOrder(_id: $_id) {\n            ", "\n        }\n    }\n"])), ORDER_ALL_BASE);
export var CopyOrder = gql(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  mutation copyOrder($input: OrderCopyInput) {\n    copyOrder(input: $input) {\n      ", "\n           ", "\n    }\n  }\n"], ["\n  mutation copyOrder($input: OrderCopyInput) {\n    copyOrder(input: $input) {\n      ", "\n           ", "\n    }\n  }\n"])), ORDER_ALL_BASE, ALL_ORDER);
export var UpdateOrder = gql(templateObject_22 || (templateObject_22 = __makeTemplateObject(["\n    mutation updateOrder($input: OrderInputUpdate!) {\n        updateOrder(input: $input) {\n            ", "\n            forwarders{\n                ", "\n            }\n        }\n    }\n"], ["\n    mutation updateOrder($input: OrderInputUpdate!) {\n        updateOrder(input: $input) {\n            ", "\n            forwarders{\n                ", "\n            }\n        }\n    }\n"])), ORDER_BASE, FORWARDER_USER_BASE);
export var PublishOrder = gql(templateObject_23 || (templateObject_23 = __makeTemplateObject(["\n    mutation publishOrder($input: OrderInputPublish) {\n        publishOrder(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation publishOrder($input: OrderInputPublish) {\n        publishOrder(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_BASE);
export var ArchiveOrder = gql(templateObject_24 || (templateObject_24 = __makeTemplateObject(["\n  mutation archiveOrder($input: OrderRestoreInput!) {\n    archiveOrder(input: $input) {\n      _id\n    }\n  }\n"], ["\n  mutation archiveOrder($input: OrderRestoreInput!) {\n    archiveOrder(input: $input) {\n      _id\n    }\n  }\n"])));
export var UnarchiveOrder = gql(templateObject_25 || (templateObject_25 = __makeTemplateObject(["\n  mutation unarchiveOrder($input: OrderRestoreInput!) {\n    unarchiveOrder(input: $input) {\n      _id\n    }\n  }\n"], ["\n  mutation unarchiveOrder($input: OrderRestoreInput!) {\n    unarchiveOrder(input: $input) {\n      _id\n    }\n  }\n"])));
export var RestoreOrder = gql(templateObject_26 || (templateObject_26 = __makeTemplateObject(["\n    mutation restoreOrder($input: OrderRestoreInput!) {\n        restoreOrder(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation restoreOrder($input: OrderRestoreInput!) {\n        restoreOrder(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_QUOTE_BASE);
export var DeleteOrder = gql(templateObject_27 || (templateObject_27 = __makeTemplateObject(["\n    mutation deleteOrder($_id: MongoId!) {\n        deleteOrder(_id: $_id) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrder($_id: MongoId!) {\n        deleteOrder(_id: $_id) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
export var CancelOrder = gql(templateObject_28 || (templateObject_28 = __makeTemplateObject(["\n    mutation cancelOrder($input: OrderInputUpdate!) {\n        cancelOrder(input: $input) {\n            ", "\n        }\n    }\n"], ["\n    mutation cancelOrder($input: OrderInputUpdate!) {\n        cancelOrder(input: $input) {\n            ", "\n        }\n    }\n"])), ORDER_BASE);
export var DeleteOrders = gql(templateObject_29 || (templateObject_29 = __makeTemplateObject(["\n    mutation deleteOrders($_ids: [MongoId]) {\n        deleteOrders(_ids: $_ids) {\n            ", "\n        }\n    }\n"], ["\n    mutation deleteOrders($_ids: [MongoId]) {\n        deleteOrders(_ids: $_ids) {\n            ", "\n        }\n    }\n"])), BASE_RESPONSE);
export var ResyncOrder = gql(templateObject_30 || (templateObject_30 = __makeTemplateObject(["\n  mutation orderResync($_id: MongoId!) {\n    orderResync(_id: $_id) {\n      _id\n    }\n  }\n"], ["\n  mutation orderResync($_id: MongoId!) {\n    orderResync(_id: $_id) {\n      _id\n    }\n  }\n"])));
export var RemoveSyncOrder = gql(templateObject_31 || (templateObject_31 = __makeTemplateObject(["\n  mutation orderRemoveSync($_id: MongoId!) {\n    orderRemoveSync(_id: $_id) {\n      _id\n    }\n  }\n"], ["\n  mutation orderRemoveSync($_id: MongoId!) {\n    orderRemoveSync(_id: $_id) {\n      _id\n    }\n  }\n"])));
export var OrderStraps = gql(templateObject_32 || (templateObject_32 = __makeTemplateObject(["\n    query orderStraps($value:String) {\n        orderStraps(value: $value) {\n            ", "\n        }\n    }\n"], ["\n    query orderStraps($value:String) {\n        orderStraps(value: $value) {\n            ", "\n        }\n    }\n"])), LIST_SIMPLE_DATA);
export var OrderSideboards = gql(templateObject_33 || (templateObject_33 = __makeTemplateObject(["\n    query orderSideboards($value:String) {\n        orderSideboards(value: $value) {\n            ", "\n        }\n    }\n"], ["\n    query orderSideboards($value:String) {\n        orderSideboards(value: $value) {\n            ", "\n        }\n    }\n"])), LIST_SIMPLE_DATA);
export var OrderPDF = gql(templateObject_34 || (templateObject_34 = __makeTemplateObject(["\n  query orderPdf($_id: MongoId!, $filter: MainFilter) {\n    orderPdf(_id: $_id, filter: $filter) {\n      html\n      ducuments {\n        path\n        htmlTemplate\n      }\n    }\n  }\n"], ["\n  query orderPdf($_id: MongoId!, $filter: MainFilter) {\n    orderPdf(_id: $_id, filter: $filter) {\n      html\n      ducuments {\n        path\n        htmlTemplate\n      }\n    }\n  }\n"])));
export var OrderUrl = gql(templateObject_35 || (templateObject_35 = __makeTemplateObject(["\n  query orderUrl($_id: MongoId!) {\n    orderUrl(_id: $_id) {\n      url\n    }\n  }\n"], ["\n  query orderUrl($_id: MongoId!) {\n    orderUrl(_id: $_id) {\n      url\n    }\n  }\n"])));
export var OrderShare = gql(templateObject_36 || (templateObject_36 = __makeTemplateObject(["\n  mutation orderShare($input: OrderShareInput) {\n    orderShare(input: $input) {\n      status\n    }\n  }\n"], ["\n  mutation orderShare($input: OrderShareInput) {\n    orderShare(input: $input) {\n      status\n    }\n  }\n"])));
export var ExportOrders = gql(templateObject_37 || (templateObject_37 = __makeTemplateObject(["\n  query exportOrders($filter: MainFilter) {\n    exportOrders(filter: $filter) {\n      data\n    }\n  }\n"], ["\n  query exportOrders($filter: MainFilter) {\n    exportOrders(filter: $filter) {\n      data\n    }\n  }\n"])));
export * from './orderCargoPackage';
export * from './orderCargoPalletType';
export * from './orderRoute';
export * from './orderCargoProductType';
export * from './orderVehicle';
export * from './orderCargo';
export * from './orderQuotes';
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21, templateObject_22, templateObject_23, templateObject_24, templateObject_25, templateObject_26, templateObject_27, templateObject_28, templateObject_29, templateObject_30, templateObject_31, templateObject_32, templateObject_33, templateObject_34, templateObject_35, templateObject_36, templateObject_37;
