var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { createStyles, Grid, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import { ACTION_CHANGE, BasicDialog, DeleteDialog, onPreventEvent, TextOverflow } from '../../components';
import { useMainStyles } from '../../styles';
import clsx from 'clsx';
import { inject } from 'mobx-react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import ChatUserAvatar from './ChatUserAvatar';
import { withChatStore } from './stores/ChannelChat';
import OrderShortView from '../../components/Order/OrderShortView';
import ChatUserTyping from './ChatUserTyping';
import { ChannelItemChatIcon } from './ChannelChatIcon';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { ChatCreateEditForm } from './ChannelForm';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import InfoIcon from '@material-ui/icons/Info';
import browserHistory from '../../browserHistory';
import SectionOrderBookingChat from '../../components/SectionDetails/SectionOrderBookingChat';
import { dateViewFormat } from '../../components/DateView';
import { useSectionRowStyles } from '../../components/SectionDetails/styles';
import BaseTooltip from '../../components/BaseTooltip';
import ArchiveIcon from '@material-ui/icons/Archive';
import { theme } from '../../theme';
import PreloadingComponent from '../../components/PreloadingComponent';
import AllUploadList from '../../components/Socket/Chat/ChannelChat/Uploads';
import BaseIconButton from '../../components/BaseIconButton';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        item: {
            textAlign: 'center',
        },
        root: {
            position: 'relative',
            border: "1px solid " + theme.palette.grey['400'],
            borderRadius: theme.spacing(0.5),
            padding: theme.spacing(1),
            marginBottom: theme.spacing(1),
            cursor: 'pointer',
            '&:hover': {
                borderColor: theme.palette.common.white,
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            },
        },
        rootActive: {
            borderColor: theme.palette.common.white,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        },
        deleted: {
            borderColor: theme.palette.grey['600'],
            background: theme.palette.grey['600'],
            '& *': {
                color: theme.palette.common.white,
            },
        },
    });
});
var ChannelItem = inject('store')(function (props) {
    var _a;
    var _b;
    var record = props.record, currentUser = props.store.accountStore.currentUser;
    var classesSectionRow = useSectionRowStyles();
    var classes = useStyles();
    var history = useHistory();
    var mainClasses = useMainStyles();
    var contentProps = {};
    if (props.onClick)
        Object.assign(contentProps, { onClick: function (e) { return props.onClick(e, record); } });
    var allUsers = __spread(record.users, [record.createdBy]).filter(function (e) { return e; });
    var users = [];
    for (var i = 0; i < allUsers.length; i++) {
        if (!users
            .map(function (e) { return e === null || e === void 0 ? void 0 : e._id; })
            .filter(function (e) { return e; })
            .includes((_b = allUsers[i]) === null || _b === void 0 ? void 0 : _b._id)) {
            users.push(allUsers[i--]);
        }
    }
    var content;
    if (props.isShort) {
        content = (React.createElement(Grid, __assign({}, contentProps, { className: classesSectionRow.root, style: { padding: "1px 0" }, container: true, alignItems: 'center' }),
            React.createElement(Grid, { container: true, xs: 12, md: 3 },
                React.createElement(Grid, { container: true, className: mainClasses.widthInitial }, users
                    .filter(function (e) { return e._id !== currentUser._id; })
                    .map(function (user, index) { return (React.createElement(ChatUserAvatar, { isShort: true, key: user._id, user: user })); }))),
            React.createElement(Grid, { container: true, xs: 12, md: 4 },
                React.createElement(TextOverflow, { variant: 'caption' }, record.lastChatMessage.text)),
            React.createElement(Grid, { container: true, xs: 12, md: 3 }, dateViewFormat(record.lastChatMessage.createdAt, 1)),
            React.createElement(Grid, { container: true, xs: 12, md: 2, justify: 'flex-end' },
                React.createElement(SectionOrderBookingChat, __assign({ record: record }, props, { defaultValue: record })))));
    }
    else {
        content = (React.createElement(Grid, __assign({}, contentProps, { container: true, alignItems: 'flex-start', justify: 'space-between', className: clsx(classes.root, (_a = {},
                _a[classes.rootActive] = history.location.pathname.match(record._id) || props.selected,
                _a[classes.deleted] = record.deletedAt,
                _a)) }),
            React.createElement(OrderShortView, { record: record }),
            React.createElement(Grid, { container: true, direction: "column", alignItems: 'flex-end', className: mainClasses.widthInitial },
                React.createElement(Grid, { container: true, justify: 'flex-end' },
                    React.createElement(Grid, { container: true, className: mainClasses.widthInitial }, users
                        .filter(function (e) { return e._id !== currentUser._id; })
                        .map(function (user, index) { return (React.createElement(ChatUserAvatar, __assign({}, props, { key: user._id, user: user }))); })),
                    !props.isAdmin && React.createElement(ChannelItemActions, __assign({}, props))),
                React.createElement(ChatUserTyping, { record: record })),
            React.createElement(ChannelItemChatIcon, { record: record, top: true })));
    }
    if (props.onClick || props.isShort)
        return content;
    return (React.createElement(Link, { to: "/chats/" + record._id, style: { textDecoration: 'none' } }, content));
});
var ChannelItemActions = inject('store')(function (p) {
    var onChange = p.onChange, record = p.record, match = p.match, props = __rest(p, ["onChange", "record", "match"]);
    var _a = __read(React.useState(null), 2), anchorEl = _a[0], setAnchorEl = _a[1];
    var langChat = useTranslation(KEYS.CHAT);
    var langApp = useTranslation(KEYS.APP);
    var currentUserId = props.store.accountStore.currentUser._id;
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
        return onPreventEvent(event);
    };
    var handleClose = function (event) {
        setAnchorEl(null);
        return onPreventEvent(event);
    };
    var onDeleteItem = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.store.chatStore.deleteChat(record)];
                case 1:
                    _a.sent();
                    onChange && onChange(record, ACTION_CHANGE.DELETED);
                    if (record && location.pathname.match(record._id)) {
                        browserHistory.push('/chats');
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var onLeaveChat = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, props.store.chatStore.updateChat(__assign(__assign({}, record), { users: record.users.filter(function (e) { return e._id !== currentUserId; }) }))];
                case 1:
                    _a.sent();
                    if (location.pathname.match(record._id)) {
                        browserHistory.push('/chats');
                    }
                    onChange && onChange(record, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var allUploads = (React.createElement(PreloadingComponent, { withoutLoader: true },
        React.createElement(AllUploadList, { v3: true, v5: true, label: langApp.t('main_documents'), hideHeader: true, config: props.config, defaultFilter: { fields: [{ name: 'parent', value: record }] } })));
    return (React.createElement(React.Fragment, null,
        React.createElement("span", { style: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            } },
            React.createElement(BaseTooltip, { title: 'More actions' },
                React.createElement(BaseIconButton, { "aria-controls": "order-actions", "aria-haspopup": "true", onClick: handleClick },
                    React.createElement(MoreVertIcon, null)))),
        React.createElement(Menu, { id: "order-actions", disableScrollLock: true, anchorEl: anchorEl, keepMounted: true, open: Boolean(anchorEl), onClose: handleClose, getContentAnchorEl: null, anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'center',
            } },
            record.createdBy._id === currentUserId ? (!record.deletedAt ? (React.createElement(MenuItem, { onClick: handleClose },
                React.createElement(Grid, { container: true, alignItems: 'center' },
                    React.createElement(DeleteDialog, { icon: React.createElement(ArchiveIcon, null), iconStyle: { color: theme.palette.primary.main }, title: langChat.t('archive_chat_title'), v1: true, useTitle: true, withText: true, withoutWrapper: true, onOk: onDeleteItem, value: "" + (record.order.orderId || '') })))) : null) : (React.createElement(MenuItem, { onClick: handleClose },
                React.createElement(BasicDialog, { withoutWrapper: true, title: React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                        React.createElement(InfoIcon, { color: 'primary' }),
                        " ",
                        langChat.t('form_leave_chat')), onOk: onLeaveChat, body: React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
                        React.createElement(TextOverflow, { variant: 'subtitle1' }, langChat.t('form_leave_chat_ask'))), actionInit: function (onOpen) { return (React.createElement(Grid, { container: true, alignItems: 'center', justify: "space-between", onClick: onOpen },
                        React.createElement(TextOverflow, { variant: 'subtitle1' }, langChat.t('form_leave_chat')),
                        React.createElement(BaseIconButton, { style: { padding: theme.spacing(0.5) } },
                            React.createElement(ExitToAppIcon, { color: 'error' })))); } }))),
            allUploads,
            React.createElement(MenuItem, { onClick: handleClose },
                React.createElement(ChatCreateEditForm, __assign({ item: record }, p, { isModalForm: true, label: true }))))));
});
export default withChatStore(withRouter(ChannelItem));
