var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { BaseList, BaseListItem, BasicDialog, FilePreview, TextOverflow, } from '../../../components';
import BaseTooltip from '../../../components/BaseTooltip';
import { useTranslation } from 'react-i18next';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { AvatarItemView } from '../../../components/Account/Avatar';
import { dateViewFormat } from '../../../components/DateView';
import { FetchLogs } from '../../../graphql/logs';
import { KEYS } from '../../../i18n';
import BaseIconButton from '../../../components/BaseIconButton';
export var DocumentHistory = function (_a) {
    var item = _a.item, store = _a.store, props = __rest(_a, ["item", "store"]);
    var langApp = useTranslation(KEYS.APP);
    var theme = useTheme();
    if (!item.needSign ||
        !store.accountStore.currentUser._id ||
        !props.config ||
        !props.quote ||
        !props.quote.createdBy ||
        !props.quote.createdBy.company)
        return null;
    var canSign = props.quote.createdBy.company._id === store.accountStore.currentCompany._id ||
        props.quote.order.transportations.find(function (e) {
            return e.routes.find(function (e) { return e.email.map(function (e) { return e.toLowerCase(); }).includes(store.accountStore.currentUser.email); });
        });
    if (!canSign)
        return null;
    var log = item.logs.find(function (e) { return e.createdBy.company._id === store.accountStore.currentCompany._id; });
    if (log) {
        var client = props.config.client;
        var baseListProps = {
            client: client,
            query: FetchLogs,
            defaultFilter: {
                fields: [
                    {
                        name: 'object',
                        value: item._id,
                    },
                    {
                        name: 'action',
                        value: ['DOCUMENT_SIGN', 'CMR_UPDATED'],
                    },
                ],
            },
        };
        var config = {
            title: '',
            hideSort: true,
            rowContent: function (record, onChange) {
                return (React.createElement(BaseListItem, { record: record },
                    React.createElement(Grid, { container: true, spacing: 2, style: { padding: theme.spacing(1) } },
                        React.createElement(Grid, { item: true, xs: 12, md: 4, container: true, alignItems: 'center' },
                            React.createElement(AvatarItemView, { typographyProps: {
                                    variant: 'h6',
                                    style: {
                                        fontSize: 14,
                                        fontWeight: 600,
                                        color: theme.palette.common.white,
                                    },
                                }, user: record.createdBy }),
                            React.createElement(TextOverflow, { style: {
                                    paddingLeft: theme.spacing(2),
                                    maxWidth: "calc(100% - 56px)",
                                } }, record.createdBy.company.name)),
                        React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'center' }, record.action),
                        React.createElement(Grid, { item: true, xs: 12, md: 2, container: true, alignItems: 'center' }, dateViewFormat(record.createdAt, 1)),
                        React.createElement(Grid, { item: true, xs: 12, md: 4, container: true, alignItems: 'center', justify: 'flex-end' },
                            React.createElement(FilePreview, { item: { path: JSON.parse(record.changes).path }, title: "" + langApp.t('sign_overview_document_before_sign'), config: props.config }),
                            React.createElement(FilePreview, { item: { path: JSON.parse(record.changes).newPath }, title: "" + langApp.t('sign_overview_document_after_sign'), config: props.config })))));
            },
        };
        var dialogBody = React.createElement(BaseList, { title: langApp.t('signed_documents'), options: baseListProps, config: config });
        return (React.createElement(React.Fragment, null,
            React.createElement(BasicDialog, __assign({ body: dialogBody }, (props.actionInit && { withoutWrapper: true }), { title: langApp.t('signed_documents'), dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: function (handleClickOpen) {
                    return (props.actionInit && props.actionInit(handleClickOpen)) || (React.createElement(BaseTooltip, { title: langApp.t('signed_documents') },
                        React.createElement(BaseIconButton, { onClick: handleClickOpen },
                            React.createElement(AssignmentTurnedInIcon, null))));
                } }))));
    }
    return null;
};
