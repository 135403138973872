var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { observer, useLocalStore } from 'mobx-react-lite';
import { i18n, KEYS } from '../../i18n';
import OrderRouteMenuItem from '../../components/Order/List/OrderRoutesMenuItem';
import { BASIC_DIALOG_ACTIONS, BasicDialog, FormikAutocompleteAsync, FormikTextField, MainButton, onPreventEvent, } from '../../components';
import { ACTION_CHANGE } from '../../components/context.store.enums';
import { onlySpacesRegExp } from '../../common';
import { theme } from '../../theme';
import BaseTooltip from '../../components/BaseTooltip';
import UploadWithDragAndDrop from '../../components/UploadWithDragAndDrop';
import { CreateIssue, UpdateIssue } from '../../graphql/issues';
import { Issues } from '../../models/Issues';
import { FetchOrders } from '../../graphql';
import { OrderStatus } from '../../models';
import { getSnapshot } from 'mobx-state-tree';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ReportIcon from '@material-ui/icons/Report';
import BaseIconButton from '../../components/BaseIconButton';
function FormItem(_a) {
    var _this = this;
    var item = _a.item, props = __rest(_a, ["item"]);
    var langToast = useTranslation(KEYS.TOAST);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var formRef = React.useRef();
    var client = props.config.client;
    var disabled = props.disabled;
    var quotesParentCollectionName = item.parent_collection_name === 'order_quotes';
    var localStore = useLocalStore(function () {
        var _a;
        return ({
            error: '',
            isLoading: false,
            updated: 1,
            defaultValue: __assign(__assign({}, JSON.parse(JSON.stringify(item || getSnapshot(Issues.create())))), (((_a = item === null || item === void 0 ? void 0 : item.files) === null || _a === void 0 ? void 0 : _a.length) && quotesParentCollectionName ? {} : { files: [] })),
            onSubmit: function (_a) {
                var _id = _a._id, status = _a.status, _input = __rest(_a, ["_id", "status"]);
                return __awaiter(this, void 0, void 0, function () {
                    var input, result, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                _b.trys.push([0, 5, 6, 7]);
                                input = __assign(__assign(__assign({ title: _input.title, description: _input.description, files: (_input.files || []).map(function (e) { return e._id; }) }, (_input.parent && typeof _input.parent === 'string' && { parent: _input.parent })), (_input.parent_collection_name &&
                                    typeof _input.parent_collection_name === 'string' && {
                                    parent_collection_name: _input.parent_collection_name,
                                })), ((_id && { _id: _id }) || {}));
                                localStore.error = '';
                                localStore.isLoading = true;
                                result = void 0;
                                if (!!_id) return [3 /*break*/, 2];
                                return [4 /*yield*/, client.mutate({
                                        mutation: CreateIssue,
                                        variables: {
                                            input: input,
                                        },
                                    })];
                            case 1:
                                result = (_b.sent()).data.createIssue;
                                toast.success(langToast.t('toast_request_issue'));
                                return [3 /*break*/, 4];
                            case 2: return [4 /*yield*/, client.mutate({
                                    mutation: UpdateIssue,
                                    variables: {
                                        input: input,
                                    },
                                })];
                            case 3:
                                result = (_b.sent()).data.updateIssue;
                                toast.success(i18n
                                    .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                                    .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_issue').toLowerCase()));
                                _b.label = 4;
                            case 4:
                                localStore.updated = Date.now();
                                if (props.parentOnChange)
                                    props.parentOnChange({ _id: _id });
                                return [2 /*return*/, result];
                            case 5:
                                e_1 = _b.sent();
                                console.log(e_1);
                                toast.error(e_1.message);
                                return [3 /*break*/, 7];
                            case 6:
                                localStore.isLoading = false;
                                return [7 /*endfinally*/];
                            case 7: return [2 /*return*/];
                        }
                    });
                });
            },
            clearError: function () {
                localStore.error = '';
            },
        });
    });
    var onSubmit = function (e, additional) { return __awaiter(_this, void 0, void 0, function () {
        var item_1, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    item_1 = _a.sent();
                    if (!item_1)
                        throw Error(langToast.t('toast_form_not_valid'));
                    return [4 /*yield*/, localStore.onSubmit(__assign(__assign({}, item_1), additional))];
                case 2:
                    item_1 = _a.sent();
                    if (props.onChange) {
                        props.onChange(item_1, ACTION_CHANGE.DELETED);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    e_2 = _a.sent();
                    console.log(e_2);
                    if (!additional) {
                        throw BASIC_DIALOG_ACTIONS.APPROVE;
                    }
                    return [3 /*break*/, 5];
                case 4: return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(React.Fragment, null,
        React.createElement(Typography, { color: "error" }, localStore.error),
        React.createElement(Formik, { innerRef: formRef, initialValues: localStore.defaultValue, validationSchema: Yup.object({
                title: Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
                description: Yup.string()
                    .max(2000, langForm.t('form_input_validate_max_char').replace(/_X_/, '2000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
            }), onSubmit: function (e) { return e; } }, function (formik) {
            var onChange = function (e) {
                formik.setFieldValue(e.target.name, e.target.value);
            };
            return (React.createElement(Form, null,
                React.createElement(Grid, { container: true, direction: "column" },
                    props.type === 1 || quotesParentCollectionName
                        ? null
                        : !formik.values._id && (React.createElement(Grid, { container: true, alignItems: 'center', style: { marginBottom: theme.spacing(3.5) } },
                            React.createElement(FormikAutocompleteAsync, { style: { width: '100%' }, v17: true, query: FetchOrders, variables: {
                                    filter: {
                                        fields: [
                                            {
                                                name: 'all-in-progress',
                                                value: 1,
                                            },
                                            {
                                                name: 'status',
                                                value: OrderStatus.ApprovedQuote,
                                            },
                                        ],
                                    },
                                }, title: langApp.t('main_order'), name: "parent", client: props.config.client, isClient: true, variant: "outlined", margin: 'dense', filterFields: ['orderId'], fullWidth: true, value: formik.values.parent, autocompleteProps: {
                                    style: { width: '100%' },
                                    getOptionSelected: function (option, value) {
                                        console.log('optionoption', option, value);
                                        return option._id === value._id;
                                    },
                                    getOptionLabel: function (option) {
                                        return [
                                            option.orderId,
                                            option.sorting_totalWeight,
                                            option.sorting_countryPickup,
                                            option.sorting_pickupAt,
                                            option.sorting_countryDelivery,
                                            option.sorting_deliveryAt,
                                        ]
                                            .filter(function (e) { return e || ''; })
                                            .join(', ');
                                    },
                                }, onChange: onChange }))),
                    React.createElement(Grid, { container: true, alignItems: 'center', style: { marginBottom: theme.spacing(3.5) } },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(FormikTextField, { disabled: disabled, title: quotesParentCollectionName ? langForm.t('form_title') : langForm.t('form_input_name'), variant: "outlined", name: "title", value: formik.values.title }))),
                    React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: theme.spacing(3.5) } },
                        React.createElement(FormikTextField, { disabled: disabled, title: langForm.t('form_input_description'), placeholder: langForm.t('form_input_description_issue_help'), variant: "outlined", multiline: true, rows: 4, name: "description", value: formik.values.description })),
                    props.type === 1
                        ? null
                        : ((!formik.values._id || quotesParentCollectionName) && (React.createElement(Grid, { item: true, xs: 12, style: { marginBottom: theme.spacing(3.5) } },
                            React.createElement(UploadWithDragAndDrop, { disabled: disabled, fileInput: props.fileInput, config: { client: client }, name: 'files', docType: 'Issue', value: formik.values.files, parent_collection_name: 'issues', UploadProps: { folder: "private/issues/" + (formik.values._id || 'create') + "/" }, item: localStore.defaultValue, onChange: onChange })))) ||
                            null)));
        })));
    var title = disabled
        ? langOfferForm.t('offer_overview')
        : (item === null || item === void 0 ? void 0 : item._id) ? langForm.t('form_btn_edit')
            : quotesParentCollectionName
                ? langForm.t('form_register_issue')
                : langForm.t('form_invoice_create_add').replace(/_X_/, langApp.t('main_issue').toLowerCase());
    return (React.createElement(BasicDialog, { v1: true, body: dialogBody, withoutWrapper: true, title: props.title || title, onOk: onSubmit, hidActionOkBth: disabled, actionOkTitle: (item === null || item === void 0 ? void 0 : item._id) ? langForm.t('form_btn_save') : langForm.t('form_btn_create'), actionInit: function (handleClickOpen) {
            if (props.actionOnInit)
                return props.actionOnInit(handleClickOpen);
            var icon = (React.createElement(BaseTooltip, { title: title },
                React.createElement(BaseIconButton, { disabled: localStore.isLoading, onClick: handleClickOpen, color: 'primary', id: 'report-issue-btn' }, props.v2 || props.v3 ? (React.createElement(ReportIcon, { style: { color: theme.palette.warning.light } })) : disabled ? (React.createElement(VisibilityIcon, null)) : (React.createElement(EditIcon, null)))));
            if (props.v3) {
                return icon;
            }
            if ((item === null || item === void 0 ? void 0 : item._id) || props.v2) {
                return (React.createElement(OrderRouteMenuItem, __assign({}, {
                    icon: icon,
                    label: title,
                    handleClickOpen: handleClickOpen,
                })));
            }
            return React.createElement(MainButton, { title: title, onClick: handleClickOpen, loading: localStore.isLoading });
        } }));
}
export default observer(FormItem);
