var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { theme } from '../../theme';
import ChatIcon from '@material-ui/icons/Chat';
import BaseTooltip from '../BaseTooltip';
import { KEYS } from '../../i18n';
import OrderRouteMenuItem from '../Order/List/OrderRoutesMenuItem';
import { withSidebarChannelsStore } from '../Socket/Chat/ChannelsChat/store';
import { defaultChatUsers } from '../../pages/Chat/utils';
import { inject } from 'mobx-react';
import { CircularIndeterminate } from '../CircularIndeterminate';
import BaseIconButton from '../BaseIconButton';
function SectionOrderBookingChat(_a) {
    var _this = this;
    var sidebarChannelsStore = _a.sidebarChannelsStore, store = _a.store, isLoading = _a.isLoading, props = __rest(_a, ["sidebarChannelsStore", "store", "isLoading"]);
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var langChat = useTranslation(KEYS.CHAT);
    var handleClickOpen = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, 5, 6]);
                    setLoading(true);
                    if (!props.defaultValue) return [3 /*break*/, 1];
                    sidebarChannelsStore.onAdd(props.defaultValue._id);
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, sidebarChannelsStore.onLoadFirst({
                        fields: [
                            { name: 'order', value: props.order._id },
                            { name: 'onlyLastChat', value: 1 },
                            // { name: 'usersRelated', value: [store.accountStore.currentUser._id] },
                            props.quote && {
                                name: 'users',
                                value: defaultChatUsers({
                                    accountStore: store.accountStore,
                                    order: __assign(__assign({}, props.order), { latestQuote: props.quote }),
                                }).map(function (e) { return e._id; }),
                            },
                            props.quote && {
                                name: 'quote',
                                value: props.quote._id,
                            },
                        ].filter(function (e) { return e; }),
                    })];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [3 /*break*/, 6];
                case 4:
                    e_1 = _a.sent();
                    return [3 /*break*/, 6];
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var icon = loading ? (React.createElement(CircularIndeterminate, { style: { width: 24, height: 24 } })) : (React.createElement(BaseTooltip, { title: langChat.t('main_title') },
        React.createElement(BaseIconButton, { style: { padding: theme.spacing(0) }, onClick: handleClickOpen },
            React.createElement(ChatIcon, { color: 'primary' }))));
    return props.label ? React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: langChat.t('main_title'), handleClickOpen: handleClickOpen })) : icon;
}
export default inject('store')(withSidebarChannelsStore(SectionOrderBookingChat));
