var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { theme } from '../../../theme';
import SectionDetails from '../../../components/SectionDetails';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { OrderLocation } from '../../../models';
import ItemBodyServices from '../../Costs/CostItem/ItemBodyServices';
import ItemBodyInformation from '../../Costs/CostItem/ItemBodyInformation';
import { FetchOrderPayments } from '../../../graphql/invoices/payments';
import PreloadingComponent from '../../../components/PreloadingComponent';
import { formatPhoneNumber } from '../../../utils1';
var PaymentItem = React.lazy(function () { return import('../../Costs/CostItem/PaymentItem'); });
export default (function (props) {
    var record = props.record;
    var langForm = useTranslation(KEYS.FORM);
    return (React.createElement(Grid, { container: true, direction: 'column', style: { padding: theme.spacing(1) } },
        React.createElement(ItemBodyServices, __assign({}, props)),
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12, md: 4 },
                React.createElement(SectionDetails, { title: langForm.t('form_invoice_client'), rows: [
                        {
                            name: langForm.t('form_input_name'),
                            value: record.client_name || (record.client_id && record.client_id.name) || '-',
                        },
                        {
                            name: langForm.t('form_input_name_company_code'),
                            value: record.client_companyCode || (record.client_id && record.client_id.companyCode) || '-',
                        },
                        {
                            name: langForm.t('form_input_name_address'),
                            value: (record.client_address &&
                                record.client_address.streetName &&
                                OrderLocation.create({ address: record.client_address }).fullAddress) ||
                                (record.client_id && record.client_id.address) ||
                                '-',
                        },
                        {
                            name: langForm.t('form_input_name_phone'),
                            value: record.client_phone || formatPhoneNumber(record.client_id && record.client_id.phone) || '-',
                        },
                        {
                            name: langForm.t('form_input_name_email'),
                            value: (record.client_emails && record.client_emails.length && record.client_emails.join(',')) ||
                                (record.client_id && record.client_id.emails) ||
                                '-',
                        },
                    ] })),
            React.createElement(ItemBodyInformation, { record: record }),
            React.createElement(Grid, { item: true, xs: 12, md: 5 },
                React.createElement(SectionDetails, { title: langForm.t('form_invoice_payments'), rows: [], config: props.config, query: FetchOrderPayments, variables: {
                        filter: {
                            fields: [
                                { name: 'invoice', value: record._id },
                                record.quote && record.quote.order && { name: 'order', value: record.quote.order._id },
                            ].filter(function (e) { return e; }),
                        },
                    }, rowContent: function (record) { return (React.createElement(PreloadingComponent, null,
                        React.createElement(PaymentItem, { record: record }))); } })))));
});
