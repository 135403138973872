var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { makeStyles, createStyles, withStyles, useTheme } from '@material-ui/core/styles';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import { theme } from '../../../theme';
import { AssignedInTransitIcon, AssignedLoadingTimeIcon, AssignedTransportIcon, AssignedUnLoadedIcon, ConfirmedIcon, } from '../../icons';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import BaseTooltip from '../../BaseTooltip';
import { OrderInProgressStatus, OrderQuoteStatus } from '../../../models';
import { TextOverflow } from '../../TextOverflow';
import { toDateOnlyV41 } from '../../../utils';
import { useStatusFocusStyles } from '../../../styles/main';
export var QontoConnectorV1 = withStyles({
    alternativeLabel: {
        top: 13,
        left: 'calc(-50% + 4px)',
        right: 'calc(50% + 0px)',
    },
    active: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
            borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: theme.palette.secondary.main,
        borderTopWidth: 1,
        borderRadius: 1,
    },
})(StepConnector);
var useColorlibStepIconStyles = makeStyles({
    root1: {
        // pointEvents: 'none',
        '& #step-title': {
            marginTop: 8,
        },
    },
    root: {
        backgroundColor: theme.palette.secondary.main,
        zIndex: 1,
        width: 14,
        height: 14,
        display: 'flex',
        borderRadius: '50%',
        alignItems: 'center',
        padding: theme.spacing(0.4),
        justifyContent: 'center',
        transition: 'all .2s ease-in-out',
        marginTop: theme.spacing(1),
        transformOrigin: 'center',
        '&:hover': {
            transform: "scale(2)",
        },
    },
    active: {
        transformOrigin: 'center',
        backgroundColor: theme.palette.success.light,
        transform: "scale(2)",
        marginTop: theme.spacing(1),
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    rootDisabled: {
        backgroundColor: theme.palette.secondary.main,
        zIndex: 1,
        width: 14,
        height: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        transformOrigin: 'center',
        transition: 'all .2s ease-in-out',
        marginTop: 6,
    },
    activeDisabled: {
        backgroundColor: theme.palette.success.light,
        transform: "scale(2)",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transformOrigin: 'center',
        marginTop: 0,
    },
    completed: {
        backgroundColor: theme.palette.primary.main,
    },
    icon: {
        width: 12,
        height: 12,
        transition: 'all .2s ease-in-out',
        '& *': {
            fill: 'white',
        },
        '& .izi-svg-path': {
            stroke: theme.palette.primary.main,
            fill: theme.palette.primary.main,
        },
    },
    activeIcon: {
        '& *': {
            fill: 'white',
        },
        '& .izi-svg-path': {
            stroke: theme.palette.success.light,
            fill: theme.palette.success.light,
        },
    },
    notCompletedIcon: {
        '& *': {
            fill: theme.palette.secondary.dark,
        },
        '& .izi-svg-path': {
            stroke: theme.palette.secondary.main,
            fill: theme.palette.secondary.main,
        },
    },
});
var statusIcons = function (_a) {
    var langMenu = _a.langMenu, getIconClass = _a.getIconClass, langOfferForm = _a.langOfferForm, props = __rest(_a, ["langMenu", "getIconClass", "langOfferForm"]);
    return ((props === null || props === void 0 ? void 0 : props.icons) && props.icons(getIconClass)) || {
        1: {
            icon: React.createElement(ConfirmedIcon, { className: getIconClass(1), "data-id": langMenu.t('menu_sidebar_in_progress_confirmed') }),
            title: langMenu.t('menu_sidebar_in_progress_confirmed'),
        },
        2: {
            icon: (React.createElement(AssignedTransportIcon, { className: getIconClass(2), "data-id": langMenu.t('menu_sidebar_in_progress_transport_assigned') })),
            title: langMenu.t('menu_sidebar_in_progress_transport_assigned'),
        },
        3: {
            icon: (React.createElement(AssignedLoadingTimeIcon, { className: getIconClass(3), "data-id": langMenu.t('menu_sidebar_in_progress_time_selected') })),
            title: langMenu.t('menu_sidebar_in_progress_time_selected'),
        },
        4: {
            icon: (React.createElement(AssignedInTransitIcon, { className: getIconClass(4), "data-id": langMenu.t('menu_sidebar_in_progress_in_transit') })),
            title: langMenu.t('menu_sidebar_in_progress_in_transit'),
        },
        5: {
            icon: React.createElement(AssignedUnLoadedIcon, { className: getIconClass(5), "data-id": langOfferForm.t('offer_un_loaded') }),
            title: langOfferForm.t('offer_un_loaded'),
        },
    };
};
export function ColorlibStepIconV1(props) {
    var _a, _b;
    var classes = useColorlibStepIconStyles();
    var active = props.active, completed = props.completed, icon = props.icon;
    var langMenu = useTranslation(KEYS.MENU);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var getIconClass = function (index) {
        var _a;
        return clsx(classes.icon, (_a = {
                'izi-active-step': icon === index && active
            },
            _a[classes.activeIcon] = icon === index && active,
            _a[classes.notCompletedIcon] = icon === index && !(active || completed),
            _a));
    };
    var icons = statusIcons(__assign({ langMenu: langMenu, getIconClass: getIconClass, langOfferForm: langOfferForm }, props));
    var iconObject = icons[String(icon)];
    var content = (React.createElement("div", { className: clsx(props.disabled ? classes.rootDisabled : classes.root, (_a = {},
            _a[props.disabled ? classes.activeDisabled : classes.active] = active,
            _a[classes.completed] = completed,
            _a)) }, iconObject.icon));
    // return props.disabled ? content : <BaseTooltip title={iconObject.title}>{content}</BaseTooltip>;
    return (React.createElement(Grid, { "data-id": "izi-order-progress-icon", className: clsx((_b = {}, _b[classes.root1] = !active, _b)), container: true, alignItems: 'center', style: { width: 'initial', maxWidth: 32 }, direction: 'column' }, props.disabled ? (content) : (React.createElement(BaseTooltip, { "data-id": "izi-order-progress-icon-tootip", title: iconObject.title }, content))));
}
export var useStepperStylesV1 = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: '170px',
            '& .MuiStepLabel-alternativeLabel.MuiStepLabel-iconContainer': {
            // paddingRight: 8,
            },
        },
        button: {
            marginRight: theme.spacing(1),
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        stepRoot: {
            padding: 0,
        },
    });
});
export default function OrderProgress(_a) {
    var _b;
    var _c, _d, _e, _f, _g;
    var order = _a.order, onlyText = _a.onlyText, isFocused = _a.isFocused, props = __rest(_a, ["order", "onlyText", "isFocused"]);
    var langMenu = useTranslation(KEYS.MENU);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var theme1 = useTheme();
    var classesFocus = useStatusFocusStyles();
    var classes = useStepperStylesV1();
    var STATUS_IN_PROGRESS_ORDER = (_b = {},
        _b[OrderInProgressStatus.AssignedTransport] = 1,
        _b[OrderInProgressStatus.AssignedLoadingTime] = 2,
        _b[OrderInProgressStatus.AssignedUnLoadingTime] = 2,
        _b[OrderInProgressStatus.AssignedInTransit] = 3,
        _b[OrderInProgressStatus.AssignedUnLoaded] = 4,
        _b);
    var curStep = STATUS_IN_PROGRESS_ORDER[(_c = order.latestQuote) === null || _c === void 0 ? void 0 : _c.inProgressStatus] || 0;
    var MAX_STEPS = 5;
    var activeStep = Math.min(typeof curStep === 'number' ? curStep : 0, MAX_STEPS);
    var icons = statusIcons(__assign({ langMenu: langMenu, getIconClass: function () { return null; }, langOfferForm: langOfferForm }, props));
    var selected = icons[String(activeStep + 1)] || icons[String(curStep + 1)];
    if (!((_d = order.latestQuote) === null || _d === void 0 ? void 0 : _d._id) ||
        ((_e = order.latestQuote) === null || _e === void 0 ? void 0 : _e.status) === OrderQuoteStatus.Cancelled || ((_f = order.latestQuote) === null || _f === void 0 ? void 0 : _f.rejectedAt))
        return null;
    if (onlyText) {
        return (selected === null || selected === void 0 ? void 0 : selected.title) || order.status || null;
    }
    return (React.createElement("div", { className: classes.root, style: { padding: theme.spacing(0, 1) }, "data-id": "izi-order-progress" },
        React.createElement(Grid, { container: true, style: { alignItems: 'center', marginBottom: theme1.spacing(1.5) } },
            React.createElement(Stepper, { alternativeLabel: true, activeStep: activeStep, className: classes.root, connector: React.createElement(QontoConnectorV1, null), style: { padding: 0, backgroundColor: 'transparent' } }, Array.from(Array(MAX_STEPS)).map(function (_, index) {
                var _a;
                return (React.createElement(Step, { className: clsx((_a = {}, _a[classesFocus.root] = isFocused && activeStep === index, _a)), classes: { root: classes.stepRoot }, key: index },
                    React.createElement(StepLabel, { style: {}, StepIconComponent: ColorlibStepIconV1 })));
            }))),
        React.createElement(TextOverflow, { variant: "caption", style: __assign(__assign({}, (!(selected === null || selected === void 0 ? void 0 : selected.title) && { visibility: 'hidden' })), { textAlign: 'center', lineHeight: 1, margin: '-2px 13px 6px 13px', fontSize: '0.55rem', width: 'calc(100% - 26px)', display: 'block', color: theme1.palette.primary.light }) }, (selected === null || selected === void 0 ? void 0 : selected.title) || '-'),
        (((_g = order.transportations) === null || _g === void 0 ? void 0 : _g.length) && (React.createElement(TextOverflow, { variant: "caption", style: __assign(__assign({}, (!(selected === null || selected === void 0 ? void 0 : selected.title) && { visibility: 'hidden' })), { textAlign: 'center', lineHeight: 1, margin: '-2px 13px 6px 13px', fontSize: '0.55rem', width: 'calc(100% - 26px)', display: 'block', fontWeight: 800, color: theme1.palette.primary.light }) }, toDateOnlyV41(order.transportations[0].updatedAt) || '-'))) ||
            null));
}
