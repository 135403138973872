var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createMuiTheme } from '@material-ui/core/styles';
var isNewFontSize = 1; //process.env.REACT_APP_APP_ENV === 'development';
var DELTA = isNewFontSize ? 0.8 : 1;
var color = '#6F6F6F';
var colorPrimaryMain = '#002B69';
var spacing = 6;
var BACK_GROUND_COLOR = "rgba(0, 0, 0, 0.04)";
var DE_FE = {
    position: 'relative',
    '& .MuiTouchRipple-root': {
        backgroundColor: "rgba(0,0,0,0)",
    },
    '&::after': {
        width: 0,
        content: "' '",
        position: 'absolute',
        left: 0,
        top: 0,
        height: '100%',
        backgroundColor: "rgba(0,0,0,0)",
        zIndex: 1,
        pointerEvents: 'none',
        transition: 'all 0.3s',
        borderRadius: 4,
    },
    '&.MuiButton-containedPrimary,button[data-id-btn="izi-btn-hoverV2"]': {
        '&:hover': {
            backgroundColor: "initial",
            '&::after': {
                content: "' '",
                width: '100%',
                backgroundColor: "rgb(255, 255, 255, 0.3)",
            },
        },
    },
    '&:hover': {
        backgroundColor: "unset",
        '&::after': {
            content: "' '",
            width: '100%',
            backgroundColor: BACK_GROUND_COLOR,
        },
    },
};
var overrides = {
    MuiButton: {
        root: __assign(__assign({}, DE_FE), { '&.MuiButton-containedPrimary,button[data-id-btn="izi-btn-hoverV2"]': {
                '&:hover': {
                    '&::after': {
                        content: "' '",
                        width: '100%',
                        backgroundColor: "rgb(255, 255, 255, 0.3)",
                    },
                },
            } }),
    },
    MuiList: {
        root: {
            '& a,.MuiListItem-root,.Mui-selected': DE_FE,
        },
    },
    // MuiList: {
    //   root: {
    //     ...DE_FE,
    //     '&.MuiButton-containedPrimary,button[data-id-btn="izi-btn-hoverV2"]': {
    //       ...DE_FE,
    //       '&:hover': {
    //         '& .MuiGrid-root': {
    //           backgroundColor: `#F6F6F6`,
    //         },
    //       },
    //     },
    //     '& .Mui-selected': {
    //       '&:hover': {
    //         '&::after': {
    //           content: "' '",
    //           width: '100%',
    //           backgroundColor: `rgb(255, 255, 255, 0.3)`,
    //           '& .MuiGrid-root': {
    //             backgroundColor: `transparent!important`,
    //           },
    //       },
    //     },
    //   },
    // },
    MuiIconButton: {
        colorPrimary: {
            '&:hover': {
                backgroundColor: 'unset',
            },
        },
        root: {
            backgroundColor: 'none',
            position: 'relative',
            transition: "all 0.3s",
            '&::after': {
                content: "' '",
                transition: "all 0.3s",
                width: '0',
                height: '0',
                position: 'absolute',
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                backgroundColor: 'none',
                transformOrigin: 'center center',
                borderRadius: '50%',
            },
            '&:hover': {
                backgroundColor: 'none',
                '& svg': {
                    transition: "all 0.3s",
                    transform: 'scale(1.2)',
                },
                '&::after': {
                    content: "' '",
                    width: '100%',
                    height: '100%',
                    backgroundColor: BACK_GROUND_COLOR,
                },
            },
        },
    },
    FormControlLabel: {
        '& .MuiFormControlLabel-label': {
            width: 'calc(100% - 50px)',
        },
    },
    MuiFormLabel: {
        root: {
            lineHeight: 1.1,
            marginTop: -1,
            // color: colorPrimaryMain,
            '&.Mui-disabled': {
                color: colorPrimaryMain,
            },
        },
    },
    MuiSelectOutlined: {
        select: {
        // padding: spacing,
        },
    },
    MuiSelect: {
        root: {
            color: color,
        },
        iconOutlined: {
            right: spacing * 0.2,
            '& 8': {
                fill: color,
                color: color,
            },
        },
    },
    MuiInput: {
        root: {
            '& .Mui-error ': {
                backgroundColor: 'white',
                zIndex: 1,
            },
            '& .MuiChip-root': {
                height: 25,
                '& svg': {
                    maxHeight: 22,
                },
            },
        },
    },
    MuiCheckbox: {
        root: {
        // transition: 'scale 0.3s ease-in-out',
        // '&:hover': {
        //   scale: '1.2',
        // },
        },
    },
    MuiAutocomplete: {
        listbox: {
            width: '100%',
        },
        root: {
            '& .MuiFormControl-root': {
                paddingRight: "0!important",
            },
        },
        inputRoot: {
            '& .MuiAutocomplete-input': {
                minWidth: 'initial',
            },
            "&[class*='MuiOutlinedInput-root']": {
                minHeight: 28,
                paddingRight: spacing * 8 + "px!important",
                paddingLeft: 0,
                '& .MuiAutocomplete-input': {
                    paddingTop: spacing * 1.2 + "px!important",
                    paddingBottom: spacing * 1.2 + "px!important",
                },
                '& .MuiAutocomplete-endAdornment': {
                    right: "0!important",
                    '& path': {
                        fill: colorPrimaryMain,
                    },
                },
                padding: 0,
            },
        },
    },
    MuiFormHelperText: {
        position: 'relative',
        root: {
            '&.Mui-error': {
                backgroundColor: 'white',
                zIndex: 1,
                lineHeight: 1,
                margin: 0,
                position: 'absolute',
                bottom: '-12px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                width: '100%',
                whiteSpace: 'nowrap',
            },
        },
    },
    MuiOutlinedInput: {
        input: {
            paddingLeft: spacing * 0.7,
            paddingRight: spacing * 0.7,
        },
        multiline: {
            paddingLeft: spacing * 0.7,
            paddingRight: spacing * 0.7,
        },
        inputMarginDense: {
            paddingTop: spacing * 1.2,
            paddingBottom: spacing * 1.2,
        },
        inputAdornedStart: {
            paddingLeft: 4,
        },
        adornedStart: {
            paddingLeft: 6,
        },
        adornedEnd: {
            right: 0,
        },
        root: {
            borderColor: "#D5DDE5",
            // '& $notchedOutline': {
            //   borderColor: 'pink',
            // },
            // '&$focused $notchedOutline': {
            //   borderColor: 'red',
            // },
            backgroundColor: 'white',
            // '& .MuiSelect-root ~ $notchedOutline': {
            //   borderColor: 'green',
            // },
            // '&$focused .MuiSelect-root ~ $notchedOutline': {
            //   borderColor: 'orange',
            // },
            '& .MuiSelect-root': {
                color: color,
            },
            color: color,
            '& ::placeholder': {
                color: color,
                opacity: 1,
            },
            '& input': {
                color: color,
                '&::placeholder': {
                    color: color,
                    opacity: 1,
                },
            },
        },
    },
    MuiCssBaseline: {
        '@global': {
            fieldset: {
                border: 0,
            },
            body: {
                backgroundColor: '#FAFAFA',
                '& *': {
                    transition: 'padding 0.3s',
                },
            },
        },
    },
};
export var theme = createMuiTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1650,
        },
    },
    spacing: spacing,
    overrides: overrides,
    palette: {
        primary: {
            main: colorPrimaryMain,
            light: '#2F5386',
        },
        secondary: {
            main: '#BAB8B8',
            dark: '#7B7B7B',
            light: '#F6F6F6',
            '900': '#323232',
        },
        text: {
            primary: '#BAB8B8',
            secondary: '#7B7B7B',
            hint: '#BAB8B8',
        },
        background: {
            paper: '#FFFFFF',
            default: '#F6F6F6',
        },
        success: {
            main: '#009572',
            light: '#00CB9C',
        },
        warning: {
            main: '#ff7000',
            light: '#ff7000',
        },
        common: {
            white: '#FFFFFF',
            black: '#000',
        },
        grey: {
            '100': '#FAFAFA',
            '200': color,
            '300': '#E7E7E7',
            '700': '#D5DDE5',
            '800': '#e2e2e2',
            '900': 'rgba(0, 0, 0, 0.42)',
        },
    },
    typography: {
        fontFamily: ['Montserrat'].join(','),
        // add font-size, change colors
        h1: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 6 + "rem" } : {})),
        h2: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 3.75 + "rem" } : {})),
        h3: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 3 + "rem" } : {})),
        h4: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 2.125 + "rem" } : {})),
        h5: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 1.5 + "rem" } : {})),
        h6: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 1.3 + "rem" } : {})),
        caption: __assign({ color: '#7B7B7B' }, (isNewFontSize ? { fontSize: DELTA * 0.875 + "rem" } : {})),
        subtitle1: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 1 + "rem" } : {})),
        subtitle2: __assign({ color: '#7B7B7B' }, (isNewFontSize ? { fontSize: DELTA * 0.875 + "rem" } : {})),
        body1: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 1 + "rem" } : {})),
        body2: __assign({ color: color }, (isNewFontSize ? { fontSize: DELTA * 0.875 + "rem" } : {})),
    },
});
theme.overrides = __assign(__assign({}, overrides), { MuiTabs: {
        scroller: (_a = {},
            _a[theme.breakpoints.down('xs')] = {
                width: '100%',
            },
            _a),
    } });
