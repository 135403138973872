var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles } from '@material-ui/core';
import { onPreventEvent } from '../../components';
import BaseTooltip from '../../components/BaseTooltip';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            width: '100%',
        },
    });
});
export default function SplitButton(_a) {
    var _b;
    var buttons = _a.buttons, v1 = _a.v1, disabled = _a.disabled, props = __rest(_a, ["buttons", "v1", "disabled"]);
    var className = useStyles();
    var _c = __read(React.useState(false), 2), open = _c[0], setOpen = _c[1];
    var anchorRef = React.useRef(null);
    var _d = __read(React.useState(0), 2), selectedIndex = _d[0], setSelectedIndex = _d[1];
    React.useEffect(function () {
        var _a;
        if (props.v2 && !((_a = buttons[selectedIndex]) === null || _a === void 0 ? void 0 : _a.button)) {
            setSelectedIndex(0);
        }
    }, [buttons.length]);
    var handleMenuItemClick = function (event, index, option) {
        if (option === void 0) { option = {}; }
        setSelectedIndex(index);
        setOpen(false);
        if (props.onChange && props.v2 && (option === null || option === void 0 ? void 0 : option.state)) {
            props.onChange(event, option === null || option === void 0 ? void 0 : option.state);
        }
    };
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClose = function (event) {
        if (anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    var buttonProps = {
        disableElevation: true,
        style: {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 0,
        },
    };
    var item = (React.createElement(React.Fragment, null,
        React.createElement(ButtonGroup, { variant: "contained", color: "primary", ref: anchorRef, "aria-label": "split button", disabled: disabled, className: className.root },
            props.v2 ? ((_b = buttons[selectedIndex]) === null || _b === void 0 ? void 0 : _b.button(buttonProps)) || buttons[selectedIndex] : buttons[selectedIndex],
            React.createElement(Button, { color: "primary", disabled: disabled, size: "small", "aria-controls": 'split-button-menu', "aria-expanded": open ? 'true' : undefined, "aria-label": "select merge strategy", "aria-haspopup": "menu", onClick: handleToggle },
                React.createElement(ArrowDropDownIcon, null))),
        React.createElement(Popper, { open: open, anchorEl: anchorRef.current, role: undefined, transition: true, style: { zIndex: 1200 } }, function (_a) {
            var TransitionProps = _a.TransitionProps, placement = _a.placement;
            return (React.createElement(Grow, __assign({}, TransitionProps, { style: {
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                } }),
                React.createElement(Paper, null,
                    React.createElement(ClickAwayListener, { onClickAway: handleClose },
                        React.createElement(MenuList, { id: "split-button-menu" }, buttons.map(function (option, index) {
                            var button = option;
                            if (props.v2) {
                                button = option.button(buttonProps);
                            }
                            return (React.createElement(BaseTooltip, { disabled: !option.disabled, title: option.disabledTitle },
                                React.createElement(MenuItem, { key: index, selected: index === selectedIndex, onClick: option.disabled
                                        ? function () { }
                                        : function (event) { return (option.disabled ? null : handleMenuItemClick(event, index, option)); } }, v1 ? (React.createElement("div", { style: { pointerEvents: 'none', width: '100%' }, onClick: onPreventEvent }, button)) : (button))));
                        }))))));
        })));
    if (props.noParent)
        return item;
    return (React.createElement(Grid, { container: true, direction: "column", alignItems: "center" },
        React.createElement(Grid, { item: true, xs: 12 }, item)));
}
