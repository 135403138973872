var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import moment from 'moment';
import React from 'react';
import { MAX_ORDERS_SHORT } from './types';
import { DayOrderDetails } from './DayOrderDetails';
import { withCalendarStore } from '../store';
import { toDateOnly } from '../../../utils';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import ClickAwayListener from '@material-ui/core/ClickAwayListener/ClickAwayListener';
import Paper from '@material-ui/core/Paper/Paper';
import * as material from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { usePopOverStyles } from '../../../styles';
import { theme } from '../../../theme';
import { TextOverflow } from '../../TextOverflow';
import ReactDOM from 'react-dom';
import { OrderRouteType } from '../../../models';
import { NoneWorkingDayTimeDetails } from './NoneWorkingDayTimeDetails';
import BaseIconButton from '../../BaseIconButton';
var Box = material.Box;
var Button = material.Button, Grid = material.Grid, LinearProgress = material.LinearProgress, useMediaQuery = material.useMediaQuery;
export default withCalendarStore(function (_a) {
    var day = _a.day, onTogglePopup = _a.onTogglePopup, _b = _a.state, transportations = _b.transportations, warehouse = _b.warehouse, loadAllDay = _a.loadAllDay, dayContent = _a.dayContent, ramps = _a.ramps, config = _a.config, noneWorkingDayTime = _a.noneWorkingDayTime, history = _a.history;
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState(null), 2), anchorEl = _d[0], setAnchorEl = _d[1];
    var langForm = useTranslation(KEYS.FORM);
    var button = React.useRef(null);
    var currentDay = toDateOnly(day);
    var dayData = transportations.data.find(function (e) { return e.date === currentDay; }) || { items: [], count: 0 };
    var dayTransportations = dayData.items;
    var onClick = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setAnchorEl(button.current.parentNode);
                    setLoading(true);
                    return [4 /*yield*/, loadAllDay(currentDay, [{ name: 'all-day', value: 1 }])];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    toast.error(e_1.message || e_1);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClickAway = function () {
        setAnchorEl(null);
    };
    var show = Boolean(anchorEl);
    return (React.createElement(Box, { display: "flex", justifyContent: 'space-between', flexDirection: 'column', height: '100%' },
        dayTransportations.length ? (React.createElement(DayOrderDetails, { v1: true, maxOrderShort: MAX_ORDERS_SHORT, config: config, onTogglePopup: onTogglePopup, dayTransportations: dayTransportations, currentDay: currentDay, noneWorkingTime: noneWorkingDayTime, history: history })) : noneWorkingDayTime.length ? (React.createElement(Grid, { container: true, direction: 'row', style: {
                flexGrow: 'initial',
                overflowY: 'auto',
            } }, noneWorkingDayTime.map(function (day) { return (React.createElement(NoneWorkingDayTimeDetails, { v1: true, key: day._id, style: { minHeight: '21px' }, warehouse: warehouse, config: config, items: [day] })); }))) : null,
        dayData.count > 0 && (React.createElement(Button, { ref: button, onClick: onClick, style: {
                width: '100%',
                textAlign: 'center',
                padding: 0,
                textTransform: 'initial',
                color: theme.palette.primary.light,
            } }, "" + langForm.t('form_input_good_loading_preview') + (dayData.count > MAX_ORDERS_SHORT ? " (" + dayData.count + ")" : ''))),
        (dayData.count > 0 && show && (React.createElement(PopupDayViewMore, { config: config, anchorEl: anchorEl, handleClickAway: handleClickAway, currentDay: currentDay, dayContent: dayContent, onTogglePopup: onTogglePopup, dayTransportations: dayTransportations, noneWorkingTime: noneWorkingDayTime, loading: loading, ramps: ramps, history: history }))) ||
            null));
});
var getWarehouse = function (transportation, currentDate, ramps, key) {
    var _a, _b, _c;
    if (key === void 0) { key = '_id'; }
    var currentDay = toDateOnly(currentDate);
    var startDay = transportation.startDate;
    var endDay = transportation.endDate;
    var rampsId = ramps.map(function (ramp) { return ramp._id; });
    if (startDay === endDay && currentDay === startDay) {
        var ramp = (_a = transportation.routes.find(function (e) { return e.type === OrderRouteType.Preferred; })) === null || _a === void 0 ? void 0 : _a.origin.warehouse;
        if (!rampsId.includes(ramp === null || ramp === void 0 ? void 0 : ramp._id)) {
            ramp = (_b = transportation.routes.find(function (e) { return e.type === OrderRouteType.Delivery; })) === null || _b === void 0 ? void 0 : _b.origin.warehouse;
        }
        if (ramp)
            return ramp[key];
    }
    var routeType = OrderRouteType.Preferred;
    if (endDay === currentDay) {
        routeType = OrderRouteType.Delivery;
    }
    return (_c = transportation.routes.find(function (e) { return e.type === routeType; })) === null || _c === void 0 ? void 0 : _c.origin.warehouse[key];
};
var PopupDayViewMore = function (_a) {
    var anchorEl = _a.anchorEl, handleClickAway = _a.handleClickAway, currentDay = _a.currentDay, dayContent = _a.dayContent, onTogglePopup = _a.onTogglePopup, dayTransportations = _a.dayTransportations, loading = _a.loading, config = _a.config, ramps = _a.ramps, noneWorkingTime = _a.noneWorkingTime, history = _a.history;
    var classes = usePopOverStyles();
    var langApp = useTranslation(KEYS.APP);
    var offsets = anchorEl.getBoundingClientRect();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var orderRamps = Array.from(new Set(dayTransportations.map(function (e) { return getWarehouse(e, currentDay, ramps); })));
    var content = (React.createElement("div", { style: {
            position: 'fixed',
            top: isSmall ? 100 : offsets.top - 50,
            left: isSmall ? '2vw' : offsets.left - 50,
            zIndex: 999,
        } },
        React.createElement(ClickAwayListener, { onClickAway: handleClickAway },
            React.createElement(Paper, { className: classes.paper, style: {
                    position: 'relative',
                    minWidth: isSmall ? '96vw' : orderRamps.length > 1 ? 500 : 260,
                    maxWidth: isSmall ? '96vw' : undefined,
                    maxHeight: isSmall ? "calc(100vh - 150px)" : undefined,
                    minHeight: 190,
                    padding: theme.spacing(1),
                } },
                React.createElement(Grid, { container: true, direction: 'column', alignItems: 'center', style: { paddingBottom: theme.spacing(1) } },
                    React.createElement(TextOverflow, { variant: 'caption' }, langApp.t(moment(currentDay).format('dddd').toLowerCase())),
                    dayContent,
                    React.createElement("div", { className: classes.closeButton },
                        React.createElement(BaseIconButton, { "aria-label": "close", onClick: handleClickAway },
                            React.createElement(CloseIcon, null)))),
                React.createElement(Grid, { container: true, direction: 'row', justify: orderRamps.length > 0 ? 'flex-start' : 'center', style: { maxHeight: 140, overflowY: 'auto' } }, (loading && React.createElement(LinearProgress, { color: "primary", style: { width: '100%' } })) || (React.createElement(PopupDayRampsDetails, __assign({}, { dayTransportations: dayTransportations, config: config, currentDay: currentDay, ramps: ramps, onTogglePopup: onTogglePopup, noneWorkingTime: noneWorkingTime, history: history }))))))));
    return ReactDOM.createPortal(content, document.body);
};
export var PopupDayRampsDetails = function (_a) {
    var dayTransportations = _a.dayTransportations, currentDay = _a.currentDay, ramps = _a.ramps, onTogglePopup = _a.onTogglePopup, props = __rest(_a, ["dayTransportations", "currentDay", "ramps", "onTogglePopup"]);
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var orderRamps = Array.from(new Set(__spread(dayTransportations.map(function (e) { return getWarehouse(e, currentDay, ramps); }), (props.noneWorkingTime ? props.noneWorkingTime.map(function (e) { return e.warehouse._id; }) : []))));
    var maxInlineRamps = isSmall ? 1 : 2;
    return (React.createElement(React.Fragment, null, orderRamps.map(function (ramp, index) {
        var _a, _b, _c, _d;
        var rampTransportation = dayTransportations.filter(function (e) { return getWarehouse(e, currentDay, ramps) === ramp; });
        var rampNonWorkingTime = ((_a = props.noneWorkingTime) === null || _a === void 0 ? void 0 : _a.filter(function (e) { var _a; return ((_a = e === null || e === void 0 ? void 0 : e.warehouse) === null || _a === void 0 ? void 0 : _a._id) === ramp; })) || [];
        return (React.createElement(Grid, { container: true, direction: 'column', alignItems: 'center', style: {
                width: 100 / Math.min(orderRamps.length, maxInlineRamps) + "%",
                borderLeft: index % maxInlineRamps !== 0 ? '2px solid #C4C4C4' : '',
                padding: isSmall ? 0 : theme.spacing(0, 4),
                margin: theme.spacing(1, 0),
            } },
            React.createElement(TextOverflow, { style: {
                    marginBottom: theme.spacing(1),
                    color: theme.palette.primary.main,
                    fontWeight: 'bold',
                    fontSize: '0.8rem',
                } }, (rampTransportation.length
                ? getWarehouse(rampTransportation[0], currentDay, ramps, 'name')
                : (_d = (_c = (_b = props.noneWorkingTime) === null || _b === void 0 ? void 0 : _b.find(function (e) { return e.warehouse._id === ramp; })) === null || _c === void 0 ? void 0 : _c.warehouse) === null || _d === void 0 ? void 0 : _d.name) || 'Ramp'),
            React.createElement(DayOrderDetails, { v1: true, config: props.config, onTogglePopup: onTogglePopup, dayTransportations: rampTransportation, currentDay: currentDay, noneWorkingTime: rampNonWorkingTime, history: props.history })));
    })));
};
