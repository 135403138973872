var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { Box, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { TextOverflow } from '../TextOverflow';
import { dateViewFormat } from '../DateView';
import { OrderQuoteStatus, OrderStatus } from '../../models';
import { CurrencyFormat, PriceComponent } from '../PriceComponent';
import { AcceptDialog } from '../Order/List/Customer/OrderQuoteItem/Quotes/AcceptDialog';
import { OrderQuoteEditModal } from '../Order/List/OrderQuoteItem/Quotes/OrderQuoteEditModal';
import { DeleteDialog } from '../Dialog';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { useLocalStore } from 'mobx-react-lite';
import { DeleteOrderQuote, UpdateOrderQuote } from '../../graphql/order';
import { toast } from 'react-toastify';
import { BaseUserAppTYpe } from '../../stores/common';
import { inject, observer } from 'mobx-react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { FormikTextField } from '../Formik';
import { HelpIconComponent } from '../HelpIcon';
import SectionOrderBookingChat from './SectionOrderBookingChat';
import { theme } from '../../theme';
import { AvatarItemView } from '../Account/Avatar';
import { withOrderListItemStore } from '../Order/List/Customer/storeListItem';
import TimeLeft from '../TimeLeft';
import PreloadingComponent from '../PreloadingComponent';
import { toDateOnlyV3 } from '../../utils';
import MenuListItem from '../MenuListItem';
import BaseTooltip from '../BaseTooltip';
import { MainButton } from '../MainButton';
import { useSectionRowStyles } from './styles';
import QuoteStatus from './SectionDetailsQuoteStatus';
import { emtyValueFill } from './utils';
import { basicParseFloat, yupTransformFloatNumber } from '../../utils3';
var OrderContraQuoteAccept = React.lazy(function () { return import('../Order/List/OrderQuoteItem/Quotes/OrderContraQuoteAccept'); });
var SectionHistory = React.lazy(function () { return import('./SectionHistory'); });
export var SectionRowQuoteSubmitted = inject('store')(observer(withOrderListItemStore(function (props) {
    var _a;
    var _this = this;
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    var order = props.order, record = props.record, v1 = props.v1, v13 = props.v13, _2 = props.store.accountStore, currentUser = _2.currentUser, currentCompany = _2.currentCompany, orderListItemProps = props.orderListItemProps;
    var className = useSectionRowStyles();
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langToast = useTranslation(KEYS.TOAST);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var formRef = React.useRef();
    var openOrders = [OrderStatus.WaitingQuotes, OrderStatus.HasQuotes, OrderStatus.AcceptedQuote].includes(order.status);
    var localStore = useLocalStore(function () { return ({
        defaultValue: __assign({}, record),
        error: '',
        isLoading: false,
        onUpdated: function (record) {
            var e = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                e[_i - 1] = arguments[_i];
            }
            Object.assign(localStore.defaultValue, record, { updatedAt: new Date().toString() });
            if (props.onChange)
                props.onChange.apply(props, __spread([record], e));
        },
        onChangeOffer: function (e) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, name, value, hasChanges, res, _b, e_1;
                var _c;
                return __generator(this, function (_d) {
                    switch (_d.label) {
                        case 0:
                            _a = e.target, name = _a.name, value = _a.value;
                            if (record[name] === value) {
                                return [2 /*return*/, console.warn('No changes!')];
                            }
                            return [4 /*yield*/, formRef.current.setFieldValue(name, value)];
                        case 1:
                            _d.sent();
                            if (formRef.current.errors[name])
                                return [2 /*return*/, formRef.current.setFieldTouched(name)];
                            hasChanges = ['forwarderAmount', 'amount'].includes(name);
                            if (hasChanges) {
                                if ((localStore.defaultValue[name] || '').toString() === (value || '').toString()) {
                                    console.warn('no changes');
                                    return [2 /*return*/];
                                }
                            }
                            _d.label = 2;
                        case 2:
                            _d.trys.push([2, 5, , 6]);
                            _b = props.config;
                            if (!_b) return [3 /*break*/, 4];
                            return [4 /*yield*/, props.config.client.mutate({
                                    mutation: UpdateOrderQuote,
                                    variables: {
                                        input: (_c = {
                                                _id: record._id
                                            },
                                            _c[name] = (hasChanges && basicParseFloat(value)) || value,
                                            _c),
                                    },
                                })];
                        case 3:
                            _b = (_d.sent());
                            _d.label = 4;
                        case 4:
                            res = _b;
                            Object.assign(localStore.defaultValue, res.data.updateOrderQuote);
                            toast.success((langToast.t('toast_items_updated') || '').replace(/_X_/, langOfferForm.t('offer_title').toLowerCase()));
                            return [3 /*break*/, 6];
                        case 5:
                            e_1 = _d.sent();
                            console.log(e_1);
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var onDelete = function () { return __awaiter(_this, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    orderListItemProps.onChange({ loading: true });
                    return [4 /*yield*/, (props.config &&
                            props.config.client.query({
                                query: DeleteOrderQuote,
                                variables: {
                                    _id: record._id,
                                },
                            }))];
                case 1:
                    _a.sent();
                    if (orderListItemProps)
                        orderListItemProps.onChange({
                            loading: false,
                            record: __assign(__assign(__assign(__assign({}, orderListItemProps.state.record), { updatedAt: new Date().toString() }), (orderListItemProps.state.record.totalQuotes
                                ? { totalQuotes: (orderListItemProps.state.record.totalQuotes || 0) - 1 }
                                : {})), { latestQuote: null }),
                        });
                    // if (props.onChange) props.onChange(record, ACTION_CHANGE.DELETED);
                    order.latestQuote = null;
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    orderListItemProps.onChange({ loading: false });
                    throw e_2;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var isOWN = currentUser._id === (record.createdBy && record.createdBy._id);
    var isOWNCompany = currentCompany._id === ((_b = record.createdBy) === null || _b === void 0 ? void 0 : _b.company._id);
    var is_forwarder = currentUser.appType.includes(BaseUserAppTYpe.FORWARDER);
    var is_carrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var is_customer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    var isForwarderCarrier = is_forwarder && (isOWN || isOWNCompany);
    var isOrderForwarder = is_forwarder &&
        record.order.forwarders
            .map(function (e) { return e.company && e.company._id; })
            .filter(function (e) { return e; })
            .includes(currentCompany._id);
    var isForwarderApprovedOffer = isOrderForwarder && [OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(record.status);
    var canManage = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER) ||
        (!isOWN && currentUser.appType.includes(BaseUserAppTYpe.FORWARDER));
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var textProps = {};
    if ([OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(record.status) &&
        record.approvedAt &&
        !record.canceledAt) {
        Object.assign(textProps, { style: { color: theme.palette.success.light } });
    }
    var disabled = is_forwarder &&
        record.order.forwarders &&
        !record.order.forwarders
            .map(function (e) { return e.company && e.company._id; })
            .filter(function (e) { return e; })
            .includes(currentCompany._id) &&
        ![record.createdBy.company._id].includes(currentCompany._id);
    var isOrderOfferSameCompany = ((_d = (_c = record.createdBy) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d._id) === order.company._id;
    var isNotFor = !isForwarderCarrier && record.status === OrderQuoteStatus.Requested;
    var isNotForCarrier = !isForwarderCarrier && [OrderQuoteStatus.Requested, OrderQuoteStatus.Accepted].includes(record.status);
    var isForV1 = isForwarderCarrier && [OrderQuoteStatus.Accepted, OrderQuoteStatus.Requested].includes(record.status);
    var statusComponent = ((is_carrier || isForwarderCarrier) &&
        !((_e = record.parent) === null || _e === void 0 ? void 0 : _e._id) &&
        record.status === OrderQuoteStatus.Requested &&
        !record.deletedAt &&
        !isAdmin && (React.createElement(React.Fragment, null,
        isOrderOfferSameCompany ? null : (React.createElement(QuoteStatus, null, langOfferForm.t('offer_form_waiting_customer_status'))),
        (isNotForCarrier || isForV1) && !is_customer ? (React.createElement(OrderQuoteEditModal, { order: order, onChange: localStore.onUpdated, config: props.config, defaultValue: record, canManage: canManage, label: true, actionInit: function (handleClickOpen) { return (React.createElement(MainButton, { disabled: props.disabled, onClick: handleClickOpen, title: langOfferForm.t('offer_form_edit_btn'), disabledStyle: { pointerEvents: 'none' }, style: __assign({ height: 25, backgroundColor: theme.palette.warning.main }, ((!props.disabled && {
                    padding: theme.spacing(0),
                    color: theme.palette.primary.main,
                }) || {
                    padding: theme.spacing(0),
                })) })); } })) : null))) ||
        ((is_customer || is_forwarder) &&
            !isForwarderCarrier &&
            record.status === OrderQuoteStatus.Requested &&
            !record.deletedAt &&
            !isAdmin &&
            ((((_f = record.parent) === null || _f === void 0 ? void 0 : _f._id) && (React.createElement(QuoteStatus, null, langOfferForm.t('offer_form_waiting_carrier_status')))) || React.createElement("span", null))) ||
        ((is_carrier || isForwarderCarrier || is_customer || is_forwarder) &&
            (record.deletedAt || record.rejectedAt) &&
            !isAdmin && (React.createElement(QuoteStatus, null, record.deletedAt ? langApp.t('app_deleted') : langApp.t('main_expired')))) ||
        null;
    var spaceContent = React.createElement("span", { style: { marginRight: theme.spacing(1) } });
    var actions = (React.createElement(Box, { id: "order-offer-actions" + (((_g = record === null || record === void 0 ? void 0 : record.parent) === null || _g === void 0 ? void 0 : _g._id) ? '-counter-offer' : ''), display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: isSmall ? 'center' : 'flex-end' },
        (is_carrier || isForwarderCarrier) && ((_h = record.parent) === null || _h === void 0 ? void 0 : _h._id) &&
            record.status === OrderQuoteStatus.Requested &&
            !record.deletedAt &&
            !isAdmin && (React.createElement(PreloadingComponent, null,
            React.createElement(OrderContraQuoteAccept, __assign({}, props, { onChange: props.onChange, quote: record, disabled: disabled || isAdmin })))),
        (is_carrier || isForwarderCarrier) && ((_j = record.parent) === null || _j === void 0 ? void 0 : _j._id) &&
            record.status === OrderQuoteStatus.Requested &&
            !record.deletedAt &&
            !isAdmin && (React.createElement(PreloadingComponent, null,
            React.createElement(OrderContraQuoteAccept, __assign({}, props, { isDeny: true, onChange: props.onChange, quote: record, disabled: disabled || isAdmin })))),
        statusComponent,
        (is_forwarder && isOWNCompany && ((_k = order.forwarderOrder) === null || _k === void 0 ? void 0 : _k.length) === 0) ||
            isAdmin ||
            record.deletedAt || ((_l = record.parent) === null || _l === void 0 ? void 0 : _l._id)
            ? null
            : (((((isNotFor && canManage) || isForV1) && !isForwarderCarrier) || isOrderOfferSameCompany) && (React.createElement(React.Fragment, null,
                (isOrderOfferSameCompany && spaceContent) || null,
                React.createElement(AcceptDialog, __assign({}, props, { disabled: disabled }))))) ||
                null,
        ((isNotForCarrier || isForV1) &&
            !isAdmin &&
            (is_customer || (isOrderForwarder && !isForwarderCarrier)) &&
            !((_m = record.parent) === null || _m === void 0 ? void 0 : _m._id) &&
            !record.deletedAt &&
            !((is_customer || is_forwarder) &&
                (record.contraOffer || record.status !== OrderQuoteStatus.Requested)) && (React.createElement(OrderQuoteEditModal, { order: order, onChange: localStore.onUpdated, config: props.config, defaultValue: record, canManage: canManage, isLowerModal: true, actionInit: function (handleClickOpen) { return (React.createElement(BaseTooltip, { title: langOfferForm.t('offer_form_request_lower_price') },
                React.createElement(Grid, { container: true, style: { width: 'fit-content', marginLeft: theme.spacing(1), padding: theme.spacing(0, 1) } },
                    React.createElement(MainButton, { disabled: props.disabled, onClick: handleClickOpen, title: langOfferForm.t('offer_form_lower_price'), disabledStyle: { pointerEvents: 'none' }, style: {
                            height: 25,
                        } })))); } }))) ||
            null,
        React.createElement("div", { style: { marginRight: theme.spacing(1) } }),
        (props.store.accountStore.isLoggedIn &&
            !isOrderOfferSameCompany &&
            !record.rejectedAt &&
            !record.deletedAt &&
            [OrderQuoteStatus.Requested, OrderQuoteStatus.Approved].includes(record.status) && (React.createElement(React.Fragment, null,
            React.createElement(SectionOrderBookingChat, { record: record, defaultValue: (_o = record.lastChatMessage) === null || _o === void 0 ? void 0 : _o.chat, config: props.config, order: order, quote: (((_p = record.parent) === null || _p === void 0 ? void 0 : _p._id) && __assign(__assign({}, record), { _id: record.parent._id })) || record, isLoading: true }),
            React.createElement("div", { style: { marginRight: theme.spacing(0.5) } })))) ||
            null,
        React.createElement(MenuListItem, { v1: true, show: true, list: [
                // {
                //   content: (
                //
                //   ),
                //   available: record.createdBy?.company?._id !== order.company._id,
                // },
                {
                    id: 'btn-offer-history',
                    content: (React.createElement(PreloadingComponent, null,
                        React.createElement(SectionHistory, { v2: true, config: props.config, onChange: props.onChange, filters: {
                                fields: [
                                    { name: 'object', value: record._id },
                                    { name: 'type', value: 2 },
                                ],
                            }, record: record, order: order }))),
                    available: true,
                },
                {
                    id: 'btn-offer-history',
                    content: (React.createElement(DeleteDialog, { v1: true, withoutWrapper: true, withText: true, disabled: !isOWN, onOk: onDelete, value: (langOfferForm.t('offer_title') || '').toLowerCase() })),
                    available: !((_q = record.parent) === null || _q === void 0 ? void 0 : _q._id) && ((isNotForCarrier && !canManage) || isForV1) && !isAdmin && !record.deletedAt,
                },
            ] })));
    var priceComponent = (React.createElement("div", { style: __assign(__assign({ position: 'relative', display: 'flex', maxWidth: '100%' }, (openOrders && { width: '100%', justifyContent: 'space-between' })), (isSmall && { justifyContent: 'center' })) },
        openOrders && !props.v14 ? (React.createElement(Box, { style: {
                display: 'flex',
                height: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '70px',
            } }, record.expireEnd && !record.deletedAt && (React.createElement(TimeLeft, { date: record.expireEnd, style: { marginRight: theme.spacing(1) } })))) : null,
        React.createElement(PriceComponent, { id: 'offer-amount', amount: record.amount, currency: record.currency, style: __assign(__assign(__assign(__assign({ color: theme.palette.success.light }, (record.contraOffer && {
                color: theme.palette.grey['500'],
            })), (((_r = record.parent) === null || _r === void 0 ? void 0 : _r._id) && {
                color: theme.palette.primary.light,
            })), ((record.status === OrderQuoteStatus.Approved && { color: theme.palette.success.light }) ||
                ((record.rejectedAt || record.deletedAt || record.status === OrderQuoteStatus.Cancelled) && {
                    color: theme.palette.grey['500'],
                }))), (is_forwarder && ((_s = order === null || order === void 0 ? void 0 : order.forwarders) === null || _s === void 0 ? void 0 : _s.length) && !isSmall && { marginRight: theme.spacing(1) })), hide: [BaseUserAppTYpe.WAREHOUSE, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (t) {
                return currentUser.appType.includes(t);
            }) })));
    var priceComponentFormHas = (is_forwarder && ((_t = order === null || order === void 0 ? void 0 : order.forwarders) === null || _t === void 0 ? void 0 : _t.length) &&
        order.forwarders.filter(function (forwarder) { var _a; return ((_a = forwarder.company) === null || _a === void 0 ? void 0 : _a._id) === currentCompany._id; }).length) ||
        (isAdmin && localStore.defaultValue.forwarderAmount);
    var priceComponentForm = (React.createElement(Formik, { innerRef: formRef, initialValues: localStore.defaultValue, validationSchema: Yup.object({
            amount: Yup.number()
                .transform(yupTransformFloatNumber)
                .max(100000, langForm.t('form_input_validate_max_size').replace(/_X_/, '100000'))
                .min(1, langForm.t('form_input_validate_min_size').replace(/_X_/, '1'))
                .required(langForm.t('form_input_validate_required')),
            forwarderAmount: Yup.number()
                .transform(yupTransformFloatNumber)
                .max(100000, langForm.t('form_input_validate_max_size').replace(/_X_/, '100000'))
                .min(record.amount || 1, langForm.t('form_input_validate_min_size').replace(/_X_/, ((_u = record.amount) === null || _u === void 0 ? void 0 : _u.toString()) || '1'))
                .required(langForm.t('form_input_validate_required')),
        }), onSubmit: function (e) { return e; } }, function (formik) {
        return (React.createElement(Form, { style: { display: 'flex', gap: theme.spacing(2) }, id: 'form-order-offer-edit' },
            isAdmin ? (React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(FormikTextField, { v14: true, className: clsx(className.textField, className.textFieldV1), disabled: isAdmin
                        ? record.deletedAt
                        : record.status !== OrderQuoteStatus.Requested || disabled || record.deletedAt, placeholder: langForm.t('form_input_name_price'), variant: "outlined", name: "amount", iziInputType: "float", value: formik.values.amount, onBlur: localStore.onChangeOffer, InputProps: {
                        endAdornment: (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 64 } },
                            React.createElement(TextOverflow, { color: 'primary' }, CurrencyFormat(record.currency)),
                            React.createElement(HelpIconComponent, { title: langOfferForm.t('offer_form_carrier_price') }))),
                    } }))) : null,
            priceComponentFormHas ? (React.createElement(Grid, { container: true, direction: "column" },
                React.createElement(FormikTextField, { v14: true, className: clsx(className.textField, className.textFieldV1), disabled: isAdmin
                        ? record.deletedAt
                        : record.status !== OrderQuoteStatus.Requested || disabled || record.deletedAt, iziInputType: "float", placeholder: isAdmin
                        ? langOfferForm.t('offer_form_price_with_percents')
                        : langForm.t('form_input_name_price'), variant: "outlined", name: "forwarderAmount", value: formik.values.forwarderAmount, onBlur: localStore.onChangeOffer, InputProps: {
                        endAdornment: (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 64 } },
                            React.createElement(TextOverflow, { color: 'primary' }, CurrencyFormat(record.currency)),
                            React.createElement(HelpIconComponent, { title: isAdmin
                                    ? langOfferForm.t('order_form_forwarder_price')
                                    : langForm.t('form_input_forwarder_price_help') }))),
                    } }))) : null));
    }));
    if (isSmall) {
        if (!textProps.style)
            textProps.style = {};
        textProps.style.textAlign = 'center';
    }
    var moreOptions = (React.createElement(Grid, __assign({ item: true, xs: 12, md: !isAdmin && props.v14 ? 4 : openOrders ? 9 : 7, container: true, justify: isSmall ? 'flex-start' : 'space-between' }, ((isSmall && { style: { padding: theme.spacing(1) } }) || {}), { alignItems: 'center' }),
        React.createElement(Grid, { xs: 12, md: isForwarderApprovedOffer ? 10 : isAdmin || priceComponentFormHas ? 6 : 3, container: true, item: true, alignItems: 'center', justify: isSmall ? 'center' : 'space-between', style: __assign(__assign({}, (!(is_forwarder && ((_v = order === null || order === void 0 ? void 0 : order.forwarders) === null || _v === void 0 ? void 0 : _v.length)) && {
                paddingRight: theme.spacing(1),
            })), (isSmall && { marginBottom: theme.spacing(1) })) },
            (!isAdmin && React.createElement("div", null, priceComponent)) || React.createElement("span", null),
            isAdmin || priceComponentFormHas ? React.createElement("div", { style: { maxWidth: '60%' } }, priceComponentForm) : null),
        React.createElement(Grid, { xs: 12, md: isForwarderApprovedOffer ? 2 : isAdmin || priceComponentFormHas ? 6 : 9, justify: isSmall ? 'center' : 'flex-end', style: __assign({ display: 'flex', flexDirection: 'row' }, (isSmall && { width: '100%', marginBottom: theme.spacing(1) })) }, actions)));
    var offerOwnerContent = (React.createElement(AvatarItemView, { moreDetail: true, withoutRole: true, withRating: true, avatarStyle: { width: 26, height: 26 }, typographyProps: {
            variant: 'h6',
            style: {
                fontSize: 14,
                fontWeight: 600,
                color: theme.palette.common.white,
            },
        }, user: record.createdBy }));
    var content = (React.createElement(React.Fragment, null,
        React.createElement(Grid, { item: true, xs: 12, md: openOrders ? 2 : 4 },
            React.createElement(TextOverflow, __assign({ variant: 'subtitle1' }, textProps), [
                [
                    record.tempTransportations &&
                        record.tempTransportations[0] &&
                        dateViewFormat(record.tempTransportations[0].startDate, 0, 0, currentUser.language),
                    ([OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(record.status) &&
                        order.transportations &&
                        order.transportations[0] &&
                        order.transportations[0].startTime[0]) ||
                        '',
                ].join(' '),
                [
                    record.tempTransportations &&
                        record.tempTransportations[record.tempTransportations.length - 1] &&
                        dateViewFormat(record.tempTransportations[record.tempTransportations.length - 1].endDate, 0, 0, currentUser.language),
                    ([OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(record.status) &&
                        order.transportations &&
                        order.transportations[order.transportations.length - 1] &&
                        order.transportations[order.transportations.length - 1].endTime[0]) ||
                        '',
                ].join(' '),
            ].join(' - '))),
        React.createElement(Grid, __assign({ item: true, xs: 12, md: 1 }, (isSmall && { container: true, justify: 'center' })), offerOwnerContent),
        moreOptions));
    if (props.v14 && !isAdmin) {
        var itemRow = [
            {
                style: { width: 110 },
                label: langApp.t('id').toUpperCase(),
                value: emtyValueFill(record.indexId, 7),
                labelSize: 3,
            },
            !(is_carrier || (is_forwarder && !isOrderForwarder)) && {
                label: langApp.t('rating'),
                style: { width: 140 },
                value: (React.createElement(TextOverflow, { v71: true, primary: true, style: { width: 34 } }, ((_w = record.createdBy) === null || _w === void 0 ? void 0 : _w.rating) ? Math.round(((_x = record.createdBy) === null || _x === void 0 ? void 0 : _x.rating) * 100) + '%' : '-')),
            },
            // {
            //   label: langApp.t('co2_emission'),
            //   value: '-',
            // },
            {
                style: { width: 140 },
                label: langOfferForm.t('offer_form_valid_until'),
                value: (record.expireEnd && !record.deletedAt && (React.createElement(TimeLeft, { date: record.expireEnd, style: { marginRight: theme.spacing(1) } }))) ||
                    '-',
            },
            {
                label: langOrderList.t('order_list_filter_sort_loading_at'),
                value: toDateOnlyV3((_y = record.tempTransportations[0]) === null || _y === void 0 ? void 0 : _y.startDate, currentUser.language),
            },
            {
                label: langOrderForm.t('create_order_route_origin_to_date'),
                value: toDateOnlyV3((_z = record.tempTransportations[record.tempTransportations.length - 1]) === null || _z === void 0 ? void 0 : _z.endDate, currentUser.language),
            },
            openOrders && {
                label: "CO2",
                value: (React.createElement(TextOverflow, { v71: true, primary: true, style: { width: 60 } }, (record.co2Emission && record.co2Emission.toFixed(2)) || '-')),
            },
            !(is_carrier || (is_forwarder && !isOrderForwarder)) && {
                label: '--',
                content: offerOwnerContent,
            },
        ]
            .filter(function (e) { return e; })
            .map(function (e, index) {
            var valueContent = typeof e.value !== 'string' ? (e.value) : e.value ? (React.createElement(TextOverflow, { v71: true, primary: true }, e.value)) : ('');
            return !isSmall ? (React.createElement("td", { key: e.label, style: e.style }, e.content || (React.createElement(Grid, { item: true, xs: 12, container: true, alignItems: "center" },
                React.createElement(TextOverflow, { variant: "subtitle1", style: __assign(__assign({ paddingRight: theme.spacing(1) }, (index === 0 && { paddingLeft: theme.spacing(1) })), ((!isSmall && { textAlign: 'right' }) || {})) }, "" + e.label + (e.label.match(/:/) ? '' : ':')),
                valueContent || e.value)))) : ((e.content && (React.createElement(Grid, __assign({ key: e.label, item: true, xs: 12, container: true, alignItems: "center" }, (isSmall && { justify: 'center' }), { style: { padding: theme.spacing(1) } }), e.content))) || (React.createElement(Grid, __assign({ key: e.label, item: true, xs: 12, container: true, alignItems: "center" }, (isSmall && { justify: 'center' }), { style: { padding: theme.spacing(1) } }),
                React.createElement(Grid, __assign({ item: true, container: true, xs: 12, md: e.labelSize || 6 }, ((!isSmall && { justify: 'flex-end' }) || {}), (isSmall && { justify: 'center' })),
                    React.createElement(TextOverflow, { v71: true, primary: true, style: __assign(__assign({ paddingRight: theme.spacing(1) }, (index === 0 && { paddingLeft: theme.spacing(1) })), ((!isSmall && { textAlign: 'right' }) || {})) }, "" + e.label + (e.label.match(/:/) ? '' : ':'))),
                React.createElement(Grid, __assign({ item: true, container: true, xs: 12, md: (12 - (e.labelSize || 6)) }, (isSmall && { justify: 'center' })), typeof e.value !== 'string'
                    ? e.value
                    : (React.createElement(TextOverflow, { variant: "subtitle1", primary: true }, e.value)) || e.value))));
        });
        content = (React.createElement(React.Fragment, null,
            React.createElement(Grid, { item: true, container: true, alignItems: "center", md: 8, xs: 12 }, isSmall ? (itemRow) : (React.createElement("table", { style: { width: '100%' } },
                React.createElement("tbody", null,
                    React.createElement("tr", null, itemRow))))),
            moreOptions));
    }
    return (React.createElement(Grid, { id: "offer-record-" + record._id, "data-id": "offer-record" + (((_0 = record === null || record === void 0 ? void 0 : record.parent) === null || _0 === void 0 ? void 0 : _0._id) ? '-counter-offer' : ''), container: true, alignItems: 'center', className: clsx((_a = {},
            _a[className.root] = !v1,
            _a[className.rootV14] = !isAdmin && props.v14,
            _a[className.rootSuccessIndication] = v13,
            _a[className.rootCancelIndication] = (_1 = record.parent) === null || _1 === void 0 ? void 0 : _1._id,
            _a)), style: __assign({ minHeight: 34 }, (!isSmall && props.total && props.total - 1 > props.index ? { borderBottom: 0 } : {})), key: "" + localStore.defaultValue.updatedAt }, content));
})));
