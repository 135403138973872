var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { types, applySnapshot, flow, getSnapshot } from 'mobx-state-tree';
import { BaseCompany, AsyncActionStatus, MaybeEmptyModel } from './common';
import { UpdateCurrentCompany, CreateCompany, DeleteCompany, LocationGeoCode, LocationGeoCodes, JoinCompanyByVatCode, CheckVatCodeExists, RemoveFromCompany, } from '../graphql/company';
import { toast } from 'react-toastify';
import { i18n, KEYS } from '../i18n';
import { CreateCompanyTermBudget, FetchCompanyTermBudgetList, UpdateCompanyTermBudget, } from '../graphql/invoiceTermSettings';
import { CompanyPaymentTermModel, OrderCurrency } from '../models';
import { cloneDeep } from '../lodashUtils';
import { basicParseFloat } from '../utils3';
export var TERM_STATUS = {
    REQUESTED: 1,
    APPROVED: 2,
    REJECTED: 3,
    FINISHED: 4,
    CANCELED: 5,
};
export var CurrentCompanyFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(AsyncActionStatus, BaseCompany, types.model({
        canP44Sync: types.optional(types.boolean, false),
        isInsuranceExists: types.optional(types.boolean, false),
        isWarehouseExists: types.optional(types.boolean, false),
        joinRequest: types.maybeNull(types.optional(MaybeEmptyModel(BaseCompany), function () { return BaseCompany.create(); })),
    }))
        .actions(function (self) { return ({
        update: flow(function (input) {
            var _input, result, companySnap, e_1;
            var _a, _b, _c, _d;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        _e.trys.push([0, 2, , 3]);
                        _input = Object.assign(__assign(__assign({}, [
                            'status',
                            'hasOffer',
                            'orders',
                            'settings',
                            'isFastPayment',
                            'name',
                            'brandName',
                            'addressV1',
                            'type',
                            'p44Code',
                            'companyCode',
                            'phone',
                            'turnoverCurrency',
                            'vatCode',
                            'companyEmail',
                            'companyEmailName',
                            'bank',
                            'bankCode',
                            'bankSwiftCode',
                            'bankAccountCode',
                            'forwarderOrderMarkUpMinPriceCurrency',
                            'forwarderOrderMarkUpMaxPriceCurrency',
                            'statusPlannedAt',
                        ]
                            .filter(function (e) { return typeof input[e] !== 'undefined'; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = input[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { _id: self._id }), __assign({}, [
                            'forwarderOrderMarkUpPercentage',
                            'fastPaymentPercentage',
                            'turnover',
                            'forwarderOrderMarkUpMinPrice',
                            'forwarderOrderMarkUpMaxPrice',
                        ]
                            .filter(function (e) { return typeof input[e] !== 'undefined'; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = basicParseFloat(input[e] || '0'), _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), input.logo ? { logo: input.logo._id || input.logo } : {}, input.manager ? { manager: input.manager._id || input.manager } : {}, input.statusV1 ? { statusV1: input.statusV1._id || input.statusV1 } : {}, input.settings
                            ? {
                                settings: __assign(__assign({}, input.settings), { chat_users: (((_a = input.settings) === null || _a === void 0 ? void 0 : _a.chat_users) && ((_b = input.settings.chat_users) === null || _b === void 0 ? void 0 : _b.length)
                                        ? input.settings.chat_users
                                        : []).map(function (e) { return e._id || e; }) }),
                            }
                            : {}, input._id ? { _id: input._id } : {});
                        if (!_input.statusPlannedAt) {
                            delete _input.statusPlannedAt;
                        }
                        if (typeof ((_c = _input.statusV1) === null || _c === void 0 ? void 0 : _c._id) !== 'undefined') {
                            delete _input.statusV1;
                        }
                        if (typeof ((_d = _input.manager) === null || _d === void 0 ? void 0 : _d._id) !== 'undefined') {
                            delete _input.manager;
                        }
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateCurrentCompany,
                                variables: {
                                    input: __assign(__assign({}, _input), { vatCode: _input.vatCode === '' ? undefined : _input.vatCode }),
                                },
                            })];
                    case 1:
                        result = _e.sent();
                        if (!input._id || input._id === self._id) {
                            companySnap = __assign(__assign({}, self), cloneDeep(result.data.updateCompany));
                            applySnapshot(self, companySnap);
                        }
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'company_label')));
                        return [2 /*return*/, result.data.updateCompany];
                    case 2:
                        e_1 = _e.sent();
                        console.log(e_1);
                        // toast.error((e && e.message) || e);
                        throw e_1;
                    case 3: return [2 /*return*/];
                }
            });
        }),
        create: flow(function (input) {
            var _input, result, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        _input = Object.assign(__assign({}, [
                            'settings',
                            'name',
                            'addressV1',
                            'type',
                            'companyEmail',
                            'companyEmailName',
                            'companyCode',
                            'phone',
                            'turnoverCurrency',
                            'vatCode',
                            'forwarderOrderMarkUpMinPriceCurrency',
                            'forwarderOrderMarkUpMaxPriceCurrency',
                        ]
                            .filter(function (e) { return typeof input[e] !== 'undefined'; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = input[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), input.turnover ? { turnover: basicParseFloat(input.turnover) } : {}, input.forwarderOrderMarkUpPercentage
                            ? { forwarderOrderMarkUpPercentage: basicParseFloat(input.forwarderOrderMarkUpPercentage) }
                            : {}, input.forwarderOrderMarkUpMinPrice
                            ? { forwarderOrderMarkUpMinPrice: basicParseFloat(input.forwarderOrderMarkUpMinPrice) }
                            : {}, input.forwarderOrderMarkUpMaxPrice
                            ? { forwarderOrderMarkUpMaxPrice: basicParseFloat(input.forwarderOrderMarkUpMaxPrice) }
                            : {});
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateCompany,
                                variables: {
                                    input: _input,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'profile_company_label')));
                        return [2 /*return*/, result.data.createCompany];
                    case 2:
                        e_2 = _a.sent();
                        // toast.error((e && e.message) || e);
                        throw e_2;
                    case 3: return [2 /*return*/];
                }
            });
        }),
        deleteItem: flow(function (_a) {
            var result, e_3;
            var _id = _a._id;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteCompany,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_deleted')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'profile_company_label')));
                        return [3 /*break*/, 3];
                    case 2:
                        e_3 = _b.sent();
                        // toast.error((e && e.message) || e);
                        throw e_3;
                    case 3: return [2 /*return*/];
                }
            });
        }),
        updateSettings: flow(function (input) {
            var result, companySnap, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateCurrentCompany,
                                variables: {
                                    input: __assign(__assign(__assign({}, input), (input.settings && {
                                        settings: __assign(__assign({}, input.settings), (input.settings.chat_users && { chat_users: input.settings.chat_users.map(function (e) { return e._id || e; }) })),
                                    })), { _id: self._id }),
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        if (!input._id || input._id === self._id) {
                            companySnap = __assign(__assign({}, self), cloneDeep(result.data.updateCompany));
                            applySnapshot(self, companySnap);
                        }
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'company_label')));
                        return [2 /*return*/, result.data.updateCompany];
                    case 2:
                        e_4 = _a.sent();
                        toast.error((e_4 && e_4.message) || e_4);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        locationGeoCode: flow(function (filter) {
            var result, e_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: LocationGeoCode,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.locationGeoCode];
                    case 2:
                        e_5 = _a.sent();
                        toast.error((e_5 && e_5.message) || e_5);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        locationGeoCodes: flow(function (filter) {
            var result, e_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: LocationGeoCodes,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        return [2 /*return*/, result.data.locationGeoCodes];
                    case 2:
                        e_6 = _a.sent();
                        toast.error((e_6 && e_6.message) || e_6);
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        checkVatCodeExists: flow(function (vatCode, moreFilterFields) {
            var res, e_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.query({
                                query: CheckVatCodeExists,
                                variables: {
                                    filter: {
                                        fields: __spread([
                                            { name: 'vatCode', value: vatCode },
                                            { name: '_id', value: self._id }
                                        ], (moreFilterFields || [])).filter(function (e) { return e; }),
                                    },
                                },
                            })];
                    case 1:
                        res = _a.sent();
                        return [2 /*return*/, res.data.checkVatCodeExists];
                    case 2:
                        e_7 = _a.sent();
                        toast.error((e_7 && e_7.message) || e_7);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        joinCompanyByVatCode: flow(function (vatCode) {
            var res, e_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: JoinCompanyByVatCode,
                                variables: {
                                    input: {
                                        _id: self._id,
                                        vatCode: vatCode,
                                    },
                                },
                            })];
                    case 1:
                        res = _a.sent();
                        applySnapshot(self, __assign(__assign({}, self), { joinRequest: res.data.joinCompanyByVatCode }));
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'company_label')));
                        return [2 /*return*/, res.data.joinCompanyByVatCode];
                    case 2:
                        e_8 = _a.sent();
                        toast.error((e_8 && e_8.message) || e_8);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        removeFromEnv: flow(function (source) {
            var res, e_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: RemoveFromCompany,
                                variables: {
                                    input: {
                                        target: self._id,
                                        source: source,
                                    },
                                },
                            })];
                    case 1:
                        res = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'company_label')));
                        return [2 /*return*/, res.data.removeFromCompany];
                    case 2:
                        e_9 = _a.sent();
                        toast.error((e_9 && e_9.message) || e_9);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        updateAdditionalInfo: flow(function (input) {
            return __generator(this, function (_a) {
                applySnapshot(self, __assign(__assign({}, self), input));
                return [2 /*return*/];
            });
        }),
        loadInvoiceTermSettings: flow(function () {
            var result, term, e_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, client.mutate({
                                mutation: FetchCompanyTermBudgetList,
                                variables: {
                                    filter: {
                                        fields: [{ name: 'status', value: TERM_STATUS.REQUESTED }],
                                    },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        term = result.data.companyTermBudgets.data[0];
                        if (term)
                            return [2 /*return*/, __assign(__assign({}, term), { currency: term.currency || OrderCurrency.EUR })];
                        return [2 /*return*/, CompanyPaymentTermModel.create()];
                    case 2:
                        e_10 = _a.sent();
                        console.log(e_10);
                        toast.error((e_10 && e_10.message) || e_10);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        }),
        updateCompanyTermBudget: flow(function (input) {
            var result, e_11;
            var _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 5, , 6]);
                        result = void 0;
                        if (!input._id) return [3 /*break*/, 2];
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateCompanyTermBudget,
                                variables: {
                                    input: __assign(__assign(__assign({ _id: input._id, status: input.status }, ['comment', 'status', 'currency']
                                        .filter(function (e) { return typeof input[e] !== 'undefined'; })
                                        .map(function (e) {
                                        var _a;
                                        return (_a = {}, _a[e] = input[e], _a);
                                    })
                                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), ['budget']
                                        .map(function (e) {
                                        var _a;
                                        return (typeof input[e] !== 'undefined' ? (_a = {}, _a[e] = basicParseFloat(input[e]), _a) : null);
                                    })
                                        .filter(function (e) { return e; })
                                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), ['term']
                                        .map(function (e) {
                                        var _a;
                                        return (typeof input[e] !== 'undefined' ? (_a = {}, _a[e] = parseInt(input[e]), _a) : null);
                                    })
                                        .filter(function (e) { return e; })
                                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})),
                                },
                            })];
                    case 1:
                        result = _c.sent();
                        result = result.data.updateCompanyTermBudget;
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, client.mutate({
                            mutation: CreateCompanyTermBudget,
                            variables: {
                                input: {
                                    budget: basicParseFloat(input.budget),
                                    term: parseInt(input.term),
                                },
                            },
                        })];
                    case 3:
                        result = _c.sent();
                        result = result.data.createCompanyTermBudget;
                        _c.label = 4;
                    case 4:
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.SETTINGS, 'settings_invoice_term_of_payment_title')));
                        if (((_b = (_a = result.createdBy) === null || _a === void 0 ? void 0 : _a.company) === null || _b === void 0 ? void 0 : _b._id) === self._id &&
                            [TERM_STATUS.CANCELED, TERM_STATUS.REJECTED].includes(result.status)) {
                            applySnapshot(self, __assign(__assign(__assign({}, self), { updatedAt: Date.now().toString() }), { paymentTerm: CompanyPaymentTermModel.create({ term: 30 }) }));
                        }
                        return [2 /*return*/, result];
                    case 5:
                        e_11 = _c.sent();
                        console.log(e_11);
                        toast.error((e_11 && e_11.message) || e_11);
                        return [3 /*break*/, 6];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        clear: function () {
            var snap = getSnapshot(CurrentCompanyFactory({ client: client }).create());
            applySnapshot(self, snap);
        },
        reset: function (snapshot) {
            applySnapshot(self, snapshot);
        },
        client: function () {
            return client;
        },
    }); })
        .named('CurrentCompany');
};
