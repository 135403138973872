var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { UserItem } from './UserItem';
import { useLocalStore } from 'mobx-react-lite';
import { inject, observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { BaseList } from '../../components/BasicList';
import { AddMembersFormModal } from './AddMembersFormModal';
import { theme } from '../../theme';
import { getSnapshot } from 'mobx-state-tree';
import { BaseUser } from '../../stores/common'; //
import BaseListTitle from '../../components/BasicList/Header/BaseListTitle';
export var UsersList = inject('store')(observer(function (_a) {
    var store = _a.store, title = _a.title, isTeam = _a.isTeam, v2 = _a.v2, config = _a.config, addMemberProps = _a.addMemberProps, userListProps = _a.userListProps, v1 = _a.v1, props = __rest(_a, ["store", "title", "isTeam", "v2", "config", "addMemberProps", "userListProps", "v1"]);
    var defaultFilter = __assign({}, (config && config.variables));
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langChat = useTranslation(KEYS.CHAT);
    var langMenu = useTranslation(KEYS.MENU);
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    var headerColumns = [
        { size: props.v3 ? 3 : 2, name: langForm.t('form_input_name') },
        {
            size: props.v3
                ? 3
                : (addMemberProps && addMemberProps.hideCompany && 2) || (addMemberProps.isAdmin && 2) || 1,
            name: langForm.t('form_input_name_email'),
        },
        {
            size: props.v3 ? 3 : (addMemberProps && addMemberProps.hideCompany && 2) || 1,
            name: langForm.t('form_input_name_phone'),
            style: { padding: theme.spacing(0, isSmall ? 0 : 1) },
            nameStyle: {
                textAlign: isSmall ? 'start' : 'end',
            },
        },
        !(addMemberProps && addMemberProps.hideCompany) && !props.v3 ? { size: 2, name: 'Company' } : null,
        props.isAdmin || (addMemberProps && addMemberProps.isAdmin)
            ? {
                size: 2,
                name: langForm.t('form_input_name_roles'),
                style: { padding: theme.spacing(0, isSmall ? 0 : 1) },
            }
            : null,
        {
            size: props.v3 ? 3 : (addMemberProps && addMemberProps.isAdmin && 1) || 2,
            name: langForm.t('form_input_name_joined_at'),
            style: {
                padding: theme.spacing(0, isSmall ? 0 : 1),
            },
            nameStyle: {
                textAlign: isSmall ? 'start' : 'end',
            },
        },
        addMemberProps && addMemberProps.isAdmin
            ? {
                size: props.v3 ? 3 : 1,
                name: langForm.t('form_visited_at'),
                style: { padding: theme.spacing(0, isSmall ? 0 : 1) },
            }
            : null,
        !props.v3 && {
            size: (addMemberProps && addMemberProps.isAdmin && 1) || 4,
            name: langForm.t('form_input_actions'),
            style: {
                padding: theme.spacing(0, isSmall ? 0 : 1),
                display: 'flex',
                justifyContent: isSmall ? 'flex-start' : 'flex-end',
            },
        },
    ].filter(function (e) { return e; });
    var baseListProps = {
        client: config && config.client,
        query: config && config.query,
        defaultFilter: defaultFilter,
        searchFields: ['firstName', 'lastName', 'email'],
        sortFields: [
            { name: langOrderList.t('order_list_filter_sort_created_at'), value: 'createdAt' },
            addMemberProps && addMemberProps.isAdmin && { name: 'Last Visited At', value: 'latestVisitAt' },
        ].filter(function (e) { return e; }),
    };
    var localStore = useLocalStore(function () { return ({
        updated: 1,
        onChange: function () {
            localStore.updated++;
        },
    }); });
    var _title = title || (props.onAddCompany ? langMenu.t('menu_sidebar_carriers') : langApp.t('team_label'));
    var _config = __assign(__assign(__assign({ dontSaveFilters: true }, props._config), { id: langChat.t('form_new_chat_input_users'), title: (v1 && !v2 && (React.createElement(React.Fragment, null,
            React.createElement(BaseListTitle, { title: _title }),
            React.createElement("div", null,
                React.createElement(AddMembersFormModal, __assign({}, addMemberProps, { onChange: localStore.onChange, config: config, item: getSnapshot(BaseUser.create(isTeam ? { appType: getSnapshot(store.accountStore.currentUser).appType } : {})) })))))) ||
            _title, headerStyle: { padding: theme.spacing(0, 1) }, headerColumns: headerColumns, rowContent: function (record, onChange) {
            return (React.createElement(UserItem, __assign({ v3: props.v3, headerColumns: headerColumns, record: record, onChange: onChange, config: config }, addMemberProps)));
        } }), userListProps);
    var content = React.createElement(BaseList, { key: localStore.updated, options: baseListProps, config: _config });
    return React.createElement(Grid, { container: true }, content);
}));
export default UsersList;
