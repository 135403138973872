var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { toast } from 'react-toastify';
import React from 'react';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { uploadEvent } from '../../api/apolloClient';
import { inject } from 'mobx-react';
export var UploadItemFile = inject('store')(function (_a) {
    var config = _a.config, onChange = _a.onChange, onClick = _a.onClick, actionInit = _a.actionInit, noUpload = _a.noUpload, accountStore = _a.store.accountStore, isBatch = _a.isBatch, props = __rest(_a, ["config", "onChange", "onClick", "actionInit", "noUpload", "store", "isBatch"]);
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var _c = __read(React.useState(0), 2), progress = _c[0], setProgress = _c[1];
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var fileInput = React.useRef();
    var onSelectFile = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var files, types_1, newFiles, _loop_1, i, e_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!e.target.files.length)
                        return [2 /*return*/, console.warn('no files')];
                    files = [].filter.call(e.target.files, function (file) { return file.size <= 1024 * 1024 * 50; });
                    if ((_a = props.InputProps) === null || _a === void 0 ? void 0 : _a.accept) {
                        types_1 = Array.isArray(props.InputProps.accept)
                            ? props.InputProps.accept.map(function (e) { return e.toLowerCase(); })
                            : (props.InputProps.accept && [props.InputProps.accept.toLowerCase()]) || [];
                        files = files.filter(function (e) {
                            return types_1.find(function (type) { return (e.name || '').toLowerCase().match(type); }) ||
                                types_1.find(function (type) { return (e.type || '').toLowerCase().match(type); }) ||
                                types_1.find(function (type) { return (e.mimeType || '').toLowerCase().match(type); }) ||
                                types_1.find(function (type) { return type.toLowerCase().match(e.type || ''); }) ||
                                types_1.find(function (type) { return type.toLowerCase().match(e.mimeType || ''); });
                        });
                    }
                    e.target.value = '';
                    if (!(files === null || files === void 0 ? void 0 : files.length))
                        return [2 /*return*/, toast.error(langOfferForm.t('offer_form_document_validate'))];
                    newFiles = [];
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, 7, 8]);
                    setLoading(true);
                    _loop_1 = function (i) {
                        var UploadProps, input, item, keys, newFile_1;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    UploadProps = props.UploadProps;
                                    setProgress(0);
                                    input = Object.assign({
                                        file: files[i],
                                        isUserAction: true,
                                    }, ['folder', 'name', 'parent', 'parent_collection_name', 'access', 'users', 'emails', 'sendEmail']
                                        .filter(function (e) { return (UploadProps && UploadProps[e]) || ['sendEmail'].includes(e); })
                                        .map(function (e) {
                                        var _a;
                                        return (_a = {}, _a[e] = UploadProps[e], _a);
                                    })
                                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }), UploadProps.type && { type: UploadProps.type._id });
                                    item = void 0;
                                    keys = [
                                        '_id',
                                        'updatedAt',
                                        'size',
                                        'access',
                                        'name',
                                        'parent_collection_name',
                                        'parent',
                                        'path',
                                        'isAssigned',
                                        'createdBy',
                                        'type',
                                    ];
                                    if (!noUpload) return [3 /*break*/, 1];
                                    item = __assign(__assign({}, keys.map(function (e) {
                                        var _a;
                                        return (_a = {}, _a[e] = input[e], _a);
                                    }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { _id: Date.now() + "-custom-" + i, size: input.file.size, name: input.file.name, file: input.file, path: URL.createObjectURL(input.file), pathBlob: URL.createObjectURL(input.file), isUserAction: true, createdBy: { _id: accountStore.currentUser._id } });
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, uploadEvent(input, setProgress)];
                                case 2:
                                    newFile_1 = _a.sent();
                                    item = __assign({}, keys.map(function (e) {
                                        var _a;
                                        return (_a = {}, _a[e] = newFile_1[e], _a);
                                    }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                                    _a.label = 3;
                                case 3:
                                    if (item.createdBy)
                                        item.createdBy = { _id: item.createdBy._id };
                                    newFiles.push(item);
                                    onChange && onChange([item]);
                                    return [2 /*return*/];
                            }
                        });
                    };
                    i = 0;
                    _b.label = 2;
                case 2:
                    if (!(i < files.length)) return [3 /*break*/, 5];
                    return [5 /*yield**/, _loop_1(i)];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4:
                    i++;
                    return [3 /*break*/, 2];
                case 5: return [3 /*break*/, 8];
                case 6:
                    e_1 = _b.sent();
                    console.log('onSelectFile error:', e_1);
                    toast.error(e_1.message);
                    return [3 /*break*/, 8];
                case 7:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    var _onClick = function (e) {
        var _a;
        if ((_a = props.fileInput) === null || _a === void 0 ? void 0 : _a.current) {
            props.fileInput.current.addEventListener('change', onSelectFile, false);
            props.fileInput.current.click(e);
            onClick && onClick(e);
        }
        else {
            fileInput.current.click(e);
            onClick && onClick(e);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(InputFile, __assign({}, props, { ref: fileInput, onSelectFile: onSelectFile })),
        actionInit(loading, _onClick, progress)));
});
var InputFile = React.forwardRef(function (_a, ref) {
    var onSelectFile = _a.onSelectFile, props = __rest(_a, ["onSelectFile"]);
    var input = (React.createElement("input", __assign({ id: props.inputId || 'file-select', type: 'file', style: { position: 'absolute', left: "-100vw", zIndex: 99999999 }, onChange: onSelectFile, ref: ref }, props.InputProps)));
    return ReactDOM.createPortal(input, document.body);
});
