var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject, observer, useLocalStore } from 'mobx-react';
import { toast } from 'react-toastify';
import { OrderStatus } from '../../../models';
import { UnarchiveOrder } from '../../../graphql/order';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import BaseTooltip from '../../BaseTooltip';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import { theme } from '../../../theme';
import { BasicDialog, onPreventEvent } from '../../Dialog';
import { ACTION_CHANGE } from '../../BasicList';
import BaseIconButton from '../../BaseIconButton';
export var OrderUnarchive = inject('store')(observer(function (_a) {
    var _b, _c;
    var config = _a.config, order = _a.order, _d = _a.store.accountStore, currentUser = _d.currentUser, currentCompany = _d.currentCompany, props = __rest(_a, ["config", "order", "store"]);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (_input) {
            return __awaiter(this, void 0, void 0, function () {
                var res, _a, e_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            localStore.error = '';
                            localStore.isLoading = true;
                            _b.label = 1;
                        case 1:
                            _b.trys.push([1, 4, 5, 6]);
                            _a = config;
                            if (!_a) return [3 /*break*/, 3];
                            return [4 /*yield*/, config.client.mutate({
                                    mutation: UnarchiveOrder,
                                    variables: {
                                        input: {
                                            order: _input._id,
                                        },
                                    },
                                })];
                        case 2:
                            _a = (_b.sent());
                            _b.label = 3;
                        case 3:
                            res = _a;
                            toast.success(langOrderList.t('order_list_restore_order_finish'));
                            return [2 /*return*/, res.data.unarchiveOrder];
                        case 4:
                            e_1 = _b.sent();
                            toast.error(e_1.message);
                            return [3 /*break*/, 6];
                        case 5:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 6: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    if (!order || !order.createdBy || !order.publishedAt) {
        return null;
    }
    var canArchive = (((_b = order.manager) === null || _b === void 0 ? void 0 : _b.company._id) || order.manager) === currentCompany._id ||
        (((_c = order.createdBy) === null || _c === void 0 ? void 0 : _c.company._id) || order.createdBy) === currentCompany._id ||
        order.forwarders.find(function (forwarder) { var _a; return ((_a = forwarder === null || forwarder === void 0 ? void 0 : forwarder.company) === null || _a === void 0 ? void 0 : _a._id) === currentCompany._id; });
    if (!(canArchive && order.status === OrderStatus.Finished && order.publishedAt && order.approvedCarrier)) {
        return null;
    }
    var label = langOrderList.t('order_list_return_order_in_progress');
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    onPreventEvent(e);
                    return [4 /*yield*/, localStore.onSubmit(order)];
                case 1:
                    _a.sent();
                    if (props.onChange)
                        props.onChange(order, ACTION_CHANGE.DELETED);
                    return [3 /*break*/, 3];
                case 2:
                    e_2 = _a.sent();
                    console.log(e_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(Grid, { container: true, justify: "center", alignItems: "center" },
        React.createElement(Typography, null, langOrderList.t('order_list_return_order_in_progress_confirmation'))));
    return ((config && (React.createElement(BasicDialog, { shouldNotPrevent: true, onOk: onOk, body: dialogBody, title: label, dialogProps: { fullWidth: true, maxWidth: 'sm' }, withoutWrapper: true, bodyStyle: {
            flexDirection: 'column',
            justifyContent: 'center',
        }, actionInit: function (handleClickOpen) {
            var icon = (React.createElement(BaseTooltip, { title: label },
                React.createElement(BaseIconButton, { onClick: handleClickOpen, id: 'btn-order-un-archive' },
                    React.createElement(UnarchiveIcon, { style: { color: theme.palette.primary.main } }))));
            if (props.label) {
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: label, handleClickOpen: handleClickOpen }));
            }
            return icon;
        } }))) ||
        null);
}));
