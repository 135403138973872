var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { theme } from '../../theme';
import { toDateOnly } from '../../utils';
import { FormikSingleDatePicker } from '../DateTimePicker';
export default function RangeDateFilterSplit(_a) {
    var onChange = _a.onChange, filter = _a.filter, initialValues = _a.initialValues, formik = _a.formik, props = __rest(_a, ["onChange", "filter", "initialValues", "formik"]);
    if (!props.startDateKey)
        props.startDateKey = 'startDate';
    if (!props.endDateKey)
        props.endDateKey = 'endDate';
    var _onChangeDate = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var existValue = (filter.fields || []).filter(function (e) { return e.name !== name; });
        if (value)
            existValue.push({
                name: name,
                value: toDateOnly(value),
            });
        onChange({
            target: {
                name: 'fields',
                value: existValue,
            },
        });
    };
    var startProps = { minDate: new Date(0) };
    var endProps = { minDate: new Date(0) };
    if (initialValues[props.endDateKey]) {
        startProps.maxDate = new Date(initialValues[props.endDateKey]);
    }
    if (initialValues[props.startDateKey]) {
        endProps.minDate = new Date(initialValues[props.startDateKey]);
    }
    return (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-end', style: { width: 'initial', marginRight: theme.spacing(1) } },
        React.createElement(Grid, { container: true, direction: 'column', style: { width: 'initial', maxWidth: 140 } },
            React.createElement(FormikSingleDatePicker, __assign({ v1: true, v14: true, canClear: true }, startProps, { name: props.startDateKey, variant: "outlined", title: props.startTitle || 'Action start', value: formik.values[props.startDateKey], onClose: function (value) { return _onChangeDate({ target: { name: props.startDateKey, value: value } }); } }))),
        React.createElement(ArrowRightAltIcon, { style: {
                margin: theme.spacing(0, 1),
                color: theme.palette.common.white,
                height: theme.spacing(5),
            } }),
        React.createElement(Grid, { container: true, direction: 'column', style: { width: 'initial', maxWidth: 140 } },
            React.createElement(FormikSingleDatePicker, __assign({ v1: true, v14: true, canClear: true }, endProps, { name: props.endDateKey, variant: "outlined", title: props.endTitle || 'Action end', value: formik.values[props.endDateKey], onClose: function (value) { return _onChangeDate({ target: { name: props.endDateKey, value: value } }); } })))));
}
