var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { BaseUserAppTYpe } from './stores/common';
import { i18n, KEYS } from './i18n';
import { browserStorage } from './browserStorage';
import { useLocation } from 'react-router-dom';
import { phoneRegExp } from './common';
export var SYSTEM_EMAIL = 'system@system.com';
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}
export var percentColorsV1 = [
    { pct: 0.0, color: { r: 0x40, g: 0x73, b: 0xba } },
    { pct: 0.5, color: { r: 0x27, g: 0x65, b: 0xbb } },
    { pct: 1.0, color: { r: 0x00, g: 0x2b, b: 0x69 } },
];
var percentColors = [
    { pct: 0.0, color: { r: 0xff, g: 0x00, b: 0 } },
    { pct: 0.5, color: { r: 0xff, g: 0xff, b: 0 } },
    { pct: 1.0, color: { r: 0x00, g: 0xff, b: 0 } },
];
export var getColorForPercentage = function (pct) {
    for (var i = 1; i < percentColors.length - 1; i++) {
        if (pct < percentColors[i].pct) {
            break;
        }
    }
    var lower = percentColors[i - 1];
    var upper = percentColors[i];
    var range = upper.pct - lower.pct;
    var rangePct = (pct - lower.pct) / range;
    var pctLower = 1 - rangePct;
    var pctUpper = rangePct;
    var color = {
        r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
        g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
        b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
    };
    return [color.r, color.g, color.b].join(',');
};
export var MAP_DEFAULT_COORDINATES = {
    center: {
        lat: 54.68,
        lng: 25.27,
    },
    zoom: 12,
};
export var createMapOptions = function (map) {
    return {
        mapTypeControlOptions: {
            position: map.ControlPosition.TOP_RIGHT,
        },
        fullscreenControlOptions: {
            position: map.ControlPosition.RIGHT_TOP,
        },
        zoomControlOptions: {
            position: map.ControlPosition.RIGHT_TOP,
            styles: [{ stylers: [{ 'background-color': 'red' }] }],
        },
        // streetView: true,
        // mapTypeControl: true,
        styles: [
            {
                stylers: [{ saturation: -100 }, { gamma: 0.8 }, { lightness: 4 }, { visibility: 'on' }],
            },
        ],
    };
};
export var getMapBounds = function (map, maps, places) {
    var bounds = new maps.LatLngBounds();
    places.forEach(function (place) {
        bounds.extend(new maps.LatLng(place.geometry.location.lat, place.geometry.location.lng));
    });
    return bounds;
};
var Base64 = {
    encode: function (e) {
        return e
            .split('')
            .map(function (e) { return e.charCodeAt(0); })
            .join('')
            .substr(0, 10);
    },
};
export var checkVisibility = function (store, list) {
    try {
        var currentUser_1 = store.accountStore.currentUser;
        if (store.accountStore.isForwarderEnvOnly) {
            list.forEach(function (record) {
                switch (currentUser_1.appType[0]) {
                    case BaseUserAppTYpe.CARRIER:
                    case BaseUserAppTYpe.CUSTOMER: {
                        if (record.createdBy.appType[0] !== currentUser_1.appType[0]) {
                            if (currentUser_1.appType[0] === BaseUserAppTYpe.CARRIER) {
                                record.createdBy.appType[0] = BaseUserAppTYpe.CUSTOMER;
                            }
                            else {
                                record.createdBy.appType[0] = BaseUserAppTYpe.CARRIER;
                            }
                            ['firstName', 'lastName'].forEach(function (key) { return (record.createdBy[key] = Base64.encode(record.createdBy[key])); });
                            ['name', 'vatCode'].forEach(function (key) { return (record.createdBy.company[key] = Base64.encode(record.createdBy.company[key])); });
                        }
                        break;
                    }
                }
            });
        }
    }
    catch (e) {
        console.log(e);
    }
};
export var urlBase64ToUint8Array = function (base64String) {
    var padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    var base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/');
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};
export var getAllBaseUserAppTypeAccessForDocuments = function (quote, currentUser, opt) {
    var _a;
    var _b, _c, _d;
    var isCarrierFromCustomerList = ((_d = (_c = (_b = quote === null || quote === void 0 ? void 0 : quote.createdBy) === null || _b === void 0 ? void 0 : _b.company) === null || _c === void 0 ? void 0 : _c.companiesParents) === null || _d === void 0 ? void 0 : _d.length) &&
        quote.createdBy.company.companiesParents
            .map(function (e) { return e.toString(); })
            .includes(quote.order.createdBy.company._id.toString());
    var access = (_a = {},
        _a[BaseUserAppTYpe.CUSTOMER] = function (accessType) {
            var _a, _b, _c, _d, _e, _f;
            return [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.WAREHOUSE, BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].includes(accessType) ||
                (((_b = (_a = quote === null || quote === void 0 ? void 0 : quote.order) === null || _a === void 0 ? void 0 : _a.forwarders) === null || _b === void 0 ? void 0 : _b.length) &&
                    !isCarrierFromCustomerList &&
                    !(opt === null || opt === void 0 ? void 0 : opt.isLocation) &&
                    accessType === BaseUserAppTYpe.FORWARDER) ||
                ((!((_d = (_c = quote === null || quote === void 0 ? void 0 : quote.order) === null || _c === void 0 ? void 0 : _c.forwarders) === null || _d === void 0 ? void 0 : _d.length) || (((_f = (_e = quote === null || quote === void 0 ? void 0 : quote.order) === null || _e === void 0 ? void 0 : _e.forwarders) === null || _f === void 0 ? void 0 : _f.length) && isCarrierFromCustomerList)) &&
                    accessType === BaseUserAppTYpe.CARRIER);
        },
        _a[BaseUserAppTYpe.CARRIER] = function (accessType) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            return [BaseUserAppTYpe.CARRIER, BaseUserAppTYpe.WAREHOUSE].includes(accessType) ||
                (((_b = (_a = quote === null || quote === void 0 ? void 0 : quote.order) === null || _a === void 0 ? void 0 : _a.forwarders) === null || _b === void 0 ? void 0 : _b.length) &&
                    !isCarrierFromCustomerList &&
                    !(opt === null || opt === void 0 ? void 0 : opt.isLocation) &&
                    accessType === BaseUserAppTYpe.FORWARDER) ||
                ((!((_d = (_c = quote === null || quote === void 0 ? void 0 : quote.order) === null || _c === void 0 ? void 0 : _c.forwarders) === null || _d === void 0 ? void 0 : _d.length) || (((_f = (_e = quote === null || quote === void 0 ? void 0 : quote.order) === null || _e === void 0 ? void 0 : _e.forwarders) === null || _f === void 0 ? void 0 : _f.length) && isCarrierFromCustomerList)) &&
                    accessType === BaseUserAppTYpe.CUSTOMER) ||
                ((accessType === BaseUserAppTYpe.SENDER || accessType === BaseUserAppTYpe.CONSIGNEE) &&
                    ((((_h = (_g = quote === null || quote === void 0 ? void 0 : quote.order) === null || _g === void 0 ? void 0 : _g.forwarders) === null || _h === void 0 ? void 0 : _h.length) && isCarrierFromCustomerList) || !((_k = (_j = quote === null || quote === void 0 ? void 0 : quote.order) === null || _j === void 0 ? void 0 : _j.forwarders) === null || _k === void 0 ? void 0 : _k.length)));
        },
        _a);
    [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].forEach(function (type) {
        access[type] = access[BaseUserAppTYpe.CUSTOMER];
    });
    return access[currentUser.appType[0]];
};
export var createAppTypeUser = function (appType) {
    try {
        var title = i18n.getResource(i18n.language, KEYS.APP, "user_app_type_" + appType.toLowerCase());
        var user = {
            firstName: title.charAt(0),
            lastName: title.charAt(1),
        };
        if ([BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].includes(appType)) {
            title = i18n.getResource(i18n.language, KEYS.OFFER_FORM, "offer_uploads_" + BaseUserAppTYpe.SENDER.toLowerCase() + "_" + BaseUserAppTYpe.CONSIGNEE.toLowerCase());
            user.lastName = title.split('/')[1][0].toUpperCase();
            user.firstName = title.split('/')[0][0].toUpperCase();
        }
        return user;
    }
    catch (e) {
        console.log(e);
        return {
            firstName: '',
            lastName: '',
        };
    }
};
export var orderHasContainerVehicleType = function (vehicleTypes) {
    return ((vehicleTypes || []).map(function (type) { return type.name; }).filter(function (name) { return (name || '').startsWith('Container'); })
        .length > 0);
};
export var googleTagManager = function () {
    var _a;
    var e = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        e[_i] = arguments[_i];
    }
    try {
        if (e[0] === 'event') {
            e[1] = e[1].replace(/-/g, '_');
            if (e[1] === 'izi_error') {
                if (window.lastErrorKey)
                    e[2].value = window.lastErrorKey;
                delete window.lastErrorKey;
            }
            Object.assign(e[2], __assign(__assign(__assign(__assign(__assign({}, (browserStorage.getItem('appType') && { app_type: browserStorage.getItem('appType') })), (browserStorage.getItem('user-id') && { user_id: browserStorage.getItem('user-id') })), (browserStorage.getItem('user-id') && { user_id_dimension: browserStorage.getItem('user-id') })), (browserStorage.getItem('forwarder') && { forwarder: browserStorage.getItem('forwarder') })), (browserStorage.getItem('companyId') && {
                company_id: browserStorage.getItem('companyId'),
            })));
        }
        (_a = window.gtag).call.apply(_a, __spread([window], e));
    }
    catch (e) {
        console.log(e);
    }
};
export var createTableLocalStoreKey = function (tableName) { return "base-table-v3-" + tableName; };
export var saveHtml = function (html) {
    var d = document.createElement('div');
    d.innerHTML = html;
    return d.innerHTML;
};
export var langTranslateValue = function (value, accountStore) {
    if (!value)
        return '';
    var langIndex = accountStore.languages.findIndex(function (e) { return e.id === accountStore.currentUser.language; });
    if (langIndex < 0)
        langIndex = 0;
    return value[langIndex] || (value === null || value === void 0 ? void 0 : value.find(function (e) { return e; }));
};
export var timeInZoneV1 = function (_a) {
    var day = _a.day, time = _a.time, toTimeZone = _a.toTimeZone;
    return new Date([day, time].filter(function (e) { return e; }).join()).toLocaleString('en', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: toTimeZone.zone,
    });
};
export var getCargoInfo = function (cargo, langOrderForm) {
    var dimension = cargo.dimension || (cargo.palletType && cargo.palletType.dimension) || '';
    return [
        cargo.cargoId || '',
        (cargo.productType && cargo.productType.name) || '',
        (cargo.weight &&
            "" + cargo.weight + ((cargo.weightMeasure && langOrderForm.t("create_order_cargo_weight_" + (cargo.weightMeasure || '') + "_short")) || '')) ||
            '',
        (cargo.amount && cargo.amount + "pcs") || '',
        (cargo.package && "" + cargo.package.name) || '',
        ((cargo.palletType && cargo.palletType.length && "" + cargo.palletType.length + dimension) || '-') + "/" + ((cargo.palletType && cargo.palletType.width && "" + cargo.palletType.width + dimension) || '-') + "/" + ((cargo.height && "" + cargo.height + dimension) || '-'),
        (cargo.isStackable && langOrderForm.t('isStackable')) || '',
    ]
        .filter(function (e) { return e; })
        .reverse()
        .join(', ');
};
export * from './utils2';
export function downloadFile(url, fileName) {
    fetch(window.APP_API_URL.replace(/\/graphql/gi, '') + "/source?url=" + encodeURIComponent(url) + "&token=" + browserStorage.getItem('token'))
        .then(function (res) { return res.blob(); })
        .then(function (res) {
        var aElement = document.createElement('a');
        aElement.setAttribute('download', fileName || url.split('/')[url.split('/').length - 1].split('?')[0]);
        var href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
    });
}
export function formatPhoneNumber(phoneNumberString) {
    if (!phoneNumberString)
        return phoneNumberString;
    var COUNTRY_PHONE_LENGTH = 10;
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    if (cleaned.length < COUNTRY_PHONE_LENGTH)
        return phoneNumberString;
    return cleaned;
    //   const intlNumbers = cleaned.slice(0, cleaned.length - COUNTRY_PHONE_LENGTH);
    //   const countryNumbers = cleaned.slice(cleaned.length - COUNTRY_PHONE_LENGTH, cleaned.length);
    //   const intlCode = intlNumbers.length ? '+' + intlNumbers : '';
    //   const match: any = countryNumbers.match(/^(\d{3})(\d{3})(\d{4})$/);
    //   return [intlCode, ' (', match[1], ') ', match[2], '-', match[3]].join('');
}
export function cleanPhoneNumber(phoneNumberString) {
    if (!phoneNumberString)
        return phoneNumberString;
    return ('' + phoneNumberString).replace(/\D/g, '');
}
export function checkDirtyPhoneNumber(phone, opt) {
    if (opt === void 0) { opt = {}; }
    if (!phone)
        return opt.isNotRequired ? true : false;
    var regex = new RegExp(phoneRegExp);
    return regex.test(cleanPhoneNumber(phone));
}
export var validateEmail = function (email) {
    if (!email)
        return false;
    return String(email)
        .toLowerCase()
        .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
};
