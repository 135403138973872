var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import OrderRouteMenuItem from '../../components/Order/List/OrderRoutesMenuItem';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { inject, observer } from 'mobx-react';
import BaseTooltip from '../../components/BaseTooltip';
import BaseIconButton from '../../components/BaseIconButton';
var SendInvoice = function (_a) {
    var store = _a.store, props = __rest(_a, ["store"]);
    var order = props.order;
    var langForm = useTranslation(KEYS.FORM);
    var handleClickOpen = function () { return store.invoiceStore.createQuoteInvoice(props.quote._id); };
    var label = langForm.t(order.sorting_hasInvoice ? 'form_btn_view_invoice' : 'form_btn_send_invoice');
    var icon = (React.createElement(BaseTooltip, { title: label },
        React.createElement(BaseIconButton, { onClick: handleClickOpen, color: 'primary', disabled: store.invoiceStore.isLoading, id: 'btn-send-invoice' },
            React.createElement(AllInboxIcon, null))));
    return props.label ? (React.createElement(OrderRouteMenuItem, __assign({}, {
        icon: icon,
        label: label,
        handleClickOpen: handleClickOpen,
        disabled: store.invoiceStore.isLoading,
    }))) : (icon);
};
export default inject('store')(observer(SendInvoice));
