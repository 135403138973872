var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BaseOrderQuoteCreationForm } from '../../OrderQuoteItem/Quotes/BaseOrderQuoteCreationForm';
export var OrderQuoteDetails = function (_a) {
    var record = _a.record, order = _a.order, client = _a.client, props = __rest(_a, ["record", "order", "client"]);
    return React.createElement(BaseOrderQuoteCreationForm, { v2: true, order: order, onChange: props.onChange, config: { client: client } });
};
