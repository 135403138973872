var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { types, flow, getSnapshot } from 'mobx-state-tree';
import { AsyncActionStatus } from './common';
import { toast } from 'react-toastify';
import { i18n } from '../i18n';
import { CreateInvoice, CreateQuoteInvoice, DeleteInvoice, FetchCreditInvoice, FetchInvoice, FetchRequestRefundInvoice, InvoiceSerial, SendInvoice, UpdateInvoice, CopyInvoice, DuplicateInvoice, CreateInvoiceTemplate, } from '../graphql';
import { KEYS } from '../i18n';
import { Invoice, InvoiceCategory } from '../models/Invoices';
import { CreateInvoiceType } from '../graphql/invoices/invoiceType';
import browserHistory from '../browserHistory';
import { toDateOnly } from '../utils';
import { CreateInvoiceService, DeleteInvoiceService, UpdateInvoiceService } from '../graphql/invoices/invoiceService';
import { InvoiceService } from '../models/InvoiceService';
import { basicParseFloat } from '../utils3';
export var InvoiceStoreFactory = function (_a) {
    var client = _a.client;
    return types
        .compose(AsyncActionStatus, types.model('InvoiceStore', {
        item: types.optional(types.maybeNull(Invoice), function () { return Invoice.create({}); }),
        isLoading: types.optional(types.boolean, false),
        isCreating: types.optional(types.boolean, false),
        isSending: types.optional(types.boolean, false),
    }))
        .actions(function (self) { return ({
        duplicateInvoice: flow(function (_id) {
            var result, _item_1, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: DuplicateInvoice,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        _item_1 = JSON.parse(JSON.stringify(result.data.duplicateInvoice));
                        if (_item_1.category === InvoiceCategory.COSTS) {
                            setTimeout(function () { return browserHistory.push("/finance/costs/" + _item_1._id); }, 1000);
                        }
                        else {
                            setTimeout(function () { return browserHistory.push("/finance/invoices/" + _item_1._id); }, 1000);
                        }
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_1 = _a.sent();
                        toast.error(e_1.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        copyInvoice: flow(function (_id) {
            var result, _item_2, e_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CopyInvoice,
                                variables: {
                                    input: {
                                        source: _id,
                                        target: self.item._id,
                                    },
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        _item_2 = JSON.parse(JSON.stringify(result.data.copyInvoice));
                        self.item.reset(__assign({}, Object.keys(_item_2)
                            .filter(function (e) { return _item_2[e] !== null; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = _item_2[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_saved')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        return [3 /*break*/, 4];
                    case 2:
                        e_2 = _a.sent();
                        toast.error(e_2.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createInvoice: flow(function (_a) {
            var result, e_3;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isCreating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateInvoice,
                                variables: {
                                    input: Object.assign(__assign({}, input), input.total
                                        ? {
                                            total: basicParseFloat(input.total.toString()),
                                        }
                                        : {}),
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_sent')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        return [2 /*return*/, result.data.createInvoice];
                    case 2:
                        e_3 = _b.sent();
                        toast.error(e_3.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isCreating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createInvoiceTemplate: flow(function () {
            var result, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateInvoiceTemplate,
                                variables: {
                                    _id: self.item._id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice_template')));
                        return [2 /*return*/, result.data.createInvoiceTemplate];
                    case 2:
                        e_4 = _a.sent();
                        toast.error(e_4.message);
                        return [3 /*break*/, 4];
                    case 3: return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createInvoiceType: flow(function (_a) {
            var result, e_5;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isCreating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateInvoiceType,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.FORM, 'form_invoice_type_invoice')));
                        return [2 /*return*/, result.data.createInvoiceType];
                    case 2:
                        e_5 = _b.sent();
                        toast.error(e_5.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isCreating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        invoiceSerial: flow(function (filter) {
            var e_6;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: InvoiceSerial,
                                variables: {
                                    filter: filter,
                                },
                            })];
                    case 1: return [2 /*return*/, (_a.sent()).data.invoiceSerial.count];
                    case 2:
                        e_6 = _a.sent();
                        toast.error(e_6.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateSendInvoice: function (isSending) {
            self.isSending = isSending;
        },
        sendInvoice: flow(function (input) {
            var current_1, result, _item_3, _origin, e_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isSending = true;
                        current_1 = getSnapshot(self.item);
                        return [4 /*yield*/, client.mutate({
                                mutation: SendInvoice,
                                variables: {
                                    input: __assign(__assign(__assign(__assign(__assign({}, [
                                        'seller_name',
                                        'seller_address',
                                        'seller_vatCode',
                                        'seller_companyCode',
                                        'seller_bankAccountCode',
                                        'seller_bank',
                                        'seller_bankCode',
                                        'seller_bankSwiftCode',
                                    ]
                                        .filter(function (e) { return typeof current_1[e] !== 'undefined'; })
                                        .map(function (e) {
                                        var _a;
                                        return (_a = {}, _a[e] = current_1[e], _a);
                                    })
                                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), input), (input.total && { total: basicParseFloat(input.total.toString()) })), (input.seller_id && { type: input.seller_id._id })), (input.type && { type: input.type._id })),
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_sent')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        _item_3 = JSON.parse(JSON.stringify(result.data.sendInvoice));
                        delete _item_3.quote;
                        _origin = JSON.parse(JSON.stringify(getSnapshot(self.item)));
                        self.item.reset(__assign(__assign({}, _origin), { sentAt: _item_3.sentAt, status: _item_3.status, serial: _item_3.serial, files: __spread(_origin.files.filter(function (e) { return e.name === _item_3.upload.name && e.parent_collection_name === 'invoices'; }), [
                                _item_3.upload,
                            ]) }));
                        return [2 /*return*/, self.item];
                    case 2:
                        e_7 = _a.sent();
                        console.log(e_7);
                        toast.error(e_7.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isSending = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createQuoteInvoice: flow(function (quoteId, noRedirect) {
            var result, _item_4, e_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isLoading = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateQuoteInvoice,
                                variables: {
                                    quoteId: quoteId,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_loaded')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        _item_4 = JSON.parse(JSON.stringify(result.data.createQuoteInvoice));
                        self.clearItem();
                        if (noRedirect) {
                            self.item = Invoice.create(__assign({}, Object.keys(_item_4)
                                .filter(function (e) { return _item_4[e] !== null; })
                                .map(function (e) {
                                var _a;
                                return (_a = {}, _a[e] = _item_4[e], _a);
                            })
                                .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        }
                        else {
                            setTimeout(function () { return browserHistory.push("/finance/invoices/" + _item_4._id); }, 1000);
                        }
                        return [2 /*return*/, self.item];
                    case 2:
                        e_8 = _a.sent();
                        console.log(e_8);
                        toast.error(e_8.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadRequestRefundInvoice: flow(function (invoiceId) {
            var result, _item_5, e_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (self.isLoading)
                            return [2 /*return*/];
                        self.isLoading = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: FetchRequestRefundInvoice,
                                variables: {
                                    invoiceId: invoiceId,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_loaded')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        _item_5 = JSON.parse(JSON.stringify(result.data.requestRefundInvoice));
                        self.item = Invoice.create(Object.keys(_item_5)
                            .filter(function (e) { return _item_5[e] !== null; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = _item_5[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                        self.isLoading = false;
                        return [2 /*return*/, self.item];
                    case 2:
                        e_9 = _a.sent();
                        console.log(e_9);
                        toast.error(e_9.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadCreditInvoice: flow(function (invoiceId) {
            var result, _item_6, e_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (self.isLoading)
                            return [2 /*return*/];
                        self.isLoading = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: FetchCreditInvoice,
                                variables: {
                                    invoiceId: invoiceId,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_loaded')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        _item_6 = JSON.parse(JSON.stringify(result.data.createCreditInvoice));
                        self.item = Invoice.create(Object.keys(_item_6)
                            .filter(function (e) { return _item_6[e] !== null; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = _item_6[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                        self.isLoading = false;
                        return [2 /*return*/, self.item];
                    case 2:
                        e_10 = _a.sent();
                        console.log(e_10);
                        toast.error(e_10.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createItem: flow(function (_a) {
            var current_2, input, result, _item_7, e_11;
            var _id = _a._id, _input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, 3, 4]);
                        self.isCreating = true;
                        current_2 = getSnapshot(self.item);
                        input = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, _input), [
                            'category',
                            'seller_name',
                            'seller_address',
                            'seller_vatCode',
                            'seller_companyCode',
                            'seller_bankAccountCode',
                            'seller_bank',
                            'seller_bankCode',
                            'seller_bankSwiftCode',
                            'client_name',
                            'client_address',
                            'client_vatCode',
                            'client_companyCode',
                            'client_bankAccountCode',
                            'client_bank',
                            'client_bankCode',
                            'client_bankSwiftCode',
                        ]
                            .filter(function (e) { return typeof current_2[e] !== 'undefined'; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = current_2[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), (_input.total && { total: basicParseFloat(_input.total.toString()) })), (_input.type && { type: _input.type._id })), (current_2.client_id && current_2.client_id._id && { client_id: current_2.client_id._id })), (current_2.seller_id && current_2.seller_id._id && { seller_id: current_2.seller_id._id })), (_input.seller_id && { seller_id: _input.seller_id._id })), (_input.client_id && { client_id: _input.client_id._id })), (_input.startDate && { startDate: toDateOnly(_input.startDate) })), (_input.endDate && { endDate: toDateOnly(_input.endDate) })), (_input.discount && { discount: basicParseFloat(_input.discount.toString()) }));
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateInvoice,
                                variables: {
                                    input: input,
                                },
                            })];
                    case 1:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        _item_7 = JSON.parse(JSON.stringify(result.data.createInvoice));
                        self.item = Invoice.create(Object.keys(_item_7)
                            .filter(function (e) { return _item_7[e] !== null; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = _item_7[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
                        self.isCreating = false;
                        setTimeout(function () { return browserHistory.push("/finance/" + location.pathname.split('/')[2] + "/" + self.item._id); }, 1000);
                        return [2 /*return*/, self.item];
                    case 2:
                        e_11 = _b.sent();
                        console.log(e_11);
                        toast.error(e_11.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isCreating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        updateItemDetails: flow(function (input) {
            return __generator(this, function (_a) {
                self.item.reset(__assign(__assign({}, getSnapshot(self.item)), input));
                return [2 /*return*/];
            });
        }),
        updateItem: flow(function (_a) {
            var item_1, keys, result, _item_8, e_12;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        item_1 = self.item;
                        if (!(!item_1 || !item_1._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createItem(input)];
                    case 1:
                        item_1 = _b.sent();
                        _b.label = 2;
                    case 2:
                        keys = Object.keys(input);
                        if (!keys.filter(function (e) { return e; }).length || keys.filter(function (e) { return input[e] === item_1[e]; }).length === keys.length) {
                            return [2 /*return*/, console.warn('No Changes')];
                        }
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateInvoice,
                                variables: {
                                    input: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ _id: _id || (item_1 && item_1._id) }, input), (input.total && { total: basicParseFloat(input.total.toString()) })), (input.client_id && { client_id: input.client_id._id })), (input.seller_id && { seller_id: input.seller_id._id })), (input.type && { type: input.type._id })), (input.startDate && { startDate: toDateOnly(input.startDate) })), (input.endDate && { endDate: toDateOnly(input.endDate) })), (input.discount && { discount: basicParseFloat(input.discount.toString()) })),
                                },
                            })];
                    case 3:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_updated')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        _item_8 = JSON.parse(JSON.stringify(result.data.updateInvoice));
                        delete _item_8.quote;
                        self.item.reset(__assign(__assign(__assign({}, getSnapshot(self.item)), Object.keys(_item_8)
                            .filter(function (e) { return _item_8[e] !== null; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = _item_8[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { services: getSnapshot(self.item).services }));
                        self.isUpdating = false;
                        return [2 /*return*/, self.item];
                    case 4:
                        e_12 = _b.sent();
                        toast.error(e_12.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        deleteItem: flow(function (_id) {
            var result, e_13;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteInvoice,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_deleted')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_invoice')));
                        self.clearItem();
                        return [2 /*return*/, self.item];
                    case 2:
                        e_13 = _a.sent();
                        toast.error(e_13.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        loadItem: flow(function (_id) {
            var result_1, e_14;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (self.item._id === _id)
                            return [2 /*return*/];
                        // self.clearItem();
                        self.isLoading = true;
                        return [4 /*yield*/, client.query({
                                query: FetchInvoice,
                                variables: {
                                    _id: _id,
                                },
                            })];
                    case 1:
                        result_1 = (_a.sent()).data.invoice;
                        self.item = Invoice.create(__assign({}, Object.keys(result_1)
                            .filter(function (e) { return result_1[e] !== null; })
                            .map(function (e) {
                            var _a;
                            return (_a = {}, _a[e] = result_1[e], _a);
                        })
                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
                        return [2 /*return*/, self.item];
                    case 2:
                        e_14 = _a.sent();
                        toast.error(e_14.message);
                        browserHistory.push("/finance/" + location.pathname.split('/')[2]);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isLoading = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        createInvoiceServiceItem: flow(function (_a) {
            var item, result, e_15;
            var _id = _a._id, input = __rest(_a, ["_id"]);
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 4, 5, 6]);
                        item = self.item;
                        if (!(!item || !item._id)) return [3 /*break*/, 2];
                        return [4 /*yield*/, self.createItem({})];
                    case 1:
                        item = _b.sent();
                        _b.label = 2;
                    case 2:
                        self.isCreating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: CreateInvoiceService,
                                variables: {
                                    input: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({ invoice: item._id }, input), (typeof input.total !== 'undefined' && { total: basicParseFloat((input.total || 0).toString()) })), (typeof input.price !== 'undefined' && { price: basicParseFloat((input.price || 0).toString()) })), (typeof input.vatPercent !== 'undefined' && {
                                        vatPercent: basicParseFloat((input.vatPercent || 0).toString()),
                                    })), (typeof input.vatAmount !== 'undefined' && {
                                        vatAmount: basicParseFloat((input.vatAmount || 0).toString()),
                                    })), (typeof input.discount !== 'undefined' && {
                                        discount: basicParseFloat((input.discount || 0).toString()),
                                    })), (typeof input.quantity !== 'undefined' && { quantity: parseInt((input.quantity || 0).toString()) })), (input.unit && { unit: input.unit._id })), (input.vat && { vat: input.vat._id })), (input.issuedBy && { unit: input.issuedBy._id })),
                                },
                            })];
                    case 3:
                        result = _b.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_created')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.FORM, 'form_invoice_create_service')));
                        item = InvoiceService.create(result.data.createInvoiceService);
                        self.item.reset(__assign(__assign({}, getSnapshot(self.item)), { updatedAt: new Date().toString(), services: __spread(getSnapshot(self.item).services, [item]) }));
                        return [2 /*return*/, item];
                    case 4:
                        e_15 = _b.sent();
                        console.log(e_15);
                        toast.error(e_15.message);
                        return [3 /*break*/, 6];
                    case 5:
                        self.isCreating = false;
                        return [7 /*endfinally*/];
                    case 6: return [2 /*return*/];
                }
            });
        }),
        updateInvoiceServiceItem: flow(function (input) {
            var result_2, e_16;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        if (!input._id) {
                            return [2 /*return*/, self.createInvoiceServiceItem(input)];
                        }
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: UpdateInvoiceService,
                                variables: {
                                    input: __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, input), (typeof input.total !== 'undefined' && { total: basicParseFloat((input.total || 0).toString()) })), (typeof input.price !== 'undefined' && { price: basicParseFloat((input.price || 0).toString()) })), (typeof input.vatPercent !== 'undefined' && {
                                        vatPercent: basicParseFloat((input.vatPercent || 0).toString()),
                                    })), (typeof input.vatAmount !== 'undefined' && {
                                        vatAmount: basicParseFloat((input.vatAmount || 0).toString()),
                                    })), (typeof input.discount !== 'undefined' && {
                                        discount: basicParseFloat((input.discount || 0).toString()),
                                    })), (typeof input.quantity !== 'undefined' && { quantity: parseInt((input.quantity || 0).toString()) })), (input.unit && { unit: input.unit._id })), (input.vat && { vat: input.vat._id })), (input.issuedBy && { unit: input.issuedBy._id })),
                                },
                            })];
                    case 1:
                        result_2 = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_updated')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.FORM, 'form_invoice_create_service')));
                        self.item.reset(__assign(__assign({}, getSnapshot(self.item)), { updatedAt: new Date().toString(), services: getSnapshot(self.item).services.map(function (e) {
                                if (e._id === input._id)
                                    return __assign(__assign({}, e), result_2.data.updateInvoiceService);
                                return e;
                            }) }));
                        return [2 /*return*/, result_2.data.updateInvoiceSerive];
                    case 2:
                        e_16 = _a.sent();
                        toast.error(e_16.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        removeInvoiceServiceItem: flow(function (_id) {
            var result, e_17;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        self.isUpdating = true;
                        return [4 /*yield*/, client.mutate({
                                mutation: DeleteInvoiceService,
                                variables: { _id: _id },
                            })];
                    case 1:
                        result = _a.sent();
                        toast.success(i18n
                            .getResource(i18n.language, KEYS.TOAST, 'toast_items_deleted')
                            .replace(/_X_/, i18n.getResource(i18n.language, KEYS.FORM, 'form_invoice_create_service')));
                        self.item.reset(__assign(__assign({}, getSnapshot(self.item)), { services: getSnapshot(self.item).services.filter(function (e) { return e._id !== _id; }) }));
                        return [2 /*return*/, result.data.deleteInvoiceService];
                    case 2:
                        e_17 = _a.sent();
                        toast.error(e_17.message);
                        return [3 /*break*/, 4];
                    case 3:
                        self.isUpdating = false;
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }),
        clearItem: function () {
            if (self.item) {
                self.item = Invoice.create();
            }
        },
    }); })
        .named('InvoiceStore');
};
