var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { BasicDialog } from '../../Dialog';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { inject, observer } from 'mobx-react';
import { BaseUserAppTYpe } from '../../../stores/common';
import { FetchOrderQuote, FetchOrderQuotes } from '../../../graphql/order';
import { OrderQuoteCardHeader } from '..';
import { OrderStatus } from '../../../models';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { CircularIndeterminate } from '../../CircularIndeterminate';
import { theme } from '../../../theme';
import { dateViewFormat } from '../../DateView';
import OrderRouteMenuItem from '../List/OrderRoutesMenuItem';
import { initChartJsLib } from '../../../utils';
import BaseTooltip from '../../BaseTooltip';
import BaseIconButton from '../../BaseIconButton';
function valuetext(value) {
    return value + "$";
}
export var OrderQuoteStatistics = inject('store')(observer(function (_a) {
    var autocompleteProps = _a.autocompleteProps, config = _a.config, order = _a.order, quote = _a.quote, currentUser = _a.store.accountStore.currentUser, props = __rest(_a, ["autocompleteProps", "config", "order", "quote", "store"]);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var _b = __read(React.useState(1), 2), updated = _b[0], setUpdated = _b[1];
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState(null), 2), data = _d[0], setData = _d[1];
    var _e = __read(React.useState([0, 0]), 2), range = _e[0], setRange = _e[1];
    var _f = __read(React.useState([0, 0]), 2), rangeLimit = _f[0], setRangeLimit = _f[1];
    var handleChange = function (event, newValue) {
        setRange(newValue);
    };
    var events = [
        {
            name: 'resize',
            context: window,
            callback: function () { return setUpdated(Date.now()); },
        },
    ];
    useEffect(function () {
        events.forEach(function (e) { return e.context.addEventListener.bind(e.context)(e.name, e.callback); });
        return function () { return events.forEach(function (e) { return e.context.removeEventListener.bind(e.context)(e.name, e.callback); }); };
    }, []);
    if (order.status !== OrderStatus.ApprovedQuote || !currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER))
        return null;
    var _loadData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var variables, res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    variables = {
                        filter: {
                            fields: [
                                { name: 'order', value: order._id },
                                { name: 'withRange', value: 1 },
                            ],
                        },
                    };
                    if (rangeLimit[1] > 0) {
                        variables.filter.fields.push({ name: 'amount', value: range, type: 'number' });
                    }
                    return [4 /*yield*/, config.client.query({
                            query: FetchOrderQuotes,
                            variables: variables,
                        })];
                case 1:
                    res = _a.sent();
                    setData(res.data.orderQuotes.data);
                    return [2 /*return*/, res];
            }
        });
    }); };
    var _onOpen = function () { return __awaiter(void 0, void 0, void 0, function () {
        var quoteDetails, res, range_1, e_1, e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 4, 5, 10]);
                    setLoading(true);
                    if (!config)
                        throw 'missing config';
                    if (!!quote.createdBy) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.query({
                            query: FetchOrderQuote,
                            variables: { _id: quote._id },
                        })];
                case 1:
                    quoteDetails = _a.sent();
                    Object.assign(quote, quoteDetails.data.orderQuote);
                    _a.label = 2;
                case 2: return [4 /*yield*/, _loadData()];
                case 3:
                    res = _a.sent();
                    if (!data) {
                        range_1 = [res.data.orderQuotes.priceRange[0].min, res.data.orderQuotes.priceRange[0].max];
                        setRange(range_1);
                        setRangeLimit(__spread(range_1));
                    }
                    return [3 /*break*/, 10];
                case 4:
                    e_1 = _a.sent();
                    console.log(e_1);
                    return [3 /*break*/, 10];
                case 5:
                    _a.trys.push([5, 8, , 9]);
                    if (!!window.Chart) return [3 /*break*/, 7];
                    return [4 /*yield*/, initChartJsLib()];
                case 6:
                    _a.sent();
                    _a.label = 7;
                case 7: return [3 /*break*/, 9];
                case 8:
                    e_2 = _a.sent();
                    return [3 /*break*/, 9];
                case 9:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 10: return [2 /*return*/];
            }
        });
    }); };
    var _onInit = function (chartRef) { return __awaiter(void 0, void 0, void 0, function () {
        var myChartRef;
        return __generator(this, function (_a) {
            if (!chartRef)
                return [2 /*return*/];
            myChartRef = chartRef.getContext('2d');
            new window.Chart(myChartRef, {
                type: 'line',
                data: {
                    labels: (data || []).map(function (e) { return dateViewFormat(e.createdAt); }),
                    datasets: [
                        {
                            label: langForm.t('form_input_name_price') + "(" + ((data.length && data[0].currency) || '$') + ")",
                            data: (data || []).map(function (e) { return e.amount; }),
                        },
                    ],
                },
                options: {
                //Customize chart options
                },
            });
            return [2 /*return*/];
        });
    }); };
    var dialogBody = (React.createElement(Grid, { container: true, direction: 'column', key: loading ? 1 : -1 },
        React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(2) } },
            React.createElement(OrderQuoteCardHeader, { order: order, record: quote, noSpacing: true })),
        loading ? (React.createElement(Grid, { container: true, justify: 'center' },
            React.createElement(CircularIndeterminate, null))) : (React.createElement(Grid, { container: true },
            React.createElement(Grid, { container: true, justify: 'flex-end' },
                React.createElement("div", null,
                    React.createElement(Typography, { id: "range-slider", gutterBottom: true },
                        langForm.t('form_input_filter_filter_by_label'),
                        ' ',
                        langForm.t('form_input_name_price').toLowerCase()),
                    React.createElement(Slider, { value: range, min: rangeLimit[0], max: rangeLimit[1], onChange: handleChange, onChangeCommitted: _loadData, valueLabelDisplay: "auto", "aria-labelledby": "range-slider", getAriaValueText: valuetext }))),
            React.createElement("canvas", { key: updated, ref: _onInit, style: { width: '100%', height: '50vh', minHeight: 240, minWidth: 240 } })))));
    return ((config && (React.createElement(BasicDialog, { shouldNotPrevent: true, v1: true, onOpen: _onOpen, withoutWrapper: true, body: dialogBody, title: langOfferForm.t('offer_form_statistic'), hidActionOkBth: true, dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: function (handleClickOpen) {
            var icon = (React.createElement(BaseTooltip, { title: langOfferForm.t('offer_form_statistic') },
                React.createElement(BaseIconButton, { onClick: handleClickOpen },
                    React.createElement(EqualizerIcon, { color: 'primary' }))));
            if (props.label) {
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: props.label, handleClickOpen: handleClickOpen }));
            }
            return icon;
        } }))) ||
        null);
}));
