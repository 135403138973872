var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { toast } from 'react-toastify';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { KEYS } from '../../i18n';
import { useIconStyles } from '../../styles';
import { FetchOrderUsers, UpdateCurrentCompany } from '../../graphql';
import { FormikAutocompleteAsync, formikAutocompleteMultiOptionsUsersProps } from '../../components/Formik';
import { theme } from '../../theme';
import BaseTooltip from '../../components/BaseTooltip';
import { AvatarItemView } from '../../components/Account/Avatar';
import { BaseUserAppTYpe } from '../../stores/common';
import { inject } from 'mobx-react';
import BaseIconButton from '../../components/BaseIconButton';
export default inject('store')(function CompanyItemForwarder(_a) {
    var _this = this;
    var record = _a.record, config = _a.config, currentCompany = _a.store.accountStore.currentCompany, isAdmin = _a.isAdmin, disabled = _a.disabled, props = __rest(_a, ["record", "config", "store", "isAdmin", "disabled"]);
    var langApp = useTranslation(KEYS.APP);
    var langToast = useTranslation(KEYS.TOAST);
    var classesIcon = useIconStyles();
    var onChange = function (e) { return __awaiter(_this, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, config.client.mutate({
                            mutation: UpdateCurrentCompany,
                            variables: { input: { _id: record._id, forwarders: e.target.value.map(function (e) { return e._id; }) } },
                        })];
                case 1:
                    _a.sent();
                    toast.success(langToast.t('toast_items_saved').replace(/_X_/, langApp.t('company_label')));
                    if (!e.target.value.length && props.onChange)
                        props.onChange();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    toast.error(e_1.message || e_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var client = config.client;
    return (React.createElement(Formik, { initialValues: { forwarders: record.forwarders }, onSubmit: function (e) { return e; } }, function (formik) {
        var _userProps = formikAutocompleteMultiOptionsUsersProps(formik.values.forwarders, function () { return 0; }, {
            chipProps: { color: 'primary', className: classesIcon.iconWhitePrimary },
            chipStyleProps: { marginBottom: theme.spacing(0) },
        });
        Object.assign(_userProps.autocompleteProps, {
            disableClearable: true,
            renderTags: function (value, getTagProps) {
                var re = (Array.isArray(value) && value) || [];
                return re.map(function (option, index) {
                    var prop = getTagProps({ index: index });
                    return (option && (React.createElement(Grid, { style: { margin: theme.spacing(0, 0.5, 0.5, 0), position: 'relative' } },
                        React.createElement(BaseTooltip, { title: langApp.t('modal_delete_title') },
                            React.createElement(BaseIconButton, { onClick: prop.onDelete, style: {
                                    position: 'absolute',
                                    right: -14,
                                    top: -6,
                                    backgroundColor: theme.palette.error.light,
                                    color: theme.palette.common.white,
                                    zIndex: 1,
                                    padding: theme.spacing(0),
                                } },
                                React.createElement(HighlightOffIcon, null))),
                        React.createElement(AvatarItemView, { moreDetail: true, hideType: true, typographyProps: {
                                variant: 'h6',
                                style: {
                                    fontSize: 14,
                                    fontWeight: 600,
                                    color: theme.palette.common.white,
                                },
                            }, user: option }))));
                });
            },
        });
        return (React.createElement(Form, { style: { width: '100%' }, id: 'form-company-forwarders' },
            React.createElement(FormikAutocompleteAsync, __assign({ isClient: true, filterFields: ['firstName', 'lastName', 'email'] }, _userProps, { disabled: disabled, v4: true, v14: true, variables: {
                    filter: {
                        fields: [
                            { name: 'appType', value: [BaseUserAppTYpe.FORWARDER] },
                            !isAdmin && { name: 'company', value: currentCompany._id },
                        ].filter(function (e) { return e; }),
                    },
                }, query: FetchOrderUsers, client: client, placeholder: langApp.t('user_app_type_forwarder_manager'), options: formik.values.forwarders || [], name: "forwarders", variant: "standard", multiple: true, value: formik.values.forwarders, onChange: onChange }))));
    }));
});
