var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { BasicDialog } from '../../components/Dialog';
import PersonIcon from '@material-ui/icons/Person';
import { Grid } from '@material-ui/core';
import BaseTooltip from '../../components/BaseTooltip';
import { i18n, KEYS } from '../../i18n';
import { ImagePreview } from '../../components/Account/Avatar';
import BaseIconButton from '../../components/BaseIconButton';
export default function LoginWithQR(_a) {
    var record = _a.record, config = _a.config, handleClickOpen = _a.handleClickOpen;
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    return (React.createElement(BasicDialog, { body: React.createElement(Grid, { container: true },
            React.createElement(ImagePreview, { path: window.APP_API_URL.replace(/\/graphql/gi, "/qr/1?_id=" + record._id) })), hidActionOkBth: true, title: i18n.getResource(i18n.language, KEYS.APP, 'login_with_qr'), withoutWrapper: true, actionInit: handleClickOpen
            ? handleClickOpen
            : function (handleClickOpen) { return (React.createElement(BaseTooltip, { title: i18n.getResource(i18n.language, KEYS.APP, 'login_with_qr') },
                React.createElement(BaseIconButton, { disabled: loading, "aria-label": "menu", color: 'primary', onClick: handleClickOpen, style: { padding: 0 } },
                    React.createElement(PersonIcon, null)))); } }));
}
