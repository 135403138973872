var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import Steps, { iconSelected } from '../../../Steps';
import { inject, observer } from 'mobx-react';
import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { TextOverflow } from '../../../TextOverflow';
import { toDateOnlyV1, toDateOnlyV41, toDateOnlyV4 } from '../../../../utils';
import { theme } from '../../../../theme';
import { langTranslateValue } from '../../../../utils1';
import { getSnapshot } from 'mobx-state-tree';
import OrderQuoteDocuments, { OrderQuoteDocumentsComponent } from '../../../../pages/Quotes/Documents';
import PreloadingComponent from '../../../PreloadingComponent';
import { useTranslation } from 'react-i18next';
import { KEYS, i18n } from '../../../../i18n';
import OrderProgress from '../../List/OrderProgress';
import { HelpIconComponent } from '../../../HelpIcon';
var PreparationStepForm = React.lazy(function () { return import('./PreparationStepForm'); });
export default inject('store')(observer(function (_a) {
    var _b, _c, _d, _e;
    var store = _a.store, preparingStatuses = _a.preparingStatuses, order = _a.order, disabled = _a.disabled, props = __rest(_a, ["store", "preparingStatuses", "order", "disabled"]);
    var langForm = useTranslation(KEYS.FORM);
    var _f = __read(React.useState(false), 2), loading = _f[0], setLoading = _f[1];
    order = order || store.orderStore.order;
    var preparingStatusesSnapshot = getSnapshot(store.accountStore.preparingStatuses);
    var _preparingStatuses = preparingStatuses.map(function (e) {
        if (e.value.order) {
            return e;
        }
        var value = preparingStatusesSnapshot.find(function (p) { return p._id === e.value._id; });
        if (value)
            e.value = value;
        return e;
    });
    var list = _preparingStatuses.sort(function (a, b) { return a.value.order - b.value.order; });
    if (!list.length && !disabled) {
        return React.createElement(TextOverflow, { v2: true }, "No status available");
    }
    var current = list.find(function (e) { return !e.assignedAt; });
    if (props.v2) {
        for (var i = 0; i < list.length; i++) {
            if (list[i].assignedAt) {
                current = list[i];
            }
        }
    }
    if (current) {
        current = current._id;
    }
    else {
        current = -1;
    }
    var onClick = function (step) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setLoading(true);
                    return [4 /*yield*/, store.orderStore.updateOrderPreparing({
                            order: (order === null || order === void 0 ? void 0 : order._id) || store.orderStore.order._id,
                            _id: step._id,
                        })];
                case 1:
                    _a.sent();
                    if (props.onChange) {
                        if (props.onChangeV1) {
                            props.onChangeV1({ withoutQuote: 1 });
                        }
                        else {
                            props.onChange({ withoutQuote: 1 });
                        }
                    }
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var steps = list.map(function (e, index) {
        // const descriptionLabel = (
        //   <small
        //     style={{ textAlign: 'center' }}
        //     dangerouslySetInnerHTML={{ __html: langTranslateValue(e.value.descriptions, store.accountStore) || '' }}
        //   />
        // );
        return {
            _id: e._id,
            text: [langTranslateValue(e.value.name, store.accountStore), e.assignedAt && toDateOnlyV1(e.assignedAt)]
                .filter(function (e) { return e; })
                .join(', '),
            titleLabel: langTranslateValue(e.value.name, store.accountStore),
            labelShort: (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', direction: 'column' },
                props.v2 ? (React.createElement(TextOverflow, { variant: props.v2 ? 'caption' : 'h6', style: __assign(__assign({}, (props.v2 && { fontSize: "0.52rem" })), (e.assignedAt && { color: theme.palette.primary.light })) }, langTranslateValue(e.value.name, store.accountStore) || '-')) : (React.createElement(Grid, { container: true, alignItems: "center", justify: "center", style: { width: 'initial' } },
                    React.createElement(TextOverflow, { variant: 'h6', style: __assign(__assign({}, (props.v2 && { fontSize: "0.52rem" })), (e.assignedAt && { color: theme.palette.primary.light })) }, langTranslateValue(e.value.name, store.accountStore) || '-'))),
                props.v2 && (e.assignedAt || (e.plannedPickupStartDate && e.plannedPickupEndDate)) && (React.createElement(Grid, { container: true, alignItems: "center", style: { justifyContent: 'center', margin: theme.spacing(0, 0, 1, 1) } },
                    React.createElement(Grid, { container: true, alignItems: "center", style: { width: 'initial', marginRight: 2 } },
                        React.createElement(HelpIconComponent, { style: { width: 14, height: 14, marginRight: 2, marginTop: -1 }, title: i18n.getResource(i18n.language, KEYS.APP, 'ETD') }),
                        React.createElement(TextOverflow, { title: i18n.getResource(i18n.language, KEYS.APP, 'ETD'), variant: 'caption', style: { fontSize: "0.5rem" } }, i18n.getResource(i18n.language, KEYS.APP, 'ETD_short'))),
                    React.createElement(TextOverflow, { variant: 'caption', style: __assign(__assign({ fontWeight: 800, maxWidth: "calc(100% - 36px)" }, (props.v2 && { fontSize: "0.5rem" })), (e.assignedAt && { color: theme.palette.primary.light })) }, [
                        e.plannedPickupStartDate && toDateOnlyV4(e.plannedPickupStartDate),
                        e.plannedPickupEndDate && toDateOnlyV4(e.plannedPickupEndDate),
                    ].join(' - ')))),
                !props.v2 && index === list.length - 1 ? (React.createElement(TextOverflow, { variant: 'h6', style: { fontWeight: 800 } },
                    React.createElement(OrderProgress, { order: order, onlyText: true }))) : null)),
            label: (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', direction: 'column' },
                React.createElement(Grid, { container: true, alignItems: "center", justify: "center", style: { width: 'initial' } },
                    React.createElement(TextOverflow, { variant: 'h6', style: __assign({ margin: 0 }, (e.assignedAt && { color: theme.palette.primary.light })) }, langTranslateValue(e.value.name, store.accountStore) || '-')),
                (e.assignedAt && (React.createElement(TextOverflow, { variant: 'caption', style: { fontSize: '0.5rem' } }, [langForm.t('form_input_name_assigned_at'), toDateOnlyV41(e.assignedAt)].join(' ')))) ||
                    null,
                (e.plannedPickupStartDate && e.plannedPickupEndDate && (React.createElement(Grid, { container: true, alignItems: "center", style: { width: 'initial' } },
                    React.createElement(Grid, { container: true, alignItems: "center", style: { width: 'initial', marginRight: 2 } },
                        React.createElement(HelpIconComponent, { style: { width: 14, height: 14, marginRight: 2 }, title: i18n.getResource(i18n.language, KEYS.APP, 'ETD') }),
                        React.createElement(TextOverflow, { title: i18n.getResource(i18n.language, KEYS.APP, 'ETD'), variant: 'caption' }, i18n.getResource(i18n.language, KEYS.APP, 'ETD_short'))),
                    React.createElement(TextOverflow, { variant: 'caption', primary: true, style: { fontWeight: 800 } }, [toDateOnlyV4(e.plannedPickupStartDate), toDateOnlyV4(e.plannedPickupEndDate)].join(' - '))))) ||
                    null,
                props.v2 || (e.assignedAt && index === list.length - 1) || index === list.length - 1 ? (React.createElement(TextOverflow, { variant: 'h6', style: { fontWeight: 800 } },
                    React.createElement(OrderProgress, { order: order, onlyText: true }))) : null)),
            active: e.assignedAt,
        };
    });
    if (!steps.length) {
        steps = [
            {
                _id: 1,
                text: 'sd',
                active: false,
            },
            {
                _id: 2,
                text: 'sd',
                active: false,
            },
            {
                _id: 3,
                text: 'sd',
                active: false,
            },
        ];
    }
    var lastActiveStep = steps.toReversed().find(function (e) { return e.active; });
    var moreProps = {};
    if (store.accountStore.currentCompany._id === (((_b = order.company) === null || _b === void 0 ? void 0 : _b._id) || ((_d = (_c = order.manager) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d._id))) {
        moreProps.onClick = onClick;
    }
    var additionalDocumentsComponentProps = {
        noData: (React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(5), flexDirection: 'column', alignItems: 'center' } },
            React.createElement(Typography, { variant: 'subtitle1', style: { margin: theme.spacing(2, 0), textAlign: 'center' } }, langForm.t('form_no_preparation_document_text')))),
        noDataImageStyle: { marginTop: theme.spacing(0) },
    };
    var DocumentsComponent = function (props) {
        return store.accountStore.isLoggedIn ? (React.createElement(OrderQuoteDocuments, __assign({}, props, additionalDocumentsComponentProps))) : (React.createElement(OrderQuoteDocumentsComponent, __assign({}, props, { state: {} }, additionalDocumentsComponentProps)));
    };
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var stepStyle = __assign(__assign({}, (isSmall ? { display: 'flex', justifyContent: 'center' } : {})), (props.v2 ? { height: 22 } : { height: 106 }));
    var index = steps.findIndex(function (e) { return current === e._id; }) + 1;
    var stepList = iconSelected({ steps: steps })(function () { return null; });
    var selected = stepList[index.toString()];
    return (React.createElement(React.Fragment, null,
        React.createElement(Steps, __assign({ stepStyle: stepStyle, steps: steps, config: props.config, order: order, isFocused: props.isFocused, activeIndex: current < 0 && list.length && list.length + 1 }, ((props.v2 && { v2: true }) || { v1: true }), { current: current, key: current }, moreProps, { onChange: props.onChange, loading: loading, isProgress: props.isProgress, disabled: disabled, content: function (p) { return (React.createElement(PreloadingComponent, null,
                React.createElement(PreparationStepForm, __assign({}, p)))); } })),
        (props.v2 && (React.createElement(TextOverflow, { variant: 'caption', style: __assign(__assign(__assign({}, (!(selected === null || selected === void 0 ? void 0 : selected.title) && { visibility: 'hidden' })), { textAlign: 'center', fontSize: '0.55rem', width: '100%', lineHeight: 0.8, display: 'block' }), (!props.v2 && { marginTop: -2, fontSize: '0.7rem', marginBottom: 6 })) }, (steps[index - 1] && steps[index - 1].labelShort) || (selected === null || selected === void 0 ? void 0 : selected.titleLabel) || (selected === null || selected === void 0 ? void 0 : selected.title) || 'empty'))) ||
            null,
        props.withDocuments ? (React.createElement(Grid, { container: true, style: { padding: theme.spacing(0, 2), minHeight: 226 } },
            React.createElement(DocumentsComponent, { isPreparation: true, isBatch: true, lastActiveStep: (_e = preparingStatuses.find(function (e) { return e._id === lastActiveStep._id; })) === null || _e === void 0 ? void 0 : _e.value._id, quote: {
                    order: order,
                }, config: props.config, parent: order._id, folder: "private/orders/" + order._id + "/preparationSteps/" }))) : null));
}));
