var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import OrderListItem from './OrderItemRecordMain';
import { inject } from 'mobx-react';
import OrderItemRecordHelp from './OrderItemRecordHelp';
import { OrderListItemProvider } from './storeListItem';
import { USER_ROLE_TOOLS } from '../../../../models/UserRoles';
function OrderItemRecord(_a) {
    var _b, _c;
    var record = _a.record, onChange = _a.onChange, listItemProps = _a.listItemProps, isDelete = _a.isDelete, onDeleteItem = _a.onDeleteItem, store = _a.store, props = __rest(_a, ["record", "onChange", "listItemProps", "isDelete", "onDeleteItem", "store"]);
    var canEdit = store.accountStore.currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.general) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.GENERAL_can_edit_company_data); });
    var isOwn = ((record.createdBy && record.createdBy._id) || record.createdBy) === store.accountStore.currentUser._id;
    var isAdmin = window.location.href.match('localhost:3002') || window.location.href.match('admin');
    return (React.createElement(React.Fragment, null,
        (!((_c = (_b = listItemProps === null || listItemProps === void 0 ? void 0 : listItemProps.defaultFilter) === null || _b === void 0 ? void 0 : _b.fields) === null || _c === void 0 ? void 0 : _c.find(function (e) { return ['to-do-list', 'withP44Issues'].includes(e.name); })) && (React.createElement(OrderItemRecordHelp, null))) ||
            null,
        React.createElement(OrderListItem, __assign({ store: store, config: props.config }, listItemProps, ((isDelete && (canEdit || isOwn || isAdmin) && { onDeleteItem: onDeleteItem }) || null), { record: record, onChange: onChange }))));
}
var OrderItemRecordComponent = function (props) {
    return (React.createElement(OrderListItemProvider, { defaultValue: { record: props.record }, config: props.config },
        React.createElement(OrderItemRecord, __assign({}, props))));
};
export default inject('store')(OrderItemRecordComponent);
