var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { useTranslation } from 'react-i18next';
import BaseTooltip from '../../../components/BaseTooltip';
import { browserStorage } from '../../../browserStorage';
import { KEYS } from '../../../i18n';
import OrderRouteMenuItem from '../../../components/Order/List/OrderRoutesMenuItem';
import { USER_ROLE_TOOLS } from '../../../models/UserRoles';
import { theme } from '../../../theme';
import BaseIconButton from '../../../components/BaseIconButton';
export var SignDocument = function (_a) {
    var _b, _c, _d, _e, _f, _g;
    var item = _a.item, store = _a.store, props = __rest(_a, ["item", "store"]);
    var langApp = useTranslation(KEYS.APP);
    var onCLick = function () {
        var _a;
        var API_URL = (((_a = props.config) === null || _a === void 0 ? void 0 : _a.URL) || window.APP_API_URL || '').replace(/graphql/, "ds/pdf-sign?token=" + browserStorage.getItem('token') + "&client_id=" + browserStorage.getItem('client_id') + "&upload=" + item._id);
        window.open(API_URL, langApp.t('sign_document'), 'location=yes,scrollbars=yes,status=yes,resizable=yes');
    };
    if (!item.needSign || !store.accountStore.currentUser._id || !props.config)
        return null;
    var canSign = (((_b = item.createdBy) === null || _b === void 0 ? void 0 : _b._id) !== store.accountStore.currentUser._id &&
        (item.createdBy.rolesV1 || []).find(function (e) { var _a; return (_a = e.tools.admin) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.ADMIN_payment_term_support); })) ||
        ((_e = (_d = (_c = props.quote) === null || _c === void 0 ? void 0 : _c.createdBy) === null || _d === void 0 ? void 0 : _d.company) === null || _e === void 0 ? void 0 : _e._id) === store.accountStore.currentCompany._id ||
        (((_g = (_f = props.quote) === null || _f === void 0 ? void 0 : _f.order) === null || _g === void 0 ? void 0 : _g.transportations) || []).find(function (e) {
            return e.routes.find(function (e) { return e.email.map(function (e) { return e.toLowerCase(); }).includes(store.accountStore.currentUser.email); });
        });
    if (!canSign)
        return null;
    var log = (item.logs || []).find(function (e) { return e.createdBy.company._id === store.accountStore.currentCompany._id; });
    if (log) {
        return null;
    }
    return (React.createElement(OrderRouteMenuItem, __assign({}, {
        handleClickOpen: onCLick,
        icon: (React.createElement(BaseTooltip, { title: langApp.t('sign_document') },
            React.createElement(BaseIconButton, { onClick: onCLick, color: 'primary', style: { padding: theme.spacing(1) } },
                React.createElement(AssignmentTurnedInIcon, null)))),
        label: langApp.t('sign_document'),
    })));
};
