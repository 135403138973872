var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React, { createContext, useReducer } from 'react';
import { MainFilterSortDir } from './types.enums';
import { TIME_OUT } from '../types';
import { checkIsDevEnv, getQueryParams } from '../../utils';
import { browserStorage } from '../../browserStorage';
import { i18n, KEYS } from '../../i18n';
import { createTableLocalStoreKey, googleTagManager } from '../../utils1';
import browserHistory from '../../browserHistory';
var baseLimit = 10;
var initialState = {
    loading: false,
    loaded: false,
    showAdvancedFilters: false,
    error: null,
    hasData: false,
    count: 0,
    data: [],
    filtersStats: null,
    filtersUpdated: 1,
    search: '',
    selected: [],
    searchFields: [],
    searchFieldsMatches: [],
    sortFields: [],
    hasFooterPagination: false,
    hasUserFilters: false,
    filter: {
        fields: [],
        sortBy: 'createdAt',
        sortDir: MainFilterSortDir.DESC,
        limit: baseLimit,
        page: 1,
        skip: 0,
    },
};
var BaseListStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = BaseListStore.Provider, Consumer = BaseListStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["START_LOAD_DATA"] = "START_LOAD_DATA";
    ACTIONS["FINISH_LOAD_DATA"] = "FINISH_LOAD_DATA";
    ACTIONS["LOAD_DATA_ERROR"] = "LOAD_DATA_ERROR";
    ACTIONS["CHANGE_FILTERS"] = "CHANGE_FILTERS";
    ACTIONS["SOFT_CHANGE_FILTERS"] = "SOFT_CHANGE_FILTERS";
    ACTIONS["TOGGLE_SELECT_ALL"] = "TOGGLE_SELECT_ALL";
    ACTIONS["TOGGLE_SELECT_ITEM"] = "TOGGLE_SELECT_ITEM";
    ACTIONS["DELETE_ITEM"] = "DELETE_ITEM";
    ACTIONS["UPDATE_ITEM"] = "UPDATE_ITEM";
    ACTIONS["ADD_ITEM"] = "ADD_ITEM";
})(ACTIONS || (ACTIONS = {}));
function jsonEqual(a, b) {
    return JSON.stringify(a) === JSON.stringify(b);
}
function isEquivalent(a, b) {
    // Create arrays of property names
    var aProps = Object.getOwnPropertyNames(a);
    var bProps = Object.getOwnPropertyNames(b);
    // If number of properties is different,
    // objects are not equivalent
    if (aProps.length != bProps.length) {
        return false;
    }
    for (var i = 0; i < aProps.length; i++) {
        var propName = aProps[i];
        // If values of same property are not equal,
        // objects are not equivalent
        if (a[propName] !== b[propName]) {
            return false;
        }
    }
    // If we made it this far, objects
    // are considered equivalent
    return true;
}
var addSearchFilter = function (fields, _searchFields, filter, value, opt) {
    if (fields === void 0) { fields = []; }
    if (_searchFields === void 0) { _searchFields = []; }
    if (opt === void 0) { opt = {}; }
    var oldFilterFields = fields.filter(function (e) { return typeof e.isSearch === 'undefined'; });
    var inUse = oldFilterFields.map(function (e) { return e.name; });
    var searchFields = _searchFields
        .filter(function (field) { return !inUse.includes(field.name || field) && value; })
        .map(function (name) { return (__assign({ name: name,
        value: value, isSearch: true }, ((opt.skipModelValidationFilterFields || []).includes(name) ? { skipModelValidation: true } : {}))); });
    if (opt.prevent && !searchFields.length)
        return 1;
    Object.assign(filter, {
        fields: __spread(oldFilterFields, searchFields),
    });
};
export var BaseLisProvider = function (_a) {
    var _b;
    var _c = _a.defaultFilter, defaultFilter = _c === void 0 ? {} : _c, _d = _a.variables, variables = _d === void 0 ? {} : _d, _e = _a.searchFields, searchFields = _e === void 0 ? [] : _e, _f = _a.searchFieldsMatches, searchFieldsMatches = _f === void 0 ? [] : _f, _g = _a.sortFields, sortFields = _g === void 0 ? ['createdAt'] : _g, query = _a.query, children = _a.children, useUrlQuery = _a.useUrlQuery, client = _a.client, onSelect = _a.onSelect, canSelect = _a.canSelect, onLoad = _a.onLoad, config = _a.config, isNotReloading = _a.isNotReloading, skipModelValidationFilterFields = _a.skipModelValidationFilterFields, mainProps = __rest(_a, ["defaultFilter", "variables", "searchFields", "searchFieldsMatches", "sortFields", "query", "children", "useUrlQuery", "client", "onSelect", "canSelect", "onLoad", "config", "isNotReloading", "skipModelValidationFilterFields"]);
    var urlQuery = (useUrlQuery && getQueryParams(location.href)) || {};
    var id = createTableLocalStoreKey(config.id || config.title)
        .replace(/ /gi, '-')
        .toLowerCase(); //id of filter
    var isSearch = location.href.includes('search=');
    var initialFilter = function (defaultFilter, noFromStorage) {
        // const filters = store.accountStore.currentUser.settings.filters.filter((e) => !e.id || e.id === id);
        var savedFilters = browserStorage.getUserItem(id);
        var filter = __assign(__assign({}, JSON.parse(JSON.stringify(initialState.filter))), JSON.parse(JSON.stringify(defaultFilter)));
        if (!config.ignoreUrlQuery &&
            !urlQuery.search &&
            !noFromStorage &&
            (!config.dontSaveFilters || config.filtersV1) &&
            savedFilters &&
            !isSearch) {
            try {
                Object.assign(filter, JSON.parse(savedFilters));
            }
            catch (e) {
                browserStorage.removeUserItem(id);
            }
        }
        if (!config.ignoreUrlQuery && urlQuery.search) {
            addSearchFilter(filter.fields, searchFields, filter, urlQuery.search, {
                prevent: 1,
                skipModelValidationFilterFields: skipModelValidationFilterFields,
            });
        }
        ['limit', 'page', 'skip'].forEach(function (e) {
            if (urlQuery[e]) {
                filter[e] = parseInt(urlQuery[e]);
            }
        });
        return filter;
    };
    var initState = __assign(__assign(__assign(__assign({}, JSON.parse(JSON.stringify(initialState))), (!config.ignoreUrlQuery && urlQuery)), JSON.parse(JSON.stringify({ searchFields: searchFields, sortFields: sortFields }))), { searchFieldsMatches: searchFieldsMatches, filter: initialFilter(defaultFilter) });
    var search = (_b = initState.filter.fields.find(function (e) { return e.isSearch; })) === null || _b === void 0 ? void 0 : _b.value;
    if (search) {
        Object.assign(initState, { search: search });
    }
    var isDefaultFilter = function (filter) {
        try {
            if (filter) {
                var isSame = jsonEqual(filter || state.filter, __assign(__assign({}, JSON.parse(JSON.stringify(initialState.filter))), JSON.parse(JSON.stringify(defaultFilter))));
                return isSame;
            }
            if (config.dontSaveFilters)
                return false;
            var initFilter = initialFilter(defaultFilter);
            var savedFilters = {};
            if (!config.dontSaveFilters && savedFilters) {
                try {
                    savedFilters = JSON.parse(browserStorage.getUserItem(id));
                }
                catch (e) {
                    browserStorage.removeUserItem(id);
                }
            }
            return (jsonEqual(state.filter, initFilter) &&
                !((config.dontSaveFilters && Object.keys(savedFilters).length > 0) || !config.dontSaveFilters));
        }
        catch (e) {
            return false;
        }
    };
    initState.hasUserFilters = !isDefaultFilter(initState.filter);
    var _h = __read(useReducer(function (state, action) {
        switch (action.type) {
            case ACTIONS.START_LOAD_DATA: {
                return __assign(__assign({}, state), { error: null, loading: true });
            }
            case ACTIONS.TOGGLE_SELECT_ITEM:
            case ACTIONS.TOGGLE_SELECT_ALL:
            case ACTIONS.DELETE_ITEM:
            case ACTIONS.ADD_ITEM:
            case ACTIONS.LOAD_DATA_ERROR:
            case ACTIONS.CHANGE_FILTERS:
            case ACTIONS.UPDATE_ITEM:
            case ACTIONS.FINISH_LOAD_DATA: {
                return __assign(__assign(__assign({}, state), action.payload), { loading: false });
            }
            case ACTIONS.SOFT_CHANGE_FILTERS: {
                var savedFilters = browserStorage.getUserItem(id) || {};
                var filterUpdates = action.payload ? action.payload.filter : {};
                var updates = {};
                try {
                    Object.assign(updates, JSON.parse(savedFilters));
                }
                catch (e) { }
                browserStorage.setUserItem(id, __assign(__assign({}, updates), filterUpdates));
                var updatedFilter = __assign(__assign({}, state), { filter: __assign(__assign({}, state.filter), filterUpdates) });
                return updatedFilter;
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _h[0], dispatch = _h[1];
    var loadDataWithoutLoading = function (filter, payload) { return __awaiter(void 0, void 0, void 0, function () {
        var _variables, result, res, data, count, filtersStats;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: 
                // await client.resetStore(); //TODO:need to clear only cache by query
                return [4 /*yield*/, checkIsDevEnv()];
                case 1:
                    // await client.resetStore(); //TODO:need to clear only cache by query
                    _c.sent();
                    _variables = JSON.parse(JSON.stringify(__assign(__assign({}, variables), { filter: __assign(__assign({}, state.filter), filter) })));
                    if (_variables && _variables.filter && _variables.filter.fields) {
                        _variables.filter.fields = _variables.filter.fields.map(function (e) {
                            if (e.value._id)
                                e.value = e.value._id;
                            if (Array.isArray(e.value))
                                e.value = e.value.map(function (d) {
                                    if (d._id)
                                        return d._id;
                                    if (d.value)
                                        return d.value;
                                    return d;
                                });
                            return e;
                        });
                    }
                    return [4 /*yield*/, client.query({
                            fetchPolicy: 'no-cache',
                            query: query,
                            variables: _variables,
                        })];
                case 2:
                    result = _c.sent();
                    res = JSON.parse(JSON.stringify(result.data[Object.keys(result.data)[0]]));
                    if (res.redirect) {
                        location.href = res.redirect;
                    }
                    return [4 /*yield*/, checkIsDevEnv()];
                case 3:
                    _c.sent();
                    if (onLoad) {
                        onLoad(res);
                    }
                    data = res.data, count = res.count, filtersStats = __rest(res, ["data", "count"]);
                    dispatch({
                        type: ACTIONS.FINISH_LOAD_DATA,
                        payload: Object.assign(__assign(__assign({}, (!state.hasData && {
                            hasData: count >
                                0 /*||
                            store.accountStore.currentUser.settings.filters.filter(
                              (e) => (!e.id || e.id === id) && e.value && e.value.length
                            ).length,*/,
                        })), { loaded: true, data: data,
                            count: count }), (!state.filtersStats && { filtersStats: filtersStats }) || null, (!state.loaded || (payload && !payload.loaded)) && {
                            hasFooterPagination: count > baseLimit || ((_b = (_a = _variables === null || _variables === void 0 ? void 0 : _variables.filter) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.find(function (e) { return ['to-do-ca-rating-list'].includes(e.name); })),
                        }),
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var softRefresh = function (filter) { return __awaiter(void 0, void 0, void 0, function () {
        var e_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, loadDataWithoutLoading(filter)];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 3];
                case 2:
                    e_1 = _a.sent();
                    console.log(e_1);
                    dispatch({
                        type: ACTIONS.LOAD_DATA_ERROR,
                        payload: { error: { message: e_1.message } },
                    });
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var loading = false;
    var loadData = function (filter, payload, noSave) { return __awaiter(void 0, void 0, void 0, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    if (loading)
                        return [2 /*return*/];
                    loading = true;
                    dispatch({
                        type: ACTIONS.START_LOAD_DATA,
                    });
                    return [4 /*yield*/, checkIsDevEnv(40000)];
                case 1:
                    _a.sent();
                    if (!noSave)
                        onSaveFilters(filter);
                    return [4 /*yield*/, loadDataWithoutLoading(filter, payload)];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 5];
                case 3:
                    e_2 = _a.sent();
                    console.log(e_2);
                    dispatch({
                        type: ACTIONS.LOAD_DATA_ERROR,
                        payload: { error: { message: e_2.message } },
                    });
                    return [3 /*break*/, 5];
                case 4:
                    loading = false;
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var onChangeFilter = function (filter, options) {
        var updateFilter = __assign(__assign({}, state.filter), filter);
        updateFilter = __assign(__assign({}, updateFilter), { fields: updateFilter.fields.filter(function (e) { return e.value; }) });
        if (filter.limit) {
            updateFilter.skip = 0;
            updateFilter.page = 1;
        }
        var payload = {
            // loaded: false,
            filter: updateFilter,
            hasUserFilters: !isDefaultFilter(updateFilter),
        };
        dispatch({
            type: ACTIONS.CHANGE_FILTERS,
            payload: payload,
        });
        var fields = updateFilter.fields, filters = __rest(updateFilter, ["fields"]);
        googleTagManager('event', 'izi_form_filters', __assign({ izi_form_id: id }, __spread(Object.keys(filters).map(function (e) {
            var _a;
            return (_a = {}, _a["izi_form_" + e] = filter[e], _a);
        }), (fields || []).map(function (e) {
            var _a;
            return (_a = {}, _a["izi_form_fields_" + e.name] = e.value, _a);
        })).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})));
        loadData(updateFilter, payload, true);
        onSaveFilters(updateFilter, options);
        return updateFilter;
    };
    var onSaveFilters = function (updateFilter, _a) {
        if (_a === void 0) { _a = {}; }
        var currentUser = _a.currentUser, defaultFilter = _a.defaultFilter, ev = _a.ev, options = __rest(_a, ["currentUser", "defaultFilter", "ev"]);
        try {
            if (updateFilter && (config.rememberFilters || !config.dontSaveFilters || options.forceSave)) {
                var filters = (currentUser && JSON.parse(JSON.stringify(currentUser.settings.filters))) || [];
                var defaultFilters = typeof defaultFilter === 'function' ? defaultFilter() : defaultFilter;
                try {
                    if (ev && ev.target.name === 'fields') {
                        var value_1 = ev.target.value;
                        var _loop_1 = function (i) {
                            var itemFilter = value_1[i];
                            if (defaultFilters &&
                                defaultFilters.fields &&
                                defaultFilters.fields.find(function (e) { return e.name === itemFilter.name; })) {
                                return "continue";
                            }
                            var item = filters.find(function (e) { return (!e.id || e.id === id) && e.name === itemFilter.name; });
                            if (!item) {
                                filters.push({
                                    id: id,
                                    name: itemFilter.name,
                                    value: itemFilter.value.toString(),
                                    type: Array.isArray(itemFilter.value) ? 'array' : typeof itemFilter.value,
                                });
                            }
                            else {
                                item.value = itemFilter.value.toString();
                            }
                        };
                        for (var i = 0; i < value_1.length; i++) {
                            _loop_1(i);
                        }
                        var _loop_2 = function (i) {
                            if (!value_1.find(function (e) { return e.name === state.filter.fields[i].name; })) {
                                filters = filters.filter(function (e) { return !(e.id === id && e.name === state.filter.fields[i].name); });
                            }
                        };
                        for (var i = 0; i < state.filter.fields.length; i++) {
                            _loop_2(i);
                        }
                    }
                }
                catch (e) { }
                if (mainProps.onSaveFilters) {
                    mainProps.onSaveFilters({ filters: filters, updateFilter: updateFilter, options: options });
                }
                if (config.rememberFilters) {
                    currentUser.updateSettings({
                        filters: filters,
                    }, i18n.getResource(i18n.language, KEYS.APP, 'main_filters'));
                }
                else if (!config.dontSaveFilters || options.forceSave) {
                    browserStorage.setUserItem(id, JSON.stringify(updateFilter));
                }
            }
        }
        catch (e) {
            console.log('onSaveFilters', e);
        }
    };
    var delay;
    var onSearch = function (e, timeout) {
        if (timeout === void 0) { timeout = TIME_OUT; }
        return __awaiter(void 0, void 0, void 0, function () {
            var _a, name, value, updates, filter;
            var _b;
            return __generator(this, function (_c) {
                if (delay)
                    clearTimeout(delay);
                _a = e.target, name = _a.name, value = _a.value;
                updates = (_b = {}, _b[name] = value, _b);
                filter = JSON.parse(JSON.stringify(state.filter));
                filter.page = 1;
                filter.skip = 0;
                filter.limit = 10;
                if (name === 'search') {
                    if (addSearchFilter(state.filter.fields, state.searchFields, filter, value, { skipModelValidationFilterFields: skipModelValidationFilterFields }) > 0)
                        console.warn("No search for " + value);
                    googleTagManager('event', 'izi-search', { search_term: value, value: id });
                    // const oldFilterFields: MainFilterField[] = state.filter.fields.filter(
                    //   (e: MainFilterField) => typeof e.isSearch === "undefined"
                    // );
                    // const inUse: string[] = oldFilterFields.map(
                    //   (e: MainFilterField) => e.name
                    // );
                    //
                    // const searchFields = state.searchFields
                    //   .filter((field: string) => !inUse.includes(field) && value)
                    //   .map((name: string) => ({ name, value, isSearch: true }));
                    // if (!searchFields.length) return console.warn(`No search for ${value}`);
                    // Object.assign(filter, {
                    //   fields: [...oldFilterFields, ...searchFields],
                    // });
                    return [2 /*return*/, (delay = setTimeout(function () {
                            var payload = __assign(__assign({}, updates), { hasUserFilters: !isDefaultFilter(filter), filter: filter });
                            dispatch({
                                type: ACTIONS.CHANGE_FILTERS,
                                payload: payload,
                            });
                            loadData(filter, payload);
                        }, timeout))];
                }
                dispatch({
                    type: ACTIONS.CHANGE_FILTERS,
                    payload: __assign(__assign({}, updates), { hasUserFilters: !isDefaultFilter(filter), filter: filter }),
                });
                loadData(filter);
                return [2 /*return*/];
            });
        });
    };
    var onResetFilter = function (e, options) {
        var urlQuery = (useUrlQuery && getQueryParams(location.href)) || {};
        if (useUrlQuery && urlQuery.search) {
            browserHistory.push(location.origin + location.pathname);
            return;
        }
        var updateFilter = initialFilter(defaultFilter, true);
        updateFilter.fields = updateFilter.fields.filter(function (e) { return !e.temp; });
        var payload = {
            loaded: false,
            search: '',
            filter: updateFilter,
            hasUserFilters: !isDefaultFilter(updateFilter),
        };
        dispatch({
            type: ACTIONS.CHANGE_FILTERS,
            payload: payload,
        });
        loadData(updateFilter, payload);
        onSaveFilters(updateFilter, __assign(__assign({}, (options || {})), { forceSave: 1 }));
    };
    var updateOnDelete = function (items) { return __awaiter(void 0, void 0, void 0, function () {
        var page;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    page = state.filter.page;
                    if (state.filter.limit * state.filter.page >= state.count && state.count % state.filter.limit === items.length) {
                        page -= 1;
                        if (!page)
                            page = 1;
                    }
                    return [4 /*yield*/, softRefresh(__assign(__assign({}, state.filter), { page: page }))];
                case 1:
                    _a.sent();
                    dispatch({
                        type: ACTIONS.SOFT_CHANGE_FILTERS,
                        payload: {
                            filter: {
                                page: page,
                            },
                        },
                    });
                    return [2 /*return*/];
            }
        });
    }); };
    var onDeleteItem = function (item) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (isNotReloading && item) {
                updateOnDelete([item._id]);
            }
            else {
                loadData();
            }
            return [2 /*return*/];
        });
    }); };
    var onMultiDeletion = function (items) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (isNotReloading && items && items.length) {
                updateOnDelete(items);
            }
            else {
                loadData();
            }
            return [2 /*return*/];
        });
    }); };
    var onAddItem = function (item) {
        if (isNotReloading && item) {
            dispatch({
                type: ACTIONS.ADD_ITEM,
                payload: {
                    data: __spread([item], state.data),
                },
            });
        }
        else {
            loadData();
        }
    };
    var onToggleAllFilters = function () {
        dispatch({
            type: ACTIONS.CHANGE_FILTERS,
            payload: {
                showAdvancedFilters: !state.showAdvancedFilters,
            },
        });
    };
    var onFreshFilter = function () {
        dispatch({
            type: ACTIONS.CHANGE_FILTERS,
            payload: {
                filtersUpdated: Date.now(),
            },
        });
    };
    var onUpdateItem = function (item) {
        dispatch({
            type: ACTIONS.UPDATE_ITEM,
            payload: {
                data: state.data.map(function (el) {
                    if (el._id === item._id) {
                        return Object.assign(el, item);
                    }
                    return el;
                }),
            },
        });
    };
    var onToggleAllSelection = function () {
        var selected = state.selected.length
            ? []
            : canSelect
                ? state.data.filter(function (e) { return canSelect(e); }).map(function (e) { return e._id; })
                : state.data.map(function (e) { return e._id; });
        dispatch({
            type: ACTIONS.TOGGLE_SELECT_ALL,
            payload: {
                selected: selected,
            },
        });
        if (onSelect)
            onSelect(selected);
    };
    var onToggleSelection = function (_id) {
        var selected = state.selected.includes(_id)
            ? state.selected.filter(function (id) { return id !== _id; })
            : __spread(state.selected, [_id]);
        dispatch({
            type: ACTIONS.TOGGLE_SELECT_ITEM,
            payload: {
                selected: selected,
            },
        });
        if (onSelect)
            onSelect(selected);
    };
    var value = {
        state: state,
        onFreshFilter: onFreshFilter,
        isDefaultFilter: isDefaultFilter,
        onUpdateItem: onUpdateItem,
        onAddItem: onAddItem,
        loadData: loadData,
        onChangeFilter: onChangeFilter,
        onToggleAllFilters: onToggleAllFilters,
        onResetFilter: onResetFilter,
        onSaveFilters: onSaveFilters,
        onDeleteItem: onDeleteItem,
        onToggleSelection: onToggleSelection,
        onToggleAllSelection: onToggleAllSelection,
        onSearch: onSearch,
        softRefresh: softRefresh,
        onMultiDeletion: onMultiDeletion,
        defaultFilter: function () { return JSON.parse(JSON.stringify(defaultFilter)); },
    };
    return React.createElement(Provider, { value: value }, children);
};
export var withBaseListStore = function (Component) {
    return function (props) { return (React.createElement(Consumer, null, function (storeProps) { return React.createElement(Component, __assign({}, __assign(__assign({}, props), storeProps))); })); };
};
