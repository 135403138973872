var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { TextField, useTheme } from '@material-ui/core';
import { Field, useField } from 'formik';
import InputTitle from './InputTitle';
import { HelpIconComponent } from '../HelpIcon';
import clsx from 'clsx';
import { TextOverflow } from '../TextOverflow';
import { useStyles } from './styles';
import BaseIconButton from '../BaseIconButton';
export var FormikTextFieldForPassword = function (props) {
    var _a;
    var _b = __read(React.useState(false), 2), showPassword = _b[0], setValues = _b[1];
    var theme = useTheme();
    var handleClickShowPassword = function () {
        setValues(!showPassword);
    };
    var _onChange = function (e) {
        helpers.setValue(e.target.value);
        if (onChange)
            onChange(e);
    };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    var classes = useStyles();
    var _c = props.helperText, helperText = _c === void 0 ? '' : _c, _d = props.fullWidth, fullWidth = _d === void 0 ? true : _d, _e = props.margin, margin = _e === void 0 ? 'dense' : _e, label = props.label, name = props.name, _f = props.disabled, disabled = _f === void 0 ? false : _f, onChange = props.onChange;
    var style = __assign({ marginTop: 0, marginBottom: 0 }, (props.v3 && { marginTop: theme.spacing(0.5) }));
    if (props.style)
        Object.assign(style, props.style);
    var _g = __read(useField(props), 3), field = _g[0], meta = _g[1], helpers = _g[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var errorText = touchedError || helperText;
    var warningText = !errorText ? props.warning : null;
    return (React.createElement(Field, __assign({ id: props.id || (props.title || 'text-field-input').toString().replace(/ /gi, '').toLowerCase(), inputProps: { maxLength: 1000 }, component: TextField }, field, { fullWidth: fullWidth, margin: margin, label: label, name: name, type: showPassword ? 'text' : 'password', disabled: disabled, onChange: _onChange, className: clsx((_a = {}, _a[classes.baseErrorV1] = props.v3 && errorText, _a[classes.baseV2] = props.v3, _a)), InputProps: {
            name: name,
            endAdornment: (React.createElement(InputAdornment, { position: "end" },
                React.createElement(BaseIconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword }, showPassword ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null)))),
        }, style: style, error: !!touchedError || warningText, helperText: (errorText && React.createElement(TextOverflow, { v2: true }, errorText)) ||
            (warningText && React.createElement(TextOverflow, { warn: true }, warningText)) })));
};
export var FormikTextFieldForPasswordV1 = React.forwardRef(function (props, ref) {
    var _a = __read(React.useState(false), 2), showPassword = _a[0], setValues = _a[1];
    var _b = props.helperText, helperText = _b === void 0 ? '' : _b, onChange = props.onChange, _c = props.fullWidth, fullWidth = _c === void 0 ? true : _c, _d = props.variant, variant = _d === void 0 ? 'standard' : _d, title = props.title, warning = props.warning, inputProps = __rest(props, ["helperText", "onChange", "fullWidth", "variant", "title", "warning"]);
    var margin = props.margin || 'dense';
    var classes = useStyles();
    var _e = __read(useField(props), 3), field = _e[0], meta = _e[1], helpers = _e[2];
    var error = meta.error, touched = meta.touched;
    var touchedError = touched && error;
    var _onChange = function (e) {
        helpers.setValue(e.target.value);
        if (onChange)
            onChange(e);
    };
    var onKeyDown = function (e) {
        if (e.key === 'Enter') {
            if (props.onEnter)
                props.onEnter(e);
        }
        if (props.onKeyDown)
            props.onKeyDown(e);
    };
    var handleClickShowPassword = function () {
        setValues(!showPassword);
    };
    var handleMouseDownPassword = function (event) {
        event.preventDefault();
    };
    var titlePlace = inputProps.placeholder || (typeof title === 'string' && title) || null;
    var InputProps = __assign(__assign({}, inputProps.InputProps), { endAdornment: (React.createElement(InputAdornment, { position: "end" },
            React.createElement(BaseIconButton, { "aria-label": "toggle password visibility", onClick: handleClickShowPassword, onMouseDown: handleMouseDownPassword }, showPassword ? React.createElement(Visibility, null) : React.createElement(VisibilityOff, null)))) });
    if (inputProps.help && !InputProps.startAdornment) {
        Object.assign(InputProps, {
            startAdornment: React.createElement(HelpIconComponent, { title: inputProps.help }),
        });
    }
    else {
        Object.assign(InputProps, { title: titlePlace });
    }
    var errorText = touchedError || helperText;
    var style = { marginTop: 0, marginBottom: 0 };
    var warningText = !errorText ? props.warning : null;
    if (props.style)
        Object.assign(style, props.style);
    return (React.createElement(React.Fragment, null,
        React.createElement(InputTitle, __assign({}, props)),
        React.createElement(TextField, __assign({ id: props.id || (props.title || 'text-field-input').toString().replace(/ /gi, '').toLowerCase(), ref: ref, onKeyDown: onKeyDown, autoComplete: "off", inputProps: { maxLength: 1000 } }, inputProps, { placeholder: titlePlace, variant: variant, margin: margin, fullWidth: fullWidth }, field, props, { type: showPassword ? 'text' : 'password', className: clsx(props.className, warningText ? classes.rootWarning : null), InputProps: InputProps, style: style, error: !!touchedError || warningText, onChange: _onChange, helperText: (errorText && React.createElement(TextOverflow, { v2: true }, errorText)) ||
                (warningText && React.createElement(TextOverflow, { warn: true }, warningText)) }))));
});
