import { Grid } from '@material-ui/core';
import { dateViewFormat } from '../../../DateView';
import { TimacomIcon } from '../../../icons';
import clsx from 'clsx';
import React from 'react';
import { withOrderListItemStore } from './storeListItem';
import { useTranslation } from 'react-i18next';
import BookmarkIcon from '@material-ui/icons/Bookmark';
import { KEYS } from '../../../../i18n';
import { useSettingsStyles } from '../../../Settings/styles';
import { useIconStyles } from '../../../../styles';
import { OrderStatus } from '../../../../models';
import BaseTooltip from '../../../BaseTooltip';
import { theme } from '../../../../theme';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BaseChip from '../../../BaseChip';
import { inject } from 'mobx-react';
import PreloadingComponent from '../../../PreloadingComponent';
import SectionHistory from '../../../SectionDetails/SectionHistory';
import RoomIcon from '@material-ui/icons/Room';
import ErrorIcon from '@material-ui/icons/Error';
import { toDateOnly, toDateTime } from '../../../../utils';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
        // opacity: 0.1,
        },
    });
});
var OrderItemRecordHelp = function (props) {
    var _a, _b, _c;
    var _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langSettings = useTranslation(KEYS.SETTINGS);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var classes = useSettingsStyles();
    var classesNames = useStyles();
    var iconStyles = useIconStyles();
    var record = props.orderListItemProps.state.record;
    if (!props.store.accountStore.isLoggedIn) {
        return null;
    }
    var log;
    var p44Log;
    try {
        log = JSON.parse(record.sync_logs[0].changes);
    }
    catch (e) { }
    try {
        p44Log = JSON.parse((_d = record.latestQuote) === null || _d === void 0 ? void 0 : _d.p44ShipmentLog.changes);
    }
    catch (e) { }
    var toDay = toDateOnly();
    var timeStart = Date.now();
    var startTime = ((_e = record.transportations) === null || _e === void 0 ? void 0 : _e.length) && ((_f = record.transportations[0]) === null || _f === void 0 ? void 0 : _f.startTime[0]);
    if (startTime)
        startTime = toDateTime(toDay, startTime);
    var endTime = ((_g = record.transportations) === null || _g === void 0 ? void 0 : _g.length) && ((_h = record.transportations[0]) === null || _h === void 0 ? void 0 : _h.endTime[0]);
    if (endTime)
        endTime = toDateTime(toDay, endTime);
    return (React.createElement(React.Fragment, null,
        (record.isTest && (React.createElement(BaseTooltip, { title: langOrderForm.t('is_test_order_help') },
            React.createElement("div", { className: classes.button, style: { position: 'absolute', left: 54, top: -6, zIndex: 1, width: 0 } },
                React.createElement(BaseChip, { style: { color: theme.palette.common.white }, size: "small", label: "" + langOrderForm.t('is_test'), clickable: true, color: "primary" }))))) ||
            null,
        record.generatedAt && (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', position: 'absolute', left: 40, top: 0, zIndex: 1, maxHeight: 22 } },
            React.createElement(BaseTooltip, { title: "Faked. Generated at " + dateViewFormat(record.generatedAt, 1) },
                React.createElement(BookmarkIcon, { color: 'primary' })))),
        React.createElement(Grid, { container: true, alignItems: 'center', className: clsx('order-status-icons', classesNames.root), style: {
                width: 'initial',
                position: 'absolute',
                right: 0,
                top: -2,
                zIndex: 1,
                maxHeight: 22,
            } },
            (((_j = record.latestQuote) === null || _j === void 0 ? void 0 : _j._id) &&
                record.status === OrderStatus.ApprovedQuote &&
                (((_k = record.forwarders) === null || _k === void 0 ? void 0 : _k.find(function (e) { var _a; return ((_a = e.company) === null || _a === void 0 ? void 0 : _a._id) === props.store.accountStore.currentCompany._id; })) ||
                    record.createdBy.company._id === props.store.accountStore.currentCompany._id) &&
                ((!record.transportations[0].inTransitAt &&
                    record.transportations[0].startDate === toDay &&
                    timeStart >= startTime + 15 * 60 * 1000 &&
                    timeStart <= startTime + 30 * 60 * 1000) ||
                    (!record.transportations[0].unloadedAt &&
                        record.transportations[0].endDate === toDay &&
                        timeStart >= endTime + 15 * 60 * 1000 &&
                        timeStart <= endTime + 30 * 60 * 1000)) && (React.createElement(BaseTooltip, { title: langSettings.t('settings_notify_admin_order_booked_not_arrived_help') },
                React.createElement(ErrorIcon, { color: "primary", style: { marginRight: 0, zIndex: 1, width: 22 } })))) ||
                null,
            (((_l = record.latestQuote) === null || _l === void 0 ? void 0 : _l.p44ShipmentLog) && ((_m = record.forwarders) === null || _m === void 0 ? void 0 : _m.find(function (e) { var _a; return ((_a = e.company) === null || _a === void 0 ? void 0 : _a._id) === props.store.accountStore.currentCompany._id; })) && (React.createElement("span", { style: { marginRight: theme.spacing(1) } },
                React.createElement(PreloadingComponent, null,
                    React.createElement(SectionHistory, { v3: true, config: props.config, filters: {
                            fields: [
                                { name: 'orders', value: [record._id] },
                                { name: 'action', value: ['P44_SYNC'] },
                            ],
                        }, icon: React.createElement(RoomIcon, { className: clsx(iconStyles.iconTimacom, (_a = {},
                                _a[iconStyles.iconTimacomSuccess] = (_o = p44Log === null || p44Log === void 0 ? void 0 : p44Log.result) === null || _o === void 0 ? void 0 : _o.mapUrl,
                                _a[iconStyles.iconTimacomError] = !((_p = p44Log === null || p44Log === void 0 ? void 0 : p44Log.result) === null || _p === void 0 ? void 0 : _p.mapUrl),
                                _a)) }), record: record, order: record }))))) ||
                (record.canP44Sync && ((_q = record.latestQuote) === null || _q === void 0 ? void 0 : _q.approvedAt) && (React.createElement("span", { style: { marginRight: 5 } },
                    React.createElement(RoomIcon, { className: clsx(iconStyles.iconTimacom, (_b = {},
                            _b[iconStyles.iconTimacomSuccess] = (_r = p44Log === null || p44Log === void 0 ? void 0 : p44Log.result) === null || _r === void 0 ? void 0 : _r.mapUrl,
                            _b[iconStyles.iconTimacomError] = !((_s = p44Log === null || p44Log === void 0 ? void 0 : p44Log.result) === null || _s === void 0 ? void 0 : _s.mapUrl),
                            _b)) })))) ||
                null,
            (log && (React.createElement(BaseTooltip, { title: langOrderList
                    .t('in_progress_has_timacom_sync')
                    .replace(/_X_/, dateViewFormat(record.sync_logs[0].createdAt, 1))
                    .replace(/_XX_/, log.status || 'NA'), style: { marginRight: 4 } },
                React.createElement("span", null,
                    React.createElement(TimacomIcon, { className: clsx(iconStyles.iconTimacom, (_c = {},
                            _c[iconStyles.iconTimacomSuccess] = log.status === 'STORED',
                            _c[iconStyles.iconTimacomError] = log.status !== 'STORED',
                            _c)) }))))) ||
                null)));
};
export default withOrderListItemStore(inject('store')(OrderItemRecordHelp));
