var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { CircularProgress, Grid, LinearProgress, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { withRouter } from 'react-router';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { inject } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../i18n';
import { checkIsDevEnv, getQueryParams, toDateOnly } from '../../utils';
import { BaseSelectV1, TextOverflow } from '../../components';
import { HelpIconComponent } from '../../components/HelpIcon';
import { theme } from '../../theme';
import { withBaseGlobalSocketStoreActiveClient } from '../../components/Socket/global/store';
import { OfferTimeBookingEventsComponentSocket } from '../../components/Socket/OfferTimeBooking/Events';
import { withOfferTimeBookingStore } from '../../components/Socket/OfferTimeBooking/store';
import browserHistory from '../../browserHistory';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { withOrderListItemStore } from '../../components/Order/List/Customer/storeListItem';
import { useAnimationStyles } from '../../styles/main';
import { SOCKET_CUSTOM_EVENT_NAME } from '../../components/Socket/types';
export var useStyles = makeStyles(function (theme) {
    return createStyles({
        disabledV1: {
            background: '#80808038',
        },
        rootNotSelected: {
            border: "1px solid " + theme.palette.error.main + "!important",
            '& .MuiSvgIcon-root,h6': {
                color: theme.palette.error.main + "!important",
            },
        },
        root: {
            border: "1px solid #BAB8B8",
            fontFamily: 'sans-serif',
            borderRadius: 4,
            cursor: 'pointer',
            padding: theme.spacing(0.5, 1),
            // marginRight: theme.spacing(1),
            marginBottom: theme.spacing(1),
            '&:hover': {
                backgroundColor: theme.palette.primary.light,
                color: theme.palette.common.white,
            },
        },
        root1: {
            border: "1px solid #BAB8B8",
            borderRadius: theme.spacing(0.5),
            marginBottom: 0,
            cursor: 'pointer',
            padding: theme.spacing(0, 1),
            '&:hover': {},
        },
        rootMini: {
            position: 'relative',
            // zIndex: 2,
            '& h6': {
                fontSize: '0.9rem',
                lineHeight: 1,
            },
            minHeight: 23,
            marginRight: theme.spacing(0),
            minWidth: 28,
            padding: theme.spacing(0),
        },
        rootPrimary: {
            boxSizing: 'border-box',
            padding: '0 5px',
            width: '50px',
            display: 'flex',
            alignItems: 'center',
            margin: '0 3px',
        },
        rootPrimaryNotDisabled: {
            borderColor: theme.palette.primary.main,
        },
        active: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.common.white,
        },
        disabled: {
            opacity: 0.8,
            pointerEvents: 'none',
        },
        selected: {
            border: "1px solid " + theme.palette.primary.light,
            color: theme.palette.primary.light,
            borderRadius: 3,
            padding: theme.spacing(0.5, 1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            paddingTop: 3,
            paddingBottom: 3,
        },
    });
});
var LoadingUnLoadingTimeComponentC = function (_a) {
    var _b, _c, _d;
    var _e, _f, _g;
    var day = _a.day, route = _a.route, title = _a.title, transportation = _a.transportation, onChange = _a.onChange, keyVal = _a.keyVal, startFrom = _a.startFrom, endTo = _a.endTo, disabled = _a.disabled, is_mini = _a.is_mini, noTransport = _a.noTransport, warehouseStore = _a.store.warehouseStore, props = __rest(_a, ["day", "route", "title", "transportation", "onChange", "keyVal", "startFrom", "endTo", "disabled", "is_mini", "noTransport", "store"]);
    var _h = __read(React.useState(false), 2), loading = _h[0], setLoading = _h[1];
    var _j = __read(React.useState(false), 2), loaded = _j[0], setLoaded = _j[1];
    var _k = __read(React.useState(false), 2), modalOpen = _k[0], setModalOpen = _k[1];
    var _l = __read(React.useState([]), 2), time = _l[0], setTime = _l[1];
    var _m = __read(React.useState(false), 2), clicked = _m[0], setClicked = _m[1];
    var classes = useStyles();
    var selectedTime = transportation[keyVal];
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var parent = React.useRef(null);
    var startTime = transportation['startTime'].length && transportation['startTime'][0];
    var endTime = transportation['endTime'].length && transportation['endTime'][0];
    var classesAnimation = useAnimationStyles();
    var onToggleTime = function (e, value) { return __awaiter(void 0, void 0, void 0, function () {
        var _time;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _time = value || e.target.value;
                    setLoading(true);
                    return [4 /*yield*/, onChange((_a = {
                                _id: transportation._id
                            },
                            _a[keyVal] = (Array.isArray(transportation[keyVal]) && transportation[keyVal].includes(_time) && []) || [_time],
                            _a))];
                case 1:
                    _b.sent();
                    return [4 /*yield*/, checkIsDevEnv()];
                case 2:
                    _b.sent();
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        var params = getQueryParams(props.location.search);
        if (params[keyVal]) {
            onToggleTime({ target: {} }, params[keyVal]);
            delete params[keyVal];
            var newPath = props.location.pathname +
                "?" +
                Object.keys(params)
                    .map(function (e) { return e + "=" + params[e]; })
                    .join('&');
            browserHistory.push(newPath);
        }
    }, [props.location.search]);
    React.useEffect(function () {
        var _a;
        if (disabled || props.is_review_offer || loading || warehouseStore.isLoading || !clicked)
            return;
        setLoading(true);
        if ((_a = route === null || route === void 0 ? void 0 : route.origin) === null || _a === void 0 ? void 0 : _a.warehouse) {
            var filter = {
                fields: [
                    { name: 'transportation', value: transportation },
                    { name: 'startFrom', value: startFrom },
                    { name: 'endTo', value: endTo },
                    { name: 'keyVal', value: keyVal },
                    { name: 'orderExclude', value: transportation.order._id },
                    // { name: 'location', value: route.origin._id },
                    { name: 'warehouse', value: route.origin.warehouse._id },
                    { name: 'day', value: day },
                ],
            };
            if (clicked) {
                warehouseStore.loadTimeToBook(filter).then(function (times) { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (times)
                                    setListTimes(times);
                                return [4 /*yield*/, checkIsDevEnv()];
                            case 1:
                                _a.sent();
                                setLoaded(true);
                                setLoading(false);
                                return [2 /*return*/];
                        }
                    });
                }); });
            }
            else {
                setLoaded(true);
                setLoading(false);
            }
        }
        else {
            var times = [];
            var end = (endTo && parseInt(endTo)) || 25;
            for (var i = startFrom || 0; i < end; i++) {
                times.push("" + (i < 10 ? '0' : '') + i + ":00");
            }
            setListTimes(times);
            setLoaded(true);
            setLoading(false);
        }
    }, [route, disabled, props.is_review_offer, clicked]);
    var eventsToRefresh = ['startDate', 'startTime'].includes(keyVal)
        ? [SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT_BOOKED, SOCKET_CUSTOM_EVENT_NAME.QUOTE_LOADING_AT_CHANGED]
        : [SOCKET_CUSTOM_EVENT_NAME.QUOTE_UNLOADING_AT_BOOKED, SOCKET_CUSTOM_EVENT_NAME.QUOTE_UNLOADING_AT_CHANGED];
    var hasUpdates = eventsToRefresh.includes((_g = (_f = (_e = props.orderListItemProps) === null || _e === void 0 ? void 0 : _e.state) === null || _f === void 0 ? void 0 : _f.lastUpdates) === null || _g === void 0 ? void 0 : _g.event);
    if (props.is_review_offer) {
        return (React.createElement(React.Fragment, null,
            title ? (React.createElement(TextOverflow, { variant: 'caption', style: { marginLeft: theme.spacing(1) } }, title)) : null,
            React.createElement(Grid, { container: true, className: clsx((_b = {},
                    _b[classesAnimation.pulse] = hasUpdates,
                    _b)), style: { zIndex: 1, position: 'relative', width: 'initial', marginLeft: theme.spacing(1) }, id: "izi_TimeSelected" },
                !selectedTime.length ? React.createElement(TextOverflow, null, langOfferForm.t('offer_form_no_time')) : null,
                __spread(selectedTime).sort(function (a, b) { return parseFloat(a.toString().replace(':', '.')) - parseFloat(b.toString().replace(':', '.')); })
                    .map(function (t) { return (React.createElement(TextOverflow, { key: t, id: "izi_time_selected_" + t, variant: 'h6', className: classes.selected, style: { margin: 0 } }, t)); }))));
    }
    var setListTimes = function (times) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (selectedTime && selectedTime[0] && !times.includes(selectedTime[0]))
                        times.push(selectedTime[0]);
                    setTime((times || []).sort(function (a, b) { return (a > b ? 1 : -1); }));
                    return [4 /*yield*/, checkIsDevEnv()];
                case 1:
                    _a.sent();
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var optionsTimes = time;
    if (transportation.startDate === transportation.endDate) {
        if (keyVal === 'endTime') {
            if (startTime) {
                optionsTimes = optionsTimes.filter(function (e) { return e > startTime; });
            }
        }
        else {
            if (endTime) {
                optionsTimes = optionsTimes.filter(function (e) { return e < endTime; });
            }
        }
    }
    if (loaded && !optionsTimes.length && !loading) {
        if (is_mini) {
            if (noTransport) {
                return React.createElement(HelpIconComponent, { error: true, title: langOfferForm.t('offer_no_time_to_select_no_transport') });
            }
            return React.createElement(HelpIconComponent, { error: true, title: langOfferForm.t('offer_no_time_to_select') });
        }
        return (React.createElement(TextOverflow, { v2: true, style: { marginTop: 10 }, id: "izi_TimeSelectedNoToSelect" }, langOfferForm.t(noTransport ? 'offer_no_time_to_select_no_transport' : 'offer_no_time_to_select')));
    }
    var keyValDate = keyVal === 'endTime' ? 'endDate' : 'startDate';
    var warnings = keyVal === 'endTime' &&
        transportation.endTime[0] &&
        transportation.startTime[0] &&
        transportation.startDate === transportation.endDate &&
        transportation.endTime[0] <= transportation.startTime[0] &&
        (is_mini ? (React.createElement(HelpIconComponent, { error: true, title: langOfferForm.t('offer_incorrect_time') })) : (React.createElement(TextOverflow, { v2: true, style: { marginBottom: 14 } }, langOfferForm.t('offer_incorrect_time'))));
    return (React.createElement(Grid, { ref: parent, container: true, alignItems: "center", style: __assign(__assign(__assign({ zIndex: 1, position: 'relative', width: 'initial', margin: theme.spacing(0, 1) }, (props.tooltipStyle || {})), (is_mini && { margin: theme.spacing(0.5) })), (props.v21 && { margin: theme.spacing(0, 0.5) })), className: clsx((_c = {},
            _c[classesAnimation.pulse] = hasUpdates,
            _c[classes.disabled] = disabled,
            _c)) }, React.createElement(React.Fragment, null,
        React.createElement(BaseSelectV1, { onClose: function () {
                setClicked(false);
                setModalOpen(false);
            }, id: ['izi_booking_popup_', keyValDate, keyVal].join('_'), variant: 'outlined', defaultValue: selectedTime, title: React.createElement(React.Fragment, null,
                React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', style: { width: 'initial' } },
                    React.createElement(TextOverflow, { style: { maxWidth: "calc(100% - " + (loading ? '50px' : '0') + ")" } }, langOfferForm.t(keyVal !== 'endTime' ? 'offer_loading_time_assigning' : 'offer_un_loading_time_assigning')),
                    React.createElement(HelpIconComponent, { title: langOfferForm.t('offer_form_booking_time'), style: { marginLeft: 4 } })),
                loading && !optionsTimes.length ? (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center', style: { width: 'initial' } },
                    React.createElement(LinearProgress, { color: "primary", style: { width: '110px' } }))) : null), titleStyle: { width: '75%' }, className: clsx((_d = {}, _d[classes.disabled] = disabled, _d)), style: { width: 'initial', minWidth: 86, zIndex: 9999 }, actionOnInit: function (_a) {
                var _b, _c, _d;
                var _e;
                var onToggle = _a.onToggle, open = _a.open;
                return (React.createElement(Grid, __assign({ id: ['izi_booking_btn_', keyValDate, keyVal].join('_') }, (_b = {},
                    _b['data-title-1'] = [
                        i18n.getResource(i18n.language, KEYS.APP, 'click_to_book'),
                        (_e = i18n
                            .getResource(i18n.language, KEYS.OFFER_FORM, "offer_" + ((keyVal === 'endTime' && 'un_') || '') + "loading_time")) === null || _e === void 0 ? void 0 : _e.toLowerCase(),
                    ].join(' '),
                    _b), { className: clsx((_c = {},
                        _c[classes.rootNotSelected] = !disabled && (!selectedTime || !selectedTime[0]),
                        _c[classes.rootMini] = is_mini,
                        _c), classes.root1, (_d = {},
                        _d[classes.disabledV1] = disabled,
                        _d[classes.rootPrimary] = props.v21,
                        _d[classes.rootPrimaryNotDisabled] = props.v21 && !disabled,
                        _d)), container: true, alignItems: 'center', style: __assign(__assign({ width: 'initial', height: '30px' }, (is_mini && { height: '21px' })), (props.v21 && { width: '90px', minHeight: '22px', height: '22px' })), justify: 'space-between' }, (!disabled
                    ? {
                        onClick: function (e) {
                            onToggle(e);
                            if (!modalOpen) {
                                setClicked(true);
                                setModalOpen(true);
                            }
                        },
                    }
                    : null)),
                    props.v21 ? (React.createElement(AccessTimeIcon, { style: { fontSize: '1rem', color: theme.palette.primary.main, marginRight: theme.spacing(0.5) } })) : null,
                    React.createElement(Typography, { variant: 'h6', id: "izi_time_selected_" + (((selectedTime === null || selectedTime === void 0 ? void 0 : selectedTime.length) && selectedTime[0]) || 'none'), style: __assign(__assign({ fontSize: '0.8rem' }, (props.v21 && { fontSize: '0.9rem' })), (props.v21 &&
                            !disabled &&
                            selectedTime &&
                            selectedTime[0] && { color: theme.palette.primary.main })) }, (selectedTime && selectedTime[0]) || (props.v21 && langApp.t('app_hours_minutes')) || ' -- : -- '),
                    (!is_mini && (React.createElement("span", { style: { pointerEvents: 'none', display: 'flex' } }, open ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDownIcon, null)))) ||
                        null,
                    !warnings && is_mini && !disabled && (React.createElement("div", __assign({}, (!disabled
                        ? {
                            onMouseEnter: function () {
                                setClicked(true);
                            },
                            onMouseLeave: function () {
                                setClicked(false);
                            },
                        }
                        : null)),
                        React.createElement(HelpIconComponent, __assign({ style: { position: 'absolute', right: -12, top: -12, width: 20, height: 20 } }, (loaded && !optionsTimes.length ? { warn: 1 } : { infoV1: 1 }), { title: langOfferForm
                                .t('offer_form_available_time')
                                .replace(/_X_/, optionsTimes.length ? optionsTimes.join(', ') : langApp.t('app_loading').toLowerCase() + "...") }))))));
            }, options: optionsTimes },
            React.createElement(Grid, { container: true, alignItems: 'center', style: { position: 'relative' }, id: "izi_booking_container", justify: optionsTimes.length < 4 ? 'space-evenly' : 'space-between' },
                (optionsTimes.length && loading && (React.createElement(Grid, { container: true, justify: 'center', style: {
                        background: '#ffffffc9',
                        pointerEvents: 'none',
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        height: '100%',
                    } },
                    React.createElement(CircularProgress, null)))) ||
                    null,
                optionsTimes.map(function (t) {
                    var _a;
                    return (React.createElement(Typography, { key: t, id: "izi_time_" + t, variant: 'h6', onClick: function (e) { return onToggleTime(e, t); }, className: clsx(classes.root, (_a = {},
                            _a[classes.active] = selectedTime.includes(t),
                            _a)) }, t));
                }))),
        warnings ||
            (is_mini
                ? null
                : transportation[keyValDate] >= toDateOnly(new Date()) && (React.createElement("div", __assign({}, (!disabled
                    ? {
                        onMouseEnter: function () {
                            setClicked(true);
                        },
                        onMouseLeave: function () {
                            setClicked(false);
                        },
                    }
                    : null)),
                    React.createElement(HelpIconComponent, { title: langOfferForm
                            .t('offer_form_available_time')
                            .replace(/_X_/, optionsTimes.length ? optionsTimes.join(', ') : langApp.t('app_loading').toLowerCase() + "...") })))) ||
            null)));
};
export var LoadingUnLoadingTimeComponent = withOrderListItemStore(inject('store')(LoadingUnLoadingTimeComponentC));
var c1 = function (props) { return (React.createElement(OfferTimeBookingEventsComponentSocket, null,
    React.createElement(LoadingUnLoadingTimeComponent, __assign({}, props)))); };
var OfferTimeBookingComponentConsumer = withRouter(withOfferTimeBookingStore(c1));
export var LoadingUnLoadingTime = withBaseGlobalSocketStoreActiveClient(function (p) {
    var client = p.client, props = __rest(p, ["client"]);
    return React.createElement(OfferTimeBookingComponentConsumer, __assign({}, props));
});
