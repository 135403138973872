var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import UpdateIcon from '@material-ui/icons/Update';
import { OrderStatus } from '../../../models';
import { Grid } from '@material-ui/core';
import { BasicDialog, onPreventEvent } from '../../Dialog';
import { toast } from 'react-toastify';
import { getSnapshot } from 'mobx-state-tree';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { FormikTextField } from '../../Formik';
import { onlySpacesRegExp } from '../../../common';
import { i18n, KEYS } from '../../../i18n';
import { useTranslation } from 'react-i18next';
import { CreateOrderQuoteRequestChange } from '../../../graphql/order/orderQuoteRequestChange';
import { OrderQuoteRequestChange } from '../../../models/OrderQuoteRequestChange';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import { MainButton } from '../../MainButton';
import { theme } from '../../../theme';
import { HelpIconComponent } from '../../HelpIcon';
import { withOrderListItemStore } from './Customer/storeListItem';
import BaseTooltip from '../../BaseTooltip';
import BaseIconButton from '../../BaseIconButton';
export var OrderQuoteRequestChanges = withOrderListItemStore(function (_a) {
    var config = _a.config, quote = _a.quote, _b = _a.defaultValue, defaultValue = _b === void 0 ? {} : _b, is_mini = _a.is_mini, orderListItemProps = _a.orderListItemProps, props = __rest(_a, ["config", "quote", "defaultValue", "is_mini", "orderListItemProps"]);
    var langForm = useTranslation(KEYS.FORM);
    var _c = props.order, latestQuote = _c.latestQuote, order = __rest(_c, ["latestQuote"]);
    var _value = JSON.parse(JSON.stringify(__assign({ order: order }, getSnapshot(OrderQuoteRequestChange.create(order.lastRequestChange || defaultValue)))));
    var _d = __read(React.useState(_value), 2), record = _d[0], setRecord = _d[1];
    var _e = __read(React.useState(false), 2), loading = _e[0], setLoading = _e[1];
    var formRef = React.useRef();
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (props.onChange) {
                        props.onChange(e, createdItem);
                    }
                    else if (props.onClose)
                        props.onClose(e, createdItem);
                    if (orderListItemProps === null || orderListItemProps === void 0 ? void 0 : orderListItemProps.state)
                        orderListItemProps.onChange({
                            record: __assign(__assign({}, orderListItemProps.state.record), { updatedAt: new Date().toString(), lastRequestChange: __assign(__assign({}, orderListItemProps.state.record.lastRequestChange), createdItem) }),
                        });
                    return [2 /*return*/];
            }
        });
    }); };
    var _onSubmit = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _b, updates, e_1;
        var updatedAt = _a.updatedAt, _id = _a._id, data = __rest(_a, ["updatedAt", "_id"]);
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 3, 4, 5]);
                    setLoading(true);
                    _b = config;
                    if (!_b) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: CreateOrderQuoteRequestChange,
                            variables: {
                                input: Object.assign(__assign(__assign({}, data), { order: order._id, quote: quote._id })),
                            },
                        })];
                case 1:
                    _b = (_c.sent());
                    _c.label = 2;
                case 2:
                    res = _b;
                    updates = __assign(__assign({}, record), res.data.createOrderQuoteRequestChange);
                    setRecord(updates);
                    if (!order.lastRequestChange)
                        order.lastRequestChange = {};
                    Object.assign(order.lastRequestChange, updates);
                    toast.success(i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_requested')
                        .replace(/_X_/, i18n.getResource(i18n.language, KEYS.FORM, 'form_btn_order_request_change')));
                    return [2 /*return*/, updates];
                case 3:
                    e_1 = _c.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var validationSchema = {
        comment: Yup.string()
            .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
            .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
            .required(langForm.t('form_input_validate_required')),
    };
    validationSchema = Yup.object(validationSchema);
    var dialogBody = (React.createElement(Formik, { key: record.updatedAt, innerRef: formRef, initialValues: record, validationSchema: validationSchema, onSubmit: _onSubmit }, function (formik) {
        return (React.createElement(Form, { style: { width: '100%' }, id: 'form-order-request-changes' },
            React.createElement(FormikTextField, { multiline: true, rows: 4, style: { marginBottom: theme.spacing(1) }, placeHolder: langForm.t('form_input_comment_request_date_change'), title: langForm.t('form_input_comment'), variant: "outlined", name: "comment", value: formik.values.comment })));
    }));
    var actionOk = langForm.t('form_btn_order_request_change');
    var dialogProps = {};
    return (order === null || order === void 0 ? void 0 : order.status) === OrderStatus.ApprovedQuote ? (React.createElement(BasicDialog, __assign({}, dialogProps, { body: dialogBody, onOk: onOk, actionOkTitle: actionOk, title: actionOk, withoutWrapper: true, isMiddleBody: true, actionInit: function (onOpen) {
            var onClick = (!(record && record._id) && onOpen) || null;
            var icon = (React.createElement(BaseTooltip, { title: (record && record._id && record.comment) || actionOk },
                React.createElement(BaseIconButton, { id: 'btn-request-changes', "aria-label": ((record && record._id && record.comment) || actionOk || '')
                        .toString()
                        .replace(/ /gi, '')
                        .toLowerCase(), disabled: loading, style: __assign({}, (is_mini && { padding: 0 })), onClick: onClick, color: (record && record._id && 'secondary') || 'primary' },
                    React.createElement(UpdateIcon, null))));
            if (props.isButton)
                return (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } },
                    React.createElement(MainButton, { loading: loading, disabled: record && record._id, id: 'btn-request-changes', style: { marginLeft: theme.spacing(2) }, onClick: onClick, title: actionOk }),
                    record && record._id && React.createElement(HelpIconComponent, { title: record.comment })));
            if (props.label)
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: props.label, handleClickOpen: onClick }));
            return icon;
        } }))) : null;
});
