import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import Register from './pages/Auth/Register/Register';
import Login from './pages/Auth/Login/Login';
import ResetPassword from './pages/Auth/ResetPassword/ResetPassword';
import SetPassword from './pages/Auth/SetPassword/SetPassword';
import RootRoutes from './RootRoutes';
import { URL, client } from './api/apolloClient';
import { GlobalNotificationsSocket, GlobalSocketClient } from '@izi-logistics/common/lib/components/Socket';
import './i18n';

import PreloadingComponent from '@izi-logistics/common/lib/components/PreloadingComponent';
import { GlobalChannelSocket } from '@izi-logistics/common/lib/pages/Chat/stores/Channel';
import PublicPage from '../src/pages/Public';
import WaitingPage from '@izi-logistics/common/lib/pages/WaitingPage'; //

const QuoteDetails = React.lazy(() => import('@izi-logistics/common/lib/pages/Quotes/Details'));
const CMROverviewComponent = React.lazy(() => import('@izi-logistics/common/lib/components/Order/CMROverview'));
const NoFoundComponent = React.lazy(() => import('@izi-logistics/common/lib/pages/404'));

const CMROverview = () => (
  <PreloadingComponent>
    <CMROverviewComponent />
  </PreloadingComponent>
);
const NoFound = () => (
  <PreloadingComponent>
    <NoFoundComponent />
  </PreloadingComponent>
);
export default ({ store }) => {
  const accountStore = store.accountStore;
  if (accountStore.isWatingScreen) {
    return (
      <Switch>
        <Route path="*" component={WaitingPage} />
      </Switch>
    );
  }
  return accountStore.isLoggedIn ? (
    <>
      <GlobalSocketClient config={{ URL, client }}>
        <GlobalNotificationsSocket>
          <GlobalChannelSocket client={client}>
            <Switch>
              <Route path="/public" component={PublicPage} />
              <Route exact path="/quote-details/:quoteId/cmr" component={CMROverview} />
              <Route
                exact
                path="/quote-details/:quoteId"
                component={(props: any) => (
                  <PreloadingComponent>
                    <QuoteDetails
                      {...props}
                      config={{
                        URL,
                        client,
                        variables: {
                          fields: [{ name: 'parent', value: props.match.params.quoteId }],
                        },
                      }}
                    />
                  </PreloadingComponent>
                )}
              />

              <Route path="/change-password" component={SetPassword} />
              <Route path="/" component={() => <RootRoutes store={store} />} />
              <Route exact path="/404" component={NoFound} />
            </Switch>
          </GlobalChannelSocket>
        </GlobalNotificationsSocket>
      </GlobalSocketClient>
    </>
  ) : (
    <Switch>
      <Route path="/public" component={PublicPage} />
      <Route exact path="/quote-details/:quoteId/cmr" component={CMROverview} />
      <Route
        exact
        path="/quote-details/:quoteId"
        component={(props: any) => (
          <PreloadingComponent>
            <QuoteDetails
              {...props}
              config={{
                client,
                variables: {
                  fields: [{ name: 'parent', value: props.match.params.quoteId }],
                },
              }}
            />
          </PreloadingComponent>
        )}
      />
      <Route exact path="/404" component={NoFound} />
      <Route exact path="/login" component={Login} />
      <Route exact path="/register" component={Register} />
      <Route exact path="/set-password" component={SetPassword} />
      <Route path="/change-password" component={SetPassword} />
      <Route exact path="/reset-password" component={ResetPassword} />
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};
