var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { inject, observer, useLocalStore } from 'mobx-react';
import { Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EditIcon from '@material-ui/icons/Edit';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid } from '@material-ui/core';
import { KEYS } from '../../i18n';
import { BasicDialog, onPreventEvent } from '../../components/Dialog';
import { Warehouse } from '../../models/Warehouse';
import { ACTION_CHANGE, FormikAutocomplete, FormikAutocompleteAsync, formikAutocompleteMultiOptionsProps, FormikGoogleTextFieldV1, FormikTextField, MainButton, } from '../../components';
import { getSnapshot } from 'mobx-state-tree';
import { onlySpacesRegExp } from '../../common';
import { FetchUsers } from '../../graphql';
import { ConsoleWarnHelper } from '../../utils';
import OrderRouteMenuItem from '../../components/Order/List/OrderRoutesMenuItem';
import BaseTooltip from '../../components/BaseTooltip';
import { useFormInputStyles } from '../../styles';
import { checkDirtyPhoneNumber, cleanPhoneNumber, formatPhoneNumber, googleTagManager, validateEmail, } from '../../utils1';
import { FormikCheckBoxField } from '../../components/Formik/FormikCheckboxField';
import BaseIconButton from '../../components/BaseIconButton';
export var WarehouseCreateEditForm = function (_a) {
    var item = _a.item, config = _a.config, isModalForm = _a.isModalForm, props = __rest(_a, ["item", "config", "isModalForm"]);
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var onChange = props.onChange;
    var formRef = React.useRef();
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var createdItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    createdItem = _a.sent();
                    if (!createdItem)
                        throw Error('No Valid');
                    if (onChange)
                        onChange(createdItem, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = React.createElement(WarehouseForm, { ref: formRef, config: config, item: item, isModalForm: true });
    var title = item.parent && item.parent._id ? 'warehouse_ramps' : 'user_app_type_warehouse';
    return (React.createElement(BasicDialog, { askCanClose: true, v1: true, body: dialogBody, withoutWrapper: true, title: langApp.t(title), onOk: onOk, dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: function (handleClickOpen) {
            var _title = langForm.t('form_btn_edit') + " " + langApp.t(title).toLowerCase();
            var icon = (item && item._id && (React.createElement(BaseTooltip, { title: _title },
                React.createElement(BaseIconButton, { onClick: handleClickOpen, color: 'primary', id: 'edit-btn' },
                    React.createElement(EditIcon, null))))) || (React.createElement(MainButton, { onClick: handleClickOpen, title: item.parent && item.parent._id
                    ? langForm.t('form_btn_add') + " " + langApp.t(title).toLowerCase()
                    : langForm.t('form_btn_new_warehouse') }));
            return props.label ? React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: _title, handleClickOpen: handleClickOpen })) : icon;
        } }));
};
export var WarehouseForm = inject('store')(observer(React.forwardRef(function (_a, ref) {
    var _b;
    var item = _a.item, _c = _a.store, warehouseStore = _c.warehouseStore, _e = _c.accountStore, currentUser = _e.currentUser, currentCompany = _e.currentCompany, config = _a.config, isModalForm = _a.isModalForm, onChange = _a.onChange, props = __rest(_a, ["item", "store", "config", "isModalForm", "onChange"]);
    var _f = __read(React.useState({}), 2), updates = _f[0], setUpdates = _f[1];
    var _g = __read(React.useState({}), 2), localValues = _g[0], setLocalValues = _g[1];
    var className = useFormInputStyles();
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var theme = useTheme();
    var _d = __assign(__assign(__assign({}, getSnapshot(Warehouse.create())), JSON.parse(JSON.stringify(item))), (props.isWizardStep &&
        !((_b = item.phones) === null || _b === void 0 ? void 0 : _b.length) &&
        currentUser.phone && {
        phones: [currentUser.phone],
    }));
    var _h = __read(React.useState(__assign(__assign({}, _d), (!_d.description && { description: '' }))), 2), defaultValue = _h[0], setDefaultValue = _h[1];
    if (defaultValue._id !== localValues._id) {
        setLocalValues(defaultValue);
    }
    var localStore = useLocalStore(function () { return ({
        error: '',
        isLoading: false,
        onSubmit: function (_a) {
            var _b;
            var _id = _a._id, updatedAt = _a.updatedAt, ramp = _a.ramp, generated = _a.generated, parent = _a.parent, ramps = _a.ramps, _input = __rest(_a, ["_id", "updatedAt", "ramp", "generated", "parent", "ramps"]);
            return __awaiter(this, void 0, void 0, function () {
                var result, input_1, e_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 6, 7, 8]);
                            localStore.error = '';
                            localStore.isLoading = true;
                            result = void 0;
                            input_1 = __assign(__assign(__assign({}, _input), (parent && parent._id && { parent: parent._id })), (_input.manager && { manager: (_b = _input.manager) === null || _b === void 0 ? void 0 : _b._id }));
                            ['emails', 'phones'].forEach(function (e) {
                                if (input_1[e])
                                    input_1[e] = input_1[e].filter(function (e) { return e; });
                            });
                            if (input_1.company) {
                                delete input_1.company;
                            }
                            if (!!_id) return [3 /*break*/, 3];
                            return [4 /*yield*/, warehouseStore.createWarehouse(input_1)];
                        case 1:
                            result = _c.sent();
                            return [4 /*yield*/, currentCompany.updateAdditionalInfo({ isWarehouseExists: true })];
                        case 2:
                            _c.sent();
                            return [3 /*break*/, 5];
                        case 3: return [4 /*yield*/, warehouseStore.updateWarehouse(__assign(__assign({}, input_1), { _id: _id }))];
                        case 4:
                            result = _c.sent();
                            _c.label = 5;
                        case 5:
                            googleTagManager('event', 'izi_form' + ("_warehouse_" + ((parent && "ramp_") || '') + (_id ? 'create' : 'edit')), {
                                event_name: "warehouse_" + ((parent && "ramp_") || '') + (_id ? 'create' : 'edit'),
                            });
                            setDefaultValue(JSON.parse(JSON.stringify(result)));
                            if (onChange)
                                onChange(result);
                            return [2 /*return*/, result];
                        case 6:
                            e_1 = _c.sent();
                            console.log(e_1);
                            googleTagManager('event', 'izi_error', {
                                event_name: "error",
                                value: e_1.key || e_1.message || e_1,
                            });
                            return [3 /*break*/, 8];
                        case 7:
                            localStore.isLoading = false;
                            return [7 /*endfinally*/];
                        case 8: return [2 /*return*/];
                    }
                });
            });
        },
        clearError: function () {
            localStore.error = '';
        },
    }); });
    var _onChange = defaultValue && defaultValue._id && !isModalForm
        ? function (e) { return __awaiter(void 0, void 0, void 0, function () {
            var _a, name, value;
            var _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = e.target, name = _a.name, value = _a.value;
                        if (JSON.stringify(value) === JSON.stringify(defaultValue[name])) {
                            return [2 /*return*/, ConsoleWarnHelper('no changes')];
                        }
                        return [4 /*yield*/, ref.current.setFieldValue(name, value)];
                    case 1:
                        _c.sent();
                        if (ref.current.errors[name])
                            return [2 /*return*/, ref.current.setFieldTouched(name)];
                        localStore.onSubmit((_b = {}, _b[name] = value, _b._id = defaultValue._id, _b));
                        return [2 /*return*/];
                }
            });
        }); }
        : function () { return 0; };
    var onAdd = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, ref.current.submitForm()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var title = defaultValue.parent && defaultValue.parent._id ? 'warehouse_ramps' : 'user_app_type_warehouse';
    var isPublic = !!location.pathname.match(/^\/public\//gi);
    var canEdit = !isPublic; // currentCompany._id === defaultValue.company?._id;
    return (React.createElement(React.Fragment, null,
        React.createElement(Typography, { color: "error" }, localStore.error),
        React.createElement(Formik, { innerRef: ref, initialValues: defaultValue, validationSchema: Yup.object(__assign(__assign({ name: Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')), description: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')) }, (!(defaultValue.parent && defaultValue.parent._id) && {
                address: Yup.object({
                    country: Yup.string(),
                }).test('country', langForm.t('form_input_validate_required'), function (val) {
                    return !!val.country;
                }),
            })), { emails: Yup.array()
                    .typeError(langForm
                    .t('form_input_validate_required_list')
                    .replace('_X_', langForm.t('form_input_name_emails').toLowerCase()))
                    .of(Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .email(langForm.t('form_input_validate_invalid_email')))
                    .min(1, 'Must have at least 1 email')
                    .max(10, 'Must have no more 10 email'), phones: Yup.array()
                    .typeError(langForm
                    .t('form_input_validate_required_list')
                    .replace('_X_', langForm.t('form_input_name_phones').toLowerCase()))
                    .of(Yup.string()
                    .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                    .test('phone', langForm.t('form_input_validate_invalid_phone'), checkDirtyPhoneNumber))
                    .min(1, 'Must have at least 1 phone')
                    .max(10, 'Must have no more 10 phones'), manager: Yup.mixed().test('manager', function () { return langForm.t('form_input_validate_required'); }, function (value) {
                    return (value && value._id) || typeof value === 'string';
                }) })), onSubmit: localStore.onSubmit }, function (formik) {
            var _a;
            var onChange = function (e) {
                var args = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    args[_i - 1] = arguments[_i];
                }
                return __awaiter(void 0, void 0, void 0, function () {
                    var _a;
                    return __generator(this, function (_b) {
                        if (e.target.name === 'phones' && Array.isArray(e.target.value)) {
                            e.target.value = e.target.value.map(cleanPhoneNumber);
                        }
                        if (e.target.name === 'manager' && args[0]) {
                            formik.setFieldValue(e.target.name, args[0]);
                            formik.setFieldValue('phones', [args[0].phone].filter(function (e) { return e; }));
                            formik.setFieldValue('emails', [args[0].email].filter(function (e) { return e; }));
                        }
                        else {
                            formik.setFieldValue(e.target.name, e.target.value);
                        }
                        formik.validateForm();
                        _onChange(e);
                        if (['emails', 'phones'].includes(e.target.name) && typeof e.target.value !== 'string') {
                            setLocalValues(__assign(__assign({}, localValues), (_a = {}, _a[e.target.name] = e.target.value, _a)));
                            formik.validateField(e.target.name);
                            if (formik.errors[e.target.name]) {
                                return [2 /*return*/, formik.setFieldTouched(e.target.name)];
                            }
                        }
                        setTimeout(function () { return formik.validateForm(); }, 500);
                        return [2 /*return*/];
                    });
                });
            };
            var onBlurPhone = function (e) {
                var _a;
                var _b = e.target, value = _b.value, name = _b.name;
                var _value = [];
                _value = __spread((formik.values[name] || []), [checkDirtyPhoneNumber(value) && value]).filter(function (e) { return e; });
                onChange({ target: { name: name, value: _value } });
                setUpdates(__assign(__assign({}, updates), (_a = {}, _a[name] = Date.now(), _a)));
            };
            var onBlurEmail = function (e) {
                var _a;
                var _b = e.target, value = _b.value, name = _b.name;
                var _value = [];
                _value = __spread((formik.values[name] || []), [validateEmail(value) && value]).filter(function (e) { return e; });
                onChange({ target: { name: name, value: _value } });
                setUpdates(__assign(__assign({}, updates), (_a = {}, _a[name] = Date.now(), _a)));
            };
            var onDeleteEmail = function (email, name) {
                var _a;
                var value = formik.values[name].filter(function (e) { return e !== email; });
                onChange({ target: { name: name, value: value } });
                setUpdates(__assign(__assign({}, updates), (_a = {}, _a[name] = Date.now(), _a)));
            };
            //
            return (React.createElement(Form, { id: 'form-warehouse' },
                React.createElement(Grid, { container: true, alignItems: 'center', spacing: 2, className: className.rootV1 },
                    React.createElement(Grid, { item: true, xs: 12, md: 6, style: { marginBottom: theme.spacing(2) } },
                        React.createElement(FormikTextField, { title: langForm.t('form_input_name_warehouse'), variant: "outlined", type: "text", name: "name", disabled: !canEdit, value: formik.values.name, onBlur: _onChange })),
                    ((_a = defaultValue.parent) === null || _a === void 0 ? void 0 : _a._id) ? (React.createElement(Grid, { item: true, xs: 12, md: 6, style: { marginBottom: theme.spacing(2) } },
                        React.createElement(FormikCheckBoxField, { color: "primary", label: langApp.t('warehouse_match_working_time'), variant: "outlined", name: "isDayWorkingMatchBooking", value: formik.values.isDayWorkingMatchBooking }),
                        React.createElement(FormikCheckBoxField, { color: "primary", label: langApp.t('warehouse_none_working_use_any_duration'), variant: "outlined", name: "isAllowAnyDurationForNoneWorkingTime", value: formik.values.isAllowAnyDurationForNoneWorkingTime }))) : (React.createElement(Grid, { item: true, xs: 12, md: 6, style: { marginBottom: theme.spacing(2) } },
                        React.createElement(FormikGoogleTextFieldV1, { v17: true, title: langForm.t('form_input_name_address_order'), variant: "outlined", disabled: !canEdit, name: "address", value: formik.values.address })))),
                React.createElement(Grid, { container: true, alignItems: 'center', spacing: 2, className: className.rootV1 },
                    React.createElement(Grid, { item: true, xs: 12, md: 6, style: { marginBottom: theme.spacing(2) } },
                        React.createElement(FormikAutocompleteAsync, { v17: true, title: langOrderForm.t('create_order_general_manger'), type: "text", disabled: !canEdit, name: "manager", client: config.client, variant: "outlined", query: FetchUsers, margin: 'dense', filterFields: ['firstName', 'lastName'], variables: {
                                filter: {
                                    fields: [
                                        {
                                            name: 'company',
                                            value: currentCompany._id,
                                        },
                                    ],
                                },
                            }, fullWidth: true, autocompleteProps: {
                                defaultValue: formik.values.manager,
                                getOptionSelected: function (option, value) { return option._id === value._id; },
                                getOptionLabel: function (option) {
                                    return "" + ((option.fullName || '').trim() ||
                                        [option.firstName, option.lastName].filter(function (e) { return e; }).join(' ') ||
                                        option.email ||
                                        '');
                                },
                            }, onChange: onChange })),
                    React.createElement(Grid, { item: true, xs: 12, md: 6, key: JSON.stringify(formik.values.manager || 1), style: { marginBottom: theme.spacing(2) } },
                        React.createElement(FormikAutocomplete, __assign({ v17: true, onlyValues: true }, formikAutocompleteMultiOptionsProps(formik.values.phones, onBlurPhone, function (email) { return onDeleteEmail(email, 'phones'); }, undefined, { formatPhoneNumber: formatPhoneNumber }), { key: updates['phones'], title: langForm.t('form_input_manager_phone'), placeholder: langForm.t('form_input_name_phones_placeholder'), name: "phones", disabled: !canEdit, value: formik.values.phones, onChange: onChange, onBlur: onBlurPhone })))),
                React.createElement(Grid, { direction: 'column', container: true, className: className.root, key: JSON.stringify(formik.values.manager || 1), style: { marginBottom: theme.spacing(2) } },
                    React.createElement(FormikAutocomplete, __assign({ v17: true, onlyValues: true }, formikAutocompleteMultiOptionsProps(formik.values.emails, onBlurEmail, function (email) {
                        return onDeleteEmail(email, 'emails');
                    }), { key: updates['emails'], title: langForm.t('form_input_manager_email'), name: "emails", disabled: !canEdit, value: formik.values.emails, placeholder: formik.values.emails
                            ? langForm.t('form_input_name_emails')
                            : langForm.t('form_input_name_emails_placeholder'), onChange: onChange, onBlur: onBlurEmail, style: { marginBottom: theme.spacing(0.5) } }))),
                React.createElement(Grid, { container: true, className: !isModalForm ? className.root : className.rootV1 },
                    React.createElement(FormikTextField, { title: langForm.t('form_input_description'), variant: "outlined", multiline: true, rows: 4, disabled: !canEdit, name: "description", value: formik.values.description, onBlur: _onChange })),
                !isModalForm && canEdit ? (React.createElement(Grid, { container: true, justify: 'flex-end', className: className.root },
                    React.createElement(MainButton, { onClick: onAdd, title: "" + (defaultValue._id
                            ? langForm.t('form_btn_edit') + " " + langApp.t(title).toLowerCase()
                            : defaultValue.parent && defaultValue.parent._id
                                ? langForm.t('form_btn_add') + " " + langApp.t(title).toLowerCase()
                                : langForm.t('form_btn_new_warehouse')) }))) : null));
        })));
})));
