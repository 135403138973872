var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { useMainStyles } from './styles';
import { ItemStepper } from './Stepper';
import { SimpleMap } from '../Order/location/SimpleMap';
import { makeStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { theme } from '../../theme';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import BaseIconButton from '../BaseIconButton';
var useStyles = makeStyles(function (theme) { return ({
    nav: {
        position: 'sticky',
        top: 0,
    },
}); });
var MyMap = inject('store')(observer(function (_a) {
    var markers = _a.markers, store = _a.store, props = __rest(_a, ["markers", "store"]);
    return (React.createElement(SimpleMap, __assign({}, props, { markers: markers, key: store.orderStore &&
            (store.orderStore.order.transportations
                ? store.orderStore.order.transportations.map(function (t) {
                    return t.routes.map(function (r) {
                        return r.origin &&
                            [
                                (r.origin.address.location && r.origin.address.location.coordinates[1]) || 0,
                                (r.origin.address.location && r.origin.address.location.coordinates[0]) || 0,
                            ].join('-');
                    });
                })
                : store.orderStore.order.updatedAt) })));
}));
export var MainForm = function (_a) {
    var children = _a.children, markers = _a.markers, opened = _a.opened, props = __rest(_a, ["children", "markers", "opened"]);
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var classesLocal = useStyles();
    var classes = useMainStyles();
    var langApp = useTranslation(KEYS.APP);
    var _b = __read(React.useState(isSmall || false), 2), isOpen = _b[0], setOpen = _b[1];
    var handleOpen = function () {
        setOpen(!isOpen);
    };
    React.useEffect(function () {
        if (isSmall && !isOpen)
            setOpen(true);
    }, [isSmall]);
    return (React.createElement(React.Fragment, null,
        React.createElement(MyMap, { v1: props.mapV1, markers: markers }),
        React.createElement(Grid, { container: true, direction: "row", justify: "space-between", className: classes.root },
            React.createElement(Grid, { item: true, md: isOpen ? 3 : 1, style: isOpen
                    ? {
                        paddingRight: theme.spacing(1.1),
                    }
                    : { maxWidth: 40 } },
                React.createElement(ItemStepper, __assign({}, props, { className: classesLocal.nav, key: isOpen, open: isOpen }),
                    React.createElement(BaseTooltip, { title: (isOpen ? langApp.t('collapse_less') : langApp.t('collapse_more')) },
                        React.createElement(BaseIconButton, { basic: true, style: { marginLeft: -5 }, onClick: handleOpen }, !isOpen ? React.createElement(ChevronRightIcon, null) : React.createElement(ChevronLeftIcon, null))))),
            React.createElement(Grid, { item: true, xs: 12, md: isOpen ? 9 : 12, style: isOpen ? {} : { maxWidth: "calc(100% - 52px)" }, container: true }, children))));
};
