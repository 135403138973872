var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { FlagComponent } from '../../Flag';
import { TextOverflow } from '../../TextOverflow';
import { theme } from '../../../theme';
import React from 'react';
import { OrderLocation } from '../../../models';
import EmptyItem from './Empty';
export default function OrderRoutesDirection(_a) {
    var route = _a.route, props = __rest(_a, ["route"]);
    var routeLocation = route && route.origin && OrderLocation.create(route.origin);
    var Flag = routeLocation && routeLocation.address.country && routeLocation.address.country.toLowerCase();
    var empty = React.createElement(EmptyItem, null);
    return routeLocation ? (React.createElement(React.Fragment, null,
        (Flag && (React.createElement(FlagComponent, { country: Flag, style: __assign({ border: '1px solid rgb(0 0 0 / 10%)', height: 22, width: 32 }, (props.v1 && { height: 22, width: 32 })) }))) ||
            empty,
        React.createElement(TextOverflow, { variant: "body2", style: __assign({ margin: theme.spacing(0, 1) }, (props.v1 && { maxWidth: 'calc(100% - 45px)', fontWeight: 500, color: theme.palette.primary.main })) },
            routeLocation.address.country && routeLocation.address.country.toUpperCase(),
            !props.hideDetails && props.v1
                ? " " + [routeLocation.address.postal && routeLocation.address.postal.toUpperCase(), routeLocation.name]
                    .filter(function (e) { return e; })
                    .join(', ')
                : null),
        !props.hideDetails && !props.v1 ? (React.createElement(TextOverflow, { v3: true }, routeLocation.address.postal && routeLocation.address.postal.toUpperCase())) : null)) : (empty);
}
