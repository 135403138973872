var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { theme } from '../../../theme';
import { Link } from 'react-router-dom';
import { inject } from 'mobx-react';
import { OrderQuoteStatus, OrderStatus } from '../../../models';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import { OrderViewMoreIcon } from '../../icons';
import { useIconStyles } from '../../../styles';
import BaseTooltip from '../../BaseTooltip';
import BaseIconButton from '../../BaseIconButton';
export default inject('store')(function OrderMoreView(_a) {
    var record = _a.record, v1 = _a.v1, currentUser = _a.store.accountStore.currentUser, order = _a.order, props = __rest(_a, ["record", "v1", "store", "order"]);
    var langForm = useTranslation(KEYS.FORM);
    var classes = useIconStyles();
    if (!record || ![OrderQuoteStatus.Approved, OrderQuoteStatus.Finished].includes(record.status))
        return null;
    var icon = (React.createElement(BaseTooltip, { title: (!order || order.state === OrderStatus.Finished
            ? langForm.t('form_btn_view_offer')
            : langForm.t('form_btn_view_offer_edit')) },
        React.createElement(BaseIconButton, { color: 'primary', id: 'btn-view-offer', style: (v1 && { padding: theme.spacing(0) }) || {}, className: classes.iconPrimary },
            React.createElement(OrderViewMoreIcon, null))));
    return (React.createElement(Link, { style: __assign({ textDecoration: 'none' }, (props.label && { width: '100%' })), to: "/quote-details/" + record._id }, props.label ? React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: props.label, handleClickOpen: function () { return 0; } })) : icon));
});
