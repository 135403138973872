import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import { KEYS, i18n } from '../i18n';
import { MainButton, TextOverflow } from '../components';
import { MainLogoIcon } from '../components/icons';
import IZIBackgroundContainer from '../components/IZIBackound';
var WaitingPage = function () {
    var theme = useTheme();
    return (React.createElement(IZIBackgroundContainer, null,
        React.createElement(Grid, { container: true, justify: "center", alignItems: "center", style: { padding: theme.spacing(10) } },
            React.createElement(Grid, { container: true, justify: "center", alignItems: "center" },
                React.createElement(MainLogoIcon, { style: { marginRight: theme.spacing(2), width: 84, height: 84 } }),
                React.createElement(TextOverflow, { variant: 'h3', style: {
                        textAlign: 'center',
                        color: theme.palette.common.white,
                        zIndex: 3,
                        fontWeight: 900,
                        margin: 0,
                    } }, document.title.toLowerCase())),
            React.createElement(Grid, { container: true, justify: "center", alignItems: "center", style: { zIndex: 3 }, direction: "column" },
                React.createElement(TextOverflow, { v1: true, variant: 'h6', style: { margin: 0, textAlign: 'center', color: 'white', fontWeight: '600' } }, i18n.getResource(i18n.language, KEYS.APP, 'app_website_slipping_title'))),
            React.createElement(MainButton, { style: { zIndex: 3 }, click: function () { return window.location.reload(); }, title: i18n.getResource(i18n.language, KEYS.FORM, 'form_btn_fresh') }))));
};
export default WaitingPage;
