var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { Grid, Typography, useTheme } from '@material-ui/core';
import React from 'react';
import Tour from 'reactour';
import CloseIcon from '@material-ui/icons/Close';
import BaseTooltip from '../BaseTooltip';
import { useMainStyles } from '../../styles';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { inject } from 'mobx-react';
import { FetchGuides } from '../../graphql/guide';
import { FilePreview } from '../Dialog';
import { TextOverflow } from '../TextOverflow';
import { checkPicture, decodeHtml, getVideoIndex } from '../../utils3';
import BaseImage from '../Files/BaseImage';
import { IS_CYPRESS_ENV } from '../../utils';
import BaseIconButton from '../BaseIconButton';
export default inject('store')(function BasicTour(props) {
    var _this = this;
    var client = props.client, store = props.store, steps = props.steps;
    var currentUser = store.accountStore.currentUser;
    var _a = __read(React.useState(false), 2), open = _a[0], setOpen = _a[1];
    var _b = __read(React.useState(1), 2), updated = _b[0], setUpdated = _b[1];
    var _c = __read(React.useState(true), 2), loading = _c[0], setLoading = _c[1];
    var _d = __read(React.useState({}), 2), data = _d[0], setData = _d[1];
    var _e = __read(React.useState(null), 2), timeOut1 = _e[0], setTimeout1 = _e[1];
    var _f = __read(React.useState(null), 2), timeOut2 = _f[0], setTimeout2 = _f[1];
    var visitGuides = function () {
        if (props.updateSettings) {
            var guides = __assign(__assign({}, (currentUser.settings.guides || {})), steps.map(function (e) {
                var _a;
                return (_a = {}, _a[e.type] = true, _a);
            }).reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
            currentUser.updateSettings({
                guides: guides,
            }, '', null, true);
        }
    };
    var onClose = function (e) {
        if (e === void 0) { e = null; }
        setOpen(false);
        visitGuides();
        if (props.onClose)
            props.onClose();
    };
    var loadData = function () { return __awaiter(_this, void 0, void 0, function () {
        var _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 2, 3, 4]);
                    if (IS_CYPRESS_ENV)
                        return [2 /*return*/];
                    setLoading(true);
                    _a = setData;
                    return [4 /*yield*/, client.mutate({
                            mutation: FetchGuides,
                            variables: {
                                filter: {
                                    fields: [{ name: 'types', value: steps.map(function (e) { return e.type; }) }],
                                },
                            },
                        })];
                case 1:
                    _a.apply(void 0, [(_b.sent()).data.guides.data]);
                    return [3 /*break*/, 4];
                case 2:
                    e_1 = _b.sent();
                    console.log(e_1);
                    return [3 /*break*/, 4];
                case 3:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    React.useEffect(function () {
        loadData();
    }, []);
    React.useEffect(function () {
        if (!IS_CYPRESS_ENV)
            setTimeout2(setTimeout(function () {
                setOpen(true);
                setUpdated(Date.now());
            }, props.fastLoad ? 0 : 2000));
        return function () {
            if (timeOut2)
                clearTimeout(timeOut2);
        };
    }, []);
    React.useEffect(function () {
        if (open && !IS_CYPRESS_ENV) {
            setTimeout1(setTimeout(function () {
                setUpdated(Date.now());
            }, 500));
        }
        return function () {
            if (timeOut1)
                clearTimeout(timeOut1);
        };
    }, [updated]);
    if (document.body.querySelector('.MuiDialog-root') || IS_CYPRESS_ENV) {
        console.log('no tour on local e2e tests');
        return null;
    }
    return (React.createElement(Tour, { isOpen: props.isOpen || open, rounded: 10, showNumber: false, showNavigation: steps.length !== 1, showButtons: steps.length !== 1, showCloseButton: false, update: updated.toString(), 
        //   prevButton={({ currentStep, setCurrentStep, steps }) => {
        //     const first = currentStep === 0;
        //     return (
        //       <button
        //         onClick={() => {
        //           if (first) {
        //             setCurrentStep((s) => steps.length - 1);
        //           } else {
        //             setCurrentStep((s) => s - 1);
        //           }
        //         }}>
        //         Back
        //       </button>
        //     );
        //   }}
        //   nextButton={({ Button, currentStep, stepsLength, setIsOpen, setCurrentStep, steps }) => {
        //     const last = currentStep === stepsLength - 1;
        //     return (
        //       <MainButton
        //         isOkScreen
        //         onClick={(e: any) =>
        //           currentStep === stepsLength ? onClose(e) : setCurrentStep(Math.min(stepsLength, currentStep + 1))
        //         }
        //         // style={{ ...(isMobile && { width: '100%' }) }}
        //         title={currentStep === stepsLength ? 'ok' : 'next'}
        //         id={'tour_next_button'}
        //       />
        //     );
        //   }}
        steps: steps.map(function (e, index) { return ({
            selector: e.selector,
            content: (React.createElement(BasicTourCard, { client: client, value: data[index], onClose: onClose, store: store, size: steps.length, hideClose: props.hideClose })),
            action: function (node) {
                if (node) {
                    var nextBtn_1 = document.querySelectorAll('button[data-tour-elem="right-arrow"]')[0];
                    node.onclick = function () {
                        setTimeout(function () {
                            if (steps.length === index + 1 || steps.length === 1) {
                                node.onclick = null;
                                onClose();
                            }
                            else if (nextBtn_1) {
                                nextBtn_1.click();
                            }
                        }, 500);
                    };
                }
            },
        }); }) }));
});
export var BasicTourCard = inject('store')(function (props) {
    var _a;
    var value = props.value, client = props.client, _b = props.store.accountStore, languages = _b.languages, currentUser = _b.currentUser, onClose = props.onClose;
    var langApp = useTranslation(KEYS.APP);
    var langForm = useTranslation(KEYS.FORM);
    var theme = useTheme();
    var classesName = useMainStyles();
    var language = currentUser.language;
    var languageIndex = languages.findIndex(function (e) { return e.id === language; });
    var videoIndex = (value === null || value === void 0 ? void 0 : value.files) && getVideoIndex(value.files);
    return (React.createElement(Grid, { container: true, key: value === null || value === void 0 ? void 0 : value.type },
        props.hideClose ? null : (React.createElement(BaseTooltip, { title: langApp.t('modal_actions_close') },
            React.createElement(BaseIconButton, { onClick: function (e) { return onClose(e); }, style: {
                    cursor: 'pointer',
                    marginRight: theme.spacing(1),
                    position: 'absolute',
                    top: -theme.spacing(0.5),
                    right: -theme.spacing(1),
                    padding: theme.spacing(1),
                } },
                React.createElement(CloseIcon, null)))),
        React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, alignItems: 'center', justify: "center", xs: 12, style: { textAlign: 'center', padding: theme.spacing(1), marginTop: -theme.spacing(4) } },
                React.createElement(TextOverflow, { variant: "h6", style: {
                        textAlign: 'center',
                        color: theme.palette.primary.main,
                    } }, (value === null || value === void 0 ? void 0 : value.name[languageIndex]) || '-')),
            React.createElement(Typography, null,
                React.createElement(Grid, { container: true, alignItems: 'center', justify: "center", xs: 12, style: { textAlign: 'center', padding: theme.spacing(1) }, className: classesName.innerHtml, dangerouslySetInnerHTML: {
                        __html: decodeHtml((value === null || value === void 0 ? void 0 : value.description[languageIndex]) || '-'),
                    } })),
            (((_a = value === null || value === void 0 ? void 0 : value.files) === null || _a === void 0 ? void 0 : _a.length) ? value.files : []).map(function (file, i) {
                var isPicture = checkPicture(file.name);
                var mediaStyle = { maxHeight: 350, maxWidth: '100%', padding: theme.spacing(2) };
                return !isPicture ? (React.createElement("video", { controls: true, autoPlay: videoIndex === i, muted: true, loop: true, style: mediaStyle },
                    React.createElement("source", { src: file.path }))) : (React.createElement(FilePreview, { v7: true, upload: file, item: file, title: langForm.t('form_btn_view'), config: { client: client }, actionInit: function (onClick) { return (React.createElement(BaseImage, { onClickFullScreen: onClick, upload: file, style: { maxHeight: 350, maxWidth: '100%' } })); } }));
            }))));
});
