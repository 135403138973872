var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { makeStyles } from '@material-ui/core';
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import Button from '@material-ui/core/Button';
import { CircularIndeterminate } from './CircularIndeterminate';
import { globalUtils, wrapTextToId } from '../utils';
import { SOUND_KEY } from './Sounds';
import EditIcon from '@material-ui/icons/Edit';
import { MainButton } from './MainButton';
var useStyles = makeStyles(function (theme) { return ({
    button: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
        fontSize: theme.spacing(2),
        '& span': {
            textTransform: 'initial',
            '&.MuiButton-startIcon': {
                marginLeft: 0,
            },
        },
    },
}); });
export var AddFormButton = function (p) {
    var title = p.title, children = p.children, addForm = p.addForm, onClick = p.onClick, withoutIcon = p.withoutIcon, v22 = p.v22, props = __rest(p, ["title", "children", "addForm", "onClick", "withoutIcon", "v22"]);
    var classes = useStyles();
    var _onClick = function (e) {
        globalUtils.sounds.play(SOUND_KEY.BUTTON_CLICK);
        onClick && onClick(e);
        addForm && addForm(e);
    };
    var buttonProps = __assign({ id: wrapTextToId(props.id || title || 'form-btn'), className: classes.button, startIcon: !withoutIcon && (props.icon || (v22 ? React.createElement(EditIcon, null) : React.createElement(ControlPointIcon, null))), onClick: _onClick, disabled: props.loading }, props);
    var buttonChildren = props.loading ? React.createElement(CircularIndeterminate, { size: "1.5em" }) : null;
    if (props.variant === 'contained')
        return (React.createElement(MainButton, __assign({}, buttonProps, { title: title || children }), buttonChildren));
    return (React.createElement(Button, __assign({}, buttonProps),
        React.createElement(React.Fragment, null,
            buttonChildren,
            title || children)));
};
