var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { StyledBadgeV5 } from '../../components/StyledBadge';
import ChatIcon from '@material-ui/icons/Chat';
import { withChannelStore } from './stores/Channel';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../i18n';
import { withChannelListStore } from './stores/ChannelList';
import { theme } from '../../theme';
import { inject } from 'mobx-react';
import BaseTooltip from '../../components/BaseTooltip';
import BaseIconButton from '../../components/BaseIconButton';
var ChannelChatIcon = function (_a) {
    var channelStore = _a.channelStore, v1 = _a.v1, props = __rest(_a, ["channelStore", "v1"]);
    var icon = (React.createElement(BaseTooltip, { title: i18n.getResource(i18n.language, KEYS.CHAT, 'main_title'), onClick: props.onClick },
        React.createElement(BaseIconButton, null,
            React.createElement(ChatIcon, __assign({}, props)))));
    if (!channelStore.state.totalUnread)
        return v1 ? icon : React.createElement(ChatIcon, __assign({}, props));
    return (React.createElement(StyledBadgeV5, { badgeContent: channelStore.state.totalUnread, key: channelStore.state.totalUnread }, v1 ? icon : React.createElement(ChatIcon, __assign({}, props))));
};
var ChannelItemChatIconComponent = inject('store')(function (_a) {
    var channelListStore = _a.channelListStore, record = _a.record, top = _a.top, props = __rest(_a, ["channelListStore", "record", "top"]);
    var langChat = useTranslation(KEYS.CHAT);
    if (props.isAdmin)
        return null;
    var channel = channelListStore.state.channelsTotalUnread.find(function (e) { return e._id === record._id; });
    if (top) {
        return ((channel && channel.totalUnread && (React.createElement("span", { style: { position: 'absolute', right: 0, top: 0 } },
            React.createElement(StyledBadgeV5, { badgeContent: channel.totalUnread, key: channel.totalUnread, color: 'primary' },
                React.createElement("span", null))))) ||
            null);
    }
    var content = (React.createElement(BaseTooltip, { title: langChat.t('main_title') },
        React.createElement(BaseIconButton, { style: { padding: theme.spacing(0.5) } },
            React.createElement(ChatIcon, null))));
    if (!channel || !channel.totalUnread)
        return content;
    return (React.createElement(BaseTooltip, { title: langChat.t('main_title') },
        React.createElement(BaseIconButton, { style: { padding: theme.spacing(0.5) } },
            React.createElement(StyledBadgeV5, { badgeContent: channel.totalUnread, key: channel.totalUnread, color: 'primary' },
                React.createElement(ChatIcon, null)))));
});
export var ChannelItemChatIcon = withChannelListStore(ChannelItemChatIconComponent);
export default withChannelStore(ChannelChatIcon);
