var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import clsx from 'clsx';
import { Button, Grid } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import BaseTooltip from '../BaseTooltip';
import BaseIconButton from '../BaseIconButton';
export var usePrevNextButtonStyles = makeStyles(function (theme) {
    return createStyles({
        disabled: {
            pointerEvents: 'none',
        },
        buttonV1: {
            padding: theme.spacing(0.5),
            color: theme.palette.success.light,
        },
        root: {
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            '& button': {
                backgroundColor: theme.palette.common.white,
                boxShadow: 'none',
                '&:nth-child(1)': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                },
                '&:nth-child(3)': {
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                },
                '&:nth-child(2)': {
                    borderRadius: 0,
                },
                '& path': {
                    fill: theme.palette.primary.light,
                },
            },
        },
    });
});
export var PrevNextButton = function (_a) {
    var _b;
    var v1 = _a.v1, label = _a.label, prevLabel = _a.prevLabel, style = _a.style, nextLabel = _a.nextLabel, onChange = _a.onChange, onReset = _a.onReset, children = _a.children;
    var classes = usePrevNextButtonStyles();
    if (v1)
        return (React.createElement(Grid, { container: true, style: __assign(__assign({}, style), { width: 'initial' }), alignItems: 'center', justify: "center" },
            React.createElement(BaseTooltip, { title: prevLabel },
                React.createElement(BaseIconButton, { disabled: !prevLabel, onClick: function (e) { return onChange(e, -1); }, className: classes.buttonV1 },
                    React.createElement(ArrowBackIosIcon, null))),
            children,
            React.createElement(BaseTooltip, { title: nextLabel },
                React.createElement(BaseIconButton, { disabled: !nextLabel, onClick: function (e) { return onChange(e, 1); }, className: classes.buttonV1 },
                    React.createElement(ArrowForwardIosIcon, null)))));
    return (React.createElement(Grid, { container: true, style: { width: 'initial' }, alignItems: 'center', className: classes.root },
        React.createElement(BaseTooltip, { title: prevLabel },
            React.createElement(Button, { variant: "contained", disabled: !prevLabel, onClick: function (e) { return onChange(e, -1); } },
                React.createElement(NavigateBeforeIcon, null))),
        React.createElement(Button, { variant: "contained", onClick: onReset, className: clsx((_b = {},
                _b[classes.disabled] = !onReset,
                _b)) }, label),
        React.createElement(BaseTooltip, { title: nextLabel },
            React.createElement(Button, { variant: "contained", disabled: !nextLabel, onClick: function (e) { return onChange(e, 1); } },
                React.createElement(NavigateNextIcon, null)))));
};
