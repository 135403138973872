var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { BasicDialog } from './BasicDialog';
import Typography from '@material-ui/core/Typography';
import { dialogStyles } from './styles';
import { Grid } from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { TextOverflow } from '../TextOverflow';
import CloseIcon from '@material-ui/icons/Close';
import BaseTooltip from '../BaseTooltip';
import { wrapTextToId } from '../../utils';
import BaseIconButton from '../BaseIconButton';
export var DeleteDialog = function (_a) {
    var _b;
    var disabled = _a.disabled, v4 = _a.v4, v1 = _a.v1, id = _a.id, props = __rest(_a, ["disabled", "v4", "v1", "id"]);
    var langApp = useTranslation(KEYS.APP);
    var classesDialogStyles = dialogStyles();
    var actonInit = props.actionInit
        ? props.actionInit
        : function (handleClickOpen, onCLose, _a) {
            var loading = _a.loading;
            var item = (React.createElement(Grid, { container: true, alignItems: 'center', style: __assign(__assign({}, (disabled && { cursor: 'not-allowed' })), ((!props.withText && { width: 'initial' }) || {})), justify: 'space-between', onClick: disabled ? function () { return 0; } : handleClickOpen },
                (props.withText && (React.createElement(TextOverflow, { variant: 'subtitle1' }, props.v5 || props.useTitle ? props.title : langApp.t('modal_delete_title')))) ||
                    null,
                React.createElement(BaseIconButton, { "aria-label": "menu", disabled: disabled || loading, className: v4 ? classesDialogStyles.icon : undefined, style: __assign(__assign(__assign({}, (disabled && { cursor: 'not-allowed' })), ((v1 && { padding: 0 }) || {})), props.iconStyle), id: wrapTextToId(id || 'delete-btn') }, v4 ? React.createElement(CloseIcon, null) : props.icon ? props.icon : React.createElement(DeleteForeverIcon, null))));
            if (disabled || props.withText)
                return item;
            return React.createElement(BaseTooltip, { title: props.title || langApp.t('modal_delete_title') }, item);
        };
    return (React.createElement(BasicDialog, __assign({}, props, { withoutWrapper: true, actionInit: actonInit, isMiddleBody: true, body: props.text ? (React.createElement(Typography, { variant: "subtitle1", className: classesDialogStyles.text }, props.text)) : (React.createElement(Typography, { variant: "subtitle1", className: classesDialogStyles.text, style: {
                lineBreak: 'anywhere',
                textAlign: 'center',
            } },
            langApp.t('modal_prompt_question'),
            ' ',
            ((_b = props.title) === null || _b === void 0 ? void 0 : _b.toLowerCase()) || langApp.t('modal_delete_title').toLowerCase(),
            " ",
            React.createElement("b", null, props.value),
            "?")) })));
};
