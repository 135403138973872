var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { i18n, KEYS } from '../../../i18n';
import { useChipStyles } from '../../../styles';
import { FormikAutocompleteAsync } from '../../Formik';
import { theme } from '../../../theme';
import { FlagComponent } from '../../Flag';
import { TextOverflow } from '../../TextOverflow';
import { FetchOrderListFilters, FetchCarrierDirListFilters } from '../../../graphql/country';
import { inject } from 'mobx-react';
import { StyledBadge } from '../../StyledBadge';
import { FetchCountries } from '../../../graphql/country';
import BaseTooltip from '../../BaseTooltip';
import RemoveIcon from '@material-ui/icons/Remove';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import BaseChip from '../../BaseChip';
import { useMediaQuery, useTheme } from '@material-ui/core';
// import { BaseUserAppTYpe } from '../../../stores';
var LocationCountryFilter = function (_a) {
    var title = _a.title, name = _a.name, value = _a.value, options = _a.options, onChange = _a.onChange, client = _a.client, multiple = _a.multiple, showCount = _a.showCount, onClear = _a.onClear, v1 = _a.v1, variables = _a.variables, store = _a.store, isAdmin = _a.isAdmin, isFull = _a.isFull, props = __rest(_a, ["title", "name", "value", "options", "onChange", "client", "multiple", "showCount", "onClear", "v1", "variables", "store", "isAdmin", "isFull"]);
    var classesChip = useChipStyles();
    var _value = multiple ? (Array.isArray(value) && value) || [] : value;
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var _onChange = function (e) {
        var r = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            r[_i - 1] = arguments[_i];
        }
        if (Array.isArray(e.target.value)) {
            var getCountryCode_1 = function (e) { return e.code || e._id; };
            var countDictionary_1 = e.target.value
                .map(function (e) {
                var _a;
                return (_a = {}, _a[getCountryCode_1(e)] = 1, _a);
            })
                .reduce(function (acc, curr) {
                var _a;
                var key = Object.keys(curr)[0];
                return __assign(__assign({}, acc), (_a = {}, _a[key] = (acc[key] || 0) + curr[key], _a));
            }, {});
            e.target.value = e.target.value.filter(function (e) { return countDictionary_1[getCountryCode_1(e)] === 1; });
        }
        if ((multiple && Array.isArray(e.target.value)) || !multiple) {
            onChange.apply(void 0, __spread([v1 && r[1] === 'select-option' ? { target: { name: e.target.name, value: r[0].code } } : e], r));
        }
    };
    var propsV = {
        query: FetchOrderListFilters,
        variables: variables,
    };
    if (isAdmin || isFull) {
        Object.assign(propsV, {
            query: FetchCountries,
        });
        delete propsV.variables;
    }
    if (props.isCarrierDirList) {
        Object.assign(propsV, {
            query: FetchCarrierDirListFilters,
        });
    }
    return (React.createElement(FormikAutocompleteAsync, __assign({ client: client }, propsV, { loadOnScroll: true, withoutNotExistsOption: true, onChageText: function () { return 0; }, onClear: function (e) {
            if (onClear)
                onClear(e);
            else
                _onChange({ target: { name: name, value: multiple ? [] : null } });
        }, onChange: _onChange, v1: !props.v2, v14: !props.v2, v26: props.v2, style: __assign(__assign({ minWidth: 130 }, (isSmall && { maxWidth: undefined })), props.style), autocompleteProps: {
            multiple: multiple,
            value: _value === 'all' ? 'WW' : _value,
            defaultValue: _value,
            filterOptions: function (options, params) {
                var fields = (options.length && Object.keys(options[0])) || [];
                var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                var filterTextStartWith = '^' + filterText;
                var res = filterText && fields.length
                    ? options.filter(function (el) {
                        if (!el)
                            return;
                        var countryCode = el.name || el._id || el.code || '';
                        return countryCode && countryCode.toLowerCase().match(new RegExp(filterTextStartWith, 'gi'));
                    })
                    : options; //filterOptions(options, params);
                if (!(res === null || res === void 0 ? void 0 : res.length)) {
                    res =
                        filterText && fields.length
                            ? options.filter(function (el) {
                                if (!el)
                                    return;
                                var countryCode = el.name || el._id || el.code || '';
                                return countryCode && countryCode.toLowerCase().match(new RegExp(filterText, 'gi'));
                            })
                            : options;
                }
                if (Array.isArray(value) && value.length) {
                    var values_1 = value.map(function (e) { return e && (e.value || e); });
                    return res.filter(function (e) { return e && !values_1.includes(e.value); });
                }
                return res;
            },
            getOptionLabel: function (option) {
                if (!option)
                    return null;
                var countryCode = option.code || option._id;
                return i18n.getResource(i18n.language, KEYS.APP, "country_" + countryCode.toUpperCase()) || countryCode;
            },
            renderOption: function (option) { return option && React.createElement(LocationCountryFilterItem, __assign({}, option, { showCount: showCount })); },
            freeSolo: false,
            renderTags: function (value, getTagProps) {
                var re = (Array.isArray(value) && value) || [];
                return re.map(function (option, index) { return (React.createElement(BaseChip, __assign({ label: React.createElement(LocationCountryFilterItem, __assign({}, option, { v1: true })) }, getTagProps({ index: index }), { color: 'primary', className: clsx(classesChip.chipRoot, classesChip.chipRootActive) }))); });
            },
        }, filterFields: [], title: title, withoutTitle: props.withoutTitle, variant: "outlined", name: name, canSearch: true, value: _value, defaultValue: _value, options: options.map(function (option) {
            return option.isCustom
                ? option
                : {
                    label: React.createElement(LocationCountryFilterItem, __assign({}, option, { showCount: showCount })),
                    value: option.code || option._id,
                };
        }) })));
};
export var LocationCountryFilterItem = function (_a) {
    var short = _a.short, _id = _a._id, code = _a.code, name = _a.name, count = _a.count, showCount = _a.showCount, v1 = _a.v1, v2 = _a.v2;
    var countryCode = code || _id;
    return ((countryCode && (React.createElement(Grid, { key: countryCode, container: true, alignItems: 'center', style: { width: 'initial', position: 'relative' } },
        React.createElement(FlagComponent, { country: (countryCode || '').toLowerCase(), style: {
                border: '1px solid rgb(0 0 0 / 10%)',
                height: 20,
                width: 28,
            } }),
        React.createElement(Grid, { style: __assign({ position: 'relative', width: 'initial' }, (v2 && { maxWidth: 'calc(100% - 30px)' })), container: true },
            React.createElement(TextOverflow, { variant: 'subtitle1', style: { marginLeft: theme.spacing(0.3) } }, (short && countryCode) ||
                i18n.getResource(i18n.language, KEYS.COUNTRIES, "country_" + countryCode.toUpperCase()) ||
                name ||
                countryCode),
            (showCount && React.createElement(StyledBadge, { color: "primary", badgeContent: count })) || null)))) ||
        null);
};
export var LocationCountryFilterItemPair = function (_a) {
    var _id = _a._id;
    var langApp = useTranslation(KEYS.APP);
    var delimiterString = _id.match('<->') ? '<->' : '-';
    var delimiter = _id.match('<->') ? (React.createElement(BaseTooltip, { title: langApp.t('round_trip') },
        React.createElement(Grid, { container: true, alignItems: 'center' },
            React.createElement(ArrowBackIosIcon, { style: { marginRight: -theme.spacing(1) } }),
            React.createElement(RemoveIcon, null),
            React.createElement(ArrowForwardIosIcon, null)))) : (React.createElement(ArrowForwardIosIcon, null));
    var options = _id.split(delimiterString);
    return (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', position: 'relative' } },
        React.createElement(LocationCountryFilterItem, { key: 3, _id: options[0], v1: true }),
        React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', padding: theme.spacing(0, 1) } }, delimiter),
        React.createElement(LocationCountryFilterItem, { key: 3, _id: options[1], v1: true })));
};
export default inject('store')(LocationCountryFilter);
