var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { useStepperStyles, useStyles } from './styles';
import clsx from 'clsx';
import { theme } from '../../theme';
import { TextOverflow } from '../TextOverflow';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Collapse } from '@material-ui/core';
import { onPreventEvent } from '../Dialog';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import BaseIconButton from '../BaseIconButton';
var Step = function (props) {
    var stepNumber = props.stepNumber, title = props.title, label = props.label, children = props.children, activeStep = props.activeStep, isLast = props.isLast, isFirst = props.isFirst, isCollapsed = props.isCollapsed, hasParent = props.hasParent, hasChildren = props.hasChildren, open = props.open;
    var defV = !!isFirst;
    var _a = __read(React.useState(defV), 2), isOpen = _a[0], setIsOpen = _a[1];
    var langApp = useTranslation(KEYS.APP);
    var classes = useStepperStyles();
    var toggleCollapse = function (e) {
        setIsOpen(!isOpen);
        return onPreventEvent(e);
    };
    React.useEffect(function () {
        setIsOpen(isFirst);
    }, [isFirst]);
    var collapseItem = isCollapsed ? (React.createElement(BaseTooltip, { title: (isOpen ? langApp.t('collapse_less') : langApp.t('collapse_more')) },
        React.createElement(BaseIconButton, { basic: true, onClick: toggleCollapse, style: { width: 34, height: 34 }, onMouseEnter: onPreventEvent }, isOpen ? React.createElement(ExpandLessIcon, null) : React.createElement(ExpandMoreIcon, null)))) : null;
    var defMargin = (React.createElement("div", { className: clsx(classes.stepLine, activeStep && classes.activeStepLine), style: __assign({ padding: theme.spacing(1, 0, 0, 4) }, (isLast ? { border: 'none' } : null)) }));
    var onMouseEnter = function (e) {
        if (hasParent)
            return onPreventEvent(e);
    };
    var _title = (title || label || '');
    return (React.createElement("a", { href: "#step-content-" + label.replace(/ /, '-').toLowerCase(), className: classes.stepNav },
        React.createElement(Grid, { container: true, direction: "row", alignItems: 'center', onMouseEnter: onMouseEnter },
            React.createElement(BaseTooltip, { title: _title },
                React.createElement("div", { className: clsx(classes.icon, activeStep && classes.activeIcon, hasParent && classes.iconSmall) })),
            (open && (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'space-between', style: { maxWidth: "calc(100% - 38px)" } },
                React.createElement(TextOverflow, { style: __assign({ maxWidth: "calc(100% - 50px)" }, (hasParent ? { paddingLeft: 26, lineHeight: 1.2 } : {})), variant: hasParent ? 'h6' : 'h5', className: clsx(classes.label, activeStep && classes.activeLabel, 'step-label') }, _title),
                collapseItem))) ||
                null),
        (open && children && (React.createElement(React.Fragment, null,
            React.createElement(Collapse, { in: isOpen || !isCollapsed, timeout: "auto", unmountOnExit: true, style: Object.assign({}) },
                React.createElement("div", { className: clsx(!hasChildren && classes.stepLine, activeStep && classes.activeStepLine), style: Object.assign({}, isLast ? { border: 'none' } : {}, hasParent || props.hasShift ? { paddingLeft: 35 } : null) }, children)),
            (isCollapsed && !isOpen && defMargin) || null))) ||
            (!isLast && defMargin) ||
            null));
};
export var ItemStepper = function (_a) {
    var steps = _a.steps, hasParent = _a.hasParent, className = _a.className, open = _a.open, props = __rest(_a, ["steps", "hasParent", "className", "open"]);
    var classes = useStyles();
    var classesStep = useStepperStyles();
    var indexCollapsedElement = 0;
    // const list: any = [];
    // for (let i = 0; i < steps.length; i++) {
    //   list.push(steps[i]);
    //   if (steps[i].children) {
    //     for (let j = 0; j < steps[i].children.length; j++) {
    //       steps[i].children[j].hasParent = true;
    //       steps[i].children[j].list = `${steps[i]._id}-${j}`;
    //       list.push(steps[i].children[j]);
    //     }
    //   }
    // }
    // console.log(list);
    return (React.createElement("div", { className: clsx(classes.root, className) },
        props.children,
        React.createElement(Grid, { className: classes.stepper }, steps.map(function (step, index) { return (React.createElement(Step, { stepNumber: index + 1, title: step.title, open: open, label: step.label, activeStep: step.isActive(), key: step._id, hasParent: hasParent, hasShift: !!step.content, isFirst: step.children && step.children.length && indexCollapsedElement++ === 0, isLast: !hasParent && index === steps.length - 1, hasChildren: step.children && step.children.length, isCollapsed: step.children && step.children.length },
            step.content ||
                (step.children && (React.createElement("div", { className: clsx(classesStep.stepLine, step.isActive() && classesStep.activeStepLine) }))),
            step.children && step.children.length ? (React.createElement(ItemStepper, { open: open, key: step.title + ("sub-list-" + open), hasParent: true, style: { paddingLeft: theme.spacing(1) }, steps: step.children })) : null)); }))));
};
