var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { createContext, useReducer } from 'react';
import socketIOClient from 'socket.io-client';
import { browserStorage } from '../../../browserStorage';
import { i18n, KEYS } from '../../../i18n';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { SOCKET_CUSTOM_EVENT_NAME } from '../types';
import { reloadPageWithoutCache } from '../../../utils';
import { MainButton } from '../../MainButton';
import { UpdateUserActivity } from '../../../graphql/user';
import BaseIconButton from '../../BaseIconButton';
var initialState = {
    client: null,
    isOffline: false,
    isOldVersion: false,
    isCloseOldVersion: false,
    hasConnected: !!window.hasSocketConnected,
    isClose: false,
    auth: null,
};
var BaseGlobalSocketStore = createContext(JSON.parse(JSON.stringify(initialState)));
var Provider = BaseGlobalSocketStore.Provider, Consumer = BaseGlobalSocketStore.Consumer;
export var ACTIONS;
(function (ACTIONS) {
    ACTIONS["CONNECT"] = "CONNECT";
    ACTIONS["UPDATE"] = "UPDATE";
    ACTIONS["DISCONNECT"] = "DISCONNECT";
})(ACTIONS || (ACTIONS = {}));
var useStyles = makeStyles(function (theme) { return ({
    root: {
        '& .MuiSnackbarContent-root': {
            background: theme.palette.common.white,
        },
        '& .MuiSnackbarContent-message': {
            maxWidth: "calc(100% - 100px)",
        },
    },
}); });
var timeOut;
export var BaseSocketProvider = function (_a) {
    var children = _a.children, config = _a.config, props = __rest(_a, ["children", "config"]);
    var initState = __assign(__assign({}, JSON.parse(JSON.stringify(initialState))), { id: Date.now(), hasConnected: !!window.hasSocketConnected });
    var _b = __read(useReducer(function (state, action) {
        switch (action.type) {
            case ACTIONS.UPDATE:
            case ACTIONS.CONNECT: {
                return __assign(__assign({}, state), action.payload);
            }
            case ACTIONS.DISCONNECT: {
                state.client && state.client.disconnect();
                return __assign(__assign({}, state), { client: null });
            }
            default:
                throw new Error("No " + action.type + " found");
        }
    }, initState), 2), state = _b[0], dispatch = _b[1];
    React.useEffect(function () {
        if (!window.connections)
            window.connections = [];
        if (state.isOffline) {
            window.hasSocketLost = 1;
            window.connections.push({
                action: 'disconnected',
                time: Date.now(),
                deviceDetails: deviceDetails(),
                state: JSON.stringify({
                    isOffline: state.isOffline,
                    isOldVersion: state.isOldVersion,
                    isCloseOldVersion: state.isCloseOldVersion,
                    hasConnected: state.hasConnected,
                    isClose: state.isClose,
                }),
            });
        }
        else {
            window.connections.push({
                action: 'connected',
                time: Date.now(),
                deviceDetails: deviceDetails(),
                state: JSON.stringify({
                    isOffline: state.isOffline,
                    isOldVersion: state.isOldVersion,
                    isCloseOldVersion: state.isCloseOldVersion,
                    hasConnected: state.hasConnected,
                    isClose: state.isClose,
                }),
            });
        }
        try {
            if (config && window.hasSocketLost)
                config.client
                    .mutate({
                    mutation: UpdateUserActivity,
                    variables: {
                        input: JSON.stringify(window.connections),
                    },
                })
                    .then(function (d) { })
                    .catch(function (e) {
                    window.connections.push({ action: 'error', error: e, deviceDetails: deviceDetails() });
                });
        }
        catch (e) {
            console.log("UpdateUserActivity error", e);
        }
        if (!state.isOffline) {
            window.connections = [];
        }
    }, [state.isOffline]);
    var deviceDetails = function () {
        try {
            var navigator_1 = window.navigator;
            return {
                appCodeName: navigator_1.appCodeName,
                appName: navigator_1.appName,
                appVersion: navigator_1.appVersion,
                cookieEnabled: navigator_1.cookieEnabled,
                pdfViewerEnabled: navigator_1.pdfViewerEnabled,
                platform: navigator_1.platform,
                onLine: navigator_1.onLine,
                userAgent: navigator_1.userAgent,
                vendor: navigator_1.vendor,
            };
        }
        catch (e) {
            return {
                title: 'device not available',
                error: e,
            };
        }
    };
    var connect = function () {
        var _a, _b;
        try {
            var options = {
                // rememberUpgrade: true,
                // transports: ["websocket"],
                secure: !!location.origin.match('https'),
                reconnection: true,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                reconnectionAttempts: 50,
                timeout: 100001,
                withCredentials: false,
                rejectUnauthorized: false,
                query: {
                    izi_client_id: browserStorage.getItem('client_id'),
                    token: browserStorage.getItem('token'),
                },
            };
            var URL_1 = ((_b = (_a = window.envirements) === null || _a === void 0 ? void 0 : _a.find(function (e) { return e.name === 'APP_SOCKET_URL'; })) === null || _b === void 0 ? void 0 : _b.value) ||
                (config && (config.URL || '').replace('/graphql', ''));
            // console.log('URL---', URL);
            var client_1 = config && socketIOClient(URL_1, options);
            var onError_1 = function (error) {
                window.isSocketConnected = false;
                if (browserStorage.getItem('token')) {
                    if (timeOut) {
                        clearTimeout(timeOut);
                    }
                    timeOut = setTimeout(function () {
                        timeOut = 0;
                        if (!window.isSocketConnected)
                            dispatch({
                                type: ACTIONS.UPDATE,
                                payload: __assign(__assign({}, state), { isOffline: error || true }),
                            });
                    }, 4000);
                }
            };
            client_1.on('disconnect', function (d) {
                // console.log('socket disconnect', d);
                onError_1(d);
            });
            client_1.on('error', function (d) {
                console.error('socket error', d);
                onError_1(d);
            });
            client_1.on(SOCKET_CUSTOM_EVENT_NAME.NOTIFICATION_APP_CONNECTED, function (d) {
                window.hasSocketConnected = true;
                window.isSocketConnected = true;
                if (timeOut) {
                    clearTimeout(timeOut);
                }
                var v = browserStorage.getItem('IZI_VERSION');
                dispatch({
                    type: ACTIONS.CONNECT,
                    payload: __assign(__assign(__assign({}, state), (!d.version ||
                        (!v && browserStorage.getItem('API_VERSION')) ||
                        (v && parseInt(d.version) !== parseInt(v))
                        ? { isOldVersion: true, isCloseOldVersion: false }
                        : { isOldVersion: false, isCloseOldVersion: true })), { hasConnected: true, auth: d, client: client_1 }),
                });
            });
            client_1.on('connect', function () {
                if (timeOut) {
                    clearTimeout(timeOut);
                }
                window.isSocketConnected = true;
                // console.log('client connected');
                dispatch({
                    type: ACTIONS.CONNECT,
                    payload: __assign(__assign({}, state), { isOffline: false, client: client_1 }),
                });
                // client.on("disconnect", () => {
                //   disconnect();
                // });
            });
        }
        catch (e) {
            console.log(e);
            // disconnect();
        }
    };
    var disconnect = function () {
        // console.log('client disconnect');
        dispatch({
            type: ACTIONS.DISCONNECT,
        });
    };
    var configData = function () { return config; };
    var handleClose = function () { return dispatch({ type: ACTIONS.UPDATE, payload: __assign(__assign({}, state), { isClose: true }) }); };
    var handleRefresh = function () {
        var _a;
        var version = ((_a = state.auth) === null || _a === void 0 ? void 0 : _a.version) || '';
        dispatch({ type: ACTIONS.UPDATE, payload: __assign(__assign({}, state), { isCloseOldVersion: true }) });
        browserStorage.setItem('IZI_VERSION', version);
        reloadPageWithoutCache();
    };
    var value = {
        globalSocketState: state,
        connect: connect,
        disconnect: disconnect,
        configData: configData,
    };
    var classes = useStyles();
    return (React.createElement(Provider, { value: value },
        React.createElement(Snackbar, { className: classes.root, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, open: !!state.isOffline && !state.isClose, action: React.createElement(React.Fragment, null,
                React.createElement(BaseIconButton, { "aria-label": "close", color: "inherit", onClick: handleClose },
                    React.createElement(CloseIcon, { color: 'primary' }))), message: React.createElement(Grid, { container: true, alignItems: "center", justify: "center", style: { textAlign: 'center' } },
                React.createElement(Typography, { color: 'primary', dangerouslySetInnerHTML: {
                        __html: i18n.getResource(i18n.language, KEYS.APP, 'lost_connection') || '',
                    } })) }),
        React.createElement(Snackbar, { className: classes.root, anchorOrigin: { vertical: 'bottom', horizontal: 'center' }, open: !!state.isOldVersion && !state.isCloseOldVersion, action: React.createElement(Grid, { container: true, style: { width: 'fit-content' } },
                React.createElement(MainButton, { withPulse: true, title: i18n.getResource(i18n.language, KEYS.FORM, 'form_btn_fresh'), onClick: handleRefresh })), message: React.createElement(Grid, { container: true, alignItems: "center", justify: "center", style: { textAlign: 'center' } },
                React.createElement(Typography, { color: 'primary', dangerouslySetInnerHTML: {
                        __html: i18n.getResource(i18n.language, KEYS.APP, 'refresh_version') || '',
                    } })) }),
        children));
};
export var withBaseGlobalSocketStore = function (Component) {
    return function (props) { return (React.createElement(Consumer, null, function (storeProps) {
        return React.createElement(Component, __assign({}, __assign(__assign({}, props), storeProps)));
    })); };
};
export var withBaseGlobalSocketStoreActiveClient = function (Component) {
    return function (props) { return (React.createElement(Consumer, null, function (storeProps) {
        var key = window.hasSocketConnected; ///props.globalSocketState.auth?.user?._id || -1//TODO: use store state instead window
        return React.createElement(Component, __assign({ key: key }, __assign(__assign({}, props), storeProps)));
    })); };
};
