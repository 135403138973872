var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { getSnapshot } from 'mobx-state-tree';
import { makeStyles } from '@material-ui/core/styles';
import { BasicDialog, onPreventEvent } from '../Dialog';
import { FormikAutocomplete, FormikTextField, formikAutocompleteMultiOptionsProps } from '../Formik';
import InputTitle from '../Formik/InputTitle';
import { i18n, KEYS } from '../../i18n';
import UploadType from '../../pages/Quotes/dialogs/UploadType';
import { UploadItem } from '../../models/UploadItem';
import DocumentItemVisibleTo from '../../pages/Quotes/Documents/DocumentItemVisibleTo';
import { theme } from '../../theme';
import { inject } from 'mobx-react';
import { BaseUserAppTYpe } from '../../stores/common';
import { TextOverflow } from '../TextOverflow';
import UploadWithDragAndDrop from '../UploadWithDragAndDrop';
import { useMainStyles } from '../../styles/main';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { HelpIconComponent } from '../HelpIcon';
import { isCanBeSigned } from '../../utils';
import { USER_ROLE_TOOLS } from '../../models/UserRoles';
import { getUsersFromAccess } from '../../utils2';
import { documentInitialValues } from './utils';
import { onlySpacesRegExp } from '../../common';
import { validateEmail } from '../../utils1';
var useStyles = makeStyles(function (theme) { return ({
    form: {
        padding: theme.spacing(2),
    },
}); });
export var AddFormDialog = inject('store')(function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    var loading = _a.loading, progress = _a.progress, autocompleteProps = _a.autocompleteProps, actionInit = _a.actionInit, defaultValue = _a.defaultValue, onChange = _a.onChange, record = _a.record, store = _a.store, isBatch = _a.isBatch, noEmail = _a.noEmail, props = __rest(_a, ["loading", "progress", "autocompleteProps", "actionInit", "defaultValue", "onChange", "record", "store", "isBatch", "noEmail"]);
    var _t = store.accountStore, currentUser = _t.currentUser, currentCompany = _t.currentCompany;
    if (!defaultValue)
        defaultValue = getSnapshot(UploadItem.create());
    var _u = __read(React.useState(1), 2), updates = _u[0], setUpdates = _u[1];
    var _v = __read(React.useState(false), 2), isOpen = _v[0], setIsOpen = _v[1];
    var _w = __read(React.useState(true), 2), willNotify = _w[0], setWillNotify = _w[1];
    var classes = useStyles();
    var mainClasses = useMainStyles();
    var formRef = props.formRef || React.useRef();
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langToast = useTranslation(KEYS.TOAST);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var _x = __read(React.useState([]), 2), emails = _x[0], setEmails = _x[1];
    var isEdit = !!record;
    var isPlaceholder = isEdit && !record.path;
    var isCarrier = currentUser.appType.includes(BaseUserAppTYpe.CARRIER);
    var isCustomer = currentUser.appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isCarrierFromCustomerList = (props === null || props === void 0 ? void 0 : props.quote) && ((_e = (_d = (_c = (_b = props.quote) === null || _b === void 0 ? void 0 : _b.createdBy) === null || _c === void 0 ? void 0 : _c.company) === null || _d === void 0 ? void 0 : _d.companiesParents) === null || _e === void 0 ? void 0 : _e.length) && ((_f = props.quote) === null || _f === void 0 ? void 0 : _f.createdBy.company.companiesParents.map(function (e) { return e.toString(); }).includes((_h = (_g = props.quote) === null || _g === void 0 ? void 0 : _g.order) === null || _h === void 0 ? void 0 : _h.createdBy.company._id.toString()));
    var onOk = function (e, createdItem) {
        if (createdItem === void 0) { createdItem = null; }
        return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        onPreventEvent(e);
                        if (!!props.v3) return [3 /*break*/, 2];
                        return [4 /*yield*/, formRef.current.submitForm()];
                    case 1:
                        createdItem = _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!createdItem)
                            throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                        if (!(onChange && !(props.v3 && !createdItem.files.length))) return [3 /*break*/, 4];
                        return [4 /*yield*/, onChange(__assign(__assign({}, createdItem), (Array.isArray(createdItem.users) && {
                                users: createdItem.users
                                    .map(function (e) { return ({ user: e.user, company: e.company }); })
                                    .filter(function (e) { return e.user; }),
                            })), { comment: createdItem.text })];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        onClose();
                        return [2 /*return*/, createdItem];
                }
            });
        });
    };
    var onSubmit = function (model) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!props.v3) return [3 /*break*/, 2];
                    return [4 /*yield*/, onOk(null, model)];
                case 1: return [2 /*return*/, _a.sent()];
                case 2: return [2 /*return*/, model];
            }
        });
    }); };
    var allAvailableUsers = [];
    var order;
    if ((_j = props.quote) === null || _j === void 0 ? void 0 : _j.order) {
        order = props.quote.order;
        allAvailableUsers = documentInitialValues(order, currentUser, props).users;
    }
    var isCarrierView = currentUser.appType.includes(BaseUserAppTYpe.CARRIER) ||
        (currentUser.appType.includes(BaseUserAppTYpe.FORWARDER) && ((_k = order === null || order === void 0 ? void 0 : order.approvedCarrier) === null || _k === void 0 ? void 0 : _k._id) === currentCompany._id);
    var startFiles = isEdit && !isPlaceholder ? [__assign(__assign({}, record), { isCanBeSigned: isCanBeSigned(record) })] : [];
    var _y = __read(React.useState(__assign(__assign(__assign({}, defaultValue), ((noEmail && {
        files: [],
        access: [],
        emails: [],
        users: [],
        sendEmail: false,
    }) || __assign(__assign({ files: startFiles, access: (record === null || record === void 0 ? void 0 : record.access) || __spread(getSnapshot(currentUser).appType, (props.isPreparation
            ? isCustomer
                ? [BaseUserAppTYpe.SENDER]
                : [BaseUserAppTYpe.CUSTOMER]
            : isCarrier
                ? ((_o = (_m = (_l = props === null || props === void 0 ? void 0 : props.quote) === null || _l === void 0 ? void 0 : _l.order) === null || _m === void 0 ? void 0 : _m.forwarders) === null || _o === void 0 ? void 0 : _o.length) && !isCarrierFromCustomerList
                    ? [BaseUserAppTYpe.FORWARDER]
                    : [BaseUserAppTYpe.CUSTOMER]
                : [])), users: [], emails: (record === null || record === void 0 ? void 0 : record.emails) || defaultValue.emails || [] }, ((record === null || record === void 0 ? void 0 : record.type) ? { type: record === null || record === void 0 ? void 0 : record.type } : {})), { sendEmail: !props.v3 }))), { description: (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.description) || '' })), 2), initialValues = _y[0], setInitialValues = _y[1];
    var onClose = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setIsOpen(false);
            if (props.onFresh)
                props.onFresh();
            setEmails([]);
            setInitialValues(__assign(__assign({}, initialValues), { files: startFiles }));
            if (props.onClose)
                props.onClose();
            return [2 /*return*/];
        });
    }); };
    var onlyFiles = props.parent_collection_name === 'order_chats' || noEmail;
    React.useEffect(function () {
        var _a;
        var users = getUsersFromAccess(allAvailableUsers, initialValues);
        var list = [];
        if ((_a = record === null || record === void 0 ? void 0 : record.type) === null || _a === void 0 ? void 0 : _a._id) {
            order.transportations.forEach(function (e) {
                var item = e.routes
                    .filter(function (e) { var _a, _b; return (_b = (_a = e.origin) === null || _a === void 0 ? void 0 : _a.locationAdvanced) === null || _b === void 0 ? void 0 : _b.find(function (e) { return e.documentType && e.documentType._id; }); })
                    .map(function (e) { var _a; return (_a = e.origin) === null || _a === void 0 ? void 0 : _a.locationAdvanced.filter(function (e) { return e.documentType && e.documentType._id; }); })
                    .reduce(function (a, b) { return __spread(a, b); }, []);
                if (item && item.length) {
                    list.push.apply(list, __spread(item));
                }
            });
            list = list.filter(function (e) { var _a, _b, _c; return ((_a = e.documentType) === null || _a === void 0 ? void 0 : _a._id) === record.type._id || ((_c = (_b = e.documentType) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.match(new RegExp(record.type.name, 'gi'))); });
            if (list.length) {
                var access = __spread(new Set(__spread((initialValues.access || []), list.map(function (e) { return e.access || []; }).reduce(function (a, b) { return __spread(a, b); }))));
                initialValues.access = access;
                list = __spread(new Set(__spread(list.map(function (e) { return e.email; }).reduce(function (a, b) { return __spread(a, b); })).filter(function (e) { return e; })));
                users = getUsersFromAccess(allAvailableUsers, { access: access });
            }
        }
        setInitialValues(__assign(__assign({}, initialValues), { users: users, emails: __spread(new Set(__spread(list, (initialValues.emails || [])))) }));
    }, []);
    React.useEffect(function () {
        var _a;
        if (((_a = defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.files) === null || _a === void 0 ? void 0 : _a.length) || (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.text) || (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.text) === '')
            setInitialValues(__assign(__assign(__assign({}, initialValues), defaultValue), { description: (initialValues === null || initialValues === void 0 ? void 0 : initialValues.description) || (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.description) || '' }));
    }, [((_p = defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.files) === null || _p === void 0 ? void 0 : _p.length) && (defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.files[0].path), defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.text]);
    var emailsValidation = {
        emails: Yup.array()
            .typeError(langForm
            .t('form_input_validate_required_list')
            .replace('_X_', langForm.t('form_input_name_emails').toLowerCase()))
            .of(Yup.string()
            .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
            .email(langForm.t('form_input_validate_invalid_email')))
            .max(10, langForm
            .t('form_input_validate_field_must_have_no_more')
            .replace('_X_', '10')
            .replace('_XX_', langForm.t('form_input_name_emails').toLowerCase())),
    };
    var dialogBody = (React.createElement(Grid, { container: true },
        React.createElement(Formik, { key: ((_q = initialValues === null || initialValues === void 0 ? void 0 : initialValues.files) === null || _q === void 0 ? void 0 : _q.map(function (e) { return e.path; }).join('_')) + "-" + ((_r = initialValues === null || initialValues === void 0 ? void 0 : initialValues.users) === null || _r === void 0 ? void 0 : _r.length) + "-" + ((_s = initialValues === null || initialValues === void 0 ? void 0 : initialValues.access) === null || _s === void 0 ? void 0 : _s.join('_')), innerRef: formRef, initialValues: initialValues, validationSchema: Yup.object(__assign({ description: Yup.string()
                    .max(1050, langForm.t('form_input_validate_max_char').replace(/_X_/g, '1050'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text')) }, (props.v3
                ? __assign({ type: Yup.object({
                        _id: Yup.string(),
                    }).test('_id', langForm.t('form_input_validate_required'), function (item) {
                        var _a;
                        return ((_a = this.parent.files) === null || _a === void 0 ? void 0 : _a.length) ? !!(item === null || item === void 0 ? void 0 : item._id) : true;
                    }) }, emailsValidation) : __assign({ files: Yup.array()
                    .typeError(langForm
                    .t('form_input_validate_required_list')
                    .replace('_X_', langApp.t('main_documents').toLowerCase()))
                    .of(Yup.object({
                    _id: Yup.string(),
                }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                    return !!val._id;
                }))
                    .min(1, langForm
                    .t('form_input_validate_required_list')
                    .replace('_X_', langApp.t('main_documents').toLowerCase()))
                    .max(10, langForm
                    .t('form_input_validate_field_must_have_no_more')
                    .replace('_X_', '10')
                    .replace('_XX_', langApp.t('main_documents').toLowerCase())) }, (!onlyFiles && __assign(__assign({}, emailsValidation), { type: Yup.object({
                    _id: Yup.string(),
                }).test('_id', langForm.t('form_input_validate_required'), function (val) {
                    return !!val._id;
                }) })))))), onSubmit: onSubmit }, function (formik) {
            var _a;
            var _b, _c;
            var _onChange = function (e) {
                var _a = e.target, value = _a.value, name = _a.name;
                if (name === 'access') {
                    var users = getUsersFromAccess(allAvailableUsers, { access: value });
                    formik.setFieldValue('users', users);
                    formik.setFieldValue(name, value);
                }
                else if (name === 'sendEmail') {
                    setWillNotify(!formik.values[name]);
                    formik.setFieldValue(name, !formik.values[name]);
                }
                else {
                    formik.setFieldValue(name, value);
                }
                if (name === 'emails' && typeof value !== 'string') {
                    setEmails(value);
                    formik.validateField(name);
                    if (formik.errors[name]) {
                        return formik.setFieldTouched(name);
                    }
                }
            };
            var onBlur = function (e) {
                var options = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    options[_i - 1] = arguments[_i];
                }
                var _a = e.target, value = _a.value, name = _a.name;
                var _value = [];
                if (['emails'].includes(name)) {
                    if (typeof formik.values.emails !== 'string') {
                        _value = formik.values[name];
                        _onChange({ target: { name: name, value: _value } });
                    }
                    else {
                        var newValue = value.trim();
                        _value = __spread((emails || []), [newValue]);
                        if (newValue !== '') {
                            _onChange({ target: { name: name, value: _value } });
                        }
                    }
                }
                setUpdates(Date.now());
            };
            var onBlurEmail = function (e) {
                var _a = e.target, value = _a.value, name = _a.name;
                var _value = [];
                _value = __spread((formik.values[name] || []), [validateEmail(value) && value]).filter(function (e) { return e; });
                _onChange({ target: { name: name, value: _value } });
                setUpdates(Date.now());
            };
            var onDeleteEmail = function (email) {
                var name = 'emails';
                var value = formik.values[name].filter(function (e) { return e !== email; });
                _onChange({ target: { name: name, value: value } });
                setUpdates(Date.now());
            };
            var onChangeFiles = function (_a) {
                var value = _a.target.value;
                return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0: return [4 /*yield*/, formik.setFieldValue('files', value)];
                            case 1:
                                _b.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
            var dataUser = formikAutocompleteMultiOptionsProps(formik.values.users, null, null, [], {
                disabled: true,
            });
            var onChangeUploadType = function (val) {
                if (!val)
                    return;
                formik.setFieldValue('type', val);
                if (!val._id)
                    return;
                try {
                    var list_1 = [];
                    order.transportations.forEach(function (e) {
                        var item = e.routes
                            .filter(function (e) { return e.origin.locationAdvanced.find(function (e) { return e.documentType && e.documentType._id; }); })
                            .map(function (e) { return e.origin.locationAdvanced.filter(function (e) { return e.documentType && e.documentType._id; }); })
                            .reduce(function (a, b) { return __spread(a, b); }, []);
                        if (item && item.length) {
                            list_1.push.apply(list_1, __spread(item));
                        }
                    });
                    list_1 = list_1.filter(function (e) { var _a, _b, _c; return ((_a = e.documentType) === null || _a === void 0 ? void 0 : _a._id) === val._id || ((_c = (_b = e.documentType) === null || _b === void 0 ? void 0 : _b.name) === null || _c === void 0 ? void 0 : _c.match(new RegExp(val.name, 'gi'))); });
                    var users = [];
                    var access = [];
                    if (list_1.length) {
                        access = __spread(new Set(__spread(list_1.map(function (e) { return e.access || []; }).reduce(function (a, b) { return __spread(a, b); }))));
                        list_1 = __spread(new Set(__spread(list_1.map(function (e) { return e.email; }).reduce(function (a, b) { return __spread(a, b); })).filter(function (e) { return e; })));
                        users = getUsersFromAccess(allAvailableUsers, { access: access });
                    }
                    if (isCarrierView ||
                        onlyFiles ||
                        props.isPreparation ||
                        props.parent_collection_name === 'order_transportations')
                        return;
                    formik.setFieldValue('users', users);
                    formik.setFieldValue('access', access);
                    formik.setFieldValue('emails', list_1);
                    formik.setFieldValue('updatedAt', new Date().toString());
                }
                catch (e) {
                    // console.log(order);
                    console.log(e, order);
                }
            };
            var styles = { marginBottom: theme.spacing(3) };
            var uploadProps = {
                allowToAccessSign: true,
            };
            if (isBatch) {
                Object.assign(uploadProps, { isBatch: isBatch });
            }
            if (props.parent_collection_name === 'order_chats') {
                uploadProps.allowToAccessSign = false;
                if (currentUser.rolesV1.find(function (e) { var _a; return (_a = e.tools.admin) === null || _a === void 0 ? void 0 : _a.includes(USER_ROLE_TOOLS.ADMIN_payment_term_support); })) {
                    uploadProps.allowToAccessSign = true;
                }
            }
            return (React.createElement(Form, { className: clsx(classes.form, (_a = {}, _a[mainClasses.disabled] = loading, _a)), style: __assign({}, (props.v3 && { padding: 0 })), id: 'form-uploads' },
                (!onlyFiles && (React.createElement(Grid, { container: true, direction: "column", style: { marginBottom: theme.spacing(3) } },
                    React.createElement(UploadType, { key: ((_b = formik.values.type) === null || _b === void 0 ? void 0 : _b._id) || 1, title: langForm.t('form_input_upload_type'), name: "type", defaultValue: formik.values.type, config: props.config, onChange: onChangeUploadType, autocompleteProps: { multiline: true } })))) ||
                    null,
                !isCarrierView &&
                    !onlyFiles &&
                    !props.isPreparation &&
                    props.parent_collection_name !== 'order_transportations' ? (React.createElement(Grid, { container: true, key: formik.values.access.join('_'), style: { marginBottom: theme.spacing(1) } },
                    React.createElement(InputTitle, { title: langOfferForm.t('offer_form_document_visible'), style: { width: 'initial', paddingRight: theme.spacing(1) } }),
                    React.createElement(DocumentItemVisibleTo, { v2: true, quote: props.quote, isEditable: true, item: formik.values, record: formik.values, onChange: _onChange }))) : null,
                (!isCarrierView && !noEmail && (React.createElement(Grid, { container: true, direction: "column", key: JSON.stringify(formik.values.access) || 1, style: __assign(__assign({}, styles), (props.isPreparation && { marginBottom: theme.spacing(3) })) },
                    React.createElement(FormikAutocomplete, __assign({ v17: true, readOnly: true }, dataUser, { disabled: !props.isPreparation, isEditable: true, name: "users" }, formikAutocompleteMultiOptionsProps(formik.values.users, null, null, initialValues.users), { onChange: _onChange, value: formik.values.users, help: langToast.t('toast_users_to_notify_help'), title: langForm.t('form_shared_with_users') }))))) ||
                    null,
                !isCarrierView && !onlyFiles ? (React.createElement(Grid, { container: true, direction: "column", key: formik.values.updatedAt || '', style: styles },
                    React.createElement(FormikAutocomplete, __assign({ v17: true, v24: true, onlyValues: true }, formikAutocompleteMultiOptionsProps(formik.values.emails, onBlurEmail, onDeleteEmail, []), { key: updates, name: "emails", placeholder: ((_c = formik.values.emails) === null || _c === void 0 ? void 0 : _c.length) ? langForm.t('form_send_emails_to')
                            : langForm.t('form_send_emails_to'), onChange: _onChange, value: formik.values.emails, help: langToast.t('toast_email_to_notify_help'), title: langForm.t('form_send_emails_to'), onBlur: onBlurEmail })))) : null,
                React.createElement(Grid, { style: { marginBottom: theme.spacing(1) } },
                    React.createElement(FormikTextField, { multiline: true, rows: 4, placeHolder: langForm.t('form_input_description'), title: langForm.t('form_input_description'), variant: "outlined", name: "description", value: formik.values.description, onChange: onChange })),
                !isCarrierView && !onlyFiles && !props.v3 ? (React.createElement(Grid, { container: true, direction: 'column', onClick: function () { return _onChange({ target: { name: 'sendEmail', value: !formik.values.sendEmail } }); } },
                    React.createElement(FormControlLabel, { style: { pointerEvents: 'none' }, control: React.createElement(Checkbox, { checked: formik.values.sendEmail, name: "sendEmail", color: "primary" }), label: React.createElement(Grid, { container: true, alignItems: 'center' },
                            React.createElement(TextOverflow, null, langForm.t('form_invoice_create_send_with_email')),
                            React.createElement(HelpIconComponent, { title: langForm.t('form_input_name_send_email_help') })) }))) : null,
                props.v4 ? (React.createElement(Grid, { container: true, direction: 'column', style: styles },
                    React.createElement(FormikTextField, { id: 'chat-text-input', multiline: true, rows: 4, title: langForm.t('form_input_comment'), variant: "outlined", name: "text", value: formik.values.text, 
                        // className={classes.root}
                        onChange: onChange }))) : null,
                React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(1) } },
                    React.createElement(UploadWithDragAndDrop, __assign({ fileInput: props.fileInput, inputId: props.inputId, UploadProps: { folder: "private/quotes/" + (props.quote._id || 'create') + "/" } }, uploadProps, { noEmail: noEmail, singleFile: isEdit, config: props.config, value: formik.values.files, item: props.quote, parent_collection_name: 'quotes', docType: 'Other', onChange: onChangeFiles, noUpload: true })),
                    formik.submitCount > 0 && !formik.values.files.length && !props.v3 ? (React.createElement(TextOverflow, { color: "error" }, langForm
                        .t('form_input_validate_fields_validate')
                        .replace(/_X_/, langApp.t('main_file'))
                        .replace(/__X__/, langForm.t('form_input_validate_required').toLowerCase()))) : null)));
        }),
        (loading && (React.createElement(Grid, { container: true, alignItems: 'center', key: progress, "data-id": "izi-upload-progress" },
            React.createElement(TextOverflow, null,
                langOfferForm.t('offer_form_document_add') + (" " + progress + "%"),
                "..."),
            React.createElement(CircularIndeterminate, __assign({ size: '1.5rem' }, (progress && { progress: progress })))))) ||
            null));
    if (props.withoutDialog) {
        return dialogBody;
    }
    return (React.createElement(BasicDialog, { withoutWrapper: true, v1: true, askCanClose: true, body: dialogBody, actionOkTitle: props.v4
            ? langForm.t('form_upload')
            : willNotify
                ? langForm.t('form_save_and_notify')
                : langForm.t('form_btn_save'), onOpen: function () { return setIsOpen(true); }, onClose: onClose, title: isEdit
            ? "" + langForm.t('form_invoice_create_edit').replace(/_X_/g, langApp.t('app_file').toLowerCase())
            : "" + langForm.t('form_btn_upload_files'), onOk: onOk, dialogProps: { fullWidth: true, maxWidth: 'md' }, actionInit: actionInit }));
});
