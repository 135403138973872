var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { BasicDialog, onPreventEvent } from '../../../components';
import BaseTooltip from '../../../components/BaseTooltip';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import OrderRouteMenuItem from '../../../components/Order/List/OrderRoutesMenuItem';
import { Grid, Typography, useTheme } from '@material-ui/core';
import { toast } from 'react-toastify';
import { RefreshDocumentRequest } from '../../../graphql';
import BaseIconButton from '../../../components/BaseIconButton';
export var RefreshDocument = function (_a) {
    var record = _a.record, config = _a.config, props = __rest(_a, ["record", "config"]);
    var langForm = useTranslation(KEYS.FORM);
    var theme = useTheme();
    var _b = __read(React.useState(false), 2), loading = _b[0], setLoading = _b[1];
    var title = langForm.t('form_refresh_document');
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var res, _a, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    onPreventEvent(e);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, 5, 6]);
                    if (!record._id)
                        return [2 /*return*/];
                    setLoading(true);
                    _a = config;
                    if (!_a) return [3 /*break*/, 3];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: RefreshDocumentRequest,
                            variables: { _id: record._id },
                        })];
                case 2:
                    _a = (_b.sent());
                    _b.label = 3;
                case 3:
                    res = _a;
                    if (props.onChange)
                        props.onChange();
                    return [3 /*break*/, 6];
                case 4:
                    e_1 = _b.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 6];
                case 5:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var dialogBody = (React.createElement(Grid, { container: true, justify: "center", alignItems: "center" },
        React.createElement(Typography, null, langForm.t('form_refresh_document_description'))));
    return (React.createElement(BasicDialog, { dialogProps: { fullWidth: true, maxWidth: 'sm' }, onOk: onOk, title: title, isMiddleBody: true, body: dialogBody, withoutWrapper: true, actionInit: function (onOpen) {
            var onClick = function () {
                onOpen();
            };
            var icon = (React.createElement(BaseTooltip, { title: title, id: 'btn-refresh-document' },
                React.createElement(BaseIconButton, { "aria-label": "menu", style: { cursor: 'initial', color: theme.palette.success.light } },
                    React.createElement(AutorenewIcon, null))));
            if (title) {
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: onClick }));
            }
        } }));
};
