import React from 'react';
import { BasicDialog } from '../../../components/Dialog';
import { Grid } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { StyledBadge } from '../../../components/StyledBadge';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import { BaseList } from '../../../components';
import UploadItem from '../../../components/UploadWithDragAndDrop/Item';
import { FetchUploads } from '../../../graphql';
import { theme } from '../../../theme';
import BaseTooltip from '../../../components/BaseTooltip';
import BaseIconButton from '../../../components/BaseIconButton';
export default (function (_a) {
    var record = _a.record, config = _a.config, content = _a.content;
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    if (!record.totalFiles)
        return (React.createElement(Grid, { container: true },
            React.createElement(AttachFileIcon, { style: { width: 22, height: 22, marginRight: theme.spacing(1) } }),
            content));
    var baseListProps = {
        client: config && config.client,
        query: FetchUploads,
        defaultFilter: {
            fields: [
                { name: 'parent', value: record._id },
                { name: 'fileType', value: 'invoices' },
            ],
        },
        searchFields: ['name'],
        sortFields: [{ name: langOrderList.t('order_list_filter_sort_created_at'), value: 'createdAt' }].filter(function (e) { return e; }),
    };
    var _config = {
        id: 'Attachments',
        title: langForm.t('form_invoice_create_attachments'),
        rowContent: function (record, onChange) {
            return React.createElement(UploadItem, { file: record, config: config });
        },
    };
    var dialogBody = React.createElement(BaseList, { options: baseListProps, config: _config });
    return (React.createElement(BasicDialog, { withoutWrapper: true, dialogProps: { fullWidth: true, maxWidth: 'md' }, title: langForm.t('form_invoice_create_attachments'), isMiddleBody: true, body: dialogBody, actionInit: function (onOpen) { return (React.createElement(Grid, { onClick: onOpen, container: true },
            React.createElement(BaseTooltip, { title: langOfferForm.t('offer_document_attached'), style: { marginLeft: 12 } },
                React.createElement(BaseIconButton, { style: { padding: 0, marginRight: theme.spacing(1) } },
                    React.createElement(StyledBadge, { badgeContent: record.totalFiles, color: "primary" },
                        React.createElement(AttachFileIcon, { style: { width: 22, height: 22 } })))),
            content)); } }));
});
