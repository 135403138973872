import { PHOTO_FILE_TYPES } from './pages/Chat/ChannelItemDetailsChatHistoryItem';
import { theme } from './theme';
export var COUNTRY_COLOR = {};
export var COLORS = [
    'rgb(66,239,24)',
    'rgb(80,40,160)',
    'rgb(219,251,37)',
    'rgb(112,211,159)',
    'rgb(145,26,46)',
    'rgb(63,47,30)',
    'rgb(87,17,168)',
    'rgb(152,111,146)',
    'rgb(225,73,32)',
    'rgb(221,148,153)',
    'rgb(167,167,159)',
    'rgb(143,64,95)',
    'rgb(137,108,99)',
    'rgb(179,189,59)',
    'rgb(91,97,41)',
    'rgb(17,32,76)',
    'rgb(54,246,232)',
    'rgb(107,95,135)',
    'rgb(14,192,57)',
    'rgb(222,142,97)',
    'rgb(223,10,56)',
    'rgb(199,139,211)',
    'rgb(72,125,234)',
    'rgb(1,242,92)',
    'rgb(241,87,145)',
    'rgb(48,142,247)',
    'rgb(225,143,145)',
    'rgb(189,171,235)',
    'rgb(160,231,177)',
    'rgb(223,185,251)',
    'rgb(43,162,53)',
    'rgb(8,164,55)',
    'rgb(121,69,105)',
    'rgb(247,102,25)',
    'rgb(89,214,92)',
    'rgb(21,164,140)',
    'rgb(181,240,15)',
    'rgb(201,250,69)',
    'rgb(213,87,146)',
    'rgb(16,238,145)',
    'rgb(69,121,211)',
    'rgb(156,192,17)',
    'rgb(212,199,158)',
    'rgb(22,229,154)',
    'rgb(75,102,217)',
    'rgb(67,114,35)',
    'rgb(38,59,215)',
    'rgb(30,235,80)',
    'rgb(182,180,106)',
    'rgb(190,110,244)',
    'rgb(75,186,93)',
    'rgb(62,209,202)',
    'rgb(89,101,160)',
    'rgb(26,81,245)',
    'rgb(89,38,38)',
    'rgb(164,176,135)',
    'rgb(34,99,85)',
    'rgb(89,243,0)',
    'rgb(85,21,139)',
    'rgb(163,148,116)',
    'rgb(128,19,19)',
    'rgb(14,167,6)',
    'rgb(206,133,106)',
    'rgb(42,107,157)',
    'rgb(164,168,21)',
    'rgb(161,3,252)',
    'rgb(57,3,191)',
    'rgb(224,54,236)',
    'rgb(63,210,170)',
    'rgb(96,108,118)',
    'rgb(181,171,206)',
    'rgb(176,61,158)',
    'rgb(97,11,202)',
    'rgb(164,196,40)',
    'rgb(37,16,226)',
    'rgb(71,192,161)',
    'rgb(157,250,29)',
    'rgb(79,199,195)',
    'rgb(12,38,142)',
    'rgb(127,51,59)',
    'rgb(87,57,143)',
    'rgb(242,224,37)',
    'rgb(125,9,221)',
    'rgb(74,149,131)',
    'rgb(129,155,209)',
    'rgb(198,68,185)',
    'rgb(249,6,159)',
    'rgb(86,178,211)',
    'rgb(53,49,54)',
    'rgb(104,181,195)',
    'rgb(11,74,247)',
    'rgb(72,177,228)',
    'rgb(180,227,237)',
    'rgb(254,80,178)',
    'rgb(181,137,61)',
    'rgb(103,5,53)',
    'rgb(212,61,164)',
    'rgb(129,145,221)',
    'rgb(23,22,205)',
    'rgb(63,231,195)',
];
export var getRandomColors = function (usedColors) {
    var set = new Set(usedColors);
    var availableColors = COLORS.filter(function (color) { return !set.has(color); });
    var randomColorIdx = Math.floor(Math.random() * availableColors.length);
    return availableColors[randomColorIdx];
};
export var legendStyleV1Dots = {
    fullWidth: false,
    align: 'start',
    labels: {
        usePointStyle: true,
        pointStyle: 'circle',
        pointRadius: 10,
        boxWidth: 7,
        color: theme.palette.common.black,
        font: {
            family: 'Montserrat',
        },
    },
};
export var ticksStyleV1 = {
    color: 'rgba(76, 111, 255, 1)',
    font: {
        family: 'Montserrat',
    },
};
export var legendMargin = function (height) {
    if (height === void 0) { height = 50; }
    return ({
        id: 'legendMargin',
        beforeInit: function (chart, legend, options) {
            var fitValue = chart.legend.fit;
            chart.legend.fit = function fit() {
                fitValue.bind(chart.legend)();
                return (this.height += height);
            };
        },
    });
};
export var checkPicture = function (fileName) {
    var splittedName = (fileName && fileName.split('.')) || [];
    return PHOTO_FILE_TYPES.includes(((splittedName.length && splittedName[splittedName.length - 1]) || '').toLowerCase());
};
export var getVideoIndex = function (files) {
    // files only pics and videos
    var videoIndex = -1;
    for (var i = 0; i < files.length; i++) {
        var image = files[i];
        if (!checkPicture(image.name)) {
            videoIndex = i;
            break;
        }
    }
    return videoIndex;
};
export function decodeHtml(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
}
export function basicParseFloat(number) {
    if (typeof number === 'string') {
        number = number.replace(/,/, '.');
    }
    return parseFloat(number);
}
export function yupTransformFloatNumber(_, value) {
    if (!value || typeof value !== 'string')
        return value;
    return +value.replace(/,/, '.');
}
