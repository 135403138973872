var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { BasicDialog, onPreventEvent } from '../../Dialog';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../../i18n';
import { inject, observer, useLocalStore } from 'mobx-react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { ACTION_CHANGE } from '../../BasicList';
import { FormikTextField } from '../../Formik';
import { onlySpacesRegExp } from '../../../common';
import OrderRouteMenuItem from './OrderRoutesMenuItem';
import GradeIcon from '@material-ui/icons/Grade';
import BaseTooltip from '../../BaseTooltip';
import { CreateUserRating, ApproveUserRating } from '../../../graphql/userRatings';
import { theme } from '../../../theme';
import { BaseUserAppTYpe } from '../../../stores/common';
import { FormikRateField } from '../../Formik/FormikRateField';
import { TextOverflow } from '../../TextOverflow';
import { HelpIconComponent } from '../../HelpIcon';
import { FetchOrderQuoteRequestChanges } from '../../../graphql/order/orderQuoteRequestChange';
import { dateViewFormat } from '../../DateView';
import { AvatarItemView } from '../../Account/Avatar';
import OrderRoutesHeader from './OrderRoutesHeader';
import { googleTagManager } from '../../../utils1';
import { toDateOnly } from '../../../utils';
import { UpdateOrder } from '../../../graphql';
import { MainButton } from '../../MainButton';
import BaseIconButton from '../../BaseIconButton';
var totalScoreCalc = function (options) {
    var value = Math.ceil((options || []).map(function (e) { return (e.value / 5) * 100; }).reduce(function (a, b) { return a + b; }, 0) / (options || []).length);
    return isNaN(value) ? 0 : value;
};
export default inject('store')(observer(function (_a) {
    var _b, _c, _d;
    var config = _a.config, order = _a.order, quote = _a.quote, currentUser = _a.store.accountStore.currentUser, props = __rest(_a, ["config", "order", "quote", "store"]);
    var formRef = React.useRef();
    var _e = __read(React.useState([]), 2), requestChanges = _e[0], setRequestChanges = _e[1];
    var langApp = useTranslation(KEYS.APP);
    var langToast = useTranslation(KEYS.TOAST);
    var langSettings = useTranslation(KEYS.SETTINGS);
    var langForm = useTranslation(KEYS.FORM);
    var openRefV1 = React.useRef();
    var isUserSawModal = (order.membersSawRatingPromotion || []).find(function (user) { return user._id.toString() === currentUser._id.toString(); });
    // if (!IS_DEV_ENV) {
    //   React.useEffect(() => {
    //     if (props.v1 && !isUserSawModal && !order.rating) {
    //       setTimeout(() => {
    //         openRefV1?.current?.click();
    //       }, 2000);
    //     }
    //   }, []);
    // }
    var localStore = useLocalStore(function () {
        var _a;
        return ({
            error: '',
            isLoading: false,
            initialValues: __assign({ totalScore: totalScoreCalc(((_a = order === null || order === void 0 ? void 0 : order.rating) === null || _a === void 0 ? void 0 : _a.options) || []), reason: '', comment: '', options: [{ name: 'satisfaction', value: 0 }] }, (order.rating || {})),
            onOpen: function (e) {
                return __awaiter(this, void 0, void 0, function () {
                    var res, e_1;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                _a.trys.push([0, 2, , 3]);
                                googleTagManager('event', "izi-user-rating-show", __assign(__assign({}, [
                                    'sorting_countryPickup',
                                    'sorting_pickupAt',
                                    'sorting_countryDelivery',
                                    'sorting_deliveryAt',
                                    'sorting_totalWeight',
                                    'orderId',
                                ]
                                    .filter(function (e) { return order[e]; })
                                    .map(function (e) {
                                    var _a;
                                    return (_a = {}, _a["order_" + e] = order[e], _a);
                                })
                                    .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { offer_startDate: toDateOnly(quote.tempTransportations.length && quote.tempTransportations[0].startDate), offer_endDate: toDateOnly(quote.tempTransportations.length &&
                                        quote.tempTransportations[quote.tempTransportations.length - 1].endDate), offer_amount: quote.amount, offer_currency: quote.currency }));
                                return [4 /*yield*/, config.client.query({
                                        query: FetchOrderQuoteRequestChanges,
                                        variables: { filter: { fields: [{ name: 'order', value: order._id }] } },
                                    })];
                            case 1:
                                res = _a.sent();
                                setRequestChanges(res.data.orderQuoteRequestChanges.data);
                                return [3 /*break*/, 3];
                            case 2:
                                e_1 = _a.sent();
                                console.log(e_1);
                                return [3 /*break*/, 3];
                            case 3: return [2 /*return*/];
                        }
                    });
                });
            },
            onSubmit: function (_a) {
                var comment = _a.comment, _input = __rest(_a, ["comment"]);
                return __awaiter(this, void 0, void 0, function () {
                    var res, options, _b, input, _c, e_2;
                    return __generator(this, function (_d) {
                        switch (_d.label) {
                            case 0:
                                localStore.error = '';
                                localStore.isLoading = true;
                                _d.label = 1;
                            case 1:
                                _d.trys.push([1, 8, 9, 10]);
                                res = void 0;
                                options = _input.options.map(function (_a) {
                                    var name = _a.name, value = _a.value;
                                    return ({ name: name, value: value });
                                });
                                if (!props.isAdmin) return [3 /*break*/, 4];
                                _b = config;
                                if (!_b) return [3 /*break*/, 3];
                                return [4 /*yield*/, config.client.mutate({
                                        mutation: ApproveUserRating,
                                        variables: {
                                            input: {
                                                comment: comment,
                                                options: options,
                                                _id: order.rating._id,
                                            },
                                        },
                                    })];
                            case 2:
                                _b = (_d.sent());
                                _d.label = 3;
                            case 3:
                                res = (_b).data.approveUserRating;
                                return [3 /*break*/, 7];
                            case 4:
                                input = {
                                    comment: comment,
                                    options: options,
                                    user: quote.createdBy._id,
                                    parent: order._id,
                                };
                                googleTagManager('event', "izi-user-rating-submit", __assign(__assign(__assign({}, [
                                    'sorting_countryPickup',
                                    'sorting_pickupAt',
                                    'sorting_countryDelivery',
                                    'sorting_deliveryAt',
                                    'sorting_totalWeight',
                                    'orderId',
                                ]
                                    .filter(function (e) { return order[e]; })
                                    .map(function (e) {
                                    var _a;
                                    return (_a = {}, _a["order_" + e] = order[e], _a);
                                })
                                    .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { offer_startDate: toDateOnly(quote.tempTransportations[0].startDate), offer_endDate: toDateOnly(quote.tempTransportations[quote.tempTransportations.length - 1].endDate), offer_amount: quote.amount, offer_currency: quote.currency }), input));
                                _c = config;
                                if (!_c) return [3 /*break*/, 6];
                                return [4 /*yield*/, config.client.mutate({
                                        mutation: CreateUserRating,
                                        variables: {
                                            input: input,
                                        },
                                    })];
                            case 5:
                                _c = (_d.sent());
                                _d.label = 6;
                            case 6:
                                res = (_c).data.createUserRating;
                                Object.assign(localStore.initialValues, res);
                                order.rating = res;
                                _d.label = 7;
                            case 7:
                                toast.success(langToast.t('toast_add_rating'));
                                if (props.onChange)
                                    props.onChange(order, ACTION_CHANGE.DELETED);
                                if (props.onChangeV1)
                                    props.onChangeV1({});
                                return [2 /*return*/, res];
                            case 8:
                                e_2 = _d.sent();
                                toast.error(e_2.message);
                                return [3 /*break*/, 10];
                            case 9:
                                localStore.isLoading = false;
                                return [7 /*endfinally*/];
                            case 10: return [2 /*return*/];
                        }
                    });
                });
            },
            clearError: function () {
                localStore.error = '';
            },
        });
    });
    if (!order)
        return null;
    var isOrderForwarder = (_b = order.forwarders) === null || _b === void 0 ? void 0 : _b.find(function (user) { return user._id.toString() === currentUser._id.toString(); });
    var isQuoteCreator = ((_d = (_c = order.latestQuote) === null || _c === void 0 ? void 0 : _c.createdBy) === null || _d === void 0 ? void 0 : _d._id.toString()) === currentUser._id.toString();
    var available = order.publishedAt &&
        ([BaseUserAppTYpe.CUSTOMER].find(function (t) { return currentUser.appType.includes(t); }) ||
            (isOrderForwarder && !isQuoteCreator));
    if (props.isAdmin) {
    }
    else if (!available || !order || !order.createdBy || !quote || !quote.createdBy) {
        return null;
    }
    var onClose = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var membersSawRatingPromotion, res, _a, e_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(props.v1 && !isUserSawModal)) return [3 /*break*/, 6];
                    membersSawRatingPromotion = __spread((order.membersSawRatingPromotion || []), [{ _id: currentUser._id }]);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 4, , 5]);
                    _a = config;
                    if (!_a) return [3 /*break*/, 3];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: UpdateOrder,
                            variables: {
                                input: {
                                    _id: order._id,
                                    membersSawRatingPromotion: membersSawRatingPromotion //TODO: this is not COOL, should be on  api side be similar logic
                                        .map(function (user) { return user._id; })
                                        .filter(function (e) { return e; }),
                                },
                            },
                        })];
                case 2:
                    _a = (_b.sent());
                    _b.label = 3;
                case 3:
                    res = _a;
                    return [3 /*break*/, 5];
                case 4:
                    e_3 = _b.sent();
                    toast.error(e_3.message);
                    return [3 /*break*/, 5];
                case 5:
                    if (props.onChangeV1)
                        props.onChangeV1({ withoutQuote: 1, softOrderUpdate: 1 });
                    _b.label = 6;
                case 6:
                    googleTagManager('event', "izi-user-rating-close", __assign(__assign({}, [
                        'sorting_countryPickup',
                        'sorting_pickupAt',
                        'sorting_countryDelivery',
                        'sorting_deliveryAt',
                        'sorting_totalWeight',
                        'orderId',
                    ]
                        .filter(function (e) { return order[e]; })
                        .map(function (e) {
                        var _a;
                        return (_a = {}, _a["order_" + e] = order[e], _a);
                    })
                        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {})), { offer_startDate: toDateOnly(quote.tempTransportations[0].startDate), offer_endDate: toDateOnly(quote.tempTransportations[quote.tempTransportations.length - 1].endDate), offer_amount: quote.amount, offer_currency: quote.currency }));
                    return [2 /*return*/];
            }
        });
    }); };
    var onOk = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var rejectedItem;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    onPreventEvent(e);
                    return [4 /*yield*/, formRef.current.submitForm()];
                case 1:
                    rejectedItem = _a.sent();
                    if (!rejectedItem)
                        throw Error(i18n.getResource(i18n.language, KEYS.TOAST, 'toast_form_not_valid'));
                    if (props.onChange)
                        props.onChange(rejectedItem, ACTION_CHANGE.DELETED);
                    return [2 /*return*/];
            }
        });
    }); };
    var disabled = ((props.isAdmin && order.rating.approvedAt) || !props.isAdmin) && !!order.rating;
    var dialogBody = (React.createElement(React.Fragment, null,
        (localStore.error && (React.createElement(Typography, { color: "error", style: { marginBottom: theme.spacing(1) } }, localStore.error))) ||
            null,
        React.createElement(Grid, { style: { marginBottom: theme.spacing(1) } },
            React.createElement(Typography, null, langForm.t('form_input_rating_help_v1'))),
        React.createElement(OrderRoutesHeader, __assign({}, __assign({ order: order, v2: true, v4: true, v5: true }, props))),
        requestChanges.length ? (React.createElement(Grid, { container: true },
            React.createElement(TextOverflow, { style: { fontWeight: 600, margin: theme.spacing(1, 'auto') } }, langForm.t('form_input_rating_request_changes').replace(/_X_/, requestChanges.length.toString())),
            requestChanges.map(function (e) { return (React.createElement(Grid, { container: true, key: e._id, alignItems: 'flex-end', spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12, md: 6, style: { paddingTop: theme.spacing(1) } },
                    React.createElement(AvatarItemView, { more: true, typographyProps: {
                            variant: 'h6',
                            style: {
                                fontSize: 14,
                                fontWeight: 600,
                                color: theme.palette.common.white,
                            },
                        }, user: e.createdBy })),
                React.createElement(Grid, { item: true, xs: 12, md: 3 },
                    React.createElement(TextOverflow, { variant: 'subtitle1' }, dateViewFormat(e.createdAt, 1))),
                React.createElement(Grid, { item: true, xs: 12, md: 3, justify: 'flex-end' },
                    React.createElement(TextOverflow, { variant: 'caption' }, e.comment)))); }),
            React.createElement(Divider, { style: { margin: theme.spacing(2, 0), width: '100%' } }))) : null,
        React.createElement(Formik, { key: [localStore.error, localStore.isLoading].join('_'), innerRef: formRef, initialValues: localStore.initialValues, validationSchema: Yup.object({
                comment: Yup.string()
                    .max(1000, langForm.t('form_input_validate_max_char').replace(/_X_/, '1000'))
                    .matches(onlySpacesRegExp, langForm.t('form_input_validate_invalid_text'))
                    .required(langForm.t('form_input_validate_required')),
                options: Yup.array().of(Yup.object({
                    name: Yup.string().required(),
                    value: Yup.number().min(1, langForm.t('form_input_validate_min_char').replace(/_X_/, '1')),
                })),
            }), onSubmit: localStore.onSubmit }, function (formik) {
            var onChange = function (e) {
                var _a = e.target, name = _a.name, value = _a.value;
                if (name.match('options')) {
                    var options = formik.values.options.map(function (e, index) {
                        if (name.match(index)) {
                            return __assign(__assign({}, e), { value: value });
                        }
                        return e;
                    });
                    formik.setFieldValue('options', options);
                    formik.setFieldValue('totalScore', totalScoreCalc(options));
                }
            };
            return (React.createElement(Form, { id: 'btn-order-rating', name: 'btn-order-rating', style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: theme.spacing(1),
                    margin: '0 auto',
                } },
                React.createElement(Grid, { container: true, alignItems: 'center', style: { gap: theme.spacing(1) }, spacing: 1 },
                    React.createElement(Grid, { item: true, xs: 12, alignItems: 'center', container: true }, (props.isAdmin && (React.createElement(Grid, { container: true, style: { width: 'initial' }, alignItems: 'center' },
                        React.createElement(TextOverflow, { style: { margin: theme.spacing(0, 1) } }, "Current user rate:"),
                        React.createElement(TextOverflow, { color: 'primary', style: { fontWeight: 600 } }, (order.rating.user.rating && Math.round(order.rating.user.rating * 100) + "%") ||
                            'No rates yet')))) ||
                        null)),
                formik.values.options.map(function (e, index) {
                    var _a;
                    return (React.createElement(Grid, { container: true, direction: "column", style: { marginLeft: -12 } },
                        React.createElement(FormikRateField, { disabled: disabled, title: langForm.t("form_input_rating_" + e.name), help: langForm.t("form_input_rating_" + e.name + "_help"), variant: "outlined", name: "options[" + index + "].value", value: formik.values.options[index].value, onChange: onChange }),
                        formik.submitCount > 0 && ((_a = formik.values.options) === null || _a === void 0 ? void 0 : _a.length) && !formik.values.options[0].value && (React.createElement(TextOverflow, { color: "error", style: { textAlign: 'center' } }, langForm.t('form_input_rating_help')))));
                }),
                React.createElement(Grid, { container: true, direction: "column", style: { marginBottom: theme.spacing(1) } },
                    React.createElement(FormikTextField, { disabled: disabled, multiline: true, rows: 4, title: langForm.t('form_input_comment'), variant: "outlined", name: "comment", value: formik.values.comment })),
                (props.noneModal && (React.createElement(Grid, { container: true, direction: "column", style: { marginBottom: theme.spacing(1), minWidth: 140 } },
                    React.createElement(MainButton, { loading: localStore.isLoading, type: 'submit', title: langForm.t('form_btn_rating'), style: { width: '100%' }, id: 'modal_save_rate' })))) ||
                    null));
        })));
    if (props.noneModal)
        return dialogBody;
    var title = props.isAdmin ? 'Approve rate' : langSettings.t('settings_notify_order_quote_un_loading_at');
    var _title = title + ((!props.isAdmin && order.rating && "." + (order.rating.comment || '')) || '') || '';
    var dialogTitle = (React.createElement(Grid, { container: true, alignItems: 'center', justify: 'center' },
        React.createElement(TextOverflow, { variant: 'h6' }, title),
        React.createElement(HelpIconComponent, { title: langForm.t('form_input_rating_label_help'), warn: true })));
    return ((config && (React.createElement(BasicDialog, __assign({ shouldNotPrevent: true }, (props.isAdmin && { actionOkTitle: 'Approve' }), { key: localStore.initialValues.updatedAt, onOk: onOk, onClose: onClose, body: dialogBody, title: dialogTitle, hidActionOkBth: disabled, actionOkTitle: title, actionCancelTitle: langApp.t('main_cancel'), dialogProps: { fullWidth: true, maxWidth: 'sm' }, withoutWrapper: true, bodyStyle: {
            minHeight: theme.spacing(40),
            flexDirection: 'column',
            justifyContent: 'center',
        }, actionInit: function (handleClickOpen) {
            if (props.v1) {
                return React.createElement("span", { onClick: handleClickOpen, ref: openRefV1 });
            }
            var onClick = disabled && 0
                ? function () { return 0; }
                : function (e) {
                    handleClickOpen(e);
                    localStore.onOpen(e);
                };
            var icon = (React.createElement(BaseTooltip, { title: _title },
                React.createElement(BaseIconButton, { onClick: onClick, id: 'btn-order-rating' },
                    React.createElement(GradeIcon, { style: ((!order.rating || (props.isAdmin && !order.rating.approvedAt)) && {
                            color: theme.palette.success.light,
                        }) ||
                            {} }))));
            if (props.label)
                return React.createElement(OrderRouteMenuItem, __assign({}, { icon: icon, label: title, handleClickOpen: onClick }));
            return icon;
        } })))) ||
        null);
}));
