var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import Menu from '@material-ui/core/Menu';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        popover: {
            pointerEvents: 'none',
        },
        paper: {
            padding: theme.spacing(1),
            '& .MuiListItem-root': {
                paddingLeft: 0,
            },
        },
        popOverRoot: {
            pointerEvents: 'none',
        },
    });
});
var index = 1;
export default function MenuOverPopover(props) {
    var currentlyHovering = false;
    var styles = useStyles();
    var _a = __read(React.useState('mouse-over-popover-' + index++), 1), id = _a[0];
    var classes = useStyles();
    var _b = __read(React.useState(null), 2), anchorEl = _b[0], setAnchorEl = _b[1];
    var _c = __read(React.useState(false), 2), openMobile = _c[0], setOpenMobile = _c[1];
    var theme = useTheme();
    var isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    React.useEffect(function () {
        return function () {
            removeEvents();
        };
    }, []);
    var handlePopoverClose = function () {
        setAnchorEl(null);
    };
    var events = [
        {
            elem: document.body,
            name: 'click,touchend',
            callback: function (e) {
                var node = e.target;
                var canFind = true;
                var delay = 0;
                do {
                    if (node && node.getAttribute && node.getAttribute('id') === props.clickedId) {
                        canFind = false;
                        delay = 1000;
                    }
                    else {
                        node = node.parentNode;
                        if (!node || !node.tagName || node.tagName.toString().toUpperCase() === 'BODY') {
                            canFind = false;
                        }
                    }
                } while (canFind);
                if (!delay) {
                    setOpenMobile(false);
                }
            },
        },
    ];
    var initEvents = function () {
        removeEvents();
        events.forEach(function (_a) {
            var elem = _a.elem, name = _a.name, callback = _a.callback;
            name.split(',').forEach(function (name) {
                elem.addEventListener(name, callback);
            });
        });
    };
    var removeEvents = function () {
        events.forEach(function (_a) {
            var elem = _a.elem, name = _a.name, callback = _a.callback;
            name.split(',').forEach(function (name) {
                elem.removeEventListener(name, callback);
            });
        });
    };
    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
        if (isSmall) {
            if (!openMobile) {
                initEvents();
                setOpenMobile(true);
            }
            else {
                setOpenMobile(false);
            }
        }
    }
    function handleHover() {
        currentlyHovering = true;
    }
    function handleClose() {
        setAnchorEl(null);
    }
    function handleCloseHover() {
        currentlyHovering = false;
        setTimeout(function () {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 50);
    }
    var open = isSmall ? openMobile : Boolean(anchorEl);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { "aria-owns": open ? id : undefined, "aria-haspopup": "true", onClick: handleClick, onMouseOver: !isSmall ? handleClick : undefined, onMouseLeave: !isSmall ? handleCloseHover : undefined }, props.children),
        React.createElement(Menu, { id: id, className: classes.popover, classes: {
                paper: classes.paper,
            }, anchorEl: anchorEl, open: open, onClose: handlePopoverClose, MenuListProps: __assign({ style: { pointerEvents: 'auto' } }, (!isSmall && { onMouseEnter: handleHover, onMouseLeave: handleCloseHover })), anchorOrigin: {
                horizontal: 'right',
                vertical: 'top',
            }, PopoverClasses: {
                root: styles.popOverRoot,
            }, transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
            }, disableRestoreFocus: true },
            React.createElement("div", { onClick: handleClose }, props.content))));
}
