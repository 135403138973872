var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React, { useEffect } from 'react';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import * as reactRouter from 'react-router';
import { Link, withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { theme } from '../theme';
import { useSectionStyles } from '../styles';
import { TabPanel, TabsV1, TabV1 } from './Settings/Tabs';
import BaseListTitle from './BasicList/Header/BaseListTitle';
import { TextOverflow } from './TextOverflow';
import { StyledBadgeV13 } from './StyledBadge';
import { useSettingsStyles } from './Settings/styles';
import clsx from 'clsx';
var Switch = reactRouter.Switch;
var Redirect = reactRouter.Redirect;
var Route = reactRouter.Route;
export var useTabsV1Styles = makeStyles(function (theme) {
    return createStyles({
        root: {
        // paddingRight: 3,
        // background: theme.palette.primary.light,
        },
        indicator: {
            background: theme.palette.primary.light,
        },
    });
});
var _checkLocation = function (pathname, routes) {
    for (var i = 0; i < routes.length; i++) {
        if (pathname && routes[i].url.toLowerCase() === pathname.toLowerCase()) {
            return i;
        }
    }
    for (var i = 0; i < routes.length; i++) {
        if ((pathname && routes[i].url.toLowerCase().match(pathname.toLowerCase())) ||
            (pathname && routes[i].id && pathname.toLowerCase().match(routes[i].id.toLowerCase())) ||
            (pathname && routes[i].urls && routes[i].urls.find(function (e) { return e.toLowerCase().match(pathname.toLowerCase()); }))) {
            return i;
        }
    }
    return 0;
};
var MainTabsRoutes = inject('store')(observer(function (_a) {
    var routes = _a.routes, currentLocation = _a.store.accountStore.currentLocation, pathname = _a.location.pathname, title = _a.title, ignoreRedirect = _a.ignoreRedirect, props = __rest(_a, ["routes", "store", "location", "title", "ignoreRedirect"]);
    var _b = __read(React.useState(_checkLocation(pathname, routes)), 2), value = _b[0], setValue = _b[1];
    var classes = useTabsV1Styles();
    var classesSection = useSectionStyles();
    var classesSettings = useSettingsStyles();
    useEffect(function () {
        if (currentLocation)
            setValue(_checkLocation(currentLocation, routes));
    }, [currentLocation]);
    var _routes = props.v1 ? (React.createElement(React.Fragment, null, routes
        .map(function (route) { return pathname === route.path && route.component(); })
        .filter(function (e) { return e; }))) : (React.createElement(Switch, null,
        routes.map(function (route) { return (React.createElement(Route, { key: route.path, path: route.path, component: route.component })); }),
        (!ignoreRedirect && (React.createElement(Route, { path: "*" },
            React.createElement(Redirect, { to: routes[0].path })))) ||
            null));
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    var routesTab = routes.filter(function (e) { return e.name; });
    return (React.createElement(Grid, { container: true },
        React.createElement(Grid, { container: true, justify: 'space-between', alignItems: 'center' },
            (title && React.createElement(BaseListTitle, { title: title })) || null,
            (!props.hideNavigation && (React.createElement(TabsV1, { orientation: isSmall ? 'vertical' : 'horizontal', style: { width: isSmall ? '100%' : 'initial' }, value: value, textColor: "primary", variant: "fullWidth", classes: {
                    root: classes.root,
                } }, routesTab.map(function (r, index) {
                var content = (React.createElement(TabV1, { label: r.label ||
                        (typeof r.name === 'string' ? React.createElement(TextOverflow, { variant: 'h5' }, r.name) : r.name), color: 'primary', selected: value === index, isLast: index === routesTab.length - 1, onClick: function () { return setValue(index); } }));
                if (r.total) {
                    content = (React.createElement(StyledBadgeV13, { color: "primary", badgeContent: r.total }, content));
                }
                if (props.withoutRoutes)
                    return content;
                return (React.createElement(Link, { to: r.url || r.path, key: r.path, style: { textDecoration: 'initial', color: 'inherit' } }, content));
            })))) ||
                null),
        props.withoutRoutes
            ? routesTab.map(function (e, index) { return (React.createElement(TabPanel, { value: value, index: index, key: e.name, className: clsx(classesSettings.rootV1, classesSection.sectionItem), style: {
                    maxHeight: 'calc(100vh - 64px)',
                    overflowY: 'auto',
                } }, e.component ? e.component() : null)); })
            : null,
        !props.withoutRoutes ? (React.createElement(Grid, { container: true, className: classesSection.sectionItem, style: { padding: theme.spacing(isSmall ? 1 : 4) }, "data-id": "izi-container-routes-view" }, _routes)) : null));
}));
export var MainTabsRoutesVIew = withRouter(MainTabsRoutes);
