var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import HistoryIcon from '@material-ui/icons/History';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import PopUpHover from '../PopupHover';
import { inject } from 'mobx-react';
import { theme } from '../../theme';
import { StyledBadgeV9 } from '../StyledBadge';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { SectionHistoryAction } from './types';
import PreloadingComponent from '../PreloadingComponent';
var Activity = React.lazy(function () { return import('../../pages/Activity'); });
export default inject('store')(function SectionHistory(propsAll) {
    var _a;
    var accountStore = propsAll.store.accountStore, record = propsAll.record, order = propsAll.order, type = propsAll.type, props = __rest(propsAll, ["store", "record", "order", "type"]);
    var _b = __read(React.useState(type === SectionHistoryAction.ORDER_HISTORY ? order === null || order === void 0 ? void 0 : order.lastLog : record.lastLog), 2), lastLog = _b[0], setLastLog = _b[1];
    var _c = __read(React.useState(__assign(__assign({}, props.filters), { fields: __spread((((_a = props === null || props === void 0 ? void 0 : props.filters) === null || _a === void 0 ? void 0 : _a.fields) || []), [!props.v3 && { name: 'history_review', value: 1 }]).filter(function (e) { return e; }) })), 2), filter = _c[0], setFilter = _c[1];
    var langApp = useTranslation(KEYS.APP);
    var content = (React.createElement(PreloadingComponent, null,
        React.createElement(Activity, __assign({ isModal: true, defaultFilter: filter, config: props === null || props === void 0 ? void 0 : props.config }, __assign({ record: record, order: order, type: type }, props)))));
    var title = langApp.t('history');
    var hasUpdates = !props.v3 &&
        lastLog &&
        !__spread(lastLog.review, [lastLog.createdBy]).map(function (e) { return e._id; }).includes(accountStore.currentUser._id);
    var icon = props.icon || (React.createElement(HistoryIcon, { id: 'btn-history', style: __assign(__assign({}, props.style), (hasUpdates && { color: theme.palette.success.light })) }));
    if (hasUpdates) {
        icon = React.createElement(StyledBadgeV9, { badgeContent: 1 }, icon);
    }
    var theme1 = useTheme();
    var isSmall = useMediaQuery(theme1.breakpoints.down('sm'));
    return (React.createElement(PopUpHover, { v1: props.v1, v2: props.v2, maxHeight: '70vh', PaperProps: {
            style: {
                overflow: 'hidden',
                paddingBottom: theme.spacing(1),
                minHeight: isSmall ? window.innerHeight : Math.min(window.innerHeight, 580),
                width: isSmall ? window.innerWidth : Math.min(window.innerWidth, 330),
            },
        }, mobileMinWidth: '91vw', title: title, style: __assign({}, (props.v3 && { padding: 0 })), parentStyle: __assign(__assign({}, (props.v1 && { width: 48 })), (props.v2 && { width: '100%' })), parentSpanStyle: __assign(__assign({}, (props.v1 && { marginLeft: 12 })), (props.v2 && { height: 40, width: '100%' })), show: true, icon: icon, content: content }));
});
