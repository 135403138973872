var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
var _a, _b, _c;
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import * as material from '@material-ui/core';
import { Form, Formik } from 'formik';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import { AssignedInTransitIcon, AssignedTransportIcon, ConfirmedIcon, AssignedLoadingTimeIcon, AssignedUnLoadedIcon, OfferRequestedLogoIcon, OfferSubmittedLogoIcon, } from '../../icons';
import { OrderQuoteState, OrderQuoteStatusState, OrderStatus } from '../../../models';
import { i18n, KEYS } from '../../../i18n';
import { useChipStyles, useIconStyles, useMainStyles } from '../../../styles';
import { FormikAutocomplete, FormikAutocompleteAsync, formikAutocompleteMultiOptionsProps, formikAutocompleteMultiOptionsUsersProps, } from '../../Formik';
import { theme } from '../../../theme';
import { FetchCompaniesShort, FetchOrderUsers, FetchWarehouses } from '../../../graphql';
import { OrderPaymentStatus } from '../../../models/OrderPayment';
import { inject, observer } from 'mobx-react';
import { BaseUserAppTYpe } from '../../../stores/common';
import { FormikCheckBoxField } from '../../Formik/FormikCheckboxField';
import { HelpIconComponent } from '../../HelpIcon';
import BaseTooltip from '../../BaseTooltip';
import { toDateOnly } from '../../../utils';
import LocationCountryFilter from './ListFilterLocationCountryFilter';
import BaseChip from '../../BaseChip';
import RangeDateFilter from '../../Formik/RangeDateFilter';
import { FetchOrderVehicleType } from '../../../graphql/order/orderVehicleType';
import BaseListFiltersWrapper from '../../BasicList/Header/FiltersWrapper';
import { withBaseListStore } from '../../BasicList/store';
var useMediaQuery = material.useMediaQuery;
var STATUS_OPEN_ORDERS = (_a = {},
    _a[OrderStatus.WaitingQuotes] = 'offer_requested',
    _a[OrderStatus.HasQuotes] = 'offer_submitted',
    _a);
var STATUS_OPEN_ORDERS_ICON = (_b = {},
    _b[OrderStatus.WaitingQuotes] = React.createElement(OfferRequestedLogoIcon, null),
    _b[OrderStatus.HasQuotes] = React.createElement(OfferSubmittedLogoIcon, null),
    _b);
var STATUS_IN_PROGRESS_ORDERS = Object.keys(OrderQuoteStatusState)
    .map(function (e) {
    var _a;
    return (_a = {},
        _a[OrderQuoteStatusState[e].replace(/-/g, '_')] = OrderQuoteState[e].replace(/-/g, '_'),
        _a);
})
    .reduce(function (a, b) { return (__assign(__assign({}, a), b)); });
var STATUS_IN_PROGRESS_ORDERS_ICON = (_c = {},
    _c[OrderQuoteStatusState.Confirmed] = React.createElement(ConfirmedIcon, null),
    _c[OrderQuoteStatusState.Transport] = React.createElement(AssignedTransportIcon, null),
    _c[OrderQuoteStatusState.TimeSelected] = React.createElement(AssignedLoadingTimeIcon, null),
    _c[OrderQuoteStatusState.InTransit] = React.createElement(AssignedInTransitIcon, null),
    _c[OrderQuoteStatusState.Unloaded] = React.createElement(AssignedUnLoadedIcon, null),
    _c);
var pickupDateTemp;
var publishedAtTemp;
var deliveryDateTemp;
var factualPickupDateTemp;
var factualDeliveryDateTemp;
var OrderFilters = function (_a) {
    var _b;
    var _c, _d;
    var defaultFilter = _a.defaultFilter, hideStatus = _a.hideStatus, hideFilters = _a.hideFilters, approvedQuotes = _a.approvedQuotes, openOrders = _a.openOrders, finished = _a.finished, filter = _a.filter, onChange = _a.onChange, statsQuery = _a.statsQuery, _e = _a.options, options = _e === void 0 ? {} : _e, config = _a.config, isCarrier = _a.isCarrier, isAdmin = _a.isAdmin, emptyFilter = _a.emptyFilter, state = _a.state, props = __rest(_a, ["defaultFilter", "hideStatus", "hideFilters", "approvedQuotes", "openOrders", "finished", "filter", "onChange", "statsQuery", "options", "config", "isCarrier", "isAdmin", "emptyFilter", "state"]);
    var _f = props.store.accountStore, _g = _f.currentUser, appType = _g.appType, _id = _g._id, _h = _f.currentCompany, settings = _h.settings, curCompany = __rest(_h, ["settings"]);
    var isPreparation = location.pathname.toLowerCase().startsWith('/preparation');
    var isForwarder = appType.includes(BaseUserAppTYpe.FORWARDER);
    var isCustomer = appType.includes(BaseUserAppTYpe.CUSTOMER);
    var isSECS = [BaseUserAppTYpe.SENDER, BaseUserAppTYpe.CONSIGNEE].find(function (a) { return appType.includes(a); });
    var langMenu = useTranslation(KEYS.MENU);
    var langOrderList = useTranslation(KEYS.ORDER_LIST);
    var langOrderForm = useTranslation(KEYS.ORDER_FORM);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var _j = __read(React.useState(null), 2), statsData = _j[0], setStats = _j[1];
    var _k = __read(React.useState(false), 2), loading = _k[0], setLoading = _k[1];
    var classesIcon = useIconStyles();
    var classesMain = useMainStyles();
    var classesChip = useChipStyles();
    var isSmall = useMediaQuery(theme.breakpoints.down('xs'));
    var showAll = state.showAdvancedFilters;
    var isMiddle = useMediaQuery(theme.breakpoints.down('md'));
    var isNotOneRow = isMiddle || showAll;
    var loadStats = useCallback(function () {
        var fetchStats = function () {
            return __awaiter(this, void 0, void 0, function () {
                var result, i, data, _a, data, _b, e_1;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            _c.trys.push([0, 10, 11, 12]);
                            setLoading(true);
                            if (!Array.isArray(statsQuery)) return [3 /*break*/, 6];
                            result = [];
                            i = 0;
                            _c.label = 1;
                        case 1:
                            if (!(i < statsQuery.length)) return [3 /*break*/, 5];
                            _a = config;
                            if (!_a) return [3 /*break*/, 3];
                            return [4 /*yield*/, config.client.query({
                                    query: statsQuery[i],
                                })];
                        case 2:
                            _a = (_c.sent());
                            _c.label = 3;
                        case 3:
                            data = (_a).data;
                            result.push.apply(result, __spread(data[Object.keys(data)[0]]));
                            _c.label = 4;
                        case 4:
                            i++;
                            return [3 /*break*/, 1];
                        case 5:
                            setStats(result);
                            return [3 /*break*/, 9];
                        case 6:
                            _b = config;
                            if (!_b) return [3 /*break*/, 8];
                            return [4 /*yield*/, config.client.query(__assign({ query: statsQuery }, (props.orderAnalytics || 1
                                    ? {
                                        variables: {
                                            filter: {
                                                fields: [
                                                    {
                                                        name: 'all-finished',
                                                        value: 1,
                                                    },
                                                ],
                                            },
                                        },
                                    }
                                    : {})))];
                        case 7:
                            _b = (_c.sent());
                            _c.label = 8;
                        case 8:
                            data = (_b).data;
                            setStats(data[Object.keys(data)[0]]);
                            _c.label = 9;
                        case 9: return [3 /*break*/, 12];
                        case 10:
                            e_1 = _c.sent();
                            return [3 /*break*/, 12];
                        case 11:
                            setLoading(false);
                            return [7 /*endfinally*/];
                        case 12: return [2 /*return*/];
                    }
                });
            });
        };
        fetchStats();
    }, [statsQuery]);
    React.useEffect(function () {
        loadStats();
    }, [loadStats]);
    React.useEffect(function () {
        return function () {
            pickupDateTemp = null;
            publishedAtTemp = null;
            deliveryDateTemp = null;
            factualPickupDateTemp = null;
            factualDeliveryDateTemp = null;
        };
    }, []);
    if (hideFilters)
        return null;
    var canViewAllOrdersTool = openOrders && isForwarder && settings && settings.canViewAllOrders;
    var initialValues = __assign({ viewMyCarrierOffers: !!{ value: canViewAllOrdersTool || false }.value, company: [], forwarders: [], manager: [], approvedCarrier: [], countryTo: [], countryFrom: [], vehicleType: [], vehicleTypeToExclude: [], payment: [], documents: [], warehouseList: [], publishedAt: ((_c = filter === null || filter === void 0 ? void 0 : filter.fields.find(function (e) { return e.name === 'publishedAt'; })) === null || _c === void 0 ? void 0 : _c.value) || [null, null], pickupRange: [null, null], deliveryRange: [null, null], factualPickupRange: [null, null], factualDeliveryRange: [null, null] }, [
        'hidePlanned',
        'hideDeliveredOrders',
        'viewAll',
        'hideInnerOrders',
        'hideUnFinishedPreparationOrders',
        'hideFinishedPreparationOrders',
        'showDeletedPublished',
        'useMyPreferences',
    ]
        .map(function (e) {
        var _a;
        var _b;
        return (_a = {}, _a[e] = !!(((_b = filter === null || filter === void 0 ? void 0 : filter.fields) === null || _b === void 0 ? void 0 : _b.find(function (item) { return item.name === e; })) || { value: false }).value, _a);
    })
        .reduce(function (a, b) { return (__assign(__assign({}, a), b)); }, {}));
    var STATUS_KEYS = (openOrders && STATUS_OPEN_ORDERS) || ((approvedQuotes || props.orderAnalytics) && STATUS_IN_PROGRESS_ORDERS);
    var KEYS_ICON = (openOrders && STATUS_OPEN_ORDERS_ICON) ||
        ((approvedQuotes || props.orderAnalytics) && STATUS_IN_PROGRESS_ORDERS_ICON);
    var langMenuTitle = (openOrders && "menu_sidebar_open_orders_" + ((isCarrier && 'carrier_') || '')) ||
        ((approvedQuotes || props.orderAnalytics) && 'menu_sidebar_in_progress_');
    var formKey = (openOrders && 'status') || ((approvedQuotes || props.orderAnalytics) && 'state');
    var f = filter.fields.find(function (e) { return e.name === formKey; });
    var processes = [
        {
            label: langOrderList.t('in_progress_has_transport'),
            value: 5,
        },
        {
            label: langOrderList.t('in_progress_has_not_transport'),
            value: 6,
        },
        {
            label: langOrderList.t('in_progress_has_time_pickup'),
            value: 7,
        },
        {
            label: langOrderList.t('in_progress_has_not_time_pickup'),
            value: 8,
        },
        {
            label: langOrderList.t('in_progress_has_time_delivery'),
            value: 9,
        },
        {
            label: langOrderList.t('in_progress_has_not_time_delivery'),
            value: 10,
        },
        {
            label: langOrderList.t('in_progress_has_loaded'),
            value: 11,
        },
        {
            label: langOrderList.t('in_progress_has_not_loaded'),
            value: 12,
        },
        {
            label: langOfferForm.t('offer_un_loaded'),
            value: 13,
        },
        {
            label: langOrderList.t('in_progress_has_not_un_loaded'),
            value: 14,
        },
        {
            label: langOrderList.t('in_progress_payment_paid'),
            value: OrderPaymentStatus.PAID,
        },
        {
            label: langOrderList.t('in_progress_payment_not_paid'),
            value: OrderPaymentStatus.UNPAID,
        },
        {
            label: langOrderList.t('in_progress_has_cmr'),
            value: 1,
        },
        {
            label: langOrderList.t('in_progress_has_not_cmr'),
            value: 2,
        },
        {
            label: langOrderList.t('in_progress_has_invoice'),
            value: 3,
        },
        {
            label: langOrderList.t('in_progress_has_not_invoice'),
            value: 4,
        },
        {
            label: langApp.t('inner'),
            value: 15,
        },
        finished &&
            !approvedQuotes && {
            label: i18n.getResource(i18n.language, KEYS.APP, 'main_status_canceled'),
            value: 16,
        },
        (approvedQuotes || finished) &&
            isForwarder && {
            label: i18n.getResource(i18n.language, KEYS.ORDER_LIST, 'with_cu_ca'),
            value: 18,
        },
    ].filter(function (e) { return e; });
    [
        'countryTo',
        'countryFrom',
        'payment',
        'process',
        'company',
        'manager',
        'warehouseList',
        'approvedCarrier',
        'forwarders',
        'pickupRange',
        'deliveryRange',
        'factualPickupRange',
        'factualDeliveryRange',
        'vehicleType',
        'vehicleTypeToExclude',
    ].forEach(function (e, index) {
        var _a, _b, _c;
        var val = filter.fields.find(function (item) { return item.name === e; });
        if (val) {
            if (e === 'process') {
                Object.assign(initialValues, (_a = {}, _a[e] = val.value.map(function (_id) { return processes.find(function (f) { return f.value === _id; }); }), _a));
            }
            else if (index > 2) {
                Object.assign(initialValues, (_b = {}, _b[e] = val.value, _b));
            }
            else if (index > 1) {
                // Object.assign(initialValues, { [e]: val.value.map((_id) => paymentOptions.find((f) => f.value === _id)) });
            }
            else {
                Object.assign(initialValues, (_c = {}, _c[e] = val.value.map(function (_id) { return ({ _id: _id }); }), _c));
            }
        }
    });
    var stats = statsData || ((_d = options.filtersStats) === null || _d === void 0 ? void 0 : _d.statsFacets);
    if (stats) {
        if (f) {
            Object.assign(initialValues, (_b = {},
                _b[formKey] = ((Array.isArray(f.value) && f.value) || f.value.split(',')).map(function (e) {
                    var v = stats.find(function (f) { return e && f && f._id && e.toLowerCase() === f._id.toLowerCase(); });
                    var title = STATUS_KEYS[v._id] === 'unloaded'
                        ? langOfferForm.t('offer_un_loaded')
                        : langMenu.t("" + langMenuTitle + STATUS_KEYS[v._id]);
                    return {
                        label: (React.createElement(BaseTooltip, { title: title },
                            React.createElement(Grid, { container: true, style: { width: 'initial' } },
                                React.createElement("div", { style: { position: 'relative' } },
                                    React.createElement("span", { className: classesIcon.icon }, KEYS_ICON[v._id]),
                                    !isAdmin && React.createElement("sup", { className: classesMain.sup }, v.count)),
                                !isAdmin && React.createElement("span", { style: { visibility: 'hidden' } }, v.count)))),
                        value: v._id,
                    };
                }),
                _b));
        }
    }
    var _onChangeCountry = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var existValue = (filter.fields || []).filter(function (e) { return e.name !== name; });
        if (value && value.length)
            existValue.push({
                name: name,
                value: value.map(function (e) { return e.code || e._id; }),
            });
        onChange({
            target: {
                name: 'fields',
                value: existValue,
            },
        });
    };
    var _onChangeDate = function (e) {
        var _a = e.target, name = _a.name, value = _a.value;
        var existValue = (filter.fields || []).filter(function (e) { return e.name !== name; });
        if (!value) {
            console.log('no value');
            return;
        }
        if (value && value[2] && value[2] === 'closed') {
            onChange({
                target: {
                    name: 'fields',
                    value: existValue.map(function (e) {
                        return (['pickupRange', 'deliveryRange', 'factualPickupRange', 'factualDeliveryRange'].includes(e.name) && __assign(__assign({}, e), { value: (e.value || []).map(function (e) { return toDateOnly(e); }) })) ||
                            e;
                    }),
                },
            });
            return;
        }
        if (!(value[0] && value[1])) {
            console.log('missing 2-nd date', value);
            return;
        }
        var formattedValue = value.map(function (date) { return toDateOnly(date); });
        existValue.push({
            name: name,
            value: formattedValue,
        });
        try {
            onChange({
                target: {
                    name: 'fields',
                    value: existValue.map(function (e) {
                        return (['pickupRange', 'deliveryRange', 'factualPickupRange', 'factualDeliveryRange'].includes(e.name) && __assign(__assign({}, e), { value: (e.value || []).map(function (e) { return toDateOnly(e); }) })) ||
                            e;
                    }),
                },
            });
        }
        catch (e) {
            console.log(e);
        }
    };
    var availableOptions = (STATUS_KEYS && Object.keys(STATUS_KEYS)) || [];
    var optionsStatus = (stats && stats.filter(function (e) { return availableOptions.includes(e._id); })) || [];
    var formKeyOptions = optionsStatus.map(function (_a) {
        var _id = _a._id, count = _a.count;
        return ({
            label: (React.createElement(Grid, { container: true, alignItems: 'center', style: { width: '100%' } },
                React.createElement("span", { className: classesIcon.icon, style: { marginRight: theme.spacing(1) } }, KEYS_ICON[_id]),
                React.createElement("span", { style: { position: 'relative', marginRight: theme.spacing(1) } }, "" + (STATUS_KEYS[_id] === 'unloaded'
                    ? langOfferForm.t('offer_un_loaded')
                    : langMenu.t("" + langMenuTitle + STATUS_KEYS[_id])),
                    !isAdmin && React.createElement("sup", { className: classesMain.sup }, count)))),
            value: _id,
        });
    });
    var startProps = { minDate: new Date(0) };
    var endProps = { minDate: new Date(0) };
    if (initialValues.endDate) {
        startProps.maxDate = new Date(initialValues.endDate);
    }
    if (initialValues.startDate) {
        endProps.minDate = new Date(initialValues.startDate);
    }
    var countryFilters = {
        filter: __assign(__assign({}, filter), { fields: (filter.fields || []).filter(function (e) {
                return ['all-open', 'viewAll', 'status', 'all-preparation', 'isTemplate'].includes(e.name);
            }) }),
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Formik, { initialValues: initialValues, onSubmit: function (e) { return e; }, key: JSON.stringify(filter) + loading }, function (formik) {
            var _a, _b;
            var _c, _d;
            var _companyProps = formikAutocompleteMultiOptionsProps(formik.values.company, function () { return 0; }, null, [], {
                chipProps: { className: clsx(classesChip.chipRoot, classesChip.chipRootActive) },
                chipStyleProps: { marginBottom: theme.spacing(0) },
            });
            var _userProps = formikAutocompleteMultiOptionsUsersProps(formik.values.manager, function () { return 0; }, {
                chipProps: { className: clsx(classesChip.chipRoot, classesChip.chipRootActive) },
                chipStyleProps: { marginBottom: theme.spacing(0) },
            });
            var warehouseListProps = formikAutocompleteMultiOptionsUsersProps(formik.values.warehouseList, function () { return 0; }, {
                chipProps: { className: clsx(classesChip.chipRoot, classesChip.chipRootActive) },
                chipStyleProps: { marginBottom: theme.spacing(0) },
            });
            var approvedCarrier = formikAutocompleteMultiOptionsUsersProps(formik.values.approvedCarrier, function () { return 0; }, {
                chipProps: { className: clsx(classesChip.chipRoot, classesChip.chipRootActive) },
                chipStyleProps: { marginBottom: theme.spacing(0) },
            });
            var _vehicleTypeProps = formikAutocompleteMultiOptionsUsersProps(formik.values.vehicleType, function () { return 0; }, {
                chipProps: { className: clsx(classesChip.chipRoot, classesChip.chipRootActive) },
                chipStyleProps: { marginBottom: theme.spacing(0) },
            });
            var _vehicleToExcludeTypeProps = formikAutocompleteMultiOptionsUsersProps(formik.values.vehicleTypeToExclude, function () { return 0; }, {
                chipProps: { className: clsx(classesChip.chipRoot, classesChip.chipRootActive) },
                chipStyleProps: { marginBottom: theme.spacing(0) },
            });
            var _userForwarderProps = formikAutocompleteMultiOptionsUsersProps(formik.values.forwarders, function () { return 0; }, {
                chipProps: { className: clsx(classesChip.chipRoot, classesChip.chipRootActive) },
                chipStyleProps: { marginBottom: theme.spacing(0) },
            });
            delete _userForwarderProps.autocompleteProps.freeSolo;
            delete _userProps.autocompleteProps.freeSolo;
            delete approvedCarrier.autocompleteProps.freeSolo;
            delete _vehicleTypeProps.autocompleteProps.freeSolo;
            delete _vehicleToExcludeTypeProps.autocompleteProps.freeSolo;
            delete _companyProps.autocompleteProps.freeSolo;
            delete warehouseListProps.autocompleteProps.freeSolo;
            var listFilters = __spread((options.filterInputes || []), [
                props.v3 && {
                    name: 'publishedAt',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: __assign(__assign({}, options.inputStyles), { width: 'initial' }) },
                        React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial', maxWidth: 166 } },
                            React.createElement(RangeDateFilter, { titleStyle: isSmall ? {} : { width: 'initial', maxWidth: 166 }, title: props.v3Label || langOrderForm.t('create_order_status_published'), name: 'publishedAt', value: formik.values.publishedAt, placeholder: props.v3LabelHelp || langOrderForm.t('create_order_status_published'), onChange: function (d) {
                                    publishedAtTemp = d;
                                }, onClose: function (value) {
                                    setTimeout(function () {
                                        _onChangeDate({ target: { name: 'publishedAt', value: publishedAtTemp || value } });
                                    });
                                }, maxDate: deliveryDateTemp && deliveryDateTemp[1] })))),
                },
                {
                    name: 'pickupRangeDelivery',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: __assign(__assign({}, options.inputStyles), { width: 'fit-content', height: '100%' }) },
                        React.createElement(Grid, { container: true, alignItems: 'flex-end', style: { width: 'initial' } },
                            React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial', maxWidth: 166 } },
                                React.createElement(RangeDateFilter, { titleStyle: isSmall ? {} : { width: 'initial', maxWidth: 166 }, title: langOfferForm.t('offer_from_label_date'), name: 'pickupRange', value: formik.values.pickupRange, placeholder: langOfferForm.t('offer_from_label_date'), onChange: function (d) {
                                        pickupDateTemp = d;
                                    }, onClose: function (value) {
                                        setTimeout(function () {
                                            _onChangeDate({ target: { name: 'pickupRange', value: pickupDateTemp || value } });
                                        });
                                    }, maxDate: deliveryDateTemp && deliveryDateTemp[1] })),
                            !isSmall ? (React.createElement(ArrowRightAltIcon, { style: { margin: theme.spacing(0, 1), color: theme.palette.common.white, height: 30 } })) : null,
                            React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial', maxWidth: 166 } },
                                React.createElement(RangeDateFilter, { titleStyle: isSmall ? {} : { width: 'initial', maxWidth: 166 }, title: langOrderForm.t('create_order_route_origin_to_date'), name: 'deliveryRange', value: formik.values.deliveryRange, placeholder: langOrderForm.t('create_order_route_origin_to_date'), onChange: function (d) {
                                        deliveryDateTemp = d;
                                    }, onClose: function (value) {
                                        setTimeout(function () {
                                            _onChangeDate({
                                                target: { name: 'deliveryRange', value: deliveryDateTemp || value },
                                            });
                                        });
                                    }, minDate: pickupDateTemp && pickupDateTemp[0] }))))),
                },
                {
                    name: 'factualPickupRangeDelivery',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: __assign(__assign({}, options.inputStyles), { width: 'fit-content', height: '100%' }) },
                        React.createElement(Grid, { container: true, alignItems: 'flex-end', style: { width: 'initial' } },
                            React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial', maxWidth: 166 } },
                                React.createElement(RangeDateFilter, { titleStyle: isSmall ? {} : { width: 'initial', maxWidth: 166 }, title: langOrderForm.t('order_form_actual_pickup_date'), name: 'factualPickupRange', value: formik.values.factualPickupRange, placeholder: langOrderForm.t('order_form_actual_pickup_date'), onChange: function (d) {
                                        factualPickupDateTemp = d;
                                    }, onClose: function (value) {
                                        setTimeout(function () {
                                            _onChangeDate({
                                                target: { name: 'factualPickupRange', value: factualPickupDateTemp || value },
                                            });
                                        });
                                    }, maxDate: factualDeliveryDateTemp && factualDeliveryDateTemp[1] })),
                            !isSmall ? (React.createElement(ArrowRightAltIcon, { style: { margin: theme.spacing(0, 1), color: theme.palette.common.white, height: 30 } })) : null,
                            React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial', maxWidth: 166 } },
                                React.createElement(RangeDateFilter, { titleStyle: isSmall ? {} : { width: 'initial', maxWidth: 166 }, title: langOrderForm.t('order_form_actual_delivery_date'), name: 'factualDeliveryRange', value: formik.values.factualDeliveryRange, placeholder: langOrderForm.t('order_form_actual_delivery_date'), onChange: function (d) {
                                        factualDeliveryDateTemp = d;
                                    }, onClose: function (value) {
                                        setTimeout(function () {
                                            _onChangeDate({
                                                target: { name: 'factualDeliveryRange', value: factualDeliveryDateTemp || value },
                                            });
                                        });
                                    }, minDate: factualPickupDateTemp && factualPickupDateTemp[0] }))))),
                },
                !props.orderAnalytics && {
                    name: 'countryFromTo',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: __assign(__assign(__assign({}, options.inputStyles), { width: 'fit-content', height: '100%' }), (isSmall && { width: '100%' })) },
                        React.createElement(Grid, { container: true, alignItems: 'flex-start', style: __assign({ width: 'initial' }, (isSmall && { width: '100%' })) },
                            React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial' } },
                                React.createElement(LocationCountryFilter, { multiple: true, v14: true, isAdmin: isAdmin, client: config.client, name: 'countryFrom', currentCompany: curCompany, variables: countryFilters, value: formik.values.countryFrom, title: langApp.t('app_from'), options: (Array.isArray(formik.values.countryFrom) && formik.values.countryFrom) || [], onChange: _onChangeCountry })),
                            !isSmall ? (React.createElement(ArrowRightAltIcon, { style: { margin: theme.spacing(3.1, 1, 0, 1), color: theme.palette.common.white, height: 30 } })) : null,
                            React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial' } },
                                React.createElement(LocationCountryFilter, { multiple: true, v14: true, isAdmin: isAdmin, client: config.client, name: 'countryTo', value: formik.values.countryTo, currentCompany: curCompany, variables: {
                                        filter: __assign(__assign({}, countryFilters.filter), { fields: __spread(countryFilters.filter.fields, [{ name: 'countryDir', value: 'delivery' }]) }),
                                    }, title: langApp.t('app_to'), options: (Array.isArray(formik.values.countryTo) && formik.values.countryTo) || [], onChange: _onChangeCountry }))))),
                },
                (isForwarder || isAdmin || props.v32) &&
                    !props.orderAnalytics && {
                    name: 'forwarders',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: __assign(__assign({}, options.inputStyles), { width: isSmall ? undefined : 'initial' }) },
                        React.createElement(Grid, { container: true, direction: 'column', style: { width: isSmall ? undefined : 'initial' } },
                            React.createElement(FormikAutocompleteAsync, __assign({ v14: true, v17: true, isClient: true, loadOnScroll: true, filterFields: ['firstName', 'lastName', 'email'] }, _userForwarderProps, { style: { minWidth: 130 }, variables: {
                                    filter: {
                                        fields: [
                                            { name: 'appType', value: [BaseUserAppTYpe.FORWARDER] },
                                            isForwarder && !props.v3 && !isAdmin && { name: 'env_forwarders', value: [_id] },
                                        ].filter(function (e) { return e; }),
                                    },
                                }, query: FetchOrderUsers, client: config.client, title: langApp.t('user_app_type_forwarder'), help: langForm.t('form_filter_help_order_forwarder'), placeholder: langApp.t('user_app_type_forwarder'), name: "forwarders", variant: "outlined", multiple: true, value: formik.values.forwarders, onChange: function (e) {
                                    var value = (filter.fields || []).filter(function (e) { return e.name !== 'forwarders'; });
                                    if (Array.isArray(e.target.value) && e.target.value.length) {
                                        value.push({
                                            name: 'forwarders',
                                            value: e.target.value,
                                        });
                                    }
                                    onChange({
                                        target: {
                                            name: 'fields',
                                            value: value,
                                        },
                                    });
                                } }))))),
                },
                (isForwarder || isAdmin || props.v32) &&
                    !props.orderAnalytics && {
                    name: 'company',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: __assign(__assign({}, options.inputStyles), { width: isSmall ? undefined : 'initial' }) },
                        React.createElement(Grid, { container: true, direction: 'column', style: { width: isSmall ? undefined : 'initial' } },
                            React.createElement(FormikAutocompleteAsync, __assign({ isClient: true, v14: true, v17: true, loadOnScroll: true, filterFields: ['name'] }, _companyProps, { getOptionLabel: function (option) {
                                    return ((option && [option.name, option.vatCode, option.companyCode].filter(function (e) { return e; }).join(',')) ||
                                        '-').toString();
                                }, style: { minWidth: 130 }, query: FetchCompaniesShort, variables: { filter: { fields: [{ name: 'type', value: BaseUserAppTYpe.CUSTOMER }] } }, client: config.client, title: langApp.t('company_label'), placeholder: langApp.t('company_label'), name: "company", variant: "outlined", multiple: true, value: formik.values.company, onChange: function (e) {
                                    var value = (filter.fields || []).filter(function (e) { return e.name !== 'company'; });
                                    if (Array.isArray(e.target.value) && e.target.value.length) {
                                        value.push({
                                            name: 'company',
                                            value: e.target.value,
                                        });
                                    }
                                    onChange({
                                        target: {
                                            name: 'fields',
                                            value: value,
                                        },
                                    });
                                } }))))),
                },
                (isCustomer || isForwarder || isAdmin) &&
                    !props.orderAnalytics && {
                    name: 'manager',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: isSmall ? { margin: theme.spacing(0) } : __assign(__assign({}, options.inputStyles), { width: 'initial' }) },
                        React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial' } },
                            React.createElement(FormikAutocompleteAsync, __assign({ isClient: true, loadOnScroll: true, v14: true, v17: true, v19: true, filterFields: ['firstName', 'lastName', 'email'] }, _userProps, { variables: {
                                    filter: {
                                        fields: [
                                            { name: 'appType', value: [BaseUserAppTYpe.CUSTOMER, BaseUserAppTYpe.FORWARDER] },
                                            isForwarder && !isAdmin && !props.v3 && { name: 'env_forwarders', value: [_id] },
                                            isCustomer && !isAdmin && !props.v3 && { name: 'company', value: curCompany._id },
                                        ].filter(function (e) { return e; }),
                                    },
                                }, style: { minWidth: 130 }, query: FetchOrderUsers, client: config.client, title: langApp.t('main_manager'), help: langForm.t('form_filter_help_order_manager'), name: "manager", variant: "outlined", multiple: true, value: formik.values.manager, onChange: function (e) {
                                    var value = (filter.fields || []).filter(function (e) { return e.name !== 'manager'; });
                                    if (Array.isArray(e.target.value) && e.target.value.length) {
                                        value.push({
                                            name: 'manager',
                                            value: e.target.value,
                                        });
                                    }
                                    onChange({
                                        target: {
                                            name: 'fields',
                                            value: value,
                                        },
                                    });
                                } }))))),
                }
            ], ((props.v3 && [
                {
                    name: 'vehicleType',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: isSmall ? { margin: theme.spacing(0) } : __assign(__assign({}, options.inputStyles), { width: 'initial' }) },
                        React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial' } },
                            React.createElement(FormikAutocompleteAsync, __assign({ isClient: true, loadOnScroll: true, v14: true, v17: true, v19: true, filterFields: ['name'] }, _vehicleTypeProps, { variables: {
                                    filter: { limit: 17, sortBy: 'orderIndex', sortDir: 'asc', fields: [] },
                                }, style: { minWidth: 130 }, query: FetchOrderVehicleType, client: config.client, title: langOrderForm.t('create_order_transport_vehicle_type_label'), name: "vehicleType", variant: "outlined", multiple: true, value: formik.values.manager, onChange: function (e) {
                                    var value = (filter.fields || []).filter(function (e) { return e.name !== 'vehicleType'; });
                                    if (Array.isArray(e.target.value) && e.target.value.length) {
                                        value.push({
                                            name: 'vehicleType',
                                            value: e.target.value,
                                        });
                                    }
                                    onChange({
                                        target: {
                                            name: 'fields',
                                            value: value,
                                        },
                                    });
                                } }))))),
                },
                {
                    name: 'vehicleTypeToExclude',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: isSmall ? { margin: theme.spacing(0) } : __assign(__assign({}, options.inputStyles), { width: 'initial' }) },
                        React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial' } },
                            React.createElement(FormikAutocompleteAsync, __assign({ isClient: true, loadOnScroll: true, v14: true, v17: true, v19: true, filterFields: ['name'] }, _vehicleToExcludeTypeProps, { variables: {
                                    filter: { limit: 17, sortBy: 'orderIndex', sortDir: 'asc', fields: [] },
                                }, style: { minWidth: 130 }, query: FetchOrderVehicleType, client: config.client, title: "Vehicle type to exclude", name: "vehicleTypeToExclude", variant: "outlined", multiple: true, value: formik.values.manager, onChange: function (e) {
                                    var value = (filter.fields || []).filter(function (e) { return e.name !== 'vehicleTypeToExclude'; });
                                    if (Array.isArray(e.target.value) && e.target.value.length) {
                                        value.push({
                                            name: 'vehicleTypeToExclude',
                                            value: e.target.value,
                                        });
                                    }
                                    onChange({
                                        target: {
                                            name: 'fields',
                                            value: value,
                                        },
                                    });
                                } }))))),
                },
            ]) ||
                []), [
                !hideStatus &&
                    (approvedQuotes || openOrders || isAdmin || props.orderAnalytics) && {
                    name: formKey,
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: isSmall ? { margin: theme.spacing(0) } : __assign(__assign({}, options.inputStyles), { width: 'initial' }) },
                        React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial', minWidth: 130 } },
                            React.createElement(FormikAutocomplete, { v14: true, v17: true, v19: true, onChageText: function () { return 0; }, style: { minWidth: 130 }, autocompleteProps: {
                                    disabled: (loading || !stats) && !props.orderAnalytics,
                                    className: clsx((_a = {},
                                        _a[classesChip.chipParentRootActive] = formik.values[formKey] && formik.values[formKey].length,
                                        _a)),
                                    multiple: true,
                                    value: (formik.values[formKey] || []).map(function (e) { return (__assign(__assign({}, e), { label: '' })); }),
                                    onChange: function (e) {
                                        var _a;
                                        var value = (filter.fields || []).filter(function (e) { return e.name !== formKey; });
                                        if (Array.isArray(e.target.value) && e.target.value.length) {
                                            value.push({
                                                name: formKey,
                                                value: e.target.value.map(function (e) { return e.value; }),
                                            });
                                        }
                                        else {
                                            value.push.apply(value, __spread((((_a = defaultFilter.fields) === null || _a === void 0 ? void 0 : _a.filter(function (e) { return e.name !== e.name; })) || []), ((emptyFilter === null || emptyFilter === void 0 ? void 0 : emptyFilter.fields) || [])));
                                        }
                                        onChange({
                                            target: {
                                                name: 'fields',
                                                value: value,
                                            },
                                        });
                                    },
                                    filterOptions: function (options, params) {
                                        var fields = (options.length && Object.keys(options[0])) || [];
                                        var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                        var res = filterText && fields.length
                                            ? options.filter(function (el) { var _a; return (_a = el === null || el === void 0 ? void 0 : el.value) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().match(filterText); })
                                            : options; //filterOptions(options, params);
                                        if (Array.isArray(formik.values[formKey])) {
                                            var values_1 = formik.values[formKey].map(function (e) { return e && (e.value || e); });
                                            return res.filter(function (e) { return e && !values_1.includes(e.value); });
                                        }
                                        return res;
                                    },
                                    getOptionLabel: function (option) { return option && option.label; },
                                    renderOption: function (option) { return option && option.label; },
                                    freeSolo: false,
                                    renderTags: function (value, getTagProps) {
                                        var re = (Array.isArray(value) && value) || [];
                                        return re.map(function (option, index) {
                                            return option &&
                                                formKeyOptions.find(function (e) { return option.value === e.value; }) && (React.createElement(BaseChip, __assign({ label: formKeyOptions.find(function (e) { return option.value === e.value; }).label }, getTagProps({ index: index }), { style: __assign({}, (re.length > 1 ? { marginRight: theme.spacing(0.5) } : {})), className: clsx(classesChip.chipRoot, classesChip.chipRootActive) })));
                                        });
                                    },
                                }, title: langForm.t('form_status'), help: langForm.t('form_status_help'), variant: "outlined", name: formKey, value: formik.values[formKey], options: formKeyOptions })))),
                },
                !hideStatus &&
                    (approvedQuotes || finished || isAdmin || props.orderAnalytics) && {
                    name: 'process',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: isSmall
                            ? { margin: theme.spacing(0) }
                            : __assign(__assign({}, options.inputStyles), { width: 'initial', minWidth: 90 }) },
                        React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial' } },
                            React.createElement(FormikAutocomplete, { v14: true, v17: true, v19: true, style: { minWidth: 130 }, onChageText: function () { return 0; }, autocompleteProps: {
                                    disabled: loading || (!props.v3 && !isAdmin && !stats) || props.disableProcess,
                                    multiple: true,
                                    value: formik.values.process,
                                    className: clsx((_b = {},
                                        _b[classesChip.chipParentRootActive] = formik.values.process && formik.values.process.length,
                                        _b)),
                                    onChange: function (e) {
                                        var value = (filter.fields || []).filter(function (e) { return e.name !== 'process'; });
                                        if (Array.isArray(e.target.value) && e.target.value.length) {
                                            value.push({
                                                name: 'process',
                                                value: e.target.value.map(function (e) { return e.value; }),
                                            });
                                        }
                                        onChange({
                                            target: {
                                                name: 'fields',
                                                value: value,
                                            },
                                        });
                                    },
                                    filterOptions: function (options, params) {
                                        var fields = (options.length && Object.keys(options[0])) || [];
                                        var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                        var res = filterText && fields.length
                                            ? options.filter(function (el) { var _a; return (_a = el === null || el === void 0 ? void 0 : el.value) === null || _a === void 0 ? void 0 : _a.toString().toLowerCase().match(filterText); })
                                            : options; //filterOptions(options, params);
                                        if (Array.isArray(formik.values.process)) {
                                            var values_2 = formik.values.process.map(function (e) { return e && (e.value || e); });
                                            return res.filter(function (e) { return e && !values_2.includes(e.value); });
                                        }
                                        return res;
                                    },
                                    getOptionLabel: function (option) { return option && option.label; },
                                    renderOption: function (option) { return option && option.label; },
                                    freeSolo: false,
                                    renderTags: function (value, getTagProps) {
                                        var re = (Array.isArray(value) && value) || [];
                                        return re.map(function (option, index) {
                                            return option && (React.createElement(BaseChip, __assign({ label: option.label }, getTagProps({ index: index }), { color: 'primary', style: __assign({}, (re.length > 1 ? { marginRight: theme.spacing(0.5) } : {})), className: clsx(classesChip.chipRoot, classesChip.chipRootActive) })));
                                        });
                                    },
                                }, title: langApp.t('main_process'), help: langApp.t('main_process_help'), variant: "outlined", name: 'process', value: formik.values.process, options: processes })))),
                },
                isCustomer &&
                    isPreparation && {
                    name: 'warehouseList',
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: isSmall
                            ? { margin: theme.spacing(0) }
                            : __assign(__assign({}, options.inputStyles), { width: 'initial', minWidth: 90 }) },
                        React.createElement(Grid, { container: true, direction: 'column', xs: isSmall ? 12 : undefined, style: isSmall ? {} : { width: 'initial' } },
                            React.createElement(FormikAutocompleteAsync, __assign({ isClient: true, loadOnScroll: true, client: config.client, ignoreInputChange: true, title: langApp.t("warehouse_ramp"), v14: true, v17: true, v19: true }, warehouseListProps, { variant: "outlined", name: 'warehouseList', multiple: true, style: { minWidth: 130 }, query: FetchWarehouses, variables: {
                                    filter: {
                                        sortBy: 'address',
                                        sortDir: 'desc',
                                        fields: [
                                            { name: 'all-ramps', value: 1 },
                                            { name: 'preparation-orders', value: 1 },
                                        ].filter(function (e) { return e; }),
                                    },
                                }, filterFields: ['name'], value: formik.values.warehouseList, onChange: function (e) {
                                    var value = (filter.fields || []).filter(function (e) { return e.name !== 'warehouseList'; });
                                    if (Array.isArray(e.target.value) && e.target.value.length) {
                                        value.push({
                                            name: 'warehouseList',
                                            value: e.target.value || [],
                                        });
                                    }
                                    onChange({
                                        target: {
                                            name: 'fields',
                                            value: value,
                                        },
                                    });
                                } }))))),
                }
            ], [
                {
                    name: 'viewAll',
                    isCheckbox: true,
                    label: (React.createElement(Grid, { container: true, alignItems: 'center' },
                        langForm.t('form_orders_view_all'),
                        React.createElement(HelpIconComponent, { light: true, title: langForm.t('form_orders_view_all_help') }))),
                    available: canViewAllOrdersTool,
                },
                {
                    name: 'useMyPreferences',
                    isCheckbox: true,
                    lang: 'form_input_name_order_preferences_use_filter',
                    available: openOrders && isCarrier && !isAdmin,
                },
                {
                    name: 'showDeletedPublished',
                    isCheckbox: true,
                    lang: 'form_show_deleted',
                    available: isAdmin,
                },
                {
                    name: 'hideInnerOrders',
                    isCheckbox: true,
                    lang: 'hide_inner_order',
                    available: (approvedQuotes || finished) && (isCustomer || isSECS || isForwarder || isAdmin),
                },
                {
                    name: 'hideFinishedPreparationOrders',
                    isCheckbox: true,
                    lang: 'hide_finished_prepare_order',
                    available: (approvedQuotes || openOrders || isPreparation) && (isCustomer || isSECS || isForwarder || isAdmin),
                },
                {
                    name: 'hideUnFinishedPreparationOrders',
                    isCheckbox: true,
                    lang: 'hide_un_finished_prepare_order',
                    available: (approvedQuotes || openOrders) && (isCustomer || isSECS || isForwarder || isAdmin),
                },
                {
                    name: 'hideDeliveredOrders',
                    isCheckbox: true,
                    available: approvedQuotes,
                },
                {
                    name: 'hidePlanned',
                    completeLang: langApp.t('hide_planned'),
                    isCheckbox: true,
                    available: openOrders && isCarrier,
                },
            ].map(function (_a) {
                var name = _a.name, lang = _a.lang, label = _a.label, available = _a.available, isCheckbox = _a.isCheckbox, completeLang = _a.completeLang;
                var body = (React.createElement(Grid, { container: true, item: !isNotOneRow, direction: 'column', style: { width: 'initial' } },
                    React.createElement(FormikCheckBoxField, { v14: true, label: label || (React.createElement(Grid, { container: true, alignItems: 'center', style: { minWidth: '100px' } }, completeLang || langForm.t(lang || name))), name: name, onChange: function (e) {
                            var value = (filter.fields || []).filter(function (e) { return e.name !== name; });
                            var _val = !formik.values[name];
                            value.push({
                                name: name,
                                value: _val,
                            });
                            onChange({
                                target: {
                                    name: 'fields',
                                    value: value,
                                },
                            });
                            if (name === 'viewAll') {
                                setTimeout(function () {
                                    props.store.accountStore.currentCompany.updateSettings({
                                        settings: __assign(__assign({}, settings), { isViewAllOrders: _val }),
                                    });
                                }, 2000);
                            }
                        }, value: formik.values[name] })));
                return (available && {
                    name: name,
                    isCheckbox: isCheckbox,
                    body: body,
                    content: (React.createElement(Grid, { item: true, container: true, alignItems: 'flex-start', style: __assign(__assign(__assign({}, options.inputStyles), { width: 'initial', marginTop: theme.spacing(3.1), marginBottom: theme.spacing(1) }), (isSmall && { marginRight: theme.spacing(2) })) }, body)),
                });
            })).filter(function (e) { return e; });
            var availableFilters = (((_c = options === null || options === void 0 ? void 0 : options.config) === null || _c === void 0 ? void 0 : _c.availableFilters) || []).map(function (e) { return e.name; });
            var simpleModeCheckBoxes = listFilters.filter(function (e) { return availableFilters.includes(e.name) && e.isCheckbox; });
            if (!showAll && simpleModeCheckBoxes.length) {
                listFilters = listFilters.filter(function (e) { return !simpleModeCheckBoxes.find(function (ee) { return ee.name == e.name; }); });
                listFilters.push({
                    name: simpleModeCheckBoxes[0].name,
                    content: (React.createElement(Grid, { item: true, container: true, direction: 'column', alignItems: 'flex-start', justify: "flex-start", className: clsx(classesMain.groupCheckBox), style: __assign(__assign({}, options.inputStyles), { marginBottom: -10, width: 'initial', minWidth: theme.spacing(21), flexGrow: 1, flexDirection: 'row' }) }, simpleModeCheckBoxes.map(function (e) { return e.content; }))),
                });
            }
            return (React.createElement(Form, { name: 'order-list-filters', id: 'order-list-filters', style: { width: '100%' } },
                React.createElement(BaseListFiltersWrapper, { isAdmin: isAdmin, style: __assign({ alignItems: 'flex-start' }, (((_d = formik.values.approvedCarrier) === null || _d === void 0 ? void 0 : _d.length) > 1 && { flexWrap: 'wrap' })), options: options, list: listFilters })));
        })));
};
export var OrderListFilters = inject('store')(observer(withBaseListStore(OrderFilters)));
