import React from 'react';
import { Grid, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        background: {
            overflow: 'auto',
            background: "linear-gradient(315deg, " + theme.palette.success.main + " 3%," + theme.palette.primary.main + " 38%, " + theme.palette.success.light + " 68%, " + theme.palette.primary.light + " 98%)",
            // animation: '$gradient 15s ease infinite',
            // backgroundSize: '400% 400%',
            // backgroundAttachment: 'fixed',
            zIndex: 3,
        },
        '@keyframes gradient': {
            '0%': {
                backgroundPosition: '0% 0%',
            },
            '50%': {
                backgroundPosition: '100% 100%',
            },
            '100%': {
                backgroundPosition: '0% 0%',
            },
        },
        wave: {
            background: 'rgb(255 255 255 / 25%)',
            borderRadius: '1000% 1000% 0 0',
            position: 'fixed',
            width: '200%',
            height: '12em',
            // animation: '$wave 10s -3s linear infinite',
            // transform: 'translate3d(0, 0, 0)',
            opacity: 0.8,
            bottom: 0,
            left: 0,
            zIndex: 2,
        },
        secondWave: {
            bottom: '-1.25em',
            // animation: '$wave 18s linear reverse infinite',
            opacity: 0.8,
        },
        thirdWave: {
            bottom: '-2.5em',
            // animation: '$wave 20s -1s reverse infinite',
            opacity: 0.9,
        },
    });
});
var IZIBackgroundContainer = function (props) {
    var classes = useStyles();
    return (React.createElement(Grid, { "dta-id": 'izi-background-container', container: true, direction: 'column', alignItems: 'center', className: classes.background, style: { justifyContent: 'center', position: 'absolute', height: '100%', width: '100%', top: 0, left: 0 } },
        React.createElement(Grid, { className: classes.wave }),
        React.createElement(Grid, { className: clsx(classes.wave, classes.secondWave) }),
        React.createElement(Grid, { className: clsx(classes.wave, classes.thirdWave) }),
        props.children));
};
export default IZIBackgroundContainer;
