var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import { Grid } from '@material-ui/core';
import { FormikAutocomplete, FormikTextField } from '../../Formik';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../../i18n';
import * as formikA from 'formik';
import { AddFormButton } from '../../AddFormButton';
import { theme } from '../../../theme';
import * as Yup from 'yup';
import { TextOverflow } from '../../TextOverflow';
import BaseTooltip from '../../BaseTooltip';
import { DeleteDialog } from '../../Dialog';
import Checkbox from '@material-ui/core/Checkbox';
import { useIconStyles } from '../../../styles';
import BaseChip from '../../BaseChip';
import * as material from '@material-ui/core';
import { allOptions } from './utils';
import { checkDirtyPhoneNumber, cleanPhoneNumber, formatPhoneNumber } from '../../../utils1';
var FieldArray = formikA.FieldArray;
var Form = formikA.Form, Formik = formikA.Formik;
export var defaultLocationMemberItem = function (isAdditional, opt) {
    if (opt === void 0) { opt = {}; }
    return ({
        email: (opt === null || opt === void 0 ? void 0 : opt.email) || '',
        phone: '',
        name: '',
        notify: isAdditional ? [] : allOptions.map(function (e) { return e.value; }),
    });
};
export default React.forwardRef(function (_a, ref) {
    var _b;
    var lang_key = _a.lang_key, onChange = _a.onChange, isAdditional = _a.isAdditional, props = __rest(_a, ["lang_key", "onChange", "isAdditional"]);
    var langForm = useTranslation(KEYS.FORM);
    var langApp = useTranslation(KEYS.APP);
    var langMenu = useTranslation(KEYS.MENU);
    var langSettings = useTranslation(KEYS.SETTINGS);
    var classesIcons = useIconStyles();
    var _c = __read(React.useState(JSON.parse(JSON.stringify({
        members: ((props.defaultValue
            ? props.defaultValue.map(function (e) { return (__assign(__assign({}, e), { phone: formatPhoneNumber(e.phone) })); })
            : isAdditional
                ? []
                : [defaultLocationMemberItem(isAdditional, { email: ((_b = props === null || props === void 0 ? void 0 : props.parent) === null || _b === void 0 ? void 0 : _b.emailV1) || null })]) || []).map(function (e) { return (__assign(__assign({}, e), { notify: e.notify || allOptions.map(function (e) { return e.value; }) })); }),
    }))), 1), defaultValue = _c[0];
    React.useEffect(function () {
        if (props.onCreate)
            props.onCreate();
    }, []);
    var onSubmit = function (values, actions) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            // actions.resetForm();
            return [2 /*return*/, {
                    members: (values.members || []).map(function (_a) {
                        var name = _a.name, email = _a.email, phone = _a.phone, notify = _a.notify;
                        return ({
                            name: name,
                            email: email,
                            phone: cleanPhoneNumber(phone),
                            notify: notify,
                        });
                    }),
                }];
        });
    }); };
    var isSmall = material.useMediaQuery(theme.breakpoints.down('sm'));
    var options = allOptions.map(function (e) { return (__assign(__assign({}, e), { icon: React.createElement("div", { className: classesIcons.iconMD }, e.icon), text: langSettings.t("settings_" + e.value), label: function (selected) { return (React.createElement(Grid, { container: true, alignItems: 'center' },
            React.createElement(Checkbox, { checked: selected.includes(e.value), style: { paddingLeft: 0 } }),
            React.createElement(BaseTooltip, { title: langSettings.t("settings_" + e.value + "_help") },
                React.createElement("div", { className: classesIcons.icon }, e.icon)),
            React.createElement(TextOverflow, { style: { marginLeft: theme.spacing(0.5), maxWidth: 'calc(100% - 40px)' } }, langSettings.t("settings_" + e.value)))); } })); });
    return (React.createElement(Grid, { container: true },
        React.createElement(Formik, { initialValues: defaultValue, onSubmit: onSubmit, innerRef: ref, validationSchema: Yup.object().shape({
                members: Yup.array()
                    .typeError(langForm.t('form_input_validate_required'))
                    .of(Yup.object().shape({
                    email: Yup.string()
                        .typeError(langForm.t('form_input_validate_required'))
                        .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                        .email(langForm.t('form_input_validate_invalid_email'))
                        .required(langForm.t('form_input_validate_required')),
                    name: Yup.string()
                        .typeError(langForm.t('form_input_validate_required'))
                        .max(250, langForm.t('form_input_validate_max_char').replace(/_X_/, '250'))
                        .required(langForm.t('form_input_validate_required')),
                    phone: Yup.string()
                        .typeError(langForm.t('form_input_validate_required'))
                        .max(50, langForm.t('form_input_validate_max_char').replace(/_X_/, '50'))
                        .test('phone', langForm.t('form_input_validate_invalid_phone'), checkDirtyPhoneNumber)
                        .required(langForm.t('form_input_validate_required')),
                }))
                    .max(10, langForm
                    .t('form_input_validate_field_must_have_no_more')
                    .replace(/_X_/, '10')
                    .replace(/_XX_/, langForm.t('form_input_name_email').toLowerCase())),
            }) }, function (_a) {
            var values = _a.values, handleChange = _a.handleChange;
            var onFieldChangeHandler = function (e) {
                if (e.target.name.toString().match('.notify')) {
                    handleChange({ target: { name: e.target.name, value: (e.target.value || []).map(function (e) { return e.value; }) } });
                }
                else {
                    handleChange(e);
                }
                if (e.target.name.toString().match('.email')) {
                    if (props.validateEmails) {
                        var indexToSkip_1 = parseInt(e.target.name.split('.')[1]);
                        var emails = values.members
                            .map(function (value) { return value.email; })
                            .filter(function (email, index) { return index !== indexToSkip_1; });
                        emails.push(e.target.value);
                        props.validateEmails(false, emails);
                    }
                }
            };
            var onBlur = function (e) {
                if (onChange)
                    onChange();
            };
            return (React.createElement(React.Fragment, null,
                React.createElement(Form, { style: { width: '100%' }, id: "form-order-create-location-members" + ((props.suffix && "-" + props.suffix) || '') },
                    React.createElement(FieldArray, { name: "members" }, function (_a) {
                        var insert = _a.insert, remove = _a.remove, push = _a.push;
                        return (React.createElement(React.Fragment, null,
                            React.createElement(Grid, { container: true, alignItems: "center", justify: 'space-between', style: { marginBottom: theme.spacing(1) } },
                                React.createElement(Grid, { item: true, xs: 12, md: 6 }, props.title || null),
                                React.createElement(Grid, { item: true, xs: 12, md: 6, container: true, style: { justifyContent: 'flex-end' } }, values.members.length < 10 ? (React.createElement(Grid, { container: true, item: true, justify: 'flex-start', style: { width: 'fit-content' } },
                                    React.createElement(AddFormButton, { style: { marginLeft: -theme.spacing(1) }, title: "" + langForm.t(isAdditional
                                            ? 'form_btn_add_location_member_additional'
                                            : 'form_btn_add_location_member'), onClick: function () { return push(defaultLocationMemberItem()); } }))) : null)),
                            React.createElement(Grid, { container: true, style: { marginBottom: theme.spacing(3) } }, values.members.map(function (user, index) {
                                return (React.createElement(Grid, { container: true, direction: "row", key: index, "data-id": "row-item-" + index + "-" + (user._id || '') },
                                    React.createElement(Grid, { container: true, spacing: 2 },
                                        React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(3) } },
                                            React.createElement(FormikTextField, { required: true, title: langApp.t("app_full_name"), variant: "outlined", name: "members." + index + ".name", value: values.members[index].name, onChange: onFieldChangeHandler, onBlur: onBlur })),
                                        React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(3) } },
                                            React.createElement(FormikTextField, { required: true, title: langForm.t("form_input_name_email"), variant: "outlined", name: "members." + index + ".email", value: values.members[index].email, onChange: onFieldChangeHandler, onBlur: onBlur })),
                                        React.createElement(Grid, { item: true, xs: 12, sm: 3, style: { marginBottom: theme.spacing(3) } },
                                            React.createElement(FormikTextField, { required: true, title: langForm.t("form_input_name_phone"), variant: "outlined", name: "members." + index + ".phone", value: values.members[index].phone, onChange: onFieldChangeHandler, onBlur: onBlur })),
                                        React.createElement(Grid, { item: true, xs: 12, sm: 3, style: __assign(__assign({}, ((values.members.length > 1 || isAdditional || !isSmall) && {
                                                paddingRight: 30,
                                            })), { position: 'relative', marginBottom: theme.spacing(3) }) },
                                            React.createElement(FormikAutocomplete, { v17: true, v27: true, disableCloseOnSelect: true, onChageText: function () { return 0; }, autocompleteProps: {
                                                    multiple: true,
                                                    disableCloseOnSelect: true,
                                                    value: (values.members[index].notify || []).map(function (e) {
                                                        return typeof e === 'string' ? options.find(function (o) { return o.value === e; }) : e;
                                                    }),
                                                    onChange: function (e, value) {
                                                        onFieldChangeHandler(e);
                                                        setTimeout(function () {
                                                            onBlur(e);
                                                        }, 500);
                                                    },
                                                    filterOptions: function (options, params) {
                                                        var fields = (options.length && Object.keys(options[0])) || [];
                                                        var filterText = params.inputValue && params.inputValue.toString().toLowerCase();
                                                        return filterText && fields.length
                                                            ? options.filter(function (el) { var _a; return (_a = el === null || el === void 0 ? void 0 : el.text) === null || _a === void 0 ? void 0 : _a.toLowerCase().match(filterText); })
                                                            : options;
                                                    },
                                                    getOptionLabel: function (option) {
                                                        return option && option.label(values.members[index].notify);
                                                    },
                                                    renderOption: function (option) {
                                                        return option && option.label(values.members[index].notify);
                                                    },
                                                    freeSolo: false,
                                                    renderTags: function (value, getTagProps) {
                                                        var re = ((Array.isArray(value) && value) || []).map(function (e) {
                                                            return typeof e === 'string' ? options.find(function (o) { return o.value === e; }) : e;
                                                        });
                                                        return (React.createElement(Grid, { container: true, wrap: "nowrap", style: { width: 'calc(100% - 40px)' }, alignItems: 'center' },
                                                            re
                                                                .filter(function (e, index) { return index < 1; })
                                                                .map(function (option, index) {
                                                                return option && React.createElement(BaseChip, __assign({ label: option.text }, getTagProps({ index: index })));
                                                            }),
                                                            re.length > 1 && (React.createElement(BaseTooltip, { title: re
                                                                    .filter(function (e, i) { return i > 0; })
                                                                    .map(function (e) { return e === null || e === void 0 ? void 0 : e.text; })
                                                                    .join(', ') },
                                                                React.createElement(BaseChip, { style: {
                                                                        marginLeft: theme.spacing(0.5),
                                                                    }, label: re.length - 1 + "+" })))));
                                                    },
                                                }, title: langMenu.t('menu_header_notifications'), variant: "outlined", name: "members." + index + ".notify", value: values.members[index].notify, options: options }),
                                            ((values.members.length > 1 || isAdditional) && (React.createElement("div", { style: { position: 'absolute', right: -4, top: 9 } },
                                                React.createElement(DeleteDialog, { onOk: function () {
                                                        if (onChange)
                                                            onChange();
                                                        remove(index);
                                                        if (props.validateEmails) {
                                                            var emails = values.members
                                                                .map(function (value) { return value.email; })
                                                                .filter(function (email, i) { return i !== index; });
                                                            props.validateEmails(false, emails);
                                                        }
                                                    }, v1: true, v4: true })))) ||
                                                null))));
                            }))));
                    }))));
        })));
});
