var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { KEYS } from '../../i18n';
import { getSnapshot } from 'mobx-state-tree';
import { onPreventEvent } from '../Dialog';
import { toast } from 'react-toastify';
import { Upload } from '../../models/Upload';
import { CircularIndeterminate } from '../CircularIndeterminate';
import { inject } from 'mobx-react';
import { uploadEvent } from '../../api/apolloClient';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            borderRadius: 4,
            border: "4px dashed " + theme.palette.grey[300],
            minHeight: 240,
            backgroundColor: theme.palette.common.white,
        },
        rootLessMinHeigh: {
            minHeight: 152,
        },
        rootLessMinHeighV1: {
            minHeight: 40,
        },
        rootLessMinHeighV2: {
            minHeight: 82,
        },
        over: {
            backgroundColor: theme.palette.grey[100],
        },
        disabled: {
            opacity: 0.7,
            pointerEvents: 'none',
        },
    });
});
export default inject('store')(function (_a) {
    var _b;
    var config = _a.config, children = _a.children, onChange = _a.onChange, noUpload = _a.noUpload, accountStore = _a.store.accountStore, props = __rest(_a, ["config", "children", "onChange", "noUpload", "store"]);
    var className = useStyles();
    var _c = __read(React.useState(false), 2), isOver = _c[0], setIsOver = _c[1];
    var _d = __read(React.useState(false), 2), loading = _d[0], setLoading = _d[1];
    var disabled = props.disabled;
    var langOfferForm = useTranslation(KEYS.OFFER_FORM);
    var onDragLeave = function () {
        if (disabled)
            return;
        setIsOver(false);
    };
    var onDragEnter = function (ev) {
        // setIsOver(true);
    };
    var onDragOver = function (ev) {
        if (disabled)
            return;
        if (!props.v3)
            onPreventEvent(ev);
        setIsOver(true);
    };
    var onDrop = function (ev) {
        var _a, _b, _c, _d;
        if (disabled)
            return;
        onPreventEvent(ev);
        if ((_a = ev === null || ev === void 0 ? void 0 : ev.dataTransfer) === null || _a === void 0 ? void 0 : _a.items) {
            var files = [];
            // Use DataTransferItemList interface to access the file(s)
            for (var i = 0; i < ((_c = (_b = ev.dataTransfer) === null || _b === void 0 ? void 0 : _b.items) === null || _c === void 0 ? void 0 : _c.length); i++) {
                // If dropped items aren't files, reject them
                if (ev.dataTransfer.items[i].kind === 'file') {
                    var file = ev.dataTransfer.items[i].getAsFile();
                    files.push(file);
                }
            }
            processFiles(files);
        }
        else {
            processFiles((_d = ev.dataTransfer) === null || _d === void 0 ? void 0 : _d.files);
        }
    };
    var processFiles = function (_files) { return __awaiter(void 0, void 0, void 0, function () {
        var files, types_1, newFiles, _loop_1, i, e_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    files = [].filter.call(_files, function (file) { return file.size <= 1024 * 1024 * 50; });
                    if ((_a = props.InputProps) === null || _a === void 0 ? void 0 : _a.accept) {
                        types_1 = Array.isArray((_b = props.InputProps) === null || _b === void 0 ? void 0 : _b.accept)
                            ? props.InputProps.accept.map(function (e) { return e.toLowerCase(); })
                            : (props.InputProps.accept && [props.InputProps.accept.toLowerCase()]) || [];
                        files = files.filter(function (e) {
                            return types_1.find(function (type) { return (e.type || '').toLowerCase().match(type); }) ||
                                types_1.find(function (type) { return (e.mimeType || '').toLowerCase().match(type); }) ||
                                types_1.find(function (type) { return type.toLowerCase().match(e.type || ''); }) ||
                                types_1.find(function (type) { return type.toLowerCase().match(e.mimeType || ''); });
                        });
                    }
                    if (!(files === null || files === void 0 ? void 0 : files.length))
                        return [2 /*return*/, toast.error(langOfferForm.t('offer_form_document_validate'))];
                    newFiles = [];
                    _d.label = 1;
                case 1:
                    _d.trys.push([1, 6, 7, 8]);
                    setLoading(true);
                    _loop_1 = function (i) {
                        var UploadProps, input, newFile;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    UploadProps = props.UploadProps || getSnapshot(Upload.create({}));
                                    input = Object.assign.apply(Object, __spread([{
                                            file: files[i],
                                        }, ['folder', 'name', 'parent', 'parent_collection_name', '_id']
                                            .filter(function (e) { return UploadProps && UploadProps[e]; })
                                            .map(function (e) {
                                            var _a;
                                            return (_a = {}, _a[e] = UploadProps[e], _a);
                                        })
                                            .reduce(function (a, b) { return (__assign(__assign({}, a), b)); })], ((((_c = UploadProps.type) === null || _c === void 0 ? void 0 : _c._id) && { type: UploadProps.type._id }) || {})));
                                    if (!noUpload) return [3 /*break*/, 1];
                                    newFiles.push(__assign(__assign({}, input), { _id: Date.now() + "-" + i, size: input.file.size, name: input.file.name, file: input.file, path: URL.createObjectURL(input.file), pathBlob: URL.createObjectURL(input.file), createdBy: { _id: accountStore.currentUser._id } }));
                                    return [3 /*break*/, 3];
                                case 1: return [4 /*yield*/, uploadEvent(input, function () { return 0; })];
                                case 2:
                                    newFile = _a.sent();
                                    // let res: any =
                                    //   config &&
                                    //   config.client &&
                                    //   (await config.client.mutate({
                                    //     mutation: CreateUpload,
                                    //     variables: {
                                    //       input,
                                    //     },
                                    //   }));
                                    newFiles.push(newFile);
                                    _a.label = 3;
                                case 3: return [2 /*return*/];
                            }
                        });
                    };
                    i = 0;
                    _d.label = 2;
                case 2:
                    if (!(i < files.length)) return [3 /*break*/, 5];
                    return [5 /*yield**/, _loop_1(i)];
                case 3:
                    _d.sent();
                    _d.label = 4;
                case 4:
                    i++;
                    return [3 /*break*/, 2];
                case 5:
                    onChange && onChange(newFiles);
                    return [3 /*break*/, 8];
                case 6:
                    e_1 = _d.sent();
                    console.log(e_1);
                    toast.error(e_1.message);
                    return [3 /*break*/, 8];
                case 7:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(Grid, { container: true, direction: 'column', alignItems: 'center', justify: 'center', className: clsx(className.root, (_b = {},
            _b[className.over] = isOver,
            _b[className.disabled] = loading,
            _b[className.rootLessMinHeigh] = props.singleFile,
            _b[className.rootLessMinHeighV1] = props.singleFileV1,
            _b[className.rootLessMinHeighV2] = props.v3,
            _b)), onDrop: onDrop, onDragOver: onDragOver, onDragEnter: onDragEnter, onDragLeave: onDragLeave },
        children,
        (loading && React.createElement(CircularIndeterminate, null)) || null));
});
