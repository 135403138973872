var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import React from 'react';
import clsx from 'clsx';
import { createStyles, Grid } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { toast } from 'react-toastify';
import { TextOverflow } from '../TextOverflow';
import { humanFileSize } from '../../utils';
import { DeleteDialog, FilePreview } from '../Dialog';
import { DeleteUpload, UpdateUpload } from '../../graphql';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Switch from '@material-ui/core/Switch/Switch';
import { useTranslation } from 'react-i18next';
import { i18n, KEYS } from '../../i18n';
import BaseTooltip from '../BaseTooltip';
import { FormikIconCheckboxField } from '../Formik/FormikIconCheckboxField';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Form, Formik } from 'formik';
import DownLoadDocument from '../Dialog/DownLoadFile';
var useStyles = makeStyles(function (theme) {
    return createStyles({
        root: {
            border: "2px solid " + theme.palette.success.light,
            borderRadius: 4,
            marginBottom: theme.spacing(1),
        },
        disabled: { opacity: 0.7, pointEvents: 'none' },
    });
});
export default (function (_a) {
    var file = _a.file, config = _a.config, onDelete = _a.onDelete, onUpdate = _a.onUpdate, props = __rest(_a, ["file", "config", "onDelete", "onUpdate"]);
    var _b = __read(React.useState(file), 2), item = _b[0], setItem = _b[1];
    var _c = __read(React.useState(false), 2), loading = _c[0], setLoading = _c[1];
    var langForm = useTranslation(KEYS.FORM);
    var className = useStyles();
    var theme = useTheme();
    var onDeleteItem = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, _b, e_1;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 7, 8, 9]);
                    if (!!item.pathBlob) return [3 /*break*/, 6];
                    setLoading(true);
                    if (!(props.parent_collection_name === 'quotes')) return [3 /*break*/, 3];
                    _a = config.client;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: UpdateUpload,
                            variables: {
                                input: {
                                    _id: file._id,
                                    path: '',
                                },
                            },
                        })];
                case 1:
                    _a = (_c.sent());
                    _c.label = 2;
                case 2:
                    _a;
                    return [3 /*break*/, 6];
                case 3:
                    _b = config.client;
                    if (!_b) return [3 /*break*/, 5];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: DeleteUpload,
                            variables: {
                                _id: file._id,
                            },
                        })];
                case 4:
                    _b = (_c.sent());
                    _c.label = 5;
                case 5:
                    _b;
                    _c.label = 6;
                case 6:
                    onDelete(file);
                    return [3 /*break*/, 9];
                case 7:
                    e_1 = _c.sent();
                    toast.error(e_1.message);
                    return [3 /*break*/, 9];
                case 8:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var _onUpdate = function (e) {
        var newItem = __assign(__assign({}, item), { needSign: !item.needSign });
        setItem(newItem);
        onUpdate(newItem);
    };
    var handleChange = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var input, _a, e_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 3, 4, 5]);
                    setLoading(true);
                    input = {
                        _id: file._id,
                        isAssigned: e.target.checked,
                    };
                    _a = config.client;
                    if (!_a) return [3 /*break*/, 2];
                    return [4 /*yield*/, config.client.mutate({
                            mutation: UpdateUpload,
                            variables: {
                                input: input,
                            },
                        })];
                case 1:
                    _a = (_b.sent());
                    _b.label = 2;
                case 2:
                    _a;
                    setItem(__assign(__assign({}, item), input));
                    toast.success(i18n
                        .getResource(i18n.language, KEYS.TOAST, 'toast_items_updated')
                        .replace(/_X_/, i18n.getResource(i18n.language, KEYS.APP, 'main_document')));
                    return [3 /*break*/, 5];
                case 3:
                    e_2 = _b.sent();
                    toast.error(e_2.message);
                    return [3 /*break*/, 5];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    if (props.singleFileV1) {
        return (React.createElement(Grid, { container: true, alignItems: 'center' },
            React.createElement(DownLoadDocument, { v1: true, item: item, config: config, style: { marginRight: theme.spacing(1) } }),
            React.createElement(TextOverflow, { style: { maxWidth: 'calc(100% - 40px)' } }, file.name)));
    }
    return (React.createElement(Formik, { initialValues: item, onSubmit: function (e) { return e; } }, function (formik) {
        var _a;
        return (React.createElement(Form, null,
            React.createElement(Grid, { container: true, className: clsx(className.root, (_a = {}, _a[className.disabled] = loading, _a)), justify: 'space-between', alignItems: 'center' },
                React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial', maxWidth: 'calc(100% - 180px)' } },
                    React.createElement(DescriptionIcon, { style: { width: 32, height: 32 } }),
                    React.createElement(Grid, { container: true, direction: 'column', style: { width: 'initial', maxWidth: 'calc(100% - 40px)' } },
                        React.createElement(TextOverflow, null, file.name),
                        React.createElement(TextOverflow, { variant: 'caption' }, humanFileSize(file.size)))),
                React.createElement(Grid, { container: true, alignItems: 'center', style: { width: 'initial' } },
                    React.createElement(FilePreview, { item: file, title: "View " + (file.name || 'file'), config: config }),
                    file.isCanBeSigned ? (React.createElement(FormikIconCheckboxField, { icon: React.createElement(AssignmentTurnedInIcon, null), checkedIcon: React.createElement(AssignmentTurnedInIcon, { style: { color: theme.palette.primary.main } }), title: langForm.t('form_allow_sign_document_helper'), name: "needSign", onChange: _onUpdate, value: formik.values.needSign })) : null,
                    (onDelete && (React.createElement(React.Fragment, null,
                        (item.parent_collection_name === 'invoices' && (React.createElement(FormControlLabel, { style: { margin: 0 }, control: React.createElement(BaseTooltip, { title: (!item.isAssigned
                                    ? langForm.t('form_invoice_attachment_assign')
                                    : langForm.t('form_invoice_attachment_un_assign')) },
                                React.createElement(Switch, { color: 'primary', style: { margin: 0 }, checked: item.isAssigned, onChange: handleChange, name: "isAssigned" })), label: null }))) ||
                            null,
                        React.createElement(DeleteDialog, { withoutWrapper: true, value: file.name || 'file', onOk: onDeleteItem })))) ||
                        null))));
    }));
});
